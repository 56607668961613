import Button from 'components/Button/Button.component'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import './DeckDetail.component'
import IconNft from '../../../assets/icons/nft.png'
import getConfig from '../../../config.service'
import CardService from '../../../services/card.service'

const config = getConfig()

const DeckDetailCard = ({
  gender, faction, name, type, art, empty, onClick, onRemove, isNft, id, onViewCard,
}: any) => {
  const getEmptyContent = () => {
    if (type === 'hq') {
      return (
            <div className='hq_empty-container'>
                <div>
                    SELECT HQ
                </div>

                <div className={'icon-type icon-type-hq'} />

            </div>
      )
    }

    return <div className='empty-card-icons'>
            <div className={'icon-type icon-type-tech'} />
            <div className={'icon-type icon-type-char'} />
        </div>
  }

  let bgImg:string = `${art}?tx=g_north,x_20,y_150,c_crop,h_200,w_600,f_auto,q_20`
  if (config.CARD_SPRITE) bgImg = CardService.getImageUrl(CardService.getSN({ Art: art } as any))

  const style:any = { backgroundImage: `url(${bgImg})` }
  if (config.CARD_SPRITE) {
    style.backgroundSize = 'cover'
    style.backgroundPosition = 'center 15%'
  }

  return (<div className={`deck-detail-card-shadow ${empty ? 'empty' : ''}`} onClick={onClick}>
        <div className={`deck-detail-card-background ${empty ? 'empty' : ''}`} style={!empty ? style : {}}>
            <div className={`deck-detail-card ${!empty ? faction : `empty ${type === 'hq' ? type : ''}`}`}>

                {empty ? getEmptyContent()
                  : <>
                        <div className={`deck-detail-card__icon-gender ${gender === 'Non Binary' ? 'nb' : ''}`}>

                            {gender === 'Female' ? '♀ ' : ''}
                            {gender === 'Male' ? '♂ ' : ''}
                            {gender === 'Non Binary' ? '☿ ' : ''}
                        </div>
                        {name}
                        <div className={`icon-faction icon-faction-${faction}`} />
                        <div className={`icon-type icon-type-${type}`} />
                        {isNft && (<div className='is-nft'><img src={IconNft} alt={'NFT'}/></div>)}

                  </>
                }

            </div>
        </div>
        <div className='deck-detail-card__close'>

            <Button variant={'danger'} size={'small'} right onClick={() => onRemove(id)}><b>
                x
            </b>
            </Button>
        </div>
        <div className='deck-detail-card__eye'>
            <Button variant={'primary'} size={'small'} right onClick={() => onViewCard(id)}><b>
                <FontAwesomeIcon
                    icon={faEye as IconProp}
                />
            </b>
            </Button>
        </div>
    </div>
  )
}

export default DeckDetailCard
