import './referral.component.scss'
import { useRecoilValue } from 'recoil'
import { useEffect, useState } from 'react'
import { Grid } from 'gridjs-react'
import { Link } from 'react-router-dom'
import ButtonTwitter from '../ButtonTwitter/buttonTwitter.component'
import ReferralService from '../../services/referral.service'
import { connectedWallet } from '../../selectors/selectors' //eslint-disable-line
import getConfig from '../../config.service'

export default function ReferralComponent() {
  const [referralLink, setReferralLink] = useState('')
  const [referrals, setReferrals] = useState([])
  const user = useRecoilValue(connectedWallet)

  const getReferralLink = async () => {
    if (!user?.address) return
    const data = await ReferralService.getReferralCode(user?.address)
    const config = getConfig()
    setReferralLink(`${config.hostURL}/?referral=${data.code}`)
    const referralsData = data.referrals.map(
      (referral: any) =>  { return { wallet: referral.referral_wallet, date: formatDate(referral.referral_date) } } //eslint-disable-line
    )
    setReferrals(referralsData)
  }

  const formatDate = (date: string) => {
    const dateArray = date.split('T')[0].split('-')
    return `${dateArray[0]}/${dateArray[1]}/${dateArray[2]}`
  }

  useEffect(() => {
    getReferralLink().then(() => {
    })
  }, [])

  const copyReferralLink = async () => {
    await navigator.clipboard.writeText(referralLink)
  }

  return (
    <div className='referral-component'>
      <div className='referral-component__link'>
        {referralLink}
      </div>
      <div className='referral-copy-link'>
        <button className="btn btn-medium btn-primary" onClick={copyReferralLink}>
          copy to clipboard
        </button>
      </div>
      <div className='referral-twitter-share'>
        <div className='referral-twitter'>
          <ButtonTwitter tweet={`Come to play Deviants Factions! The best %23NFT %23PlayAndEarn Trading Card Game based on real world events ${referralLink}` //eslint-disable-line
          }/>
        </div>
      </div>
      <div>
        <p className='disclaimer'>By Copying or Sharing this URL you are accepting <Link className='link-color' to='/terms-and-conditions#Referrals'><strong>Terms & conditions</strong></Link> of  Referrals Program
        </p>
      </div>
      {referrals.length === 0 && (
        <div className='referrals-table'>
          <h4>You don&apos;t have any referrals</h4>
        </div>
      )}
      {referrals.length > 0 && (
        <div>
          <div className='referrals-table'>
            <h3> Your referrals list </h3>
          </div>
          <div>
            <Grid
              data={referrals}
              columns={['wallet', 'date']}
              style={{
                td: {
                  padding: '5px',
                  textAlign: 'center',
                  color: '#ffffff',
                  fontSize: '1.1em',
                  background: 'rgba(255, 84, 0, 0.1)',
                  boxShadow: '0 0 10px rgba(255, 84, 0, .4) inset',
                  margin: '5px 10px',
                  border: '3px solid #813008',
                  fontFamily: 'Rajdhani',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  justifyContent: 'space-between',
                },
                th: {
                  padding: '5px',
                  color: '#ff5c00',
                  fontSize: '1.1em',
                  background: 'rgba(255, 84, 0, 0.1)',
                  boxShadow: '0 0 10px rgba(255, 84, 0, .4) inset',
                  margin: '5px 10px',
                  border: '3px solid #813008',
                  fontFamily: 'Rajdhani',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                  justifyContent: 'space-between',
                },
                table: {
                  'font-size': '18px',
                  'font-family': 'Rajdhani',
                  'background-color': '#000000',
                  'font-color': '#ffffff',
                },
              }}
              search={false}
              pagination={{
                enabled: false,
                limit: 30,
              }}
            />

          </div>
        </div>
      )}

    </div>

  )
}
