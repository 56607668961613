/* eslint-disable no-undef */
import getConfig from 'config.service'
import {
  Link,
  ERC721TokenType, ERC20TokenType, ETHTokenType,
} from '@imtbl/imx-sdk'
import { AssetCatalog } from 'domain/assetCatalog.model'
import { VolExchange } from 'domain/volExchange.model'
import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'
import MyAssetsRepository from './my-assets.repository'
import WalletIMX from '../domain/wallet-imx.model'
import { LockTime } from './staking.repository'

type TokenInfo = {
  tokenAddress: string,
  tokenId: string
}

const { hostURL } = getConfig()
export default abstract class VolExchangeRepository {
  public static async getTodayWithdraw(user: string, asset: string):
    Promise<{ withdraw: number, dailyLimits: number }> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/today-withdraw/${user}/${asset}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const withDraw = await ServiceConsumer.get<{ withdraw: number, dailyLimits: number }>(requestData)
    return withDraw
  }

  public static async processTokenExchange(txId: string) {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/token-exchange`,
      body: { txId },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    await ServiceConsumer.post(requestData)
  }

  public static async get(): Promise<AssetCatalog[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/catalog`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const volExchange = await ServiceConsumer.get<AssetCatalog[]>(requestData)
    return volExchange
  }

  public static async convertXdevToDev(assetId: string, amount: number, address?: string): Promise<VolExchange> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/xdev-to-dev`,
      body: {
        address,
        assetId,
        amount,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<VolExchange>(requestData)
    return response
  }

  public static async oneStepExchangeStaking(txId: string, lockTime: LockTime) {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/token-exchange`,
      body: { txId, lockTime },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    await ServiceConsumer.post(requestData)
  }

  public static async convertOffChainToNFT(assetId: string, address?: string): Promise<VolExchange> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/soft-to-nft`,
      body: {
        address,
        assetId,
        amount: 1,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<VolExchange>(requestData)
    return response
  }

  public static async convertNFTToOffChain(assetId: string, address?: string): Promise<string> {
    const token = ValidateIfTokenIsPresent()
    const txHash = await this.transferNft(assetId, address ?? '')

    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/nft-to-soft`,
      body: {
        address,
        assetId,
        txHash: txHash.toString(),
        amount: 1,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<Boolean>(requestData)
    return response ? txHash : ''
  }

  public static async convertDevToXdev(amount: string, address: string, assetId: string): Promise<string> {
    const token = ValidateIfTokenIsPresent()
    const txHash = await this.transferDev(amount)

    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/dev-to-xdev`,
      body: {
        address,
        txHash: txHash.toString(),
        amount: Number.parseFloat(amount),
        assetId,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<Boolean>(requestData)
    return response ? txHash : ''
  }

  public static async exchangeTokenToDev({ token, amount }: {
    token: 'USDC' | 'ETH' | 'IMX' | 'GODS',
    amount: string,
  }): Promise<string> {
    try {
      const config = getConfig()
      const link = new Link(config.LINK_ADDRESS)
      const toAddress = config.EXCHANGE_ADDRESS
      const getTokenAdress = () => {
        switch (token) {
          case 'USDC':
            return { address: config.USDC_TOKEN_ADDRESS, type: ERC20TokenType.ERC20 }
          case 'ETH':
            return { address: config.ETH_TOKEN_ADDRESS, type: ETHTokenType.ETH }
          case 'IMX':
            return { address: config.IMX_TOKEN_ADDRESS, type: ERC20TokenType.ERC20 }
          case 'GODS':
            return { address: config.GODS_TOKEN_ADDRESS, type: ERC20TokenType.ERC20 }
          default:
            throw new Error('Token not valid')
        }
      }
      const tokenData = getTokenAdress()
      let cfg: any = [
        {
          type: tokenData.type,
          tokenAddress: tokenData.address,
          toAddress,
          amount,
          symbol: token,
        },
      ]
      if (token === 'ETH') {
        cfg = [{
          type: ETHTokenType.ETH,
          amount,
          toAddress,
        }]
      }
      const result: any = await link.transfer(cfg)
      return result.result[0].txId
    } catch (e) {
      if (!e) throw new Error('Transfer was cancelled')
      throw e
    }
  }

  private static async transferDev(amount: string) {
    try {
      const config = getConfig()
      const link = new Link(config.LINK_ADDRESS)
      const toAddress = config.WALLET_TREASURY_ADDRESS
      const result: any = await link.transfer([
        {
          type: ERC20TokenType.ERC20,
          tokenAddress: config.DEV_TOKEN_ADDRESS,
          toAddress,
          amount: amount.toString(),
          symbol: 'DEV',
        },
      ])
      return result.result[0].txId
    } catch (e) {
      if (!e) throw new Error('Transfer was cancelled')
      throw e
    }
  }

  private static async transferNft(assetId: string, address: string): Promise<string> {
    try {
      const asset = await this.getVol(assetId)
      const tokenInfo = await this.getTokenInfo(address, asset)
      if (tokenInfo.tokenAddress === '' || tokenInfo.tokenId === '') throw new Error('Asset not Found')

      const config = getConfig()
      const link = new Link(config.LINK_ADDRESS)
      const toAddress = config.WALLET_TREASURY_ADDRESS
      const result: any = await link.transfer([
        {
          type: ERC721TokenType.ERC721,
          tokenAddress: tokenInfo.tokenAddress,
          tokenId: tokenInfo.tokenId,
          toAddress,
        },
      ])
      return result.result[0].txId
    } catch (e: any) {
      if (!e) throw new Error('Transfer was cancelled')
      throw e
    }
  }

  public static async getTokenInfo(address: string, vol: AssetCatalog): Promise<TokenInfo> {
    const config = getConfig()
    const wallet = new WalletIMX(address, config.ETH_NETWORK)
    const { assetTypeFieldKey, assetTypeFieldValue } = vol
    const assets = await MyAssetsRepository.listAssets(
      wallet,
      undefined, // cursor for pagination
      JSON.stringify({ [assetTypeFieldKey]: [assetTypeFieldValue] }),
      undefined, // collection
      1,
    )
    if (assets.result.length === 0) throw new Error('Asset not Found')
    const asset = assets.result
    const tokenInfo: TokenInfo = { tokenAddress: asset[0].token_address, tokenId: asset[0].token_id }
    return tokenInfo
  }

  private static async getVol(volId: string): Promise<AssetCatalog> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/asset-exchange/catalog/${volId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.get<AssetCatalog>(requestData)
    return response
  }
}
