/* eslint-disable */
import { sortBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion/dist/framer-motion'
import Button from 'components/deckbuilder/Button/Button.component'
import Card from 'components/deckbuilder/Card/Card.component'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import Footer from 'components/Footer/Footer.component'
import { useEffect, useState } from 'react'
import {
  useRecoilCallback, useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable,
} from 'recoil'
import { setLoader, Spinner } from 'components/Loader/Loader.component'
import AnimatedCard from "components/AnimatedCard/AnimatedCard.component"
import LibraryTitle from 'components/deckbuilder/Library/LibraryTitle.component'
import { myCardsSelector, connectedWallet } from 'selectors/selectors'
import CheckIconAnimated from 'components/CheckIconAnimated'
import ErrorIconAnimated from 'components/ErrorIconAnimated'
import { AnimateSharedLayout } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { CardType } from '../../domain/card.model'
import { faTimes } from '../../infraestructure/repositories/icons.repository'
import getConfig from 'config.service'
import {filterCardsBy} from "../../components/deckbuilder/Library/Library.component";
import { Box } from 'components/Box/box.component'
import Text from 'components/Text/Text'
import ProgressBar from '@ramonak/react-progress-bar'
import SearchFilter from './searchFilter.component'
import './my-cards.scss'
import useCountCards from 'hooks/useCountCards.hook'


const config = getConfig()
const { ENABLE_STORE } = config

export default function MarketplaceMyCardsView() {
  const _cards = useRecoilValueLoadable(myCardsSelector)
  const _cardsRefresh = useRecoilRefresher_UNSTABLE(myCardsSelector)
  const [search, setSearch] = useState('')
  const [card, setCard] = useState<CardType | undefined>()
  const history = useHistory()
  const [openSellModal, setOpenSellModal] = useState(false)
  const { count, loading } = useCountCards()
  // const { status } = useWallet()

  useEffect(() => _cardsRefresh(), [])
  useEffect(() => {
    if (_cards.state === 'loading') { // TODO: check me!
    // if (_cards.state === 'loading' || status === 'INITIALIZING') {
      setLoader(true)
      return
    }

    if (_cards.state === 'hasError') {
      alert(_cards.contents)
      setLoader(false)
      return
    }
    setLoader(false)
  }, [_cards.state])

  const closeRefresh = useRecoilCallback(({ snapshot, set }) => async () => {
    _cardsRefresh()
    setCard(undefined)
    setOpenSellModal(false)
  })

  const renderCards = (crds: any) => {
    if (crds.length === 0) return <div>No results</div>

    return crds.map((cardItem: CardType) => cardItem.id !== (card?.id || undefined) && (
      <AnimatedCard cardItem={cardItem} key={cardItem.id} onClick={openSingleModal} />
    ))
  }

  if (_cards.state === 'hasError' || !_cards.contents) {
    return <div style={{ textAlign: 'center', width: '100%' }} className="marketplace-mycards-viewerror-getting-cards">
    <h1 style={{ display: 'block', width: '100%' }} >
      Error while loading your nfts
    </h1>
    <Button onClick={() => window.location.reload()} variant={'primary'} size={'medium'} >refresh</Button>
  </div>
  }

  if (_cards.state === 'loading' || !_cards.contents) return <></>

  const openSingleModal = (card: CardType) => {
    setCard(undefined)
    setTimeout(() => {
      setCard(card)
    }, 0.5)
    // setModalSingle(true)
  }

  const submitSearch = (evt: any) => {
    evt.stopPropagation()
    evt.preventDefault()
  }

  const cards = filterCardsBy(search,_cards.contents||[])
  const chars = cards.filter((c: any) => c.CardType === 'Character')
  const techs = cards.filter((c: any) => c.CardType === 'Technology')

  const TOTAL_CARDS = loading ? 0 : count
  const TOTAL_OWNED = _cards.contents.length||0
  const TOTAL_PERCENTAGE = Math.round(TOTAL_OWNED/TOTAL_CARDS * 100)

  const { TokenIds, ListedTokens } = card as any || { TokenIds: [] }
  const availableTokens = TokenIds.filter((t: string) => !ListedTokens.includes(t))

  return (
    <div className="marketplace-mycards-view" style={{ textAlign: 'center' }}>
      <Modal isActive={openSellModal} id={'offer modal'} closeAction={() => closeRefresh()}>
        {card && <SellModal card={card as CardType} onCancel={() => closeRefresh()} availableTokens={availableTokens} />}
      </Modal>

      <div className='title' style={{ fontSize: '4rem' }}>My NFT Collection</div>
      
      <Box width='500px' style={{ textAlign: 'center', margin: 'auto' }}>
        <p style={{ padding: '10px' }}>
          <ProgressBar completed={TOTAL_PERCENTAGE} maxCompleted={100} labelColor="white" bgColor="#ff5c00" baseBgColor='#444' />
        </p>
        <Text fontSize={'0.9rem'}>You have collected { TOTAL_OWNED } of { TOTAL_CARDS } existing cards.
        </Text>
      </Box>

      <div style={{ width: '50%', margin: 'auto' }}>
        <SearchFilter searchAction={setSearch} submitAction={submitSearch} value={search} />
      </div>

      <div style={{ display: 'flex' }}>
      
        <AnimateSharedLayout type="crossfade">
          <div className={`card-list ${card ? 'selected' : ''}`} style={{ flex: 1 }
          }>
            { chars.length > 0 ? (
              <>
                <LibraryTitle title={'Character'} />
                {renderCards(chars)}
              </>
            ) : <></> }
            { techs.length > 0 ? (
              <>
                <LibraryTitle title={'Technology'} />
                { renderCards(techs) }
              </>
            ) : <></> }
          </div>
          {/* <AnimatePresence> */}

          {card && <motion.div
            layout
            initial={{
              opacity: 0, x: 50,
            }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ x: 50, opacity: 0 }}
            style={{ position: 'relative', overflow: 'visible' }}
            className='card-list-detail'
          >
            <Button
              variant={'icon'}
              size={'small'}
              right
              className="close-btn"
              onClick={() => setCard(undefined)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <div style={{
              position: 'sticky',
              display: 'flex',
              top: 125,
              // background: 'rgba(13, 13, 13, 1)',
              height: 'calc(100vh - 125px)',
              overflowY: 'scroll',
              flexDirection: 'column',
              padding: 30,
            }}
              className="card-detail scroll-style">
              <motion.div style={{ flex: 5, width: '100%' }} layout layoutId={card.id}>

                <Card
                  {...card}
                  key={`nft_card_${card.id}`}
                />
              </motion.div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div>
                  <b>
                    {card?.Name}
                  </b>
                  <br /><br />
                </div>

                <div style={{ marginTop: 10 }}>
                  <div>{card?.Description}</div>
                </div>

                <div style={{ display: 'flex', gap: 10, padding: 20 }} className="card-detail-actions">
                  {/* <Button  disabled variant={"outline"} size={"medium"} disabled={availableTokens.length === 0} onClick={() => {}}>sell card</Button> */}
                  {ENABLE_STORE && <Button variant={"primary"} size={"medium"} onClick={() => history.push('/store')}>get more cards</Button>}
                </div>
              </div>
              <div style={{ flex: 6 }}>
                <div>
                  <h2>Mint numbers</h2>
                  <ul style={{ listStyleType: 'none', paddingBottom: 20 }}>
                    {sortBy(TokenIds, (tId) => tId)
                    .map((token: any) => {
                      const isListed = (card as any).ListedTokens.includes(token)
                      return <li key={token} style={{ textDecoration: isListed ? 'line-through' : 'none', color: isListed ? 'gray' : 'white', textAlign: 'center' }}>{token} {isListed && 'on sale'}</li>
                    })
                    }

                  </ul>
                </div>
                <h2>Content</h2>
                <table style={{ width: '100%' }} className='card-detail-table' >
                  <tbody>

                    <tr>
                      <td><b>Type:</b> {card.CardType}</td>
                      <td><b>Planning:</b> {card.Planning}</td>
                    </tr>

                    <tr>
                      <td><b>Faction:</b> {card.Faction}</td>
                      <td><b>Rarity:</b> {card.Rarity}</td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div>

                {
                  card?.Gender && <div>
                    <b>
                      Gender:&nbsp;
                    </b>
                    {card?.Gender}
                  </div>
                }
                {Number(card?.Ability?.length) > 0 ? (
                  <div style={{
                    marginTop: 20,

                  }}>
                    <b>Abilities</b>
                    {card?.Ability?.map((a: any, i: number) => (<div key={`a_${i}`} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                      <div style={{ cursor: 'pointer' }} >
                        <img src={`/images/abilities/${a.toLowerCase()}.png`} alt={''} />
                      </div>
                      <div>
                        <b>{a}</b>
                        <div>{getAbilityText(a)}</div>
                      </div>
                    </div>
                    ))}

                  </div>) : <></>}

              </div>

            </div>
          </motion.div>}

          {/* </AnimatePresence> */}
        </AnimateSharedLayout>

      </div>

      <p style={{ textAlign: 'center', fontSize: '15px', marginTop: '20px' }}>
        Your cards can take up to 10 minutes to appear here after you opened a Booster
      </p>
      <Footer />
    </div>
  )
}
const getAbilityText = (ability: any) => {
  if (ability === 'BERSERK') return "Gets +X% Attack, where X% is the amount of damage received by that Character, over that Character's total HP, expressed as a percentage. When a Character with Berserk heals up to their initial HP, they die."
  if (ability === 'FRIGHTEN') return 'Whenever Frighten triggers, the frightened Character moves to the last slot, leaving their own slot empty.'
  if (ability === 'GETS') return "A card acquires a modification to their stats or abilities. Get can be either a triggered ability or an ongoing effect. If it's a trigger, the effect is permanent. If it's an ongoing effect, that effect will cease when the card providing it leaves the playfield. "
  if (ability === 'GRIP') return 'Whenever a Character with Grip moves from one slot to another, it does so with their attached Technology. If necessary, other cards will move to make room for them.'
  if (ability === 'HEAL') return 'Whenever Heal triggers, the healed Character recovers X Health Points. A Character cannot gain HP over their initial HP.'
  if (ability === 'OBEY') return 'Whenever Obey triggers, the obeying Character exchanges places with the Character on the first lane. No other Characters move.'
  if (ability === 'RIOT') return 'Whenever Riot triggers, the rioting Character moves X slots to the specified direction. When necessary, Characters move a slot to their right to make room for the rioting Character.'
  if (ability === 'SWAP') return 'Whenever Swap triggers, swapping Characters exchange slots.'
  if (ability === 'TOUGHNESS') return 'A Character with Toughness receives X less damage whenever they are attacked. If a Character with Toughness X gets Toughness Y through any method, they consider the highest number +1.'
  return 'UNKNOWN'
}

const SellModal = ({ card, onCancel, availableTokens }: { card: CardType, onCancel: Function, availableTokens: string[] }) => {
  // const lcd = useLCDClient()

  const TokenIds = availableTokens

  const [id, setId] = useState<string>(TokenIds[0])
  const [value, setValue] = useState<string>('')
  const [transactionStatus, setTXStatus] = useState<string>('')
  const wallet = useRecoilValue(connectedWallet)
  const [txhash, setTxHash] = useState('')

  const sendListMessage = async () => {
    setTXStatus('Please confirm in extension')
    // TODO: publish sale
    // try {
    //   const response = await connectedWallet?.post({
    //     fee: new Fee(2000000, '400000uusd'),
    //     msgs: [
    //       new MsgExecuteContract(connectedWallet.walletAddress, config.cards(connectedWallet?.network.name), {

    // //         list_token: {
    // //           token_id: id,
    //           ask: {
    //             amount: String((!value ? 0.01 : Number(value)) * 1000000),
    // //             denom: 'uusd',

    //           },
    //           blocks_to_list_for: 5256000,
    //         }

    //       }),
    //     ],
    //   })
    //   setTXStatus('Broadcasting tx')
    //   const getTx = async () => {
    //     try {
    //       const { tx } = lcd
    //       const txr = await tx.txInfo(
    //         response?.result.txhash || ''
    //       );

    // //       setTxHash(txr.txhash)
    // //       setTXStatus(txr.code === 0 ? 'Success' : `Error`)
    // //     } catch (e) {
    // //       const err: any = e;
    // //       console.error({ e })
    // //       if (err.response) {
    // //         console.error({ response })
    // //         await new Promise(resolve => setTimeout(resolve, 1000));// waits for 1 sec
    // //         getTx()
    // //       } else {
    // //         setTXStatus("error" + err.message)
    // //         console.error(e)
    // //       }

    // //     }
    // //   }

    // //   getTx()

    // // } catch (e) {
    // //   const err: any = e

    // //   if (err.name === "UserDenied" || err.name === 'CreateTxFailed') {
    // //     setTXStatus("User denied or missing password")
    // //   } else {
    // //     alert(err.message)
    // //   }

    // // }
  }

  const showOrSelectId = () => {
    const styles = {
      width: 'fit-content',
      margin: 'auto',
      fontFamily: 'Rajdhani',
      padding: 10,
    }
    if (TokenIds.length === 1) {
      return <div style={styles}>Id: {TokenIds[0]}</div>
    }

    return <div style={styles}>
        Select id:
        <select style={{
          background: '#171A19', fontFamily: 'Rajdhani', border: 'none', color: 'white', fontWeight: 'bold',
        }} value={id} onChange={setTokenId}>
          {TokenIds.map((id: string) => (<option key={id} label={id} value={id} />))}
        </select></div>
  }
  // const royalty = (value || 0 ) * 0,05
  const valueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const regexp = /^\d+(\.\d{0,2})?$/
    const val = e.target.value

    if (val === '') return setValue('')
    if (val === '00') return setValue('0')

    if (val.length === 1 && !Number(val) && val !== '0') return setValue('')

    if (regexp.test(val)) {
      return setValue(val)
    }

    setValue(value)
  }
  const setTokenId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    setId(e.target.value)
  }

  if (transactionStatus !== '') {
    return <div className="sell-modal-transaction">
    {['Broadcasting tx', 'Please confirm in extension'].includes(transactionStatus) && <Spinner />}
    {transactionStatus === 'Success' && <CheckIconAnimated />}
    {(['User denied or missing password', 'Error'].includes(transactionStatus) || transactionStatus.includes('error')) && <ErrorIconAnimated />}
    <><p className="title" style={{ fontSize: 22, width: '100%' }}>
      {(transactionStatus !== 'Please confirm in extension') && transactionStatus}
      {(transactionStatus === 'Please confirm in extension') && (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <p style={{ textAlign: 'center' }}>
        Waiting for confirmation
        </p>
        <br/>
      <p style={{ textAlign: 'center', fontSize: '15px' }}>
      please check your wallet extension if there is no dialog present
      </p>

      </div>)}

    </p>
    </>
    {/* ["Error", "Success"].includes(transactionStatus) && <a target="_blank" href={`https://finder.terra.money/${connectedWallet?.network.name}/tx/${txhash}`} style={{
      fontSize: '0.5em', width: '100%', textOverflow: 'ellipsis',
      maxWidth: 300,
      overflow: 'hidden'
    }}>Tx:{txhash}</a> */}
    {['Success', 'User denied or missing password', 'Error'].includes(transactionStatus) && <Button variant={'outline'} size={'medium'} onClick={() => {
      setTXStatus('')
      onCancel()
    }}>Close</Button>}
  </div>
  }

  const royalty = 0.06 * (Number(value))
  return (
    <div style={{ fontFamily: 'Rajdhani', fontSize: 16 }}>
      <h2>List for sale</h2>
      <h1>
        {card?.Name}

      </h1>
      {showOrSelectId()}
      <div style={{
        background: 'rgba(47, 58, 55, 1)', width: 'fit-content', margin: 'auto', fontSize: 16, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, fontFamily: 'Rajdhani',
      }}>
        <div style={{
          display: 'flex', alignItems: 'center', gap: 10, flex: 1, width: '100%', padding: 30,
        }}>
          <div style={{
            display: 'flex', alignItems: 'center', fontFamily: 'Rajdhani', fontWeight: 'bold',
          }}>

            <img src='' style={{ maxHeight: 20 }} />
            UST
            <input
              onPaste={(e) => { e.preventDefault(); return false }}
              value={value}
              onChange={valueChange}
              pattern="/^\d+(\.\d{0,2})?$/"
              type={'text'}
              maxLength={10}
              style={{
                background: 'rgba(16, 16, 16, 1)',
                padding: 5,
                color: 'white',
                border: 'none',
                marginLeft: 5,
                fontFamily: 'Rajdhani',
                fontSize: 16,
                fontWeight: 'bold',
              }} placeholder='SET PRICE' />
          </div>
        </div>
        <div style={{
          width: '100%', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', fontFamily: 'Rajdhani', fontWeight: 'bold', padding: 20, paddingTop: 0, textAlign: 'center',
        }}>
          {value && (<div style={{ display: 'block', textAlign: 'center' }}>FEE = {parseFloat(royalty.toFixed(4)).toString()} UST</div>)}
          <div style={{ fontSize: '0.7em' }}>
            {!value && ('FEES')} 5% ROYALTY + 1% PLATFORM
          </div>
        </div>
      </div>

      {value && (<div style={{
        textAlign: 'center', fontFamily: 'Rajdhani', fontSize: '1em', fontWeight: 'bold', padding: 10,
      }}>YOU WILL RECEIVE {parseFloat((Number(value) - royalty).toFixed(4)).toString()} UST</div>)}
      <div style={{ display: 'flex', gap: 10, padding: 20 }} >
        <Button variant={'outline'} size={'medium'} onClick={() => { onCancel() }}>Cancel</Button>
        <Button disabled={TokenIds.length === 0 || Number(value) < 0.01} variant={'primary'} size={'medium'} onClick={() => sendListMessage()}>List Card</Button>
      </div>
    </div>

  )
}

