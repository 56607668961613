import { useEffect } from 'react'
import Button from 'components/Button/Button.component'
import Footer from 'components/Footer/Footer.component'

type errorViewType = {
  title: string,
  type: string,
  description: string,
}

export default function ErrorView({
  title,
  type,
  description,
}:errorViewType) {
  useEffect(() => {
    window.location.href = '/'
  }, [])

  return (
    <div className="error-view">
      <div className="error-wrapper">
      <div className="error-message">
        <h1 className="error-title">{title}</h1>
        <p className="error-type">{type}</p>
        <p className="error-description">{description}
        </p>
        <div className="error-actions">
        {/* <Button variant={"outline"} size={"medium"}>
          Contact
        </Button> */}
        <Button variant={'primary'} size={'medium'}>
          Back to home
        </Button>
        </div>
      </div>
      <div className="error-image"></div>
      </div>
      <Footer/>
    </div>
  )
}
