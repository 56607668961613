import { Grid } from 'gridjs-react'

import './grid.view.scss'
import Footer from '../../components/Footer/Footer.component'

export default function GridExample() {
  return (
    <div className="gridExample-view">
      <div className='gridExample-view__title'>
        <h3>Grid Example</h3>

        <Grid
          data={[
            ['1111122', 'Super Booster', 1, 'In Process'],
            ['2222222', 'Mega Booster', 1, 'Completed'],
            ['1111122', 'Super Booster', 1, 'In Process'],
            ['2222222', 'Mega Booster', 1, 'Completed'],
            ['1111122', 'Super Booster', 1, 'In Process'],
            ['2222222', 'Mega Booster', 1, 'Completed'],
            ['1111122', 'Super Booster', 1, 'In Process'],
            ['2222222', 'Mega Booster', 1, 'Completed'],
          ]}
          columns={['PAYMENT', 'AMOUNT', 'TYPE', 'STATUS']}
          style= {{
            td: {
              padding: '5px',
              textAlign: 'center',
            },
            th: {
              padding: '5px',
              color: '#ff5c00',
            },
            table: {
              'font-size': '18px',
              'font-family': 'Rajdhani',
            },
          }}
          search={false}
          pagination={{
            enabled: false,
            limit: 30,
          }}
        />

      </div>
      <div className='gridExample-view__footer'> </div>
      <Footer />
    </div>
  )
}
