import { memo } from 'react'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import ReactGA from 'react-ga'
import { connectedWallet } from 'selectors/selectors'
import ProfileBtn from 'components/profileBtn/profile-btn'
import ProviderService from '../../infraestructure/services/web3-provider/imx-provider.service'

type Account = {
  address: string,
  starkPublicKey: string,
  ethNetwork: string,
  providerPreference: string,
  email?: string,
}

type ConnectWalletButtonType = {
  className?: string,
  id?: string,
  children?: any,
  onlyConnect?: boolean,
}

const ConnectWalletButton = memo(({
  className,
  onlyConnect = false,
  id,
}: ConnectWalletButtonType) => {
  const cw = useRecoilValue(connectedWallet)
  const login = useRecoilCallback(({ set }) => async () => {
    const providerService = new ProviderService() // TODO: inject with strategy
    const wallet: Account = await providerService.login()
    // console.log('wallet is ', wallet.address)
    ReactGA.set({ userId: wallet.address })
    set(connectedWallet, wallet)
  })

  return (
    <div className={className} id={id}>
      {cw?.address ? (
        <>
        <div className='net-indicator'> {/* `${provider?.network.name === "testnet" ? cw.network.name : ' '}` */}</div>
          <div className="wallet-connected" id="connect-wallet-successful-login">
          {!onlyConnect && <ProfileBtn wallet={cw} />}
          </div>
        </>
      ) : (
        <div className="btn-shadow">
          <button className="btn btn-primary btn-medium" onClick={() => login()} id={id}>
            Connect
          </button>
        </div>
      )
      }
    </div>
  )
})

ConnectWalletButton.displayName = 'ConnectWalletButton'

export default ConnectWalletButton
