import { Box } from 'components/Box/box.component'
import Flex from 'components/Flex/Flex'
import diamond from '../../assets/rarity_diamond.png'
import gold from '../../assets/rarity_gold.png'
import silver from '../../assets/rarity_silver.png'
import bronze from '../../assets/rarity_bronze.png'
import copper from '../../assets/rarity_copper.png'

type LeaderboardListItemProps = {
  title: string
  level: string
  xp: number
  position: number
}

export default function LeaderboardListItem(props: LeaderboardListItemProps) {
  const {
    title, level, xp, position,
  } = props
  return <Box>
    <Flex flexDirection='row' gap='5px'>
      <div style={{ fontSize: '14px', color: 'white', width: '30px' }}>
        {level === 'diamond' && <img src={diamond} alt={level} height="20px" />}
        {level === 'gold' && <img src={gold} alt={level} height="24px" />}
        {level === 'silver' && <img src={silver} alt={level} height="24px" />}
        {level === 'bronze' && <img src={bronze} alt={level} height="24px" />}
        {level === 'copper' && <img src={copper} alt={level} height="24px" />}
      </div>
      <div style={{ width: '25px' }}>
        #{position}
      </div>
      <div style={{ width: '120px' }}>
        {title}
      </div>
      <div style={{ width: '90px', textAlign: 'right' }}>
        {xp} <span style={{ fontSize: '12px', color: 'grey' }}>XP</span>
      </div>
    </Flex>
  </Box>
}
