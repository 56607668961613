import getConfig from 'config.service'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import { ServiceConsumer } from 'infraestructure/services/service-consumer/service-consumer.service'

export type ValidationSchema = {
  id: string,
  name: string,
  schema: string,
}

const config = getConfig()

const ValidationSchemaRepository = {
  async getValidationSchema(schemaId: string): Promise<ValidationSchema> {
    const token = ValidateIfTokenIsPresent()
    const validationSchema = await ServiceConsumer.get<ValidationSchema>({
      url: `${config.hostURL}/api/v1/economy-tools/validation-schemas/${schemaId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return validationSchema
  },
  async getVlidationSchemas(): Promise<ValidationSchema[]> {
    const token = ValidateIfTokenIsPresent()
    const validationSchema = await ServiceConsumer.get<ValidationSchema[]>({
      url: `${config.hostURL}/api/v1/economy-tools/validation-schemas`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return validationSchema
  },
  async validate({ schemaId, payload }: { schemaId: string, payload: string }):
    Promise<{ result: boolean, errors: any[] }> {
    const token = ValidateIfTokenIsPresent()
    const validation = await ServiceConsumer.post<{ result: boolean, errors: any[] }>({
      url: `${config.hostURL}/api/v1/economy-tools/validation-schemas/validate`,
      body: {
        schemaId,
        payload,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return validation
  },
}

export default ValidationSchemaRepository
