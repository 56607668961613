import './EntryFee.component.scss'
import { useMemo } from 'react'
import MoneyComponent from 'components/Money/Money.component'
import { Tournament } from 'domain/tournament.model'
import { uniqueId } from 'lodash'
import TicketComponent from './Ticket.component'

export default function EntryFee({ tournament, onlyData }: { tournament: Tournament, onlyData?: boolean }) {
  const fees = useMemo(() => (<div className='entry-fee__items'>
    {tournament.buyIn?.filter((b) => b.type === 'MONEY')
      .map((b) => (<MoneyComponent convert={true} key={uniqueId()} money={b.value!} />))}
    {tournament.buyIn?.filter((b) => (b.type === 'TICKET' || b.type === 'FREE_ROLL_TICKET'))
      .map((ticket) => (<TicketComponent key={uniqueId()} ticket={ticket} />))}
  </div>), [tournament.id])

  return (
    <div className="entry-fee">
      {
        onlyData ? <></> : (
          <>
            <div>(starting from)</div>
            <div>entry fee</div>
          </>
        )
      }
      {fees}
    </div>
  )
}
