import { Box } from 'components/Box/box.component'
import { useRecoilValueLoadable } from 'recoil'
import { dailyXpSelector, xpSelector } from 'selectors/play2airdrop.selectors'
import { AccumulatedXpByTask } from 'domain/play2airdrop.types'
import AirdropStatsItem from './AirdropStatsItem.component'

type AirdropStatsProps = {
  title: string,
  tasks: AccumulatedXpByTask[]
}

export default function AirdropStats(props: AirdropStatsProps) {
  const myXp = useRecoilValueLoadable(props.title === "Yesterday's" ? dailyXpSelector : xpSelector)
  const { title } = props
  return <Box width='50%' style={{ marginBottom: '30px' }}>
    <h2 style={{ textAlign: 'left', paddingBottom: '10px' }}>{title} {myXp.valueMaybe() || '-'} XP</h2>
    {props.tasks.map((task: AccumulatedXpByTask) => <AirdropStatsItem
      key={task.task}
      title={task.task} xp={task.xp} />)}
  </Box>
}
