import './TournamentRanking.component.scss'
import { Grid } from 'gridjs-react'
import { Tournament } from 'domain/tournament.model'
import { useState } from 'react'
import { Match } from '../../../../domain/match.model'

function getPosition(index: number) {
  switch (index) {
    case 0: return '1st'
    case 1: return '2nd'
    case 2: return '3rd'
    default: return `${index + 1}th`
  }
}

// function getPlayersWLD(tournament: Tournament): Map<string, string> {
//   const accumulated = new Map<string, {w: number, l: number, d: number}>()
//   tournament.currentStage?.matches.forEach((match) => {
//     if (!accumulated.has(match.playerAId)) accumulated.set(match.playerAId, { w: 0, l: 0, d: 0 })
//     if (!accumulated.has(match.playerBId)) accumulated.set(match.playerBId, { w: 0, l: 0, d: 0 })
//     const resultMatchA = accumulated.get(match.playerAId)!
//     const resultMatchB = accumulated.get(match.playerBId)!
//     const totalGames = match.results.playerAWins + match.results.playerBWins + match.results.draws
//     resultMatchA.w += match.results.playerAWins
//     resultMatchB.w += match.results.playerBWins
//     resultMatchA.d += match.results.draws
//     resultMatchB.d += match.results.draws
//     resultMatchA.l += totalGames - match.results.playerAWins - match.results.draws
//     resultMatchB.l += totalGames - match.results.playerBWins - match.results.draws
//   })
//   return new Map(Array.from(accumulated.entries())
//     .map((value) => [value[0], `${value[1].w}-${value[1].l}-${value[1].d}`]))
// }

function getPlayersFromTournament(tournament: Tournament) {
  if (tournament.state === 'PUBLISHED') return tournament?.subscribedPlayers?.map((p) => (['-', p.name ?? p.playerId, '-']))
  // const results = getPlayersWLD(tournament)
  return tournament?.currentStage?.players.map((player, i) => [
    getPosition(i),
    player.name ?? player.id,
    player.score,
    player.gamePoints,
    // results.get(player.id) ?? '-',
  ])
}

function getMatchResult(match: Match, playerA:string, playerB:string): string {
  if (match.state === 'ONGOING') return 'ONGOING'
  if (match.results.playerAWins === match.results.playerBWins) return 'DRAW'
  if (match.results.playerAWins > match.results.playerBWins) return playerA
  return playerB
}

function getMatchesFromTournament(tournament: Tournament) {
  if (!tournament?.currentStage?.matches) return []
  const players:any = {}
  tournament?.currentStage?.players.forEach((player) => {
    players[player.id] = player.name ?? player.id
  })

  return tournament?.currentStage?.matches.map((match) => [
    match.round,
    players[match.playerAId] ?? match.playerAId,
    players[match.playerBId] ?? match.playerBId,
    match.state,
    getMatchResult(match, players[match.playerAId] ?? match.playerAId, players[match.playerBId] ?? match.playerBId),
  ])
}

export default function TournamentRanking({ tournament }: {tournament: Tournament}) {
  const [mode, setMode] = useState('scoreboard')

  let data:any[] = []
  let keys:string[] = []

  if (mode === 'scoreboard') {
    data = getPlayersFromTournament(tournament) ?? []
    keys = ['Position', 'Player', 'score', 'Perf']
  } else if (mode === 'matches') {
    data = getMatchesFromTournament(tournament) ?? []
    keys = ['Round', 'Player A', 'Player B', 'State', 'Winner']
  }

  return (
    <div className="tournament-ranking">
      <div className='tournament-ranking-container'>
        <div className='tr-tab-container'>
          <div className={`tr-tab ${mode === 'scoreboard' ? 'tr-tab-enabled' : ''}`} onClick={() => setMode('scoreboard')} >SCOREBOARD</div>
          <div className={`tr-tab ${mode === 'matches' ? 'tr-tab-enabled' : ''}`} onClick={() => setMode('matches')} >MATCHES</div>
        </div>
        <Grid
          data={data}
          columns={keys}
          search={false}
          pagination={{
            enabled: false,
            limit: 64,
          }}
        />
      </div>
    </div>
  )
}
