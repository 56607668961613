import { useEffect, useRef, useState } from 'react'
import './DeckContainer.scss'
import _ from 'lodash'

export const getScrollPosition = () => window.scrollY || document.documentElement.scrollTop

export const getScrollTop = () => (document.scrollingElement || document.documentElement).scrollTop

export const getScrollBottom = () => Math.abs(
  window.innerHeight + getScrollPosition() - document.body.scrollHeight,
)

export const getScrollPercentage = () => {
  const scrollPosition = getScrollPosition()
  const scrollHeight = document.documentElement.scrollHeight
    - document.documentElement.clientHeight
  const scrollTop = scrollPosition / scrollHeight || 0

  return scrollTop * 100
}

function useScroll(throttle = 100) {
  const scrollThrottle = useRef(throttle)
  const [scroll, setScroll] = useState({
    hasScrolled: false,
    top: 0,
    bottom: 0,
    percentage: 0,
  })

  useEffect(() => {
    scrollThrottle.current = throttle
  }, [throttle])

  useEffect(() => {
    const updateScroll = () => {
      setScroll({
        hasScrolled: true,
        top: getScrollTop(),
        bottom: getScrollBottom(),
        percentage: getScrollPercentage(),
      })
    }
    const scrollListener = _.throttle(updateScroll, scrollThrottle.current)

    window.addEventListener('scroll', scrollListener)
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return scroll
}

const DeckContainer = ({ children }:any) => {
  const scrollInfo = useScroll()

  const height = scrollInfo.top < 132 ? 280 - scrollInfo.top : 140

  return <div className='deck' style={{ height: `calc(100vh - ${height}px)` }}>
        <div className='deck__container'>
            {children}
        </div>

        <div className='deck__head'></div>
    </div>
}

export default DeckContainer
