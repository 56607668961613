import CardsRepository from 'infraestructure/repositories/cards.repository'
import { useEffect, useState } from 'react'

export default function useCountCards() {
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        const { cards } = await CardsRepository.countAllCardsExceptHQ()
        setCount(cards)
      } catch (error) {
        // console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetch()
  }, [])
  return { count, loading }
}
