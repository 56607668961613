import './profile.view.scss'
import ProfileComponent from 'components/Profile/profile.component'
import Footer from '../../components/Footer/Footer.component'

export default function ProfileView() {
  return (
    <div className="user-profile-view">
      <div className="user-profile-container">
        <ProfileComponent />
      </div>
      <div className='user-profile-view__footer'> </div>
      <Footer />
    </div>
  )
}
