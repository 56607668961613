import { useRef, useState } from 'react'
import './faction.section.scss'
import { ReactComponent as IconChevron } from '../../assets/icons/icon-chevron.svg'
import AwakenSvg from './svgs/awaken.svg'
import OwnerSvg from './svgs/owner.svg'
import InhumanSvg from './svgs/inhuman.svg'
import EntropySvg from './svgs/entropy.svg'
import OwnerLogo from './assets/owner-logo.png'
import EntropyLogo from './assets/entropy-logo.png'
import InhumanLogo from './assets/inhuman-logo.png'
import AwakenLogo from './assets/awaken-logo.png'
import OwnerIlustration from './assets/owner-ilustration.png'
import EntropyIlustration from './assets/entropy-ilustration.png'
import InhumanIlustration from './assets/inhuman-ilustration.png'
import AwakenIlustration from './assets/awaken-ilustration.png'

type FactionsType = {
  awaken: boolean, entropy: boolean, inhuman: boolean, owner: boolean,
}
type FactionsKeyType = 'owner'|'entropy'|'inhuman'|'awaken'

const initFactions:FactionsType = {
  awaken: true, entropy: false, inhuman: false, owner: false,
}
export default function FactionSection() {
  const self:any = useRef()

  const [index, setIndex] = useState<number>(1)
  const [factions, setFactions] = useState<FactionsType>({ ...initFactions })
  const [flipFactions, setFlipFactions] = useState<FactionsType>({ ...initFactions })
  const [flip, setFlip] = useState<string>('front')

  if (!self.current) {
    self.current = {
      factions: { ...initFactions },
      setFaction(faction:FactionsKeyType) {
        const facts = self.current.factions
        setFlipFactions({ ...facts })
        const keys = Object.keys(facts)
        keys.forEach((key) => {
          facts[key] = key === faction
        })
        setFlip('back')
        const idx:number = keys.findIndex((key) => facts[key])
        setIndex(idx + 1)
        setFactions({ ...facts })
        clearTimeout(self.current.t)
        self.current.t = setTimeout(() => {
          setFlip('front')
          setFlipFactions({ ...facts })
        }, 250)
      },
      nextFaction(num:number) {
        const facts = self.current.factions
        const keys = Object.keys(facts)
        let idx:number = keys.findIndex((key) => facts[key])
        idx += num
        if (idx < 0) idx = keys.length - 1
        if (idx >= keys.length) idx = 0
        self.current.setFaction(keys[idx] as FactionsKeyType)
      },
    }
  }

  return (
    <section className="faction-section" id="factions">
      <h2>DISCOVER</h2>
      <h3>THE FACTIONS</h3>
      <div className="faction-section__container">
        <ul className="faction-section__factions-list">
          <li className={`faction-list__owner ${factions.awaken ? 'selected' : ''}`} onClick={() => self.current.setFaction('awaken')}>
            <AwakenSvg />AWAKEN
          </li>
          <li className={`faction-list__entropy ${factions.entropy ? 'selected' : ''}`} onClick={() => self.current.setFaction('entropy')}>
            <EntropySvg />ENTROPY
          </li>
          <li className={`faction-list__inhuman ${factions.inhuman ? 'selected' : ''}`} onClick={() => self.current.setFaction('inhuman')}>
            <InhumanSvg />INHUMAN
          </li>
          <li className={`faction-list__awaken ${factions.owner ? 'selected' : ''}`} onClick={() => self.current.setFaction('owner')}>
            <OwnerSvg />OWNER
          </li>
        </ul>
        <div className="faction-section__content">
          <div className="faction-section__image-wrapper">
            <div className="faction-section__image">
              <img className={`img-image ${factions.awaken ? 'selected' : ''}`} src={AwakenIlustration} alt='awaken' />
              <img className={`img-image ${factions.entropy ? 'selected' : ''}`} src={EntropyIlustration} alt='entropy' />
              <img className={`img-image ${factions.inhuman ? 'selected' : ''}`} src={InhumanIlustration} alt='inhuman' />
              <img className={`img-image ${factions.owner ? 'selected' : ''}`} src={OwnerIlustration} alt='owner' />
            </div>
            <div className="faction-section__image-logo flip-container">
              <div className={`flipper flip-${flip}`}>{ /* flip-back, back */ }
                <img className={`img-logo ${flipFactions.awaken ? 'front' : ''}`} src={AwakenLogo} alt="Awaken" />
                <img className={`img-logo ${flipFactions.entropy ? 'front' : ''}`} src={EntropyLogo} alt="Entropy" />
                <img className={`img-logo ${flipFactions.inhuman ? 'front' : ''}`} src={InhumanLogo} alt="Inhuman" />
                <img className={`img-logo ${flipFactions.owner ? 'front' : ''}`} src={OwnerLogo} alt="Owner" />
              </div>
            </div>
          </div>
          <div className="faction-section__description">
            <div className="faction-section__description-title">
              <span className={`${factions.awaken ? 'selected' : ''}`}>AWAKEN</span>
              <span className={`${factions.entropy ? 'selected' : ''}`}>ENTROPY</span>
              <span className={`${factions.inhuman ? 'selected' : ''}`}>INHUMAN</span>
              <span className={`${factions.owner ? 'selected' : ''}`}>OWNER</span>
            </div>
            <p className={`${factions.awaken ? 'selected' : ''}`}>
              The Awaken are the keenest observers. The Great Seer once said, “Attention is the truest way to empathy.”
              They advocate for peaceful coexistence with the Undeviants, but they are far from pacifists:
              their deadliest assets will not hesitate to do what needs to be done for the greater good.
            </p>
            <p className={`${factions.entropy ? 'selected' : ''}`}>
              Entropians believe that power must follow the Second Law of Thermodynamics: a system of power should
              always evolve towards its most probable configuration, which is the one with the most Entropy.
              Structured power corrupts. But when it comes to making a freer world, no means is forbidden.
            </p>
            <p className={`${factions.inhuman ? 'selected' : ''}`}>
              Humans are obsolete beings, no longer suited to oversee things of this Earth. It is the time of the
              Deviant, claim the Inhuman. This faction denies any and all commonality with humanity – that rabble
              of flesh unfit to consume even the air they breathe, much less the resources that belong to the new by
              rights. Inhumans continually strive for perfection, using every kind of upgrade and enhancement they
              can devise.
            </p>
            <p className={`${factions.owner ? 'selected' : ''}`}>
              Owners believe in the power of structured, methodical work. If their goal is to seize control over
              History,order and determination must be the cornerstone of that work. They understand their condition
              as superior beings as a burden: they must rule wisely over the ignorant but productive Undeviants.
            </p>
            <div className="faction-section__tools">
              <div className="faction-section__tools-buttons">
                <div
                  className="btn-shadow"
                  onClick={() => self.current.nextFaction(-1)}
                  >
                  <span className="btn btn-left btn-small btn-outline">
                    <IconChevron className="next-button" />
                  </span>
                </div>
                <div
                  className="btn-shadow"
                  onClick={() => self.current.nextFaction(+1)}
                  >
                  <span className="btn btn-right btn-small btn-outline">
                    <IconChevron className="next-button" />
                  </span>
                </div>
              </div>
              <div className="faction-section__tools-info">{ `${index}/4` }</div>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
