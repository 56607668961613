import './crafting.view.scss'
import { CraftingProvider } from './crafting-context.component'
import CraftingRecipes from './crafting-recipes.component'
import CraftingTable from './crafting-table.component'
import NftPickerModal from './nft-picker/nft-picker-modal.component'
import CraftingResultModal from './crafting-result-modal.component'
import tokenTrove from './assets/token_trove.png'
import imx from './assets/immutable_x.png'
import df from '../../assets/logo.png'
import PendingCraftings from './pending-craftings.component'

export default function CraftingView() {
  return <CraftingProvider>
    <div className="crafting-view">
      <div className='crafting-header'>
        <PendingCraftings />
        <div style={{ flex: 1 / 2 }} />
        <h1>CRAFTING</h1>
        <div className='crafting-links'>
          <a href='/store' target='_blank' rel='noreferrer'>
            <img className='store-img-icon' src={df} height={'35px'} alt='deviants factions' />
          </a>
          <a href='https://market.immutable.com/collections/0xb93a4e2c02ed1486351e90cf52de8d51a67aaaf6'
            target='_blank'
            rel='noreferrer'>
            <img className='store-img-icon' height={'35px'} src={imx} alt='immutable x' />
          </a>
          <a href='https://tokentrove.com/collection/DeviantsFactions' target='_blank' rel='noreferrer'>
            <img className='store-img-icon' src={tokenTrove} height={'35px'} alt='token trove' />
          </a>
        </div>
      </div>
      <div className='crafting-container'>
        <CraftingRecipes />
        <CraftingTable />
        <NftPickerModal />
        <CraftingResultModal />
      </div>
    </div>
  </CraftingProvider>
}
