import PurchasesComponent from '../../components/purchases/purchases.component'
import './purchases.view.scss'
import Footer from '../../components/Footer/Footer.component'

export default function PurchasesView() {
  return (
    <div className="purchases-view">
      <div className='purchases-view__title'>
        <h3>My Purchases</h3>
      </div>
      <PurchasesComponent />
      <div className='purchases-view__footer'> </div>
      <Footer />
    </div>
  )
}
