import { useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import VolExchangeRepository from 'repositories/asset-exchange.repository'
import { connectedWallet } from 'selectors/selectors'

export type UseDevDepositProps = {
  onSuccess?: () => void,
  onFail?: (error: string) => void,
}

export default function useDevDeposit(props: UseDevDepositProps) {
  const [loading, setLoading] = useState(false)
  const wallet = useRecoilValueLoadable(connectedWallet)
  const deposit = async (amount: number) => {
    setLoading(true)
    try {
      if (!wallet.contents.address) throw new Error('Wallet not connected')
      await VolExchangeRepository.convertDevToXdev(amount.toString(), wallet.contents.address, '7')
      props.onSuccess?.()
    } catch (error: any) {
      props.onFail?.(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    deposit,
    loading,
  }
}
