import './NFTlist.scss'
import { Link } from 'react-router-dom'
// eslint-disable-next-line camelcase
import { useRecoilValueLoadable, useRecoilRefresher_UNSTABLE } from 'recoil'
import { myBoostersSelector, myAssetsSelector } from 'selectors/selectors'
import NftList from './starter-pack-list.component'
import { LoaderFallback, setLoader } from '../Loader/Loader.component'

// onOpened is used to refresh when a pack is opened

export default function NftListComponent() {
  const boosters = useRecoilValueLoadable(myBoostersSelector)
  const refreshAssets = useRecoilRefresher_UNSTABLE(myAssetsSelector)

  if (boosters.state === 'loading') {
    return <LoaderFallback text={'loading ...'}/>
  }
  setLoader(false)

  if (boosters.contents.length === 0) {
    return (
      <div className="empty-state">
        <h2/>
        <h1 className="title">You don&apos;t have any Boosters.</h1>
        <Link to="/store" className="btn-shadow">
          <button className="btn btn-primary btn-medium">BUY BOOSTERS</button>
        </Link>
        <h2/>
      </div>
    )
  }

  return <NftList nfts={boosters.contents} onOpened={() => refreshAssets()}/>
}
