/* eslint-disable camelcase */
import './TournamentJoinModal.component.scss'
import MoneyComponent from 'components/Money/Money.component'
import Button from 'components/Button/Button.component'
import { DeckType } from 'domain/deck.model'
import { useState } from 'react'
import TournamentsRepository from 'repositories/tournaments.repository'
import ButtonLoader from 'components/ButtonLoader/ButtonLoader.component'
import CrystalModal from 'components/CrystalModal/CrystalModal.component'
// eslint-disable-next-line camelcase
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
} from 'recoil'
import { CardType } from 'domain/card.model'
import {
  DeckFormatsSelector,
  profileBalancesSelector,
  subscriptionStatusSelector,
} from 'selectors/selectors'
import Select from 'react-select'
import getConfig from 'config.service'
import {
  currentTournamentAtom,
  isShowJoinSuccessModalAtom,
} from '../tournamentDetail.recoil'
// import IconFreeroll from '../../../../assets/icons/icon-freeroll.png'
import { AlertModal } from '../../../../components/deckbuilder/DeckDetail/DeckDetail.component'
import { DeckFormatDto } from '../../../../services/deck-formats.service'
import { customSelectStyles } from '../../../../components/deckbuilder/EditDeck/EditDeck.component'

export default function TournamentJoinModal({
  onClose,
  isActive,
  decks,
  userId: playerId,
}: {
  onClose: any
  isActive: boolean
  decks: DeckType[]
  userId: string
}) {
  const [inputs, setInputs] = useState<any>({ acceptTerms: false })
  const [isRegistering, setIsRegistering] = useState<boolean>(false)
  const [tournament] = useRecoilState(currentTournamentAtom)
  const [, setShowJoinSuccessModal] = useRecoilState(isShowJoinSuccessModalAtom)
  const [showError, setError] = useState({ visible: false, text: '' })
  const balances = useRecoilValueLoadable(profileBalancesSelector)
  const entryFee = tournament?.buyIn?.find((b) => b.type === 'COIN')
  const entryFeeTicket = tournament?.buyIn?.find((b) => ['TICKET', 'FREE_ROLL_TICKET'].includes(b.type))
  const deckFormats = useRecoilValueLoadable(DeckFormatsSelector)
  const refreshBalance = useRecoilRefresher_UNSTABLE(profileBalancesSelector)
  const subscriptionStateReload = useRecoilRefresher_UNSTABLE(
    subscriptionStatusSelector(tournament?.id),
  )

  const ticketTypeId: string | undefined = entryFeeTicket?.value.assetType
  const tickets: any = balances?.contents?.tickets || {}
  const hasEnoughTickets = ticketTypeId ? tickets[ticketTypeId] > 0 : true

  function onSubmit() {
    setIsRegistering(true)
    if (tournament) {
      const { deckId } = inputs
      TournamentsRepository.subscribe(tournament.id, playerId, deckId)
        .then(() => {
          onClose(true)
          setShowJoinSuccessModal(true)
          // eslint-disable-next-line max-len
          // setTournament({ ...tournament, subscribedPlayers: [...tournament.subscribedPlayers, { playerId, deckId }] })
          refreshBalance()
          subscriptionStateReload()
        })
        .catch((err) => {
          setError({
            text: err?.response?.data?.message ?? 'Unknown Error',
            visible: true,
          })
        })
        .finally(() => {
          setIsRegistering(false)
        })
    }
  }

  function filterTournaments(currentDecks: DeckType[]): DeckType[] {
    const minNftCards = getConfig().ENABLE_NFT_CHECKING ? tournament?.deckValidations?.minNftCards || 0 : 0
    let filteredDecks = currentDecks.filter((deck) => deck.playable)
    if (minNftCards) {
      filteredDecks = filteredDecks.filter((deck) => {
        const uniqueCards = deck?.uniqueNftCards || 0
        // Israel fix: if the deck has non OTK cards with zero NFT
        let hasInvalidNftOnlyCards = false
        deck?.cards?.forEach((card) => {
          const overridedCards = card as unknown as CardType & { tokenIds: string[] }
          if (!['Outbreak', 'Echoes 1'].includes(card.Set!) && overridedCards.tokenIds?.length === 0) {
            hasInvalidNftOnlyCards = true
          }
        })
        if (hasInvalidNftOnlyCards) return false
        return uniqueCards >= minNftCards
      })
    }
    if (
      deckFormats
      && deckFormats.contents
      && Array.isArray(deckFormats.contents.formats)
    ) {
      const format = deckFormats.contents.formats.find(
        (df: DeckFormatDto) => df.id === tournament?.deckValidations?.deckFormat,
      )
      if (format) {
        filteredDecks = filteredDecks.filter((deck) => {
          const deckFormat = deck?.format || 'standard'
          return format.id === deckFormat && deck.validFormat
        })
      }
    }
    if (filteredDecks.length === 1 && !inputs.deckId) {
      setInputs({
        ...inputs,
        deckId: filteredDecks[0].id,
      })
    }
    return filteredDecks
  }

  function handleChange(event: any) {
    const { name, value } = event.target
    setInputs({
      ...inputs,
      [name]: name !== 'acceptTerms' ? value : !inputs.acceptTerms,
    })
  }

  const formatComponentDisplay = () => {
    if (
      deckFormats
      && deckFormats.contents
      && Array.isArray(deckFormats.contents.formats)
    ) {
      const format = deckFormats.contents.formats.find(
        (df: DeckFormatDto) => df.id === tournament?.deckValidations?.deckFormat,
      )
      if (format) { return <p style={{ marginTop: 0, marginBottom: 0 }}>{format.name}</p> }
    }
    return <></>
  }

  const options = filterTournaments(decks).map((deck) => ({
    value: deck.id,
    label: deck.name,
  })) as any

  // noinspection HtmlUnknownTarget
  return (
    <CrystalModal
      closeAction={onClose}
      isActive={isActive}
      title="Join event"
      id="tournament-join-modal"
    >
      <AlertModal
        isOpen={showError.visible}
        onClose={() => setError({ text: '', visible: false })}
        key="error-alert"
        text={showError.text}
      />
      <div className="tournament-join-modal">
        <div className="tournament-join-modal__name">{tournament?.name}</div>
        {formatComponentDisplay()}
        <div className="tournament-join-modal__select-deck">
          {options.length > 0 ? (
            <label>
              Select Deck:
              <div className="deck-selector">
                <Select
                  styles={customSelectStyles}
                  className={'deviants-select'}
                  options={options}
                  value={options.find(
                    (opt: any) => opt.value === inputs.deckId,
                  )}
                  defaultValue={{ label: 'Select a deck', value: undefined }}
                  onChange={(opt) => handleChange({
                    target: { name: 'deckId', value: opt?.value },
                  })
                  }
                />
              </div>
            </label>
          ) : (
            <h1>{"We're sorry, but you don't have a valid deck to enroll."}</h1>
          )}
          <div className="tournament-rules">
            {(tournament?.deckValidations?.minNftCards || 0 > 0) && ( // eslint-disable-line
              <div className="warning-text">
                * You must have at least{' '}
                {tournament?.deckValidations?.minNftCards} NFT cards in your
                deck to play this tournament
              </div>
            )}
            * You must play each tournament with unique NFT copies if they are
            required
            <br />* These NFT cards will be locked till the end of the
            tournament
            <br />* You must keep your NFTs in your wallet until the end of the
            tournament
          </div>
        </div>
        <div className="tournament-join-modal__accept-terms">
          <label>
            <input
              type="checkbox"
              checked={inputs.acceptTerms}
              name="acceptTerms"
              onChange={handleChange}
            />
            I have read the
          </label>
          <a
            href="/tournaments/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
        <div className="tournament-join-modal__pre-actions">
          <div className="tournament-join-modal__actions">
            {entryFee && (
              <div className="tournament-join-modal__entry-fee">
                <div>ENTRY FEE</div>
                <MoneyComponent convert={true} money={entryFee?.value} />
              </div>
            )}
            <div>&nbsp;</div>
            <Button
              variant="primary"
              size="medium"
              onClick={onSubmit}
              disabled={
                !(inputs.acceptTerms && inputs.deckId && hasEnoughTickets)
                || isRegistering
              }
            >
              Join Now!
              {isRegistering && <ButtonLoader />}
            </Button>
            <div>&nbsp;</div>
          </div>
        </div>
        {!hasEnoughTickets && (
          <>
            <p>&nbsp;</p>
            <div className="tournament-join-modal__tickets-error">{`You don't have any ${TournamentsRepository.getTicketName(
              ticketTypeId,
            )}`}</div>
          </>
        )}
      </div>
    </CrystalModal>
  )
}
