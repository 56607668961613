import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
/* import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider' */
import { RecoilRoot } from 'recoil'
import ReactGA from 'react-ga'
/* import { find, matches } from 'lodash' */
import App from 'App'
import './index.scss'
import { Loader } from './components/Loader/Loader.component'

ReactGA.initialize('GTM-MSCBMFJ', { useExistingGa: true })

ReactDOM.render(
  <StrictMode>
    <RecoilRoot >
      <App />
      <Loader />
    </RecoilRoot >
  </StrictMode>,
  document.getElementById('root'),
)
