/* eslint-disable no-alert */
import moment from 'moment'
import './league-header.scss'
import { LeagueScores } from 'domain/league.type'
import Button from 'components/Button/Button.component'
import LeagueSelector from '../league-selector/league-selector.component'

export default function LeagueHeader(props: { leagueData: LeagueScores }) {
  return <div className='league-header'>
    <img className='league-header__hero' src='/images/league-hero.png' alt='league-hero' />
    <img className='league-header__hero-curve' src='/images/curve.png' alt='league-hero-curve' />
    <div className='league-header__title'>
      <h1>{props.leagueData.league.name}</h1>
    </div>
    <div className='league-header__subtitle'>
      <LeagueSelector />
      {!props.leagueData.league.isEnded && props.leagueData.league.isStarted && <h5>Ends on {moment(props.leagueData.league.endDate).utc().format('MMM DD HH:mm [UTC]')}</h5>}
      {!props.leagueData.league.isStarted && <h5>Starts at {moment(props.leagueData.league.startDate).utc().format('MMM DD HH:mm [UTC]')}</h5>}
      <a href='https://docs.deviantsfactions.com/deviants-factions-rulebook/deviants-league' target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
        <Button variant='primary_alt' size='medium'>League rules</Button>
      </a>
    </div>
  </div>
}
