/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion/dist/framer-motion'
import Button from 'components/deckbuilder/Button/Button.component'
import Card from 'components/deckbuilder/Card/Card.component'
import CardDetailModal from 'components/Modal/card-detail/card-detail.modal'
import Footer from 'components/Footer/Footer.component'
import { useState } from 'react'
import {
  useRecoilCallback, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable,
} from 'recoil'

import { AnimateSharedLayout } from 'framer-motion'
// import { useHistory } from 'react-router-dom';
import { AllCardsSelector, myCardsSelector } from 'selectors/selectors'
import { faTimes } from '../infraestructure/repositories/icons.repository'
import { CardType } from "../domain/card.model";
import getConfig from 'config.service'

export default function AllCardsView() {
  const config = getConfig()
  const { ENABLE_ECHOES } = config
  const _cards = useRecoilValueLoadable(myCardsSelector)
  const _cardsRefresh = useRecoilRefresher_UNSTABLE(myCardsSelector)
  // const [search, setSearch] = useState('');
  const [card, setCard] = useState<CardType | undefined>()
  const [selectedCard, setSelectedCard] = useState<any>(null)
  // const history = useHistory();
  // const [openSellModal, setOpenSellModal] = useState(false);

  const closeRefresh = useRecoilCallback(({ snapshot, set }) => async () => {
    _cardsRefresh()
    setCard(undefined)
  })

  const renderCards = (crds: any) =>
    // if (crds.length === 0) return <div>No results</div>

    crds
      .filter((card: CardType) => ENABLE_ECHOES ? true : !['Echoes 1', 'Echoes 2'].includes(card.Set!))
      .map((cardItem: CardType) => cardItem.id !== (card?.id || undefined) && (
        <AnimatedCard cardItem={cardItem} key={cardItem.id} onClick={() => setSelectedCard(cardItem)} />
      ))
  const openSingleModal = (card: CardType) => {
    setCard(undefined)
    setTimeout(() => {
      setCard(card)
    }, 0.5)
    // setModalSingle(true)
  }

  const submitSearch = (evt: any) => {
    evt.stopPropagation()
    evt.preventDefault()
  }

  const cardsLoadable = useRecoilValueLoadable(AllCardsSelector)
  // const cards = getCards()
  //   const chars = cards
  //   .filter((c: any) => c.CardType === 'Character' && c.ListedTokens.length !== c.TokenIds.length)
  // const techs = cards
  //   .filter((c: any) => c.CardType === 'Technology' && c.ListedTokens.length !== c.TokenIds.length)

  if (cardsLoadable.state !== 'hasValue') {
    return <></>
  }
  const cards = cardsLoadable.contents

  return (
    <div className="marketplace-mycards-view">
      {/* <form className="search-bar" onSubmit={submitSearch}>
        <FontAwesomeIcon icon={faSearch} className="search-bar-icon" />
        <input type="text" placeholder="Search by name..." value={search} onChange={(evt) => {
          setSearch(evt.target.value)
        }} />

        <input type={'submit'} style={{ display: 'none' }} />
      </form> */}
      <div style={{ display: 'flex' }}>
        <AnimateSharedLayout type="crossfade">
          <div className={`card-list ${card ? 'selected' : ''}`} style={{ flex: 1 }
          }>
            {/* <LibraryTitle title={"Character"} /> */}

            {/* <AnimatePresence> */}

            {
              renderCards(cards)
            }
            {/* </AnimatePresence> */}
            {/* <LibraryTitle title={"Technology"} /> */}
            {/* <AnimatePresence> */}
            {
              // renderCards(techs)
            }
            {/* </AnimatePresence> */}

          </div>
          {/* <AnimatePresence> */}

          {card && <motion.div
            layout
            initial={{
              opacity: 0, x: 50,
            }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ x: 50, opacity: 0 }}
            style={{ position: 'relative', overflow: 'visible' }}
            className='card-list-detail'
          >
            <Button
              variant={'icon'}
              size={'small'}
              right
              className="close-btn"
              onClick={() => setCard(undefined)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <div style={{
              position: 'sticky',
              display: 'flex',
              top: 125,
              // background: 'rgba(13, 13, 13, 1)',
              height: 'calc(100vh - 125px)',
              overflowY: 'scroll',
              flexDirection: 'column',
              padding: 30,
            }}
              className="card-detail scroll-style">
              <motion.div style={{ flex: 5, width: '100%' }} layout layoutId={card.id}>

                <Card
                  {...card}
                  key={`nft_card_${card.id}`}
                />
              </motion.div>

              <div>

                {
                  card?.Gender && <div>
                    <b>
                      Gender:&nbsp;
                    </b>
                    {card?.Gender}
                  </div>
                }
                {Number(card?.Ability?.length) > 0 ? (
                  <div style={{
                    marginTop: 20,

                  }}>
                    <b>Abilities</b>
                    {card?.Ability?.map((a: any, i: number) => (<div key={`a_${i}`} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                      <div style={{ cursor: 'pointer' }} >
                        <img src={`/images/abilities/${a.toLowerCase()}.png`} alt={''} />
                      </div>
                      <div>
                        <b>{a}</b>
                        <div>{getAbilityText(a)}</div>
                      </div>
                    </div>
                    ))}

                  </div>) : <></>}

              </div>

            </div>
          </motion.div>}

          {/* </AnimatePresence> */}
        </AnimateSharedLayout>

      </div>
      <CardDetailModal card={selectedCard} closeAction={() => setSelectedCard(null)} />
      <Footer />
    </div>
  )
}
const getAbilityText = (ability: any) => {
  if (ability === 'BERSERK') return "Gets +X% Attack, where X% is the amount of damage received by that Character, over that Character's total HP, expressed as a percentage. When a Character with Berserk heals up to their initial HP, they die."
  if (ability === 'FRIGHTEN') return 'Whenever Frighten triggers, the frightened Character moves to the last slot, leaving their own slot empty.'
  if (ability === 'GETS') return "A card acquires a modification to their stats or abilities. Get can be either a triggered ability or an ongoing effect. If it's a trigger, the effect is permanent. If it's an ongoing effect, that effect will cease when the card providing it leaves the playfield. "
  if (ability === 'GRIP') return 'Whenever a Character with Grip moves from one slot to another, it does so with their attached Technology. If necessary, other cards will move to make room for them.'
  if (ability === 'HEAL') return 'Whenever Heal triggers, the healed Character recovers X Health Points. A Character cannot gain HP over their initial HP.'
  if (ability === 'OBEY') return 'Whenever Obey triggers, the obeying Character exchanges places with the Character on the first lane. No other Characters move.'
  if (ability === 'RIOT') return 'Whenever Riot triggers, the rioting Character moves X slots to the specified direction. When necessary, Characters move a slot to their right to make room for the rioting Character.'
  if (ability === 'SWAP') return 'Whenever Swap triggers, swapping Characters exchange slots.'
  if (ability === 'TOUGHNESS') return 'A Character with Toughness receives X less damage whenever they are attacked. If a Character with Toughness X gets Toughness Y through any method, they consider the highest number +1.'
  return 'UNKNOWN'
}

const AnimatedCard = ({ cardItem, onClick }: { cardItem: CardType, onClick: Function }) => {
  const spring = {
    type: 'spring',
    damping: 25,
    stiffness: 120,
  }
  const { TokenIds, ListedTokens } = cardItem as any

  return <motion.div
    className="card-layout"
    variants={{
      hidden: {
        opacity: 0,
        y: -50,
        rotate: -50,
        scale: 0.1,
      },
      visible: (i: any) => ({
        opacity: 1,
        y: 0,
        rotate: 0,
        scale: 1,
        transition: {
          delay: i * 0.05,
        },
      }),
    }}
    transition='visible'
    initial='hidden'
    animate='visible'

    exit='hidden'
    custom={cardItem.id}
    style={{
      height: 'fit-content',
    }}
    layout
    layoutId={cardItem.id}
    onClick={() => onClick(cardItem)}
    key={`nft_card_${cardItem.id}`}
  >

    <Card {...cardItem} />
    <div className="nft-background" />
  </motion.div >
}

