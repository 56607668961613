import './TournamentsHead.component.scss'
import { Link } from 'react-router-dom'
import InfoHex from 'assets/icons/infohexagon.svg'
import TournamentsFilters from './TournamentsFilters.component'

export default function TournamentsHead({
  filters, setFilters, search, setSearch,
}:{filters:any, setFilters:Function, search:string, setSearch:Function}) {
  return (
  <div className="tournaments-head">
    <div className="tournaments-head__content">
      <Link to="/tournaments/info" className='tournament-hero-top__info' style={{ marginTop: -20 }}>
        Tournaments info &nbsp; <img src={InfoHex} alt={'tournaments info'} />
      </Link>
      { /* <div className="tournaments-head__content__featured">Featured Now</div> */ }
    </div>
    { /* <div className="tournaments-head__slider"></div> */ }
    { /* <h1 className="tournaments-head__title">Tournaments</h1> */ }
    <TournamentsFilters filters={filters} setFilters={setFilters} search={search} setSearch={setSearch} />
  </div>
  )
}
