// @ts-ignore
import { Balance } from 'domain/balance'
import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import getConfig from '../config.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

export default class BalancesService {
  // eslint-disable-next-line max-len
  public static async offChainBalance(wallet:string)
  : Promise<Balance[]> {
    const config = getConfig()
    const token = ValidateIfTokenIsPresent()
    const requestData : ServiceData = {
      url: `${config.hostURL}/api/v1/rewards/offchain?wallet=${wallet}`,
      body: {},
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    return ServiceConsumer.get<Balance[]>(requestData)
  }
}
