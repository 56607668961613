interface Account {
  address: string;
  ethNetwork: string;
}

export default abstract class Wallet implements Account {
  address: string

  ethNetwork: string

  constructor(address: string, network: string) {
    this.address = address
    this.ethNetwork = network
  }
}
