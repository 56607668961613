/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import StakingRepository from 'repositories/staking.repository'
import { connectedWallet } from 'selectors/selectors'

type useUnstakeProps = {
  onSuccess: () => void
  onError: (error: string) => void
}

export default function useUnstake(props: useUnstakeProps) {
  const wallet = useRecoilValue(connectedWallet)
  const [loading, setLoading] = useState(false)
  const [error] = useState('')

  const unstake = async (id: number) => {
    setLoading(true)
    try {
      if (!wallet) return
      await StakingRepository.unstake(id, wallet.address)
      props.onSuccess()
    } catch (e: any) {
      props.onError(e.message)
    }
    setLoading(false)
  }

  return {
    unstake,
    loading,
  }
}
