export type RewardState = 'PENDING' | 'CLAIMED'

export type RewardDto = {
  address: string,
  amount: number,
  currency: string,
  state: RewardState,
  date: string,
  rewardId: string,
  txHash: string,
  id: string,
  assetTypeFieldValue: string
  origin: string
  originId: string
  nameToDisplay: string
  originToDisplay: string
}

class Reward {
  address: string

  amount: number

  currency: string

  state: RewardState

  date: string

  id: string

  txHash: string

  assetTypeFieldValue: string

  origin: string

  originId: string

  nameToDisplay: string

  originToDisplay: string

  constructor(dto: RewardDto) {
    this.address = dto.address
    this.amount = dto.amount
    this.state = dto.state
    this.date = dto.date
    this.id = dto.id
    this.currency = dto.currency
    this.txHash = dto.txHash
    this.assetTypeFieldValue = dto.assetTypeFieldValue
    this.origin = dto.origin
    this.originId = dto.originId
    this.nameToDisplay = dto.nameToDisplay
    this.originToDisplay = dto.originToDisplay
  }

  clone() {
    return new Reward(this as any)
  }

  public alreadyClaimed() {
    return this.state === 'CLAIMED'
  }

  public setNameToDisplay(newNameToDisplay: string) {
    this.nameToDisplay = newNameToDisplay
  }

  public setOriginToDisplay(newOriginToDisplay: string) {
    this.originToDisplay = newOriginToDisplay
  }
}

export default Reward
