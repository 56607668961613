import { Booster } from 'domain/booster.model'
import { getHost } from 'infraestructure/services/host-consumer/host-consumer.service'
import { ServiceConsumer } from 'infraestructure/services/service-consumer/service-consumer.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

const BoostersRepository = {
  async getAll(): Promise<Booster[]> {
    const token = ValidateIfTokenIsPresent()
    const { boosterpacks } = await ServiceConsumer.get<{ boosterpacks: Booster[] }>({
      url: `${getHost()}/api/v1/minter/boosterpacks`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return boosterpacks
  },

  async open(body: any): Promise<any> {
    const token = ValidateIfTokenIsPresent()
    const mintedTokens = await ServiceConsumer.post({
      url: `${getHost()}/api/v1/minter/boosterpacks/open`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body,
    })
    return mintedTokens
  },
}

export default BoostersRepository
