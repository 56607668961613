import Token from './token.model'

export default class Asset extends Token {
  private data: any

  public metadata: any

  constructor(data: any) {
    super('ERC721', false)
    this.data = data
    this.metadata = data.metadata
  }

  public getId(): string {
    return this.data.token_id
  }

  public getImage(): string {
    return this.data.image_url
  }

  public getName(): string {
    return this.data.name
  }

  public getStatus(): string {
    return this.data.status
  }

  public getOwner(): string {
    return this.data.user
  }

  public getMintNumber(): string {
    return this.metadata.MintNumber
  }

  public getTokenAddress(): string {
    return this.data.token_address
  }
}
