/* eslint-disable no-console,max-len */
// noinspection JSDeprecatedSymbols

import './card-sprite.component.scss'
import { CardType as CardTypeModel } from 'domain/card.model'
import type { MutableRefObject } from 'react'
import { useRef } from 'react'
import CardMarkupComponent from './card-markup.component'
import CardService from '../../../services/card.service'

export default function CardSprite(card: CardTypeModel & { disabled?: boolean }) {
  const self: MutableRefObject<{ sn: string; isVideo: boolean; bg: HTMLElement | undefined; video: HTMLVideoElement | undefined; playing: boolean; callback: any; handler: any; message: any } | undefined> = useRef()
  const isDisabled = card.disabled

  if (!self.current) {
    const sn: string = CardService.getSN(card)
    self.current = {
      sn,
      isVideo: CardService.isCardGameVideoId(sn),
      bg: undefined,
      video: undefined,
      playing: false,
      callback() { },
      handler() { },
      message: undefined,
    }
  }

  const imgUrl: string = self.current?.isVideo ? CardService.getVideoUrl(self.current?.sn) : CardService.getImageUrl(self.current?.sn)
  const bg = (
    <div className="bg stretch" style={{
      backgroundImage: `url('${imgUrl}')`,
      filter: isDisabled ? 'grayscale(1)' : 'none',
    }}></div>
  )

  switch (card.CardType) {
    case 'HQ':
      return (
        <div className="card-sprite hq">
          <div className="card-container">
            <div className="card">
              {bg}
              <div className="mainFrame" style={{
                backgroundImage: `url(/images/card/hq/color/${CardService.slugify(card.Faction)}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="box" style={{
                backgroundImage: `url(/images/card/hq/rarity/${CardService.slugify(card.Rarity)}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="name"><b>{card.Name}</b></div>
              <div className="st">{card.St}</div>
              <div className="artist">{card.Artist}</div>
              <div className="cardId">{self.current.sn}</div>
              <CardMarkupComponent {...card} />
            </div>
          </div>
        </div>
      )
    case 'Character':
      return (
        <div className="card-sprite character">
          <div className="card-container">
            <div className="card">
              {bg}
              <div className="mainFrame" style={{
                backgroundImage: `url(/images/card/character/color/${CardService.slugify(card.Faction) || 'undeviant'}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="box" style={{
                backgroundImage: `url(/images/card/character/rarity/${CardService.slugify(card.Rarity) || 'common'}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="box" style={{
                backgroundImage: 'url(/images/card/character/atk/planning.png)',
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="box" style={{
                backgroundImage: `url(/images/card/character/atk/${CardService.slugify(card.AtkType)}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="name">
                <div className={`gender ${CardService.slugify(card.Gender)}`} />
                <b>{card.Name}</b>
              </div>
              <div className="atk_range">{card.AtkRange || ''}</div>
              <div className="atk">{card.Atk}</div>
              <div className="hp">{card.HP}</div>
              <div className="artist">{card.Artist}</div>
              <div className="cardId">{self.current.sn}</div>
              <CardMarkupComponent {...card} />
            </div>
          </div>
        </div>
      )
    case 'Technology':
      return (
        <div className="card-sprite technology">
          <div className="card-container">
            <div className="card">
              {bg}
              <div className="mainFrame" style={{
                backgroundImage: `url(/images/card/technology/color/${CardService.slugify(card.Faction) || 'undeviant'}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="box" style={{
                backgroundImage: `url(/images/card/technology/rarity/${CardService.slugify(card.Rarity) || 'common'}.png)`,
                filter: isDisabled ? 'grayscale(1)' : 'none',
              }} />
              <div className="name"><b>{card.Name}</b></div>
              <div className="res">{card.Res}</div>
              <div className="artist">{card.Artist}</div>
              <div className="cardId">{self.current.sn}</div>
              <CardMarkupComponent {...card} />
            </div>
          </div>
        </div>
      )
    default:
      return <></>
  }
}
