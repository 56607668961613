import { useRef, useState } from 'react'
import './header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useOnClickOutside } from 'usehooks-ts'

type HeaderDropdownT = {
  children?: any;
  className?: string;
  sectionTitle: string;
}

export default function HeaderDropdown({ children, sectionTitle }: HeaderDropdownT) {
  const containerElement = useRef<HTMLDivElement>(null)

  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useState(false)
  const onClick = () => setIsActive(!isActive)
  useOnClickOutside(containerElement, () => setIsActive(false))

  return (
    <div ref={containerElement} className="header-dropdown">
      <p className={`header-dropdown-title ${isActive ? 'active' : 'inactive'}`} onClick={onClick} >
        {sectionTitle}
        {isActive
          ? <FontAwesomeIcon icon={faChevronUp as IconProp}/> : <FontAwesomeIcon icon={faChevronDown as IconProp}/>
        }
      </p>
      <div className={`header-dropdown-elements ${isActive ? 'active' : 'inactive'}`} ref={dropdownRef}>
        <div className="dropdown-background"></div>
        <div className="dropdown-content" onClick={onClick}>
         {children}
        </div>
      </div>
    </div>
  )
}
