/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import StakingRepository from 'repositories/staking.repository'
import { connectedWallet } from 'selectors/selectors'

type StakedDailyRewardComponentProps = {
  onSuccess: () => void
  onError: (error: string) => void
}

export default function useBuySdevItem(props: StakedDailyRewardComponentProps) {
  const wallet = useRecoilValue(connectedWallet)
  const [loading, setLoading] = useState(false)
  const [error] = useState('')

  const buyItem = async (id: string) => {
    setLoading(true)
    try {
      if (!wallet) return
      await StakingRepository.buyItem(id, wallet.address)
      props.onSuccess()
    } catch (e: any) {
      props.onError(e.message)
    }
    setLoading(false)
  }

  return {
    buyItem,
    loading,
  }
}
