export const ValidateIfTokenIsPresent = (): string => {
  try {
    const sessionStr = localStorage.getItem('session')
    if (!sessionStr) return ''
    const session = JSON.parse(sessionStr ?? '')
    if (!session.token) return ''
    return session.token
  } catch (e) {
    return ''
  }
}

type sessionData = {
  wallet: string,
  token: string,
}

export const getSessionData = (): sessionData|undefined => {
  const sessionString = localStorage.getItem('session')
  if (!sessionString) return undefined
  const session: sessionData = JSON.parse(sessionString)
  return session
}

export const sessionExpired = (): void => {
  localStorage.removeItem('session')
  window.location.reload()
}
