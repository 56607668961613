import ReferralComponent from '../../components/Referral/referral.component'
import '../views.scss'
import './referral.view.scss'
import Footer from '../../components/Footer/Footer.component'
import Banner from './assets/referral-banner.png'

export default function ReferralView() {
  return (
      <div className="referral-view">
        <div className='referral-view__title'>
          <h2 className='bigger-text'>Bring your friends!</h2>
          <h3 className='smaller-text'>Welcome to our Referral Program</h3>
        </div>
        <div className='referral-banner-centered'>
          <img src={Banner} className='img-banner-size'/>
        </div>
        <div>
          <div className="faction-section__container">
          <ReferralComponent />
          </div>
        </div>
        <Footer />
      </div>
  )
}
