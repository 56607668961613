/* eslint-disable */
import './library.component.scss'
import { CardType } from 'domain/card.model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil'
import {
  cardsFetchAtom,
  connectedWallet,
  currentDeckAtom,
  currentDeckSelector,
  deckCardsSelector,
  DeckFormatsSelector,
  TechSelector,
} from 'selectors/selectors'
import { checkPlayable } from 'views/deckbuilder/deck-builder.view'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import { useState } from 'react'
import { Spinner } from 'components/Loader/Loader.component'
import Card from '../Card/Card.component'
import { faPlus, faEye } from '../../../infraestructure/repositories/icons.repository'
import Button from '../Button/Button.component'
import IconNft from '../../../assets/icons/nft.png'
import DeckFormatsService from "../../../services/deck-formats.service";
import { allowNftOnly, filterCardsBy } from "./Library.component";
import getConfig from 'config.service'

export default function LibraryTechnology({
  onViewCard,
  search,
}: { onViewCard: Function, search: string }) {
  const config = getConfig()
  const { ENABLE_ECHOES } = config
  const [loading, setLoading] = useState<string>('')
  const technologyCards = useRecoilValueLoadable(TechSelector)
  const deck = useRecoilValueLoadable(currentDeckSelector)
  const deckFormats = useRecoilValueLoadable(DeckFormatsSelector)

  const addCardToDeck = useRecoilCallback(({ snapshot, set }) => async (card: CardType) => {
    const release = snapshot.retain()
    try {
      const currentDeck = await snapshot.getPromise(currentDeckSelector)
      const deckCards = await snapshot.getPromise(deckCardsSelector)
      const wallet = await snapshot.getPromise(connectedWallet)
      const newDeck = {
        ...currentDeck,
        cards: card.CardType === 'HQ'
          ? [
            { ...card },
            ...deckCards.filter((deckCard: any) => deckCard.CardType !== 'HQ'),
          ]
          : [
            ...deckCards,
            { ...card },
          ],
      }
      if (wallet) {
        try {
          const isPlayable = checkPlayable(newDeck)
          setLoading(card.id as string)
          const res = await DeckRepository.update({ ...newDeck, playable: isPlayable }, wallet.address)
          set(currentDeckAtom, res)
        } catch (e: any) {
          alert(e.message)
        }
      }
      const r = await snapshot.getPromise(cardsFetchAtom)
      set(cardsFetchAtom, () => r + 1)
      setLoading('')
    } catch (e) {
      setLoading('')
      release()
    }
  }, [deck.contents])

  if (technologyCards.state === 'loading') return <>loading..</>
  if (technologyCards.state === 'hasError') {
    alert('error getting technology cards')
    console.error(technologyCards.contents)
    return <></>
  }
  const techCards: CardType[] = filterCardsBy(search, DeckFormatsService.filterByDeckFormat(technologyCards.contents, deckFormats.contents, deck.contents))
  if (techCards.length < 1) return <></>
  const addedCards = deck.state === 'hasValue' ? deck.contents.cards.map((c: CardType) => c.Name) : []
  
  return (
    <div className="library-technology-card-list">
      {(techCards)
        .filter((card: CardType) => ENABLE_ECHOES ? true : !['Echoes 1', 'Echoes 2'].includes(card.Set!))
        .map((card: CardType) => (
          <div className={`library-technology-card-list-space ${(addedCards.includes(card.Name) || loading === card.id) ? 'added' : ''}`} key={`card_${card.id}`}>
            {addedCards.includes(card.Name) && (<div>Added</div>)}
            {loading === card.id && (<div style={{
              width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
            }}>
              <Spinner />
              loading...</div>)}
            <Card disabled={allowNftOnly(card)} {...card} />
            <div className="hover-overlay" />
            <div className="hover-options">
              <div className="hover-buttons">
                {!allowNftOnly(card) && <Button variant={'primary'} size={'small'} left className="add-btn"
                  onClick={() => { addCardToDeck(card).then() }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>}

                <Button variant={'primary'} size={'medium'} className="view-btn" onClick={() => onViewCard(card)} >
                  <FontAwesomeIcon icon={faEye} />
                  View card
                </Button>
              </div>
              {/* <div className="hover-status"> */}
              {/* <Button variant={"primary"} size={"small"} left className="fav-btn">
              <FontAwesomeIcon icon={faStar} />
                </Button> */}
              {/* <Button variant={"primary"} size={"small"} right className="count-btn">1</Button> */}
              {/* </div> */}
            </div>
            {(card as any).TokenIds.length > 0 && (<div className="nft-amount">{(card as any).TokenIds.length}</div>)}
            {card.IsNft && (<div className='is-nft is-nft-technology'><img src={IconNft} alt={"nft"} /></div>)}
          </div>
        ))}
    </div>
  )
}
