import { useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import VolExchangeRepository from 'repositories/asset-exchange.repository'
import { connectedWallet } from 'selectors/selectors'

export type UseDevDepositProps = {
  token: 'IMX' | 'USDC' | 'ETH' | 'GODS',
  onSuccess?: (tx: string) => void,
  onFail?: (error: string) => void,
}

export default function useTokenToDevExchange(props: UseDevDepositProps) {
  const [loading, setLoading] = useState(false)
  const wallet = useRecoilValueLoadable(connectedWallet)
  const exchange = async (amount: number) => {
    setLoading(true)
    try {
      if (!wallet.contents.address) throw new Error('Wallet not connected')
      const tx = await VolExchangeRepository.exchangeTokenToDev({
        amount: amount.toString(),
        token: props.token,
      })
      props.onSuccess?.(tx)
    } catch (error: any) {
      props.onFail?.(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    exchange,
    loading,
  }
}
