/* eslint-disable */
import { useState } from 'react'
import './DeckDetail.scss'
import Button from 'components/Button/Button.component'

import {
  connectedWallet, currentDeckAtom, currentDeckSelector, deckCardsSelector,
  cardsFetchAtom, playableDeckSelector, openHQPickerAtom, decksFetchAtom,
} from 'selectors/selectors'

import {
  useRecoilCallback, useRecoilValueLoadable, useRecoilState, useRecoilRefresher_UNSTABLE,
} from 'recoil'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import { CardType } from 'domain/card.model'
import { Spinner } from 'components/Loader/Loader.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Modal } from '../Modal/Modal.component'
import DeckDetailCard from './DeckDetailCard.component'
import DeckContainer from '../DeckContainer/DeckContainer.component'
import { LoaderRepository } from "../../../infraestructure/repositories/loader.repository";

const DeckDetail = ({ onViewCard }: any) => {
  const cards = useRecoilValueLoadable(deckCardsSelector)
  const playable = useRecoilValueLoadable(playableDeckSelector)
  const [openHQPicker, setOpenHQPicker] = useRecoilState(openHQPickerAtom)
  const refreshDeck = useRecoilRefresher_UNSTABLE(currentDeckSelector)
  const [confirmOpened, setConfirmOpened] = useState(false)
  const [errorText, setErrorText] = useState({ visible: false, text: '' })

  const checkPlayable = (newDeck: any) => {
    const hq = newDeck.cards.find((c: any) => c.CardType === 'HQ')
    const nonHQ = newDeck.cards.filter((c: any) => c.CardType !== 'HQ')
    return hq && nonHQ.length === 8
  }
  const removeCardFromDeck = useRecoilCallback(({ snapshot, set }) => async (card: String) => {
    const release = snapshot.retain()
    try {
      const cardsState = await snapshot.getPromise(deckCardsSelector)
      const currentDeck = await snapshot.getPromise(currentDeckSelector)
      const wallet = await snapshot.getPromise(connectedWallet)
      const afterRemoved: CardType[] = cardsState.filter((c: CardType) => c.id !== card)
      const newDeck = { ...currentDeck, cards: afterRemoved }

      if (wallet) {
        const isPlayable = checkPlayable(newDeck)
        try {
          const res = await DeckRepository.update({ ...newDeck, playable: isPlayable }, wallet.address)
          set(currentDeckAtom, res)
          const r = await snapshot.getPromise(cardsFetchAtom)
          set(cardsFetchAtom, () => r + 1)
          LoaderRepository.refreshDecks = true
        } catch (e: any) {
          setErrorText({ visible: true, text: e?.response?.data?.message ?? "Unknown error" })
        }
      }
    } finally {
      release()
    }
  })
  const deleteDeck = useRecoilCallback(({ snapshot, set }) => async () => {
    const release = snapshot.retain()
    try {
      const currentDeck = await snapshot.getPromise(currentDeckSelector)
      const wallet = await snapshot.getPromise(connectedWallet)
      if (wallet) {
        await DeckRepository.deleteDeck(currentDeck.id, wallet.address)
        set(currentDeckAtom, undefined)
      }

      const r = await snapshot.getPromise(decksFetchAtom)

      set(decksFetchAtom, () => r + 1)
    } catch (e: any) {
      closeConfirm()
      setErrorText({ visible: true, text: e?.response?.data?.message ?? "Unknown error" })
    } finally {
      release()
    }
  })

  const openConfirm = () => {
    setConfirmOpened(true)
  }
  const closeConfirm = () => {
    setConfirmOpened(false)
  }
  if (
    cards.state === 'loading' || playable.state === 'loading'

  ) {
    return <DeckContainer>
      <div style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
      }}>
        <Spinner />
        loading...

      </div>
    </DeckContainer>
  }

  if (cards.state === 'hasError' || playable.state === 'hasError') {
    console.error('error',cards,playable)
    return <DeckContainer>
      <div style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
      }}>
        Error loading deck
        <Button variant={'primary'} size={'medium'} onClick={() => { refreshDeck() }}>refresh</Button>
      </div>
    </DeckContainer>
  }

  const hq = cards.contents.find((c: any) => c.CardType === 'HQ')
  const nonHQ = cards.contents.filter((c: any) => c.CardType !== 'HQ')

  const emptySlots = nonHQ.length >= 8 ? 0 : (8 - nonHQ.length)

  return (<>
    <AlertModal isOpen={errorText.visible} onClose={() => setErrorText({ visible: false, text: '' })} text={errorText.text} />
    <Modal isActive={confirmOpened} closeAction={closeConfirm} id="conditions-modal">
      <FontAwesomeIcon icon={faExclamationCircle as IconProp} size='3x' className='error-icon' />
      <h1 className="error-title">ATTENTION</h1>
      <p className="error-description" style={{ textTransform: 'initial' }}>
        Are you sure you want to delete this deck?
      </p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          variant={'outline'}
          size={'medium'}
          onClick={closeConfirm}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          variant={'primary'}
          size={'medium'}
          onClick={() => deleteDeck()}
        >
          Accept
        </Button>
      </div>
    </Modal>
    <DeckContainer>
      <div className='deck__container__list'>
        {!hq ? <DeckDetailCard empty type='hq' onClick={() => { setOpenHQPicker(!openHQPicker) }} /> : <DeckDetailCard id={hq.id} gender={hq.Gender} name={hq.Name} faction={hq.Faction?.toLowerCase()} type={hq.CardType.toLowerCase().substr(0, 4)} art={hq.Art} key={hq.id} onRemove={removeCardFromDeck} onViewCard={() => { onViewCard(hq) }} />}

        {nonHQ.map((c: any) => (
          <DeckDetailCard
            id={c.id}
            gender={c.Gender}
            name={c.Name}
            faction={c.Faction.toLowerCase()}
            type={c.CardType.toLowerCase().substr(0, 4)}
            art={c.Art}
            key={c.id}
            isNft={c.IsNft}
            onRemove={removeCardFromDeck}
            onViewCard={() => { onViewCard(c) }} />
        ))}

        {[...Array(emptySlots).keys()].map((c: any, i: number) => (<DeckDetailCard empty key={i} />))}
      </div>
      <div className='deck__container__details'>
        <div className='deck__container__details__deck-counter'>
          <p>Cards in Deck</p>
          <p className={`total-cards ${playable.contents ? 'complete' : ''}`}>{nonHQ.length}/8</p>
        </div>
        <div className='deck__container__details__deck-actions'>
          <Button
            variant={'danger'}
            size={'medium'}
            onClick={() => openConfirm()}
          >
            delete deck
          </Button>
        </div>
      </div>
    </DeckContainer></>)
}

export const AlertModal = ({ isOpen, onClose, text }: { isOpen: boolean, text: string, onClose: () => void }) => {
  return <Modal isActive={isOpen} closeAction={onClose} id="conditions-modal">
    <FontAwesomeIcon icon={faExclamationCircle as IconProp} size='3x' className='error-icon' />
    <h1 className="error-title">ATTENTION</h1>
    <p className="error-description" style={{ textTransform: 'initial' }}>
      {text}
    </p>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        variant={'outline'}
        size={'medium'}
        onClick={onClose}
      >
        Ok
      </Button>
    </div>
  </Modal>
}

export default DeckDetail
