/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import Button from 'components/Button/Button.component'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { SDevStoreItem } from 'selectors/staking.selectors'
import Flex from 'components/Flex/Flex'
import useBuySdevItem from 'views/staking/hooks/useBuySdevItem.hook'
import Text from 'components/Text/Text'
import Toast from 'components/Toast/Toast.component'
import { useState } from 'react'
import useItemLockTimer from 'views/staking/hooks/useItemLockTimer.hook'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import IconsDEV from '../../../../assets/icons/icon-sdev.png'

type modalProps = {
  isActive: boolean
  closeAction: () => void,
  item?: SDevStoreItem
}

export default function StakeStoreModalComponent(props: modalProps) {
  const { isActive, closeAction } = props
  const [showToast, setShowToast] = useState<'success' | 'error' | undefined>(undefined)
  const sDevBalanceRefresher = useRecoilRefresher_UNSTABLE(profileBalancesSelector)
  const { lockTime, loadingTimer, refreshTimer } = useItemLockTimer({ id: props.item?.id })
  const { loading, buyItem } = useBuySdevItem({
    onError() {
      setShowToast('error')
    },
    onSuccess() {
      setShowToast('success')
      closeAction()
      refreshTimer()
      sDevBalanceRefresher()
    },
  })

  if (!props.item) return null
  return <>
    <Toast isActive={showToast === 'error'} id={`error-toast-sdev-store-${props.item.id}`} style={{ background: 'red' }}
      onClose={() => setShowToast(undefined)}>
      <Text fontWeight={600}>
        An error has ocurred, please reload the page and try again.
      </Text>
    </Toast>
    <Toast isActive={showToast === 'success'}
      id={`sucess-toast-dev-store'-${props.item.id}`}
      style={{ background: '#32a852' }} onClose={() => setShowToast(undefined)}>
      <Text fontWeight={600}>
        Purchase successful!
      </Text>
    </Toast>
    <Modal id="withdraw-dev" isActive={isActive} closeAction={() => closeAction()}>
      <h1>Purchase {props.item.name}?</h1>
      <img src={props.item.art} alt={'Booster'} height={'200px'} />
      {(!loading && !loadingTimer && !(lockTime > 0)) && <Button
        disabled={false}
        style={{ width: '200px', height: '40px', fontSize: '20px' }}
        variant='primary_alt'
        size='medium'
        onClick={() => {
          if (props.item) {
            buyItem(props.item.id)
          }
        }}>
        <Flex flexDirection='row' gap='0.5ch' justifyContent='center' alignItems='center'>
          <Text>
            Get it for
          </Text>
          <img src={IconsDEV} alt={'aaa'} height="18px" />
          <Text>
            {props.item.cost}
          </Text>
        </Flex>
      </Button>}
      {loading && <Text>Please wait...</Text>}
      {lockTime > 0 && <Text>
        You can buy again in {lockTime} seconds.
      </Text>}
    </Modal >
  </>
}
