import Asset from 'domain/asset.model'
import Button from 'components/Button/Button.component'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useCallback, useContext } from 'react'
import { CraftingContext } from '../crafting-context.component'
import './nft-picker-modal.component.scss'
import useIsIngredientCompleted from '../hooks/useIsIngredientCompleted.hook'

function NftCard({ asset }: { asset: Asset }) {
  const {
    pickedNfts,
    setPickedNfts,
  } = useContext(CraftingContext)
  // eslint-disable-next-line max-len
  const isPicked = useCallback(() => pickedNfts.includes(asset.getId()), [pickedNfts.length])
  const isCompleted = useIsIngredientCompleted(asset.metadata.id)
  const handlePick = () => {
    if (isPicked()) {
      setPickedNfts(pickedNfts.filter((picked) => picked !== asset.getId()))
    } else {
      if (isCompleted) return
      setPickedNfts([...pickedNfts, asset.getId()])
    }
  }

  return <div key={asset.getId()}
    onClick={() => handlePick()}
    className={`crafting-nft-card ${isPicked() ? 'crafting-nft-card__selected' : ''}`}>
    <div className={isPicked() ? 'crafting-nft-selected' : 'crafting-nft-unselected'}>
      {isPicked() ? 'SELECTED' : 'CLICK TO SELECT'}
    </div>
    <img
      src={asset.getImage()}
      alt={asset.getName()} />
    <div className='crafting-nft-card__overlay' />
    {asset.getMintNumber() && <h2>Mint: {asset.getMintNumber()}</h2>}
  </div>
}

function AvailableNfts() {
  const {
    ingredientsAvailable,
    selectingCard,
  } = useContext(CraftingContext)
  return <div className='available-nfts scroll'>
    {ingredientsAvailable?.get(selectingCard!)?.map((asset) => <NftCard asset={asset} key={asset.getId()} />)}
  </div>
}

export default function NftPickerModal() {
  const { selectingCard, setSelectingCard, ingredientsAvailable } = useContext(CraftingContext)
  const handleClose = () => {
    setSelectingCard(undefined)
  }

  return <Modal isActive={(!!selectingCard && !!ingredientsAvailable?.get(selectingCard)?.length)} closeAction={handleClose} id='nft-modal'>
    <h1>Available NFTs</h1>
    <h2>Pick the NFT(s) to be used in the crafting</h2>
    <AvailableNfts />
    <div className='modal-button'>
      <Button variant={'outline'} size={'medium'} onClick={(event) => {
        event.preventDefault()
        handleClose()
      }} >Done</Button>
    </div>
  </Modal>
}
