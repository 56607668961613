import { Box } from 'components/Box/box.component'
import './exchange.view.scss'
import Rectangle from 'components/Rectangle/Rectangle.component'
import Flex from 'components/Flex/Flex'
import Footer from 'components/Footer/Footer.component'
import Text from 'components/Text/Text'
import InGameDev from './components/InGameDev.component'
import InWalletDev from './components/InWalletDev.component'
import StakedDev from './components/StakedDev.component'
import BuyEthModal from './components/BuyTokens/EthExchangeModal.component'
import BuyUsdcModal from './components/BuyTokens/UsdcExchangeModal.component'
import BuyImxModal from './components/BuyTokens/ImxExchangeModal.component'
import useDevConvertion from './hooks/useDevConvertion.hook'
import BuyGodsModal from './components/BuyTokens/GodsExchangeModal.component'

export default function ExchangeView() {
  const devPriceInUsd = useDevConvertion(1)
  if (devPriceInUsd === undefined || devPriceInUsd === 0) return (<div>Loading...</div>)

  return (
    <div className='exchange-view'>
      <div className='title' style={{ fontSize: '3rem' }}>DEV EXCHANGE</div>
      <Text fontSize={'0.9rem'}>Exchange transactions may take a couple of minutes to be completed.</Text>
      <Flex flexDirection='row' gap="10px">
        <Box width='40vw'>
          <h1>DEV TOKEN</h1>
          <Flex flexDirection='column' gap='10px'>
            <Rectangle direction='left'>
              <InGameDev />
            </Rectangle>
            <Rectangle direction='left'>
              <InWalletDev />
            </Rectangle>
            <Rectangle direction='left'>
              <StakedDev />
            </Rectangle>
          </Flex>
        </Box>
        <Box width='40vw'>
          <h1>IMMUTABLE X WALLET</h1>
          <Flex flexDirection='column' gap='10px'>
            <Rectangle>
              <BuyEthModal />
            </Rectangle>
            <Rectangle>
              <BuyUsdcModal />
            </Rectangle>
            <Rectangle>
              <BuyImxModal />
            </Rectangle>
            <Rectangle>
              <BuyGodsModal />
            </Rectangle>
          </Flex>
        </Box>
      </Flex>
      <Footer />
    </div>
  )
}
