import Rectangle from 'components/Rectangle/Rectangle.component'
import Flex from 'components/Flex/Flex'
import { useRecoilValueLoadable } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import Text from 'components/Text/Text'
import { yesterdayEarningsSdevSelector } from 'selectors/staking.selectors'
import IconsDEV from '../../../assets/icons/icon-sdev.png'

export default function StakingMysDev() {
  const sDev = useRecoilValueLoadable(profileBalancesSelector).contents?.sdev || 0
  const sDevYesterday = useRecoilValueLoadable(yesterdayEarningsSdevSelector)
  return <>
    <Flex flexDirection='column' gap='10px'>
      <h1>MY sDEV</h1>
      <Rectangle classname='rectangle' width='100%'>
        <Flex flexDirection='column' gap="10px">
          <Flex flexDirection='row' gap="10px">
            <div style={{ width: '40px' }}>
              <img src={IconsDEV} alt={'aaa'} height="40px" />
            </div>
            <div style={{ width: '220px' }}>
              <p><span>Total:</span> {sDev}</p>
              <p><span>Gained Yesterday:</span> {sDevYesterday.valueMaybe()?.toFixed(4) || '-'}</p>
            </div>
          </Flex>
          <Text fontWeight={'600'} color="gray" fontSize={'0.75rem'}>These values are updated everyday at 00:00 UTC</Text>
        </Flex>
      </Rectangle>
    </Flex>
  </>
}
