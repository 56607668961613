/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import Button from 'components/Button/Button.component'
import Flex from 'components/Flex/Flex'
import Input from 'components/Input/Input.component'
import Text from 'components/Text/Text'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useState } from 'react'
import Toast from 'components/Toast/Toast.component'
import {
  useRecoilRefresher_UNSTABLE, useRecoilValue,
} from 'recoil'
import { connectedWallet } from 'selectors/selectors'
import TokenSelector from 'views/exchange/components/OneStep-ExchangeStake/TokenSelector.component'
import { stakedDevPoolSelector } from 'selectors/staking.selectors'
import useBalance from 'views/exchange/hooks/useBalance.hook'
import useTokenToDevConvertion from 'views/exchange/hooks/useTokenToDevConvertion.hook'
import useConfirmDialog from 'hooks/confirm/useConfirmDialog.hook'
import VolExchangeRepository from 'repositories/asset-exchange.repository'
import useTokenToDevExchange from 'views/exchange/hooks/useTokenToDevExchange'
import { LockTime } from 'repositories/staking.repository'
import Rectangle from 'components/Rectangle/Rectangle.component'
import StakingTimeOptionComponent from './stakingtime-option.component'

const mapTokenName = (token: string) => {
  switch (token) {
    case 'ETH':
      return 'ethereum'
    case 'USDC':
      return 'usd-coin'
    case 'GODS':
      return 'gods-unchained'
    case 'IMX':
      return 'immutable-x'
    default:
      return token.toLowerCase()
  }
}

export default function AddOneStepStakingModalComponent() {
  const [token, setToken] = useState('ETH')
  const [isActive, setIsActive] = useState(false)
  const [option, setOption] = useState('')
  const [amount, setAmount] = useState(0)
  const user = useRecoilValue(connectedWallet)
  const { balance: inWalletTokenAmount, refresh: refreshTokenAmount } = useBalance(token.toLowerCase() as any)
  const stakingRefresher = useRecoilRefresher_UNSTABLE(stakedDevPoolSelector)
  const [toast, setToast] = useState(false)
  const [error, setError] = useState(false)
  const tokenEquivalenceInDev = useTokenToDevConvertion({
    token: mapTokenName(token),
    amount: amount || 0,
  })
  const { confirm } = useConfirmDialog({ text: 'Are you sure you want to stake?' })

  const { exchange, loading } = useTokenToDevExchange({
    token: token as any,
    async onSuccess(txId: string) {
      await VolExchangeRepository.oneStepExchangeStaking(txId, option as LockTime)
      setToast(true)
      stakingRefresher()
      setOption('')
      refreshTokenAmount()
      setIsActive(false)
      setAmount(0)
    },
    onFail() {
      setError(true)
    },
  })

  const handleStake = async () => {
    if (!user) return
    const confirmed = await confirm()
    if (confirmed) {
      exchange(amount)
    }
  }

  return <>
    <Toast
      style={{ background: '#f54242' }}
      id='stake-dev-toast-error'
      isActive={error}
      duration={4000}
      onClose={() => {
        setError(false)
      }}>
      <Flex flexDirection='column' gap="1ch">
        <Text fontWeight={'bold'} fontSize='0.8rem'>
          <Text fontWeight={'bold'} fontSize='0.8rem'>🫣 Something went wrong.</Text>
        </Text>
        <Text fontWeight={'bold'} fontSize='0.8rem'>Please, refresh the page and try again.</Text>
      </Flex>
    </Toast>
    <Toast id='stake-dev-toast' isActive={toast} duration={3000} onClose={() => { setToast(false) }}>
      <Flex flexDirection='column' gap="1ch">
        <Text fontWeight={'bold'} fontSize='0.8rem'>
          <Text fontWeight={'bold'} fontSize='0.8rem'>🚀 Stake successful!</Text>
        </Text>
        <Text fontWeight={'bold'} fontSize='0.8rem'>Come back in a few hours to see your accumulated reward!</Text>
      </Flex>
    </Toast>
    <Modal id="withdraw-dev" isActive={isActive} closeAction={() => setIsActive(false)} style={{ minWidth: '35vw' }}>
      <Flex flexDirection='column' gap="0.4ch" alignItems='center'>
        <h1>STAKE $DEV</h1>
        <Text fontWeight={'500'} textAlign='center'>
          Select the amount to be converted to $DEV and a lock time. More time = higher reward.
        </Text>
        <TokenSelector onChange={setToken} />
        <Flex justifyContent='center' flexDirection='row' gap="1ch" style={{ width: '100%', height: '60px' }}>
          <Input step={0.1} value={amount} onChange={(e) => {
            e.preventDefault()
            setAmount(Number(e.target.value))
          }} max={inWalletTokenAmount} type='number' placeholder='Amount' style={{ width: '280px', fontSize: '1.2rem', textAlign: 'center' }} />
          <Button onClick={() => setAmount(inWalletTokenAmount)} variant='ghost' size='medium' style={{ padding: '5px 25px' }}>MAX</Button>
        </Flex>
        <Text fontWeight={'bold'} fontSize='0.8rem'>IN WALLET: {inWalletTokenAmount} {token}</Text>
      </Flex>
      <Flex flexDirection='row' gap='5px'>
        <StakingTimeOptionComponent title='Stake' description='100%APR 30 Day lock' selected={option === '30d'} select={() => setOption('30d')} option='30d' amount={tokenEquivalenceInDev} />
        <StakingTimeOptionComponent title='Stake' description='200%APR🔥 6 month lock' selected={option === '6m'} select={() => setOption('6m')} option='6m' amount={tokenEquivalenceInDev} />
        <StakingTimeOptionComponent title='Stake' description='300%APR🚀 12 month lock' selected={option === '12m'} select={() => setOption('12m')} option='12m' amount={tokenEquivalenceInDev} />
      </Flex>
      <Button disabled={(amount === 0 || option === '' || amount > inWalletTokenAmount || loading)} style={{ width: '290px', height: '50px' }} variant='primary_alt' size='medium' onClick={() => {
        handleStake()
      }}>{loading ? '...' : `STAKE ${tokenEquivalenceInDev > 0 ? `${tokenEquivalenceInDev.toFixed(4)} DEV` : ''}`}</Button>
      <Flex flexDirection='row' flexWrap='wrap' gap="0.5ch" justifyContent='center' alignItems='center'>
        <Text>
          By staking $DEV, you are accepting the
        </Text>
        <a target='_blank' rel='noreferrer' className='df-text' style={{ textDecoration: 'underline' }} href="https://docs.deviantsfactions.com/deviants-factions-rulebook/dev-staking">[STAKING RULES]</a>
      </Flex>
    </Modal>
    <Flex flexDirection='column' gap="10px" alignItems='center'>
      <h1>Convert your tokens to sDEV</h1>
      <Rectangle classname='rectangle' width='100%'>
        <Flex flexDirection='column' alignItems='center' gap="10px">
          <Flex flexDirection='row' gap="10px" justifyContent='center'>
            {['ETH', 'GODS', 'IMX', 'USDC'].map((t) => <img
              key={t}
              src={`/images/currency/${t}.png`} alt={t}
              style={{ width: '50px', borderRadius: '50%' }} />)}
          </Flex>
          <Button onClick={() => setIsActive(true)} size='medium' variant='primary_alt' style={{ width: '300px', height: '50px' }}>Stake</Button>
        </Flex>
      </Rectangle>
    </Flex>
  </>
}
