// noinspection DuplicatedCode

import {
  useState,
  useEffect, useCallback, CSSProperties,
} from 'react'
import './modal.component.scss'
import useScrollBlock from 'components/Modal/scrollBlock'
import classNames from 'classnames'
import getConfig from '../../../config.service'
import CardSprite from '../Card/card-sprite.component'

const config = getConfig()

type ModalType = {
  children?: any,
  isActive: boolean,
  closeAction?: any,
  id: any,
  style?: CSSProperties
}

export function Modal({
  children,
  isActive,
  closeAction,
  id,
  style,
}: ModalType) {
  const [blockScroll, allowScroll] = useScrollBlock()
  const [showModal, setShowModal] = useState<boolean>(isActive)

  if (isActive !== showModal) {
    setShowModal(isActive)
  }
  const classNameOverlay = classNames('overlay', {
    show: showModal,
    hide: !showModal,
  })

  const closeModalBackground = (event: any) => {
    if (event.target.id === id) {
      if (closeAction) { closeAction() }
      setShowModal(false)
    }
  }
  const closeModal = useCallback(() => {
    if (closeAction) closeAction()
    else setShowModal(false)
  }, [closeAction])

  // const escFunction = useCallback((event) => {
  //   if (event.keyCode === 27) {
  //     closeModal();
  //   }
  // }, [closeModal]);

  // useEffect(() => {
  //   document.addEventListener("keydown", escFunction, false);

  //   return () => {
  //     document.removeEventListener("keydown", escFunction, false);
  //   };
  // });
  useEffect(() => {
    if (isActive) blockScroll()
    else allowScroll()
    return () => allowScroll()
  }, [isActive])
  return (
    <div className={classNameOverlay} id={id} onClick={(event) => {
      closeModalBackground(event)
    }}>
      <div className="shadow" onClick={closeModal}></div>
      <div className="modal bevel" style={{ ...style }}>
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  )
}

export function ModalWithCard(card: any) {
  const {
    children,
    isActive,
    closeAction,
    id,
    image,
  } = card
  const [showModal, setShowModal] = useState<boolean>(isActive)
  const [blockScroll, allowScroll] = useScrollBlock()

  if (isActive !== showModal) {
    setShowModal(isActive)
  }
  const classNameOverlay = classNames('overlay', {
    show: showModal,
    hide: !showModal,
  })

  const closeModalBackground = (event: any) => {
    if (event.target.id === id) {
      if (closeAction) { closeAction() }
      setShowModal(false)
    }
  }
  const closeModal = useCallback(() => {
    if (closeAction) closeAction()
    else setShowModal(false)
  }, [closeAction])

  // const escFunction = useCallback((event) => {
  //   if (event.keyCode === 27) {
  //     closeModal();
  //   }
  // }, [closeModal]);

  // useEffect(() => {
  //   document.addEventListener("keydown", escFunction, false);

  //   return () => {
  //     document.removeEventListener("keydown", escFunction, false);
  //   };
  // });
  useEffect(() => {
    if (isActive) blockScroll()
    else allowScroll()
    return () => {
      allowScroll()
    }
  }, [isActive])
  if (!image) return <></>

  return (
    <div className={`${classNameOverlay} card-modal`} id={id} onClick={(event) => {
      closeModalBackground(event)
    }}>
      <div className="shadow" onClick={closeModal}></div>
      <div className="modal-component-container">
        <div style={{ zIndex: 1, flex: 2 }}>
          {
            config.CARD_SPRITE ? (
              <CardSprite {...card} />
            ) : (
              <img src={`${image}?tx=h_600,q_80,f_auto`} style={{ width: '100%' }} alt="" />
            )
          }
        </div>
        <div style={{ flex: 1, justifyContent: 'center' }}>
          <div className="modal bevel">
            <div className="content">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
