import { Box } from 'components/Box/box.component'
import './airdrop.view.scss'
import Rectangle from 'components/Rectangle/Rectangle.component'
import Flex from 'components/Flex/Flex'
import Footer from 'components/Footer/Footer.component'
import Text from 'components/Text/Text'
import { useRecoilValueLoadable } from 'recoil'
import { dailyTaskLogsSelector, totalTaskLogsSelector, xpSelector } from 'selectors/play2airdrop.selectors'
import Play2AirdropService from 'services/play2airdrop.service'
import ProgressBar from '@ramonak/react-progress-bar'
import PageHeader from './components/page-header/page-header.component'
import AirdropTaskList from './components/tasks/AirdropTasksList.component'
import LeaderboardList from './components/leaderboard/LeaderboardList.component'
import AirdropStats from './components/stats/AirdropStats.component'
import TwitterButton from './components/twitter-button/twitter-button.component'
import hero from './assets/airdrop-hero.jpg'
import PageBody from './components/page-body/page-body.component'

export default function AirdropView() {
  const myXp = useRecoilValueLoadable(xpSelector)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const todayCompletedTasks = useRecoilValueLoadable(dailyTaskLogsSelector)
  const allTimeCompletedTasks = useRecoilValueLoadable(totalTaskLogsSelector)
  const minimumXp = Play2AirdropService.minXp
  const percentageCompleted = ((myXp.contents || 0.1) / minimumXp) * 100
  return (
    <div className='airdrop-view'>
      <PageHeader imageSrc={hero} overlay>
        <div className='page-header__title' style={{ fontSize: '3rem' }}>
          <h3>PLAY 2 AIRDROP</h3>
        </div>
        <div className='page-header__subtitle'>
          <Text fontSize={'1.2rem'} fontWeight={'600'}>Collect XP Points by performing the daily tasks. Check the <a href='https://docs.deviantsfactions.com/deviants-factions-rulebook/play-2-airdrop' style={{ color: 'orange' }} target='_blank' rel='noreferrer'>Campaign Rules</a></Text>
        </div>
      </PageHeader>
      <PageBody>
        <Box width='30vw' style={{ textAlign: 'center' }}>
          <p >
            {myXp.state === 'hasValue' && <ProgressBar
              maxCompleted={100}
              completed={percentageCompleted >= 100 ? 100 : percentageCompleted.toFixed(2)}
              labelColor="white" bgColor="#ff5c00" baseBgColor='#444' />}
          </p>
          {myXp.state === 'hasValue' && myXp.contents < minimumXp && <Text fontSize={'0.9rem'}>Reach at least 4500 XP to qualify</Text>}
          {myXp.state === 'hasValue' && myXp.contents >= minimumXp && <Text fontSize={'0.9rem'}>4500 XP reached! Collect more for a higher allocation!</Text>}
        </Box>
        <Flex flexDirection='row' gap="10px">
          <Box width='50vw'>
            <Flex flexDirection='column' gap='10px'>
              <Rectangle classname='rectangle'>
                <Flex flexDirection='row'>
                  <div style={{ width: '50%', paddingLeft: '20px' }}>
                    <h1 style={{ textAlign: 'left' }}>
                      MY XP: {myXp.valueMaybe() || '-'}<span style={{ fontSize: '1rem' }}>XP</span>
                    </h1>
                  </div>
                  <div style={{ textAlign: 'right', paddingRight: '10px', width: '50%' }}><TwitterButton /></div>
                </Flex>
              </Rectangle>
            </Flex>
            <Flex flexDirection='column' gap='10px'>
              <Rectangle classname='rectangle'>
                <AirdropTaskList />
              </Rectangle>
              <Rectangle classname='rectangle'>
                <Flex flexDirection='row' gap="10px">
                  <AirdropStats tasks={todayCompletedTasks.valueMaybe() || []} title="Yesterday's" />
                  <AirdropStats tasks={allTimeCompletedTasks.valueMaybe() || []} title='Total Accumulated' />
                </Flex>
                <Text fontSize={'0.9rem'}>XP is calculated every 1 hour. Twitter Tasks are checked at the end of the day.</Text>
              </Rectangle>
            </Flex>
          </Box>
          <Box width='350px'>
            <h1>LEADERBOARD</h1>
            <Flex flexDirection='column' gap='10px'>
              <Rectangle classname='rectangle'>
                <LeaderboardList />
              </Rectangle>
            </Flex>
          </Box>
        </Flex>
        <Footer />
      </PageBody>
    </div>
  )
}
