import './library.component.scss'

export default function LibraryTitle({
  title,
}: {title : string}) {
  return (
    <div className="library-card-header">
      <div className="library-divider-left"></div>
      <span className="library-line left"></span>
      <p className="library-title">{title}</p>
      <span className="library-line right"></span>
      <div className="library-divider-right"></div>
    </div>
  )
}
