import { useEffect, useRef, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import './userprofile.component.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useOnClickOutside } from 'usehooks-ts'
import { Link } from 'react-router-dom'
import getConfig from 'config.service'
import { profileBalancesSelector, rewardsSelector } from 'selectors/selectors'
import { ProfileDataType } from '../../repositories/profile.repository'
import IconVol from '../../assets/icons/icon-vol.png'
import IconxDev from '../../assets/icons/icon-dev.png'

type UserProfileType = {
  disconnectClose: Function,
  profile: ProfileDataType
}

const config = getConfig()
const {
  ENABLE_PURCHASE, ENABLE_REFERRAL, ENABLE_BALANCE,
  ENABLE_REWARDS, ENABLE_PROFILE, ENABLE_ACHIEVEMENTS, ENABLE_CHALLENGES,
} = config

export default function UserProfile({ disconnectClose, profile }: UserProfileType) {
  const [isActive, setActive] = useState(false)
  const className = classNames('user-profile', { 'connected-wallet': profile })
  const actionsToggle = classNames('profile-actions', { show: isActive })
  const toggleActive = () => setActive(!isActive)
  const containerElement = useRef<HTMLDivElement>(null)
  useOnClickOutside(containerElement, () => setActive(false))
  // const dropdownRef = useRef(null)
  const balances = useRecoilValueLoadable(profileBalancesSelector)
  const rewards = useRecoilValueLoadable(rewardsSelector)

  const [toClaim, setToClaim] = useState(0)
  useEffect(() => {
    let num: number = 0
    if (rewards.state === 'hasValue') {
      rewards.contents[0].forEach((r) => {
        if (r.state === 'PENDING') num += 1
      })
      setToClaim(num)
    }
  }, [rewards])

  return (<>
    <div ref={containerElement} className={className} onClick={toggleActive}>
      <div className={`user-avatar-container${toClaim ? ' rewards-to-claim' : ''}`}>
        <div className='user-avatar'>
          {profile?.info?.avatar ? <div className={'avatar-img'} style={{ backgroundImage: `url(${profile.info.avatar})` }} /> : <FontAwesomeIcon icon={faUserCircle as IconProp} size="3x" className="user-avatar-desktop" />}
        </div>
      </div>
      <p className="user-name">{profile?.info?.name || 'PLAYER'}</p>
      {isActive ? <FontAwesomeIcon icon={faChevronUp as IconProp} className="icon-chevron" /> : <FontAwesomeIcon icon={faChevronDown as IconProp} className="icon-chevron" />}
      {ENABLE_BALANCE && balances.state === 'hasValue'
        && <div className="user-balances">
          <div className='user-balances-tickets'>
            <span className="user-balances-item"><img src={IconxDev} className="user-balances-icon" alt={'user-balances-icon-xdev'} /> {balances.contents?.xdev ? balances.contents.xdev : 0}</span>
            <span className="user-balances-item"><img src={IconVol} className="user-balances-icon" alt={'user-balances-icon-vol'} /> {balances.contents?.vol ? balances.contents.vol : 0}</span>
          </div>
        </div>
      }
    </div>
    <div className={actionsToggle}>
      {ENABLE_PROFILE && <Link to="/user-profile" className='profile-menu-item'>Profile</Link>}
      {ENABLE_PURCHASE && <Link to="/purchases" className='profile-menu-item'>Purchases</Link>}
      {ENABLE_REFERRAL && <Link to="/referral" className='profile-menu-item'>Referral program</Link>}
      {ENABLE_REWARDS && <Link to="/rewards" className={`profile-menu-item${toClaim ? ' rewards-to-claim' : ''}`}>Rewards</Link>}
      {ENABLE_ACHIEVEMENTS && <Link to="/achievements" className='profile-menu-item'>Achievements</Link>}
      {ENABLE_CHALLENGES && <Link to="/challenges" className='profile-menu-item'>Challenges</Link>}
      <a onClick={() => disconnectClose()} className="profile-menu-item">Logout</a>
    </div>
  </>)
}
