import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button/Button.component'
import Modal from 'components/Modal/Modal.component'
import './errormodal.component.scss'

export type ErrorModalType ={
  title: string,
  description: string,
  id: string,
  closeAction: any,
  isActive: boolean
}

export default function ErrorModal({
  title,
  description,
  id,
  closeAction,
  isActive,
}: ErrorModalType) {
  return (
    <Modal isActive={isActive} closeAction={closeAction} id={id}>
      <FontAwesomeIcon icon={faExclamationCircle as IconProp} size='3x' className='error-icon'/>
      <h1 className="error-title">{title}</h1>
      <p className="error-description">{description}</p>
      <Button
      variant={'primary'}
      size={'medium'}
      onClick={closeAction}
      >
        Close
      </Button>
    </Modal>
  )
}
