import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { connectedWallet } from 'selectors/selectors'
import getCraftingService from 'services/crafting'

const CraftingService = getCraftingService()

export default function usePendingCraftings(dependencies: any[] = []) {
  const [pending, setPending] = useState<number>(0)
  const wallet = useRecoilValue(connectedWallet)
  useEffect(() => {
    const fetchPending = async () => {
      try {
        if (!wallet) throw new Error('Wallet not connected')
        const response = await CraftingService.getPendingTransactions(wallet.address)
        setPending(response.pending)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
    fetchPending()
  }, dependencies)

  return { pending }
}
