import Flex from 'components/Flex/Flex'
import { useRecoilValueLoadable } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import { sdevStoreSelector } from 'selectors/staking.selectors'
import Text from 'components/Text/Text'
import StakeStoreItem from './stakestore-item.component'

export default function StakeStore() {
  const sDev = useRecoilValueLoadable(profileBalancesSelector).contents?.sdev || 0
  const store = useRecoilValueLoadable(sdevStoreSelector).valueMaybe() || []

  return <div style={{ marginTop: '5px' }}>
    <Flex flexDirection='row' justifyContent='space-evenly'>
      {store?.map((item) => (
        <StakeStoreItem
          item={item}
          key={item.id}
          image={item.art}
          price={item.cost}
          title={item.name}
          enabled={sDev >= item.cost}
        />))}
    </Flex>
    <Text textAlign='center' fontWeight={'bold'}>
      {"* Prices are calculated based on their cost in USDC in the Deviants' Factions store."}
    </Text>
  </div>
}
