import { rewardsTableStyle } from 'components/rewards/rewards.component'
import { Achievement } from 'domain/assignedAchievement.model'
import { Grid } from 'gridjs-react'
import './economy-achievements-grid.component.scss'

export default function EconomyAchievementGrid(
  { achievements }: { achievements: Achievement[] },
) {
  return <Grid
    columns={[
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'NAME' },
    ]}
    style={{
      ...rewardsTableStyle,
      table: {
        width: '100%',
      },
      td: {
        ...rewardsTableStyle.td,
        fontSize: '0.85em',
      },
    }}
    data={achievements.map((a: Achievement) => [
      a.id,
      a.name,
    ])}
    sort={true} />
}
