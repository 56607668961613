import './featcards.scss'
import { Link } from 'react-router-dom'
import Button from 'components/Button/Button.component'
import { FeatCard } from 'components/FeatCard/feat-card'
import AliceCarousel from 'react-alice-carousel'
import getConfig from 'config.service'
import { ReactComponent as IconChevron } from '../../assets/icons/icon-chevron.svg'
import 'react-alice-carousel/lib/alice-carousel.css'

import galleryTopLeft from './assets/gallery-top-left.png'
import galleryTopRight from './assets/gallery-top-right.png'
import galleryLowerLeftStart from './assets/gallery-lower-left-holder-start.png'
import galleryLowerLeftEnd from './assets/gallery-lower-left-holder-end.png'
import galleryLowerRightStart from './assets/gallery-lower-right-holder-start.png'

const config = getConfig()
const { ENABLE_STORE } = config

type FeatCardsType = {
  title: string,
  subtitle: string,
}

const FeatCards = ({
  title,
  subtitle,
}: FeatCardsType) => {
  const items = [
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/d6fd19fb-6e40-4606-6f74-daf4831a1200/public'} name="char" variant="CHAR" key="OTK023"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/856b059f-2d16-480b-d894-f9bdce416500/public'} name="char" variant="TECH" key="OTK148"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/f0f9e08f-b1d2-4d80-2c1f-676dca3be600/public'} name="char" variant="CHAR" key="OTK018"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/878145b7-bdbd-4c04-1a5e-24a0f7d88100/public'} name="char" variant="TECH" key="OTK138"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/88fbc000-3e31-4a08-0c04-7fc0cae56300/public'} name="char" variant="CHAR" key="OTK062"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/cd5b1fd4-b751-4436-4ef1-d39414b51900/public'} name="char" variant="CHAR" key="OTK073"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/68f59d20-6fc6-43dc-a3c4-f749bbd0a300/public'} name="char" variant="CHAR" key="OTK038"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/e68912c6-0626-4aea-b2fa-5ff7ae363000/public'} name="char" variant="TECH" key="OTK144"/>,
    <FeatCard image={'https://imagedelivery.net/kC92r6_ITnilWJwDH0MVFg/d91fa5de-f198-4097-eb47-5a49faa50000/public'} name="char" variant="CHAR" key="OTK019"/>,
  ]

  let element: any

  return (
    <section className="featcards-section" id="featured">
      <div className="featcards-title">
        <h2>{subtitle}</h2>
        <h3>{title}</h3>
      </div>
      <div className="featcards-list">

        <AliceCarousel
        activeIndex={0}
        disableDotsControls
        disableButtonsControls
        infinite
        mouseTracking
        autoWidth
        autoHeight
        paddingLeft={23}
        items={items}
        ref={(el) => { element = el }}
        />

      </div>

      <div className="featcards-top-left" style={{ backgroundImage: `url(${galleryTopLeft})` }} ></div>
      <div className="featcards-lower-left-start" style={{ backgroundImage: `url(${galleryLowerLeftStart})` }} ></div>

      <div className="featcards-actions">
        <div className="featcards-actions-left" style={{ backgroundImage: `url(${galleryLowerLeftEnd})` }} >
          <div className="featcards-actions-left-buttons">

            <Button size="small" left variant="outline"
            onClick={() => element.slidePrev()}
            >
              <IconChevron className="next-button" />
            </Button>
            <Button size="small" right variant="outline"
            onClick={() => element.slideNext()}
            >
              <IconChevron className="next-button" />
            </Button>

          </div>
          <p className="featcards-actions-left-scroll">
            Drag
          </p>
        </div>
        <p className="featcards-actions-drag">
          Drag
        </p>
        <div className="featcards-actions-right-buttons">
          { ENABLE_STORE && (
            <Link to="/store" className="btn-shadow" id="home-featcards-link-store" rel="noreferrer">
              <div className="btn btn-medium btn-primary" id="feature-cards-get-packs">
                get boosters
              </div>
            </Link>
          )}
        </div>
        <div className="featcards-actions-right-bg"></div>
      </div>
      <div className="featcards-top-right" style={{ backgroundImage: `url(${galleryTopRight})` }} ></div>
      <div className="featcards-lower-right-start" style={{ backgroundImage: `url(${galleryLowerRightStart})` }} ></div>

    </section>
  )
}

export default FeatCards
