/* eslint-disable class-methods-use-this */
import axios, { AxiosInstance } from 'axios'
import getConfig from 'config.service'
import Asset from 'domain/asset.model'
import { CraftingRecipe } from 'domain/crafting-recipe.model'
import { CraftingService, CraftingTransaction } from 'domain/services/crafting.service'
import Wallet from 'domain/wallet.model'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import MyAssetsRepository from 'repositories/my-assets.repository'

export default class ApiCraftingService implements CraftingService {
  private axios: AxiosInstance

  constructor() {
    const { hostURL } = getConfig()
    const token = ValidateIfTokenIsPresent()
    this.axios = axios.create({
      baseURL: `${hostURL}/api/v1/rewards/crafting`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  async getPendingTransactions(wallet: string): Promise<{ pending: number }> {
    const { data } = await this.axios.get<{ pending: number }>(`/pending/${wallet}`)
    return data
  }

  async checkAvailability(recipeIds: string[]): Promise<{ [key: string]: number }[]> {
    try {
      const treasury = getConfig().WALLET_TREASURY_ADDRESS
      const imxApiAddress = getConfig().API_ADDRESS
      const { data } = await axios.get(`${imxApiAddress}/v1/assets?user=${treasury}`, {
        params: {
          metadata: encodeURI(JSON.stringify({ id: recipeIds })),
        },
      })
      return recipeIds.map((id) => ({
        [id]: data.result.filter((asset: any) => asset.metadata.id === id).length,
      }))
    } catch (error) {
      return recipeIds.map((id) => ({ [id]: 0 }))
    }
  }

  public async getAvailableIngredients(ingredients: string[], wallet: Wallet): Promise<Map<string, Asset[]>> {
    const assetsFound = new Map<string, Asset[]>(ingredients.map((ingredient) => [ingredient, []] as [string, Asset[]]))
    if (!wallet) return new Map()
    const assets = await MyAssetsRepository
      .listAssets(wallet, undefined, encodeURI(JSON.stringify({ id: ingredients }))) as unknown as { result: Asset[] }
    assets.result.forEach((asset) => {
      const metadata = asset.metadata as { id: string }
      const assetObj = new Asset(asset)
      if (assetsFound.has(metadata?.id)) {
        assetsFound.set(metadata?.id, [...assetsFound.get(metadata?.id)!, assetObj])
      } else {
        assetsFound.set(metadata?.id, [assetObj])
      }
    })
    return assetsFound
  }

  public async getRecipes(): Promise<CraftingRecipe[]> {
    const { data } = await this.axios.get<CraftingRecipe[]>('/recipes')
    return data
  }

  public async getRecipe(id: string): Promise<CraftingRecipe> {
    const { data } = await this.axios.get<CraftingRecipe>(`recipes/${id}`)
    return data
  }

  public async craft(recipeId: string, wallet: string, txIds: number[]): Promise<CraftingTransaction> {
    const { data } = await this.axios.post<CraftingTransaction>('/craft', {
      wallet,
      txIds,
      recipeId,
    })
    return data
  }

  public async checkCrafteability(recipe: CraftingRecipe): Promise<boolean> {
    const { data } = await this.axios.get<boolean>(`/check-crafteability/${recipe.id}`)
    return data
  }
}
