/* eslint-disable no-undef */
import getConfig from 'config.service'
import Reward, { RewardDto } from 'domain/reward.model'
import { OffChainBalance } from 'domain/offchain-rewards.model'
import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

export type Convertion = {
  from: string,
  to: string,
  value: number
}

export type ExchangeRate = {
  from: string,
  to: string,
  rate: number,
  date: string
}

export interface Paginated<T> {
  data: T,
  nextPage: number | null,
  prevPage: number | null,
  totalPages: number,
  currentPage: number
}

const { hostURL } = getConfig()
export default abstract class RewardRepository {
  public static async getByUserAddress(
    userAddress?: string,
    origin?: string,
    page?: number,
    pageSize?: number,
  ): Promise<Reward[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards`,
      params: {
        address: userAddress,
        origin,
        page,
        pageSize,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const rewards = await ServiceConsumer.get<RewardDto[]>(requestData)
    return rewards.map((reward) => new Reward(reward))
  }

  public static async getByUserAddressV2(
    userAddress?: string,
    origin?: string,
    page?: number,
    pageSize?: number,
  ): Promise<Paginated<Reward[]>> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/paginated`,
      params: {
        address: userAddress,
        origin,
        page,
        pageSize,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const rewards = await ServiceConsumer.get<Paginated<RewardDto[]>>(requestData)
    return { ...rewards, data: rewards.data.map((reward) => new Reward(reward)) }
  }

  public static async getCurrencyConvertions(): Promise<ExchangeRate[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/currency-equivalence`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.get<ExchangeRate[]>(requestData)
    return response
  }

  public static async convert(from: string, to: string, amount: number): Promise<Convertion> {
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/currency-equivalence/convert/${from}/${to}/${amount}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
    const response = await ServiceConsumer.get<Convertion>(requestData)
    return response
  }

  public static async claimReward(rewardId: string, wallet?: string): Promise<Reward> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/claim`,
      body: {
        rewardId,
        wallet,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<Reward>(requestData)
    return response
  }

  public static async getBalance(userAddress: string | undefined): Promise<OffChainBalance[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/offchain?wallet=${userAddress}`,

      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.get<OffChainBalance[]>(requestData)
    return response
  }
}
