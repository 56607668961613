import Flex from 'components/Flex/Flex'
import Text from 'components/Text/Text'
import './Dialog.component.scss'
import Button from 'components/Button/Button.component'
import { motion } from 'framer-motion'

type DialogProps = {
  onConfirm: () => void,
  onCancel: () => void,
  text?: string
}

export default function Dialog(props: DialogProps) {
  return <div className='confirm-dialog-overlay'>
    <motion.div
      exit={{ scale: 0.5 }}
      initial={{ scale: 0.5 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.25 }}
      className='confirm-dialog-portal'>
      <Flex flexDirection='column' gap="15px">
        <Text fontWeight={600} fontSize={'1.2rem'}>{props.text || 'Are you sure?'}</Text>
        <Flex flexDirection='row' justifyContent='center' gap="10px">
          <Button size='medium' variant='primary_alt' onClick={() => props.onConfirm()}>Yes</Button>
          <Button size='medium' style={{ backgroundColor: 'lightgray', color: 'black' }} variant='primary_alt' onClick={() => props.onCancel()}>No</Button>
        </Flex>
      </Flex>
    </motion.div>
  </div>
}
