export default function FakeGrid({ columns, data }: { columns:string[], data:any }) {
  function slugify(text:string) {
    let str:string = text
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    const to = 'aaaaeeeeiiiioooouuuunc------'
    for (let i:number = 0, l = from.length; i < l; i += 1) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  }

  return (
    <div>
      <div role="complementary" className="gridjs gridjs-container" style={{ width: '100%' }}>
        <div className="gridjs-wrapper" style={{ height: 'auto' }}>
          <table role="grid" className="gridjs-table" style={{ height: 'auto' }}>
            <thead className="gridjs-thead">
            <tr className="gridjs-tr">
              {
                columns.map((key) => (<th key={slugify(key)} data-column-id={slugify(key)} className="gridjs-th">
                  <div className="gridjs-th-content">{key}</div>
                </th>))
              }
            </tr>
            </thead>
            <tbody className="gridjs-tbody">
            {
              data.map((row:any[], i:number) => (
                <tr key={`tr-${i}`} className="gridjs-tr">
                  {
                    row.map((cell:any, j:number) => (
                      <td key={`td-${i}-${j}`} data-column-id="name" className="gridjs-td">
                        <div>{cell}</div>
                      </td>
                    ))
                  }
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
        <div id="gridjs-temp" className="gridjs-temp"/>
      </div>
    </div>
  )
}
