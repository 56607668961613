import Asset from 'domain/asset.model'
import { CardType } from 'domain/card.model'
import { CraftingRecipe } from 'domain/crafting-recipe.model'
import { CraftingTransaction } from 'domain/services/crafting.service'
import CardsRepository from 'infraestructure/repositories/cards.repository'
import {
  createContext, ReactElement, useEffect, useState,
} from 'react'
import { useRecoilValue } from 'recoil'
import { connectedWallet } from 'selectors/selectors'
import getCraftingService from 'services/crafting'

interface ICraftingContext {
  selectedRecipe?: CraftingRecipe,
  selectRecipe: (recipe: CraftingRecipe | undefined) => void,
  cardResult?: CardType,
  loadingResultInfo: boolean,
  ingredientsAvailable?: Map<string, Asset[]>,
  selectingCard?: string,
  setSelectingCard: (card: string | undefined) => void,
  pickedNfts: string[],
  setPickedNfts: (nfts: string[]) => void,
  craftingTransaction?: CraftingTransaction,
  setCraftingTransaction: (transaction: CraftingTransaction | undefined) => void,
  isError: boolean,
  setIsError: (isError: boolean) => void,
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
  ingredientsCardInfo?: Map<string, CardType>,
}

export const CraftingContext = createContext<ICraftingContext>({
  selectedRecipe: undefined,
  selectRecipe: () => { },
  loadingResultInfo: false,
  setSelectingCard: () => { },
  setPickedNfts: () => { },
  pickedNfts: [],
  setCraftingTransaction: () => { },
  setIsError: () => { },
  isError: false,
  isLoading: false,
  setIsLoading: () => { },
})

export const CraftingProvider = ({ children }: { children: ReactElement | ReactElement[] }) => {
  const [recipe, setRecipe] = useState<CraftingRecipe | undefined>()
  const [cardResult, setCardResult] = useState<CardType>()
  const [loadingResultInfo, setLoadingResultInfo] = useState(false)
  const [ingredientsAvailable, setIngredientsAvailable] = useState<Map<string, Asset[]>>(new Map())
  const [selectingCard, setSelectingCard] = useState<string | undefined>()
  const [pickedNfts, setPickedNfts] = useState<string[]>([])
  const [craftingTransaction, setCraftingTransaction] = useState<CraftingTransaction | undefined>(undefined)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ingredientsCardInfo, setIngredientsCardInfo] = useState<Map<string, CardType>>(new Map())

  const wallet = useRecoilValue(connectedWallet)
  const craftingService = getCraftingService()

  useEffect(() => {
    const fetchIngredients = async () => {
      if (recipe) {
        const ingredients = await craftingService.getAvailableIngredients(recipe.ingredients.map((i) => i.id), wallet!)
        setIngredientsAvailable(ingredients)
      }
    }
    fetchIngredients()
  }, [recipe?.id])

  useEffect(() => {
    setCardResult(undefined)
    setPickedNfts([])
    const getCardResult = async () => {
      try {
        setLoadingResultInfo(true)
        const card = await CardsRepository.getByIds([recipe?.result.id])
        setCardResult(card[0])
      } catch (error) {
        //
      } finally {
        setLoadingResultInfo(false)
      }
    }
    if (recipe) {
      getCardResult()
    }
  }, [recipe?.id])

  useEffect(() => {
    const handleIngredientsCardInfo = async () => {
      if (recipe) {
        const ingredientsData = await CardsRepository.getByIds(recipe.ingredients.map((i) => i.id))
        setIngredientsCardInfo(new Map(ingredientsData.map((card) => [card.id!, card])))
      }
    }
    handleIngredientsCardInfo()
  }, [recipe?.id])

  return <CraftingContext.Provider value={{
    selectedRecipe: recipe,
    selectRecipe: setRecipe,
    cardResult,
    loadingResultInfo,
    ingredientsAvailable,
    selectingCard,
    setSelectingCard,
    pickedNfts,
    setPickedNfts,
    craftingTransaction,
    setCraftingTransaction,
    isError,
    setIsError,
    isLoading,
    setIsLoading,
    ingredientsCardInfo,
  }}>
    {children}
  </CraftingContext.Provider>
}
