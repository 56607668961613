/* eslint-disable */
import '../views.scss'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import { Suspense, useEffect, useState } from 'react'
import {
  useRecoilCallback, useRecoilRefresher_UNSTABLE, useRecoilStateLoadable, useRecoilValueLoadable,
  useRecoilValue,
} from 'recoil'
import {
  connectedWallet, openHQPickerAtom, hasAdeptDecksState, currentDeckSelector, deckListState, HQSelector,
  deckCardsSelector, cardsFetchAtom, decksFetchAtom, currentDeckAtom, AllCardsSelector, DeckFormatsSelector,
} from 'selectors/selectors'
import cookie from 'cookiejs'
import DeckDetail from 'components/deckbuilder/DeckDetail/DeckDetail.component'
import CardDetailModal from 'components/Modal/card-detail/card-detail.modal'
import { useHistory } from 'react-router-dom'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Card from '../../components/deckbuilder/Card/Card.component'
import Footer from '../../components/Footer/Footer.component'
import { Modal } from '../../components/deckbuilder/Modal/Modal.component'
import Library from '../../components/deckbuilder/Library/Library.component'
import EditDeck from '../../components/deckbuilder/EditDeck/EditDeck.component'
import Button from '../../components/Button/Button.component'
import Banner from '../../components/Banner/Banner.component'
import { CardType } from '../../domain/card.model'
import {LoaderRepository} from "../../infraestructure/repositories/loader.repository";
import DeckFormatsService from "../../services/deck-formats.service";
import AdeptModal from 'components/AdeptModal/AdeptModal.component'

export const checkPlayable = (newDeck: any) => {
  const hq = newDeck.cards.find((c: any) => c.CardType === 'HQ')
  const nonHQ = newDeck.cards.filter((c: any) => c.CardType !== 'HQ')
  return hq && nonHQ.length === 8
}

export function DeckBuilderView() {
  const [pickHQModal, setShowHQModal] = useRecoilStateLoadable<boolean>(openHQPickerAtom)
  const refresh = useRecoilRefresher_UNSTABLE(AllCardsSelector)
  const hasAdeptDecks = useRecoilValueLoadable(hasAdeptDecksState)
  const [selectedCard, setCard] = useState(null)
  const wallet = useRecoilValue(connectedWallet)
  const deckList = useRecoilValueLoadable(deckListState)
  const history = useHistory()
  
  const [showAdeptModal, setShowAdeptModal] = useState(false)

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    let showModal = true

    if (!cookie.get('adeptdecks')) {
      if(deckList.contents.length) {
        for (let i = 0; i < deckList.contents.length; i++) {
          const deck = deckList.contents[i];
          console.log(deck.format)
          if (deck.format === 'adept') {
            cookie.set('adeptdecks', 'true', { expires: 365 })
            showModal = false
            break;
          }
        }
      }
    } else {
      showModal = false
    }

    if(showModal) setShowAdeptModal(true)

  }, [deckList.contents])

  const addCardToDeck = useRecoilCallback(({ snapshot, set }) => async (card: CardType) => {
    const release = snapshot.retain()
    try {
      setShowHQModal(false)
      const currentDeck = await snapshot.getPromise(currentDeckSelector)
      const deckCards = await snapshot.getPromise(deckCardsSelector)
      const wallet = await snapshot.getPromise(connectedWallet)
      const newDeck = {
        ...currentDeck,
        cards: card.CardType == 'HQ'
          ? [
            { ...card },
            ...deckCards.filter((card: any) => card.CardType !== 'HQ'),
          ]
          : [
            ...deckCards,
            { ...card },
          ],
      }
      if (wallet) {
        const isPlayable = checkPlayable(newDeck)
        const res = await DeckRepository.update({ ...newDeck, playable: isPlayable }, wallet.address)
        set(currentDeckAtom, res)
      }
      const r = await snapshot.getPromise(cardsFetchAtom)
      set(cardsFetchAtom, () => r + 1)
      LoaderRepository.refreshDecks = true
    } catch (e) {
      release()
    }
  })

  const newDeck = useRecoilCallback(({ snapshot, set }) => async () => {
    const release = snapshot.retain()
    try {
      const wallet = await snapshot.getPromise(connectedWallet)
      const newDeck = { cards: [], owner: wallet?.address }

      if (wallet) {
        await DeckRepository.save(newDeck, wallet?.address)
        const d = await snapshot.getPromise(decksFetchAtom)
        await set(decksFetchAtom, () => d + 1)
        const decks = await DeckRepository.getByWallet(wallet.address)
        await set(currentDeckAtom, decks[decks.length - 1])
      }
    } catch (e) {
      release()
    }
  })

  if (deckList.state === 'loading') return <div className="connect-wallet message-alert"><h2 /><h1>Loading decks ...</h1><h2 /></div>
  if (!wallet) return <div className="connect-wallet message-alert"><h2 /><h1>must connect to wallet ...</h1><h2 /></div>

  return (
    <div>
      <CardDetailModal card={selectedCard} closeAction={() => setCard(null)} />
      {showAdeptModal && <AdeptModal />}
      {wallet && deckList.contents && deckList.contents.length > 0
        ? (
          <div className='deck-builder'>
            <Modal isActive={pickHQModal.contents} closeAction={() => setShowHQModal(false)} id='HQ-picker'>
              <p className='title'>select your HQ</p>
              <Suspense fallback={<div className="connect-wallet message-alert"><h2 /><h1>loading headquarters ...</h1><h2 /></div>}>
                <DeckHQSelector
                  onHQSelected={addCardToDeck}
                  onViewHQ={setCard}
                />
              </Suspense>
            </Modal>

            {/* <Modal isActive={warningModal} id='overlay-action'>
            <img src={iconWarning}/>
            <p className='title'>{modalTitle}</p>
            <p>{modalContent}</p>
            <div className='modal-actions'>
              <Button variant={'outline'} size={'medium'}>{cancelCTA}</Button>
              <Button variant={'primary'} size={'medium'}>{modalCTA}</Button>
            </div>
          </Modal> */}

            <EditDeck newDeck={newDeck} playDeck={() => { }} />

            {/* <Filter/> */}
            <div className='deck-wrapper'>
              {/* <Deck key={'deck-model'} showHQModal={showModal} cleanDeck={cleanDeck}/>  */}
              <DeckDetail onViewCard={setCard} />
              <Library key={'deck-library'}
                onAddCard={addCardToDeck}
                onViewCard={setCard}
              />
            </div>
          </div>
        )
        : (
          <div className='empty-deck'>
            <h2>no decks!</h2>
            <h3>Looks like you haven&apos;t built any decks yet</h3>
            <p>Build your deck now to start playing</p>
            <div className='actions'>
              <Button variant={'outline'} size={'medium'} onClick={() => history('store')}>buy cards</Button>
              <EditDeck onlyNew />
            </div>
          </div>
        )
      }
      <Banner text={'Don’t know how to build a deck? Here’s How!'}>
        <Button variant={'primary'} size={'medium'} onClick={() => history.push('tutorials')}>
          see a tutorial
        </Button>
      </Banner>
      <Footer />
    </div>

  )
}

export function DeckHQSelector(
  { onHQSelected, onViewHQ }: any,
) {

  const deck = useRecoilValue(currentDeckSelector)
  const deckFormats = useRecoilValue(DeckFormatsSelector)
  const HQCards:CardType[] = DeckFormatsService.filterByDeckFormat(useRecoilValue(HQSelector),deckFormats,deck)

  return (
    <div className='HQ-picker-card-list'>
      {HQCards.map((headquarter: CardType, index: number) => (
        <div className='HQ-picker-card-list-single' key={headquarter.id}>
          <div className="HQ-picker-container">
            <Card
              {...headquarter}
              key={index}
            />
            <div className="hover-options">
              <div className="hover-buttons">
                <Button variant={'primary'} size={'medium'} className="view-btn" onClick={() => onViewHQ(headquarter)} >
                  <FontAwesomeIcon icon={faEye as IconProp} />
                  View card
                </Button>
              </div>
            </div>
          </div>
          <Button className="zIndexSup" variant={'primary'} size={'medium'} onClick={() => onHQSelected(headquarter)}>Select</Button>
        </div>
      ))}
    </div>
  )
}
