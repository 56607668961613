import './views.scss'
import Footer from 'components/Footer/Footer.component'
import NftListComponent from 'components/NFTList/NFTList.component'
import { useRecoilValueLoadable } from 'recoil'
import { profileSelector } from 'selectors/selectors'
import UserNotConnectedView from './user/user-not-connected.view'

export default function StarterPackView() {
  const profile = useRecoilValueLoadable(profileSelector)

  return (
    <div className="inventory-view" style={{ gridTemplateRows: 'initial' }}>
      {profile.contents
        ? <NftListComponent />
        : <UserNotConnectedView/>}
      <Footer />
    </div>
  )
}
