/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-restricted-syntax */
import './volExchange.component.scss'
import getConfig from 'config.service'
// eslint-disable-next-line camelcase
import { useRecoilValue, useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import {
  connectedWallet, profileBalancesSelector, profileSelector,
  userBalanceSelector,
} from 'selectors/selectors'
import { Grid, _ } from 'gridjs-react'
import { useEffect, useState } from 'react'
import RewardRepository from 'repositories/rewards.repository'
import { LoaderFallback, setLoader } from 'components/Loader/Loader.component'
import { OffChainBalance } from 'domain/offchain-rewards.model'
import { Price, AssetCatalog } from 'domain/assetCatalog.model'
import VolExchangeRepository from 'repositories/asset-exchange.repository'
import { getAssetType } from 'domain/volExchange.model'
import WalletIMX from 'domain/wallet-imx.model'
import MyAssetsRepository from 'repositories/my-assets.repository'
import Text from 'components/Text/Text'
import moment from 'moment'
import { ReactComponent as MetalHolderTopLeft } from '../../assets/images/metal-holder-top-left.svg'

interface Balance {
  [key: string]: number
}

export const checkCashierPayment = (prices: Price[], balance: Balance): boolean => {
  const pass: boolean[] = []
  for (const price of prices) {
    if (price.type) pass.push((balance[price.type] ?? 0) >= price.amount)
    else pass.push((balance[price.currency] ?? 0) >= price.amount)
  }
  return !pass.includes(false)
}

function getIconType(assetType: string) {
  switch (assetType.toLowerCase()) {
    case 'currency':
      return 'currency'
    case 'ticket':
      return 'tickets'
    case 'booster':
      return 'boosters'
    default:
      return 'currency'
  }
}

export default function VolExchangeComponent({ setModalMessage }:
  { setModalMessage: (message: [string, string]) => void }) {
  const user = useRecoilValue(connectedWallet)
  const [volCatalog, setVolCatalog] = useState<AssetCatalog[]>([])
  const [balances, setBalances] = useState<Balance>({})
  const [mode, setMode] = useState<string>('POINT_TO_NFT')
  const [converting, setConverting] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const userProfile = useRecoilValueLoadable(profileSelector)
  const userAge = userProfile.state === 'hasValue' ? moment().diff(moment(userProfile.contents?.info.createdAt), 'days') : 0
  const isUserAgeValid = userAge > 2

  const refreshBalances = useRecoilRefresher_UNSTABLE(profileBalancesSelector)
  const refreshTicketBalances = useRecoilRefresher_UNSTABLE(userBalanceSelector)

  const mapBalances = (offchainBalances: OffChainBalance[]) => {
    const newBalance: Balance = {}
    for (const offchainBalance of offchainBalances) {
      if (offchainBalance.offChainCurrency === 'FREE_ROLL_TICKET') {
        newBalance[offchainBalance.ticketId ?? 0] = offchainBalance.balance
      } else {
        newBalance[offchainBalance.offChainCurrency] = offchainBalance.balance
      }
    }
    return newBalance
  }

  // @TODO: Change loop for each VOL and retrieve the balance for all Assets at once
  const getNFTBalances = async (volNFTCatalog: AssetCatalog[]) => {
    const config = getConfig()
    const treasury = config.WALLET_TREASURY_ADDRESS
    const userWallet = new WalletIMX(user?.address ?? '', config.ETH_NETWORK)
    const treasuryWallet = new WalletIMX(treasury, config.ETH_NETWORK)
    const newBalance: Balance = {}
    for (const vol of volNFTCatalog) {
      const assetTypeCapitalized = getAssetType(vol.assetType)
      const metadata = { AssetType: [assetTypeCapitalized], [vol.assetTypeFieldKey]: [vol.assetTypeFieldValue] }
      // eslint-disable-next-line no-await-in-loop
      const { qty } = await MyAssetsRepository.previewAssetsQuantity(userWallet, JSON.stringify(metadata))
      newBalance[`VOLNFT${vol.id}`] = qty

      // eslint-disable-next-line no-await-in-loop
      const { qty: balanceTreasury } = await MyAssetsRepository
        .previewAssetsQuantity(treasuryWallet, JSON.stringify(metadata))
      newBalance[`TREASURY_VOLNFT${vol.id}`] = balanceTreasury
    }
    return newBalance
  }

  const getBalances = (showLoading?: boolean) => {
    if (showLoading) setLoading(true)
    Promise.all([
      VolExchangeRepository.get(),
      RewardRepository.getBalance(user?.address),
    ])
      .then((result) => {
        const newVolCatalog = result[0].filter((vol) => vol !== null)
        setVolCatalog(newVolCatalog)
        getNFTBalances(newVolCatalog)
          .then((balanceNFT) => {
            setBalances({
              ...mapBalances(result[1]),
              ...balanceNFT,
            })
          })
      })
      .finally(() => showLoading && setLoading(false))
  }

  useEffect(() => {
    getBalances(true)
  }, [])

  const convertVolToNFT = (volId: string) => {
    setConverting(true)
    VolExchangeRepository.convertOffChainToNFT(volId, user?.address)
      .then((volExchange) => {
        getBalances(true)
        refreshBalances()
        refreshTicketBalances()
        setModalMessage([
          'CONVERTION SUCCESSFUL!',
          `You have successfully converted your assets. Your balance will be updated soon. 
          Transaction ID: ${volExchange.txHash}`,
        ])
      })
      .catch(() => {
        setModalMessage([
          'ERROR CONVERTING!',
          'There was an error converting your assets. Please try again later.',
        ])
      })
      .finally(() => setConverting(false))
  }

  const convertNFTToVol = (volId: string) => {
    setConverting(true)
    VolExchangeRepository.convertNFTToOffChain(volId, user?.address)
      .then((txHash) => {
        getBalances(true)
        setTimeout(() => {
          refreshBalances()
        }, 5000)
        refreshTicketBalances()
        setModalMessage([
          'CONVERTION SUCCESSFUL!',
          `You have successfully converted your NFT to VOL POINTS. Your balance will be updated soon. 
          Transaction ID: ${txHash}`,
        ])
      })
      .catch((error) => {
        if (error.message === 'Transfer was cancelled') {
          setModalMessage(['ERROR CONVERTING!', 'Transfer was cancelled.'])
        } else {
          setModalMessage([
            'ERROR CONVERTING!',
            'There was an error converting your NFT to VOL POINTS. Please try again later.',
          ])
        }
      })
      .finally(() => setConverting(false))
  }

  const ConvertSoftToNFTButton = ({ id, prices }: { id: string, prices: Price[] }) => {
    let buttonToolTip = 'Not Enough Balance'
    if (isUserAgeValid) {
      if (mode === 'POINT_TO_NFT') {
        if (checkCashierPayment(prices, balances)) {
          if (balances[`TREASURY_VOLNFT${id}`] > 0) {
            return <div className='convert-button-wrapper'>
              <div key={id} className="btn-convert btn btn-primary" onClick={() => convertVolToNFT(id.toString())}>CONVERT</div>
            </div>
          }
          buttonToolTip = 'Not Enough Stock'
        }
      }
      if (mode === 'NFT_TO_POINT') {
        if (balances[`VOLNFT${id}`] > 0) {
          return <div className='convert-button-wrapper'>
            <div key={id} className="btn-convert btn btn-primary" onClick={() => convertNFTToVol(id.toString())}>CONVERT</div>
          </div>
        }
      }
    }
    return (
      <div className='convert-button-wrapper'>
        <div key={id} className="btn btn-convert disabled" title={buttonToolTip}>CONVERT</div>
      </div>
    )
  }

  const ConvertionName = ({
    id, name, assetTypeFieldValue, assetType,
  }:
    { id: string, name: string, assetTypeFieldValue: string, assetType: string }) => {
    const balanceLoading = Object.entries(balances).length === 0
    const volNftQty = balances[`VOLNFT${id}`] ?? 0
    const balance = volNftQty === 20 ? '20+' : volNftQty
    const iconFolder = getIconType(assetType)
    return (
      <div className="vol-catalog-name-container">
        <div className="vol-catalog-icon">
          <img height="48" src={`/images/${iconFolder}/${assetTypeFieldValue}.png`} />
        </div>
        <div className="vol-catalog-content">
          <div className='vol-catalog-name'>
            {name}
          </div>
          <div className='vol-in-wallet'>{`In wallet: ${balanceLoading ? '...' : balance}`}</div>
        </div>
      </div>
    )
  }

  const ConvertionDescription = ({ description }: { description: string }) => (
    <div className='description-container'>
      {description}
    </div>
  )

  const convertionCell = ({
    id, name, price, description, assetTypeFieldValue, assetType,
  }: AssetCatalog) => _(<div className='convertion-cell'>
    <ConvertionName assetTypeFieldValue={assetTypeFieldValue} assetType={assetType} name={name} id={id} />
    <ConvertionDescription description={description || ''} />
    <ConvertSoftToNFTButton
      id={id}
      prices={price}
    />
  </div>)

  if (converting || loading) return <LoaderFallback text={'Please wait ...'} />
  setLoader(false)

  const tableStyle = {
    td: {
      textAlign: 'center',
      alignContent: 'center',
      fontSize: '1.15em',
      fontWeight: '600',
      'font-family': 'Rajdhani',
    },
    th: {
      textAlign: 'center',
      color: '#ff5c00',
      fontSize: '1.3em',
    },
    table: {
      'font-family': 'Rajdhani',
    },
  }

  return (
    <div className="vol-catalog">
      <MetalHolderTopLeft className='metal-holder-left' />
      <div className='vol-catalog-title-header'>
        <div className='title'>VOL EXCHANGE</div>
      </div>
      <div className='vol-catalog-grid'>
        <div className='vol-catalog-tab-container'>
          <div className={`vol-catalog-tab ${mode === 'POINT_TO_NFT' ? 'vol-catalog-tab-enabled' : ''}`} onClick={() => setMode('POINT_TO_NFT')} >POINTS to NFT</div>
          <div className={`vol-catalog-tab ${mode === 'NFT_TO_POINT' ? 'vol-catalog-tab-enabled' : ''}`} onClick={() => setMode('NFT_TO_POINT')} >NFT to POINTS</div>
        </div>
        {!isUserAgeValid && <div className='account-age-restriction'>
          <Text
            textAlign='center'
            fontWeight={600} fontSize={'1.2rem'}>This account cannot exchange VOL NFT yet. Please try again in 24 hours.</Text>
        </div>}
        <Grid
          data={volCatalog
            .filter((x) => x.type === 'vol')
            .map((vol) => [
              convertionCell(vol),
            ])}
          columns={[
            { id: 'vol', name: '' },
          ]}
          style={tableStyle}
          search={false}
          pagination={{
            enabled: false,
            limit: 30,
          }}
        />
        <div style={{ display: 'grid', placeContent: 'center', margin: '1ch 0' }}>
          <span className='title' style={{ fontSize: '2rem' }}>TICKET EXCHANGE</span>
        </div>
        <Grid
          data={volCatalog
            .filter((x) => x.assetType === 'Ticket')
            .map((vol) => [
              convertionCell(vol),
            ])}
          columns={[
            { id: 'vol', name: '' },
          ]}
          style={tableStyle}
          search={false}
          pagination={{
            enabled: false,
            limit: 30,
          }}
        />
      </div>
    </div >
  )
}
