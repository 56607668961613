import getConfig from 'config.service'
import { TaskDto } from 'domain/task.model'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import { ServiceConsumer, ServiceData } from 'infraestructure/services/service-consumer/service-consumer.service'

const { hostURL } = getConfig()

export default class TasksRepository {
  public static async saveTasks(tasks: TaskDto[]): Promise<{ success: boolean }> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/achievements/tasks`,
      body: {
        tasks,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    // eslint-disable-next-line no-undef
    const resp = await ServiceConsumer.post<{ success: boolean }>(requestData)
    return resp
  }

  public static async getAllTasks(): Promise<TaskDto[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/achievements/tasks`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const tasks = await ServiceConsumer.get<TaskDto[]>(requestData)
    return tasks
  }
}
