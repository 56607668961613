import Flex from 'components/Flex/Flex'
import Spacer from 'components/Flex/Spacer'
import { ReactNode } from 'react'

const Title = ({ text }: { text: string }) => <h2 style={{ textAlign: 'left' }}>{text}</h2>
const Value = ({ text }: { text: string }) => <p style={{ fontFamily: 'rajdhani', fontWeight: '600' }}>{text}</p>
const Equivalence = ({ text }: { text: string }) => <p style={{ fontFamily: 'rajdhani', fontWeight: '500' }}>({text})</p>

function ExchangeItem({ children }: { children: ReactNode[] }) {
  return <Flex flexDirection='row' alignItems='center' gap="20px" style={{ width: '100%' }}>
    {children[0]}
    <Flex flexDirection='column' gap='2.5px'>
      {children[1]}
      <Flex flexDirection='row' gap="1ch">
        {children[2]}
        {children[3]}
      </Flex>
    </Flex>
    <Spacer />
    {children.slice(4)}
  </Flex>
}

ExchangeItem.Title = Title
ExchangeItem.Value = Value
ExchangeItem.Equivalence = Equivalence

export default ExchangeItem
