import './card.component.scss'
import { CardType as CardTypeModel } from 'domain/card.model'
import { useState } from 'react'
import CardSprite from './card-sprite.component'
import getConfig from '../../../config.service'

const config = getConfig()

export default function Card(card: CardTypeModel & {disabled?: boolean}) {
  const [error, setError] = useState(false)

  if (!card.CardNumber) return <></>
  let background = {}
  if (!config.CARD_SPRITE) {
    background = error ? {
      background: 'linear-gradient(90deg, rgba(158,43,0,0) 0%, rgba(158,43,0,1) 50%, rgba(158,43,0,0) 100%)',
    } : { backgroundImage: `url(${card.Art}?tx=h_400,q_80,f_auto)` }
  }

  return (
    <button className={`card-holder ${card.className || ''}`} onClick={card.onClick} style={{
      ...background,
      aspectRatio: card.CardType.toUpperCase() === 'CHARACTER' ? '15/17' : '10/9',
    }}>
      {
        config.CARD_SPRITE ? (
          <CardSprite {...card} />
        ) : (
          <>
            <img src={`${card.Art}?tx=h_400,q_80,f_auto`} onError={() => setError(true)} style={{ width: 0, height: 0 }} alt={card.Name ?? ''} />
            {error && <div>{card.Name} <br/>has no image.</div>}
          </>
        )
      }
    </button>
  )
}
