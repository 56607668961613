import { useContext } from 'react'
import hourglass from './assets/hourglass.svg'
import usePendingCraftings from './hooks/usePendingCraftings.hook'
import { CraftingContext } from './crafting-context.component'

export default function PendingCraftings() {
  const { selectedRecipe } = useContext(CraftingContext)
  const { pending } = usePendingCraftings([selectedRecipe])
  if (pending === 0) return <div className="pending-craftings" />
  return <div className="pending-craftings">
    <h2>You have {pending} recipes in process</h2>
    <img style={{ padding: '0px 10px' }} src={hourglass} alt="hourglass" />
  </div>
}
