import { useEffect, useCallback, memo } from 'react'
import './modal.scss'
import classNames from 'classnames'

import useScrollBlock from './scrollBlock'

export type ModalType = {
  children?: any,
  isActive: boolean,
  closeAction: any,
  id: any,
  title?: string,
}

const ModalContainer = ({
  children,
  isActive,
  closeAction,
  id,
}: ModalType) => {
  const classNameOverlay = classNames('overlay', {
    show: isActive,
    hide: !isActive,
  })

  const [blockScroll, allowScroll] = useScrollBlock()
  const closeModalBackground = (event: any) => {
    if (event.target.id === id) {
      closeAction()
    }
  }
  const closeModal = useCallback(() => {
    closeAction()
  }, [closeAction])

  useEffect(() => {
    if (isActive) blockScroll()
    else allowScroll()
    return () => {
      allowScroll()
    }
  }, [isActive])

  return (
    <div className={classNameOverlay} id={id} onClick={(event) => closeModalBackground(event)}>
      <div className="shadow" onClick={closeModal} />
      <div className="modal modal-container">
        <div className="modal bevel" />
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  )
}
export default memo(ModalContainer)
