import './TournamentsFilters.component.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function TournamentsFilters({
  filters, setFilters, search, setSearch,
}:{filters:any, setFilters:Function, search:string, setSearch:Function}) {
  const submitSearch = (evt: any) => {
    evt.stopPropagation()
    evt.preventDefault()
  }

  function setFilter(selected:string) {
    const $filters = { ...filters }
    Object.keys($filters).forEach((key) => {
      $filters[key].active = false
    })
    $filters[selected].active = true
    setFilters($filters)
  }

  return (
    <div className="tournaments-filters">
      <div className="tournaments-filters__types">
        {
          Object.keys(filters).map((key:any) => {
            const filter = filters[key]
            return (
              <div onClick={() => setFilter(key)} key={`filter-${key}`} className={`tournaments-filters__types${filter.active ? ' active' : ''}`}>{filter.title}</div>
            )
          })
        }
      </div>
      <form className="search-bar tournaments-filters__search" onSubmit={submitSearch}>
        <FontAwesomeIcon icon={faSearch as IconProp} className="search-bar-icon" />
        <input type="text" placeholder="Search..." value={search} onChange={(evt) => {
          setSearch(evt.target.value)
        }} />
        <input type={'submit'} style={{ display: 'none' }} />
      </form>
    </div>
  )
}
