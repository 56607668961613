import { useRecoilValueLoadable } from 'recoil'
import Flex from 'components/Flex/Flex'
import { connectedWallet } from 'selectors/selectors'
import layerswapIcon from '../../assets/layerswap.icon.svg'
import './LayerSwapBuyLink.component.scss'

type LayerSwapBuyLinkProps = {
  token: string
}

export default function LayerSwapBuyLink(props: LayerSwapBuyLinkProps) {
  const wallet = useRecoilValueLoadable(connectedWallet)
  return <a
    rel="noreferrer"
    target='_blank'
    // eslint-disable-next-line max-len
    href={`https://www.layerswap.io/app/?to=IMMUTABLEX_MAINNET&destAddress=${wallet.contents.address}&lockAddress=true&asset=${props.token}&lockAsset=true`}>
    <Flex className='layer-swap-button' flexDirection='column' gap="1ch">
      <Flex
        flexDirection='row'
        alignItems='center'
        gap="1ch"
      >
        <Flex
          alignContent='flex-start'
          alignItems='center'
          justifyContent='flex-start'
          flexDirection='column'
          gap="1ch"
          style={{
            textAlign: 'center',
            color: '#fff',
          }}>
          <p style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>FROM OTHERS CHAINS OR EXCHANGES</p>
          <img
            src={layerswapIcon}
            height={50} width={50}
          />
        </Flex>
        <Flex flexDirection='column' gap="1.5ch" style={{ width: '250px', padding: '10px 5px' }}>
          <p style={{
            fontWeight: '500',
            color: '#fff',
            fontSize: '1rem',
          }}>
            By clicking this button you will be redirected to the <b>layerswap</b> platform to complete this action.
          </p>
          <Flex flexDirection='row' gap="1ch">
            <img src="/images/chains/binance.webp" width={32} style={{ borderRadius: '50%' }} />
            <img src="/images/chains/ethereum_mainnet.webp" width={32} style={{ borderRadius: '50%' }} />
            <img src="/images/chains/kraken.webp" width={32} style={{ borderRadius: '50%' }} />
            <img src="/images/chains/coinbase.webp" width={32} style={{ borderRadius: '50%' }} />
            <img src="/images/chains/okex.webp" width={32} style={{ borderRadius: '50%' }} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </a>
}
