import { useEffect, memo, useState } from 'react'
import './progressBar.component.scss'

interface IPropsProgressBar {
  total: number
  progress: number
}

const ProgressBar = ({ total, progress }: IPropsProgressBar) => {
  const [barPercentage, setBarPercentage] = useState<number>(0)

  useEffect(() => {
    const percentage = (progress * 100) / total
    setBarPercentage(percentage)
  }, [total, progress])

  return (
    <div className="progress-bar">
      <div className="progress-bar__background-bar"></div>
      <div className="progress-bar__bar" style={{ width: `${barPercentage}%` }}></div>
    </div>
  )
}

export default memo(ProgressBar)
