import './TournamentItem.component.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { faCalendarAlt, faLock, faUsers } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useHistory } from 'react-router-dom'
import { Tournament } from 'domain/tournament.model'
import EntryFee from './EntryFee.component'
import PrizePool from './PrizePool.component'
import { DeckFormatsDto } from '../../../../services/deck-formats.service'

function getItemClass(tournament: Tournament): string {
  return `tournament-item--${tournament.faction.toLowerCase()}`
}

function getTournamentBackground(tournament: Tournament): string {
  if (tournament.branding?.key === 'RED') { return 'redtournament.jpg' }
  if (tournament.branding?.key === 'BLUE') { return 'bluetournament.jpg' }
  if (tournament.branding?.key === 'YELLOW') { return 'yellowtournament.jpg' }
  if (tournament.branding?.key === 'GREEN') { return 'greentournament.jpg' }
  if (tournament.branding?.key === 'HALLOWEEN') { return 'halloweentournament.jpg' }
  if (tournament.branding?.key === 'FIGHT') { return 'fighttournament.jpg' }
  if (tournament.branding?.key === 'PLAYGROUND') { return 'playgroundtournament.jpg' }
  if (tournament.branding?.key === 'STREET') { return 'streettournament.jpg' }
  if (tournament.branding?.key === 'UNDERGROUND') { return 'undergroundtournament.jpg' }
  return 'redtournament.png'
}

export const TournamentFormatComponentDisplay = ({ tournament, deckFormats }:
  { tournament: Tournament, deckFormats?: DeckFormatsDto }) => {
  if (deckFormats) {
    const format = deckFormats.formats.find((df) => df.id === tournament.deckValidations?.deckFormat)
    if (format) return <p>{format.name} (Min {tournament.deckValidations?.minNftCards} NFT)</p>
  }
  return <></>
}

export default function TournamentItem(
  { tournament, deckFormats }: { tournament: Tournament, deckFormats?: DeckFormatsDto },
) {
  const history = useHistory()

  const topComponentDisplay = () => {
    if (tournament.state === 'ANNOUNCED') return <div className="tournament-item__announced">JUST ANNOUNCED</div>
    return <div>
      {tournament.state === 'PUBLISHED' ? <FontAwesomeIcon icon={faUsers as IconProp} style={{ color: 'rgba(75, 214, 52, 1)' }} />
        : <FontAwesomeIcon icon={faLock as IconProp} style={{ color: 'rgba(251, 67, 67, 1)' }} />} {tournament.state === 'PUBLISHED' ? `${tournament.subscribedPlayers?.length ?? 0}/` : ''}{tournament.maxPlayers}
    </div>
  }

  const dateComponentDisplay = () => {
    if (tournament.state === 'ANNOUNCED' || tournament.state === 'PUBLISHED' || tournament.state === 'SUBSCRIPTION_CLOSED') {
      return (
        <div>
          <FontAwesomeIcon icon={faCalendarAlt as IconProp} />
          {moment(tournament.startDate).utc().format('MMMM DD, YYYY z HH:mm')}
        </div>
      )
    } if (tournament.state === 'ENDED') {
      return <div>ENDED</div>
    } if (tournament.state === 'SUBSCRIPTION_CLOSED') {
      return <div>SUBSCRIPTION CLOSED</div>
    } if (tournament.state === 'ONGOING') {
      return <div>IN PROGRESS</div>
    }
    return <></>
  }

  function goToTournament() {
    history.push(`/tournaments/${tournament.id}`)
  }

  return (
    <div className="tournament-item">
      {/* <div className="avatar-img" style={{ backgroundImage: 'url(https://deviants-factions.mo.cloudinary.net/cards/avatar/isra.jpg?tx=h_300,f_auto)' }}>
      </div> */}
      <div className={`tournament-item__content ${getItemClass(tournament)}`} onClick={goToTournament}>
        <div className="tournament-item__registers">
          {topComponentDisplay()}
        </div>
        <div className="tournament-item__date">
          {dateComponentDisplay()}
        </div>
        <div className="tournament-item__title">
          {tournament.name}
        </div>
        {TournamentFormatComponentDisplay({ tournament, deckFormats })}
        <div className="tournament-item__bottom">
          <EntryFee tournament={tournament} />
          <PrizePool tournament={tournament} />
        </div>
      </div>
      <div className="tournament-item__background"
        style={{ backgroundImage: `url(/images/tournaments/${getTournamentBackground(tournament)})` }}
      />
    </div>
  )
}
