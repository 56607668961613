/* eslint-disable no-undef */
import getConfig from 'config.service'
import { ServiceConsumer } from 'infraestructure/services/service-consumer/service-consumer.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

export type PlayerBalance = {
  balance: number
  symbol: string
}

/* const MIN_DEV_PRICE = 0.05 */

const getLocalCache = (key: string) => {
  const cache = localStorage.getItem('convertions')
  if (!cache) return 0
  const parsedData = JSON.parse(cache)
  const now = new Date().getTime()
  const diff = now - parseInt(parsedData.time, 10)
  if (diff > 1000 * 60 * 5) return 0
  return parsedData.data[key].usd
}

const storeConvertionsInCache = async (data: { [key: string]: { usd: number } }) => {
  localStorage.setItem('convertions', JSON.stringify({ data, time: new Date().getTime() }))
}

const BalanceRepository = {
  async convertDevToUsd(): Promise<number> {
    try {
      const coingeckoUrl = 'https://api.coingecko.com/api/v3/simple/price?ids=deviantsfactions&vs_currencies=usd'
      const response = await ServiceConsumer.get<{ deviantsfactions: { usd: number } }>({ url: coingeckoUrl })
      return response.deviantsfactions.usd
    } catch (error) {
      return 0
    }
  },
  async convertToken(token: string): Promise<number> {
    const tokens = 'usd-coin,immutable-x,ethereum,deviantsfactions,gods-unchained'
    try {
      const cache = getLocalCache(token)
      if (cache !== 0) return cache
      const coingecko = `https://api.coingecko.com/api/v3/simple/price?ids=${tokens}&vs_currencies=usd`
      const response = await ServiceConsumer.get<{ [key: string]: { usd: number } }>({ url: coingecko })
      storeConvertionsInCache(response)

      // MIN PRICE FOR DEV
      /* if (token === 'deviantsfactions' && response[token].usd < MIN_DEV_PRICE) {
        return MIN_DEV_PRICE
      } */

      return response[token].usd
    } catch (error) {
      return getLocalCache(token)
    }
  },
  async getImxBalance(wallet: string, token: string): Promise<number> {
    const config = getConfig()
    const playerBalance = await ServiceConsumer.get<PlayerBalance>({
      url: `${config.API_ADDRESS}/v2/balances/${wallet}/${token}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return playerBalance.balance
  },
  async getEthBalance(wallet: string): Promise<number> {
    const config = getConfig()
    const playerBalance = await ServiceConsumer.get<any>({
      url: `${config.API_ADDRESS}/v2/balances/${wallet}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return playerBalance.result.find((balance: any) => balance.symbol === 'ETH')?.balance || 0
  },
  async getBalance(wallet: string, tokenName?: 'dev' | 'usdc' | 'imx' | 'eth' | 'gods'): Promise<PlayerBalance> {
    const config = getConfig()
    let tokenAddress = ''
    let decimals = 6
    switch (tokenName) {
      case 'usdc':
        tokenAddress = config.USDC_TOKEN_ADDRESS
        decimals = 6
        break
      case 'dev':
        tokenAddress = config.DEV_TOKEN_ADDRESS
        decimals = 6
        break
      case 'imx':
        tokenAddress = config.IMX_TOKEN_ADDRESS
        decimals = 18
        break
      case 'gods':
        tokenAddress = config.GODS_TOKEN_ADDRESS
        decimals = 18
        break
      case 'eth':
        tokenAddress = ''
        break
      default:
        tokenAddress = config.USDC_TOKEN_ADDRESS
        break
    }

    const token = ValidateIfTokenIsPresent()
    const playerBalance = await ServiceConsumer.get<PlayerBalance>({
      url: `${config.API_ADDRESS}/v2/balances/${wallet}/${tokenAddress}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return { ...playerBalance, balance: playerBalance.balance / (10 ** decimals) }
  },
}

export default BalanceRepository
