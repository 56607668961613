// noinspection HtmlUnknownTarget

import './hero.scss'
import { useRecoilValueLoadable } from 'recoil'
import { profileSelector } from 'selectors/selectors'
import { NavLink } from 'react-router-dom'
import leftholder from './assets/hero-section-left-holder.png'
import rightholder from './assets/hero-section-right-holder.png'
import live from '../../assets/img/live.png'
import newAsset from '../../assets/img/new.png'
import getConfig from '../../config.service'

const config = getConfig()
const { ENABLE_STORE, showPlayButton } = config

type HeroType = {
  className?: string;
  title: string;
  subTitle?: string;
}

const showTutorials = false

export default function Hero({ title, subTitle }: HeroType) {
  const profile = useRecoilValueLoadable(profileSelector)

  return (
  <section className="hero-section">
    <div className="hero-section-background" />
    <div className="hero-section-container">
      {
        showPlayButton ? <></> : (
          <a className="outbreak-link" href="https://youtu.be/quDj9cEG3CI" rel="noreferrer" target="_blank" id="alpha-launch">
            <div className="outbreak-launch"/>
          </a>
        )
      }
      <h1 className="hero-section-title">{title}</h1>
      <p className="hero-section-subtitle">{subTitle}</p>
      <div className={ `hero-section-actions${(config.tutorials && showTutorials ? ' actions-row' : '')}` }>
        {
         showPlayButton ? (
            <>
              {
                profile.state === 'hasValue' && profile.contents?.id ? (
                  <NavLink to="/deck-select" className="hero-button btn-play btn-shadow">
                    <div className="btn btn-medium btn-primary" id="hero-play">
                      PLay
                    </div>
                  </NavLink>
                ) : (
                  <a href="/play/tutorial-01" className="hero-button btn-play btn-shadow">
                    <div className="btn btn-medium btn-primary" id="hero-play">
                      PLay
                    </div>
                  </a>
                )
              }
              { ENABLE_STORE && (
                <NavLink to="/store" className="hero-button btn-rulebook btn-shadow">
                  <img src={live} alt="" className="new-asset-img"/>
                  <div className="btn btn-medium btn-primary" id="hero-mint">
                    Mint
                  </div>
                </NavLink>
              )}
            </>
         ) : (
            <>
              <a target="_blank" href="https://deviants.mypinata.cloud/ipfs/QmXE7zDRGTtsAYFmBqCoKrL8NpK6WrCbVtH3xb6utYcrrL" rel="noreferrer" className="hero-button btn-shadow">
                <div className="btn btn-medium btn-primary" id="hero-litepaper">
                  Litepaper
                </div>
              </a>
              <a target="_blank" href="https://deviants.mypinata.cloud/ipfs/QmbYiC9LJDxG934HuP9zcSQDkTR3G7pPFqCHSFMRvyNqC8" rel="noreferrer" className="hero-button btn-shadow">
                <img src={newAsset} alt="" className="new-asset-img"/>
                <div className="btn btn-medium btn-primary" id="hero-rulebook">
                  Rulebook
                </div>
              </a>
            </>
         )
        }

      </div>
      {
        config.tutorials && showTutorials ? (
          <>
            <p className="hero-section-subtitle" style={{ marginTop: '40px' }}>Do you want to know the rules</p>
            <div className="hero-section-actions actions-row">
              <NavLink to="/tutorials" className="hero-button btn-rulebook btn-shadow">
                <div className="btn btn-medium btn-primary" id="hero-rulebook">
                  Tutorials
                </div>
              </NavLink>
              <a target="_blank" href="https://docs.deviantsfactions.com" rel="noreferrer" className="hero-button btn-rulebook btn-shadow">
                <div className="btn btn-medium btn-primary" id="hero-rulebook">
                  Rulebook
                </div>
              </a>
            </div>
          </>
        ) : null
      }
    </div>
    <span className="hero-section-scroll-down">scroll down</span>
    <img className="hero-section-left" src={leftholder} alt="hero-section-left" />
    <img className="hero-section-right" src={rightholder} alt="hero-section-left" />
  </section>
  )
}
