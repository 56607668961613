import { useState } from 'react'
import './accordion.component.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export type AccordionType = {
  title: string
  children?: any
  counter?: string
  showOnInit?: boolean
}

export function Accordion({
  title,
  children,
  counter,
  showOnInit,
}: AccordionType) {
  const [show, setShow] = useState(!!showOnInit)
  const handleOpen = () => {
    setShow(!show)
  }

  const footerClass = classNames('accordion-footer', {
    open: show,
  })

  return (
  <div className="accordion">
    <div className="accordion-header"
    onClick={handleOpen}
    >
      <span className="accordion-header-title">
        {title}
      </span>
      <span className="left-top"></span>
      <span className="middle-top"></span>
      <span className="right-top"></span>
      <span className="accordion-header-divider"></span>
      <p className="accordion-counter">
        {counter}
      </p>
      <div className="accordion-header-icon">
        { show ? (
          <FontAwesomeIcon icon={faChevronUp as IconProp} color="#FF7A00"/>
        ) : (
          <FontAwesomeIcon icon={faChevronDown as IconProp}/>
        )}
      </div>
    </div>
    { show && (
      <div className="accordion-content">
        {children}
      </div>
    )}
    <div className={footerClass}>
     <span className="left-bottom"></span>
     <span className="middle-bottom"></span>
     <span className="right-bottom"></span>
    </div>
  </div>
  )
}
