import { TournamentBuyIn } from 'domain/tournament.model'
import TournamentsRepository from 'repositories/tournaments.repository'

export default function TicketComponent({ ticket }: { ticket: TournamentBuyIn }) {
  return <div>
    <div className="entry-fee__ticket">
      <img width={24} src={TournamentsRepository.getTicketIcon(ticket.value?.assetType)} alt="Ticket" />
      <span>&#x2715;</span> {ticket?.value.amount!}
    </div>
    <div className='entry-fee__name'>
      {TournamentsRepository.getTicketName(ticket.value.assetType)}
    </div>
  </div>
}
