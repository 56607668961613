import cookie from 'cookiejs'
import { useEffect, useState } from 'react'
import BoostersRepository from 'repositories/boosters.repository'
import PaymentService from 'services/payments.service'
import ButtonLoader from 'components/ButtonLoader/ButtonLoader.component'
import './minter.component.scss'
import ReactHtmlParser from 'react-html-parser'
import dompurify from 'dompurify'
import qs from 'query-string'
import { useRecoilValue } from 'recoil'
import { connectedWallet } from 'selectors/selectors'
import ConnectWalletButton from 'components/connect-wallet-button/connect-wallet-button.component'
import BalanceRepository, { PlayerBalance } from 'repositories/balance.repository'
import { Booster } from '../../domain/booster.model'
import ResponseMinterModal from './modal/response-minter-modal.component'
import getConfig from '../../config.service'

const DOMPurify = dompurify(window)
const config = getConfig()

const { ENABLE_PURCHASE: enableBuyButton } = config

export default function MinterComponent() {
  const [amount, setAmount] = useState(1)
  const [packs, setPacks] = useState<Booster[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedPack, setSelectedPack] = useState<Booster | undefined>(undefined)
  // eslint-disable-next-line no-restricted-globals
  const parsed:any = qs.parse(window.location.search)
  const [response, setResponse] = useState({ message: '', type: '', paymentId: undefined })
  const [loading, setLoading] = useState(false)
  const [loadingBoosters, setLoadingBoosters] = useState(false)
  const [balance, setBalance] = useState<PlayerBalance | undefined>()
  const reloadBoosterSecs: number = 30000

  function registerReferral(): void {
    if (parsed.referral && !cookie.get('user')) {
      cookie.set('referral', parsed.referral, { expires: 1 })
    }
  }
  const user = useRecoilValue(connectedWallet)

  registerReferral()

  const getReferral = (): string => {
    if (cookie.get('referral')) return cookie.get('referral').toString()
    return 'deviants'
  }

  function loadBalance() {
    if (!user?.address) return
    BalanceRepository.getBalance(user?.address)
      .then((currentBalance) => {
        setBalance(currentBalance)
      })
  }

  function loadBoosters() {
    setLoadingBoosters(true)
    BoostersRepository.getAll().then((_packs) => {
      setPacks(_packs)
      setSelectedPack((currentPack) => {
        if (_packs?.length > 0 && currentPack === undefined) return _packs[0]
        if (currentPack) return _packs.find((p) => p.type === currentPack?.type) ?? _packs[0]
        return _packs[0]
      })
    })
      .finally(() => setLoadingBoosters(false))
  }
  useEffect(() => {
    loadBalance()
    loadBoosters()
    const reloadBoostersInterval = setInterval(() => {
      loadBoosters()
    }, reloadBoosterSecs)
    return () => clearInterval(reloadBoostersInterval)
  }, [])

  function substractAmount(amountToSubstract: number = 1) {
    if (loading) return
    if (amount - amountToSubstract > 1) {
      setAmount(amount - amountToSubstract)
    } else {
      setAmount(1)
    }
  }

  function addAmount(amountToAdd: number = 1) {
    const maxAvailable: number = (selectedPack?.total ?? 0) - (selectedPack?.sold ?? 0)
    if (loading) return
    if ((amount + amountToAdd) <= (maxAvailable ?? 0)) {
      setAmount(amount + amountToAdd)
    } else {
      setAmount(maxAvailable)
    }
  }

  function buyBoosters() {
    if (loading) return
    if (!selectedPack) throw new Error('No pack selected')
    setLoading(true)
    if (!user) {
      setResponse({ message: 'You need to be connected to buy boosters', type: 'error', paymentId: undefined })
      setShowModal(true)
      return
    }
    PaymentService.buyPack(selectedPack, amount, user.address, getReferral())
      .then((res: any) => {
        setResponse({ message: 'There you go, Deviant! Stay tuned for more events! Join the fun in Discord or share your buy on Twitter.', type: 'success', paymentId: res?.paymentId })
        setShowModal(true)
        loadBalance()
      })
      .catch((error: any) => {
        const message = error.response?.data?.message || "Something went wrong but don't sweat it!"
        setResponse({ message, type: 'error', paymentId: error?.paymentId })
        setShowModal(true)
      })
      .finally(() => setLoading(false))
  }

  function selectPack(packId: string) {
    if (loading) return
    const pack = packs.find((p) => p.type === packId)
    setSelectedPack(pack)
    setAmount(1)
  }

  function selectImg(pack: Booster) {
    return <img className='img' src={pack.image} alt={pack.name} />
  }
  if (loadingBoosters && selectedPack === undefined) {
    return <div className="connect-wallet message-alert"><h2 /><h1>Loading booster packs...</h1><h2 /></div>
  }

  return (
    <div className="minter">
      <div className="minter__list">
        {
          packs.map((pack) => (
            <>
              <div className={`minter__item ${selectedPack?.type === pack.type ? 'active' : ''}`} key={`pack-${pack.type}`} onClick={() => selectPack(pack.type)} id={`buy_${pack.name.replace(' ', '').toLowerCase()} `}>
                {pack.name}
                {/* {pack.name.toLowerCase().indexOf('trick') === -1 && (
                  // <div className='pack-sale'>30% OFF</div>)
                } */}
              </div>
            </>
          ))
        }
      </div>
      {selectedPack && (
        <div className="minter__body">
          <div className="minter__head">
            <div className="minter__title">{selectedPack.name}</div>
            <div className="minter__price">
              <div className="minter__price__content">
                {selectedPack.price.amount}<span>{selectedPack.price.currency}</span>
              </div>
            </div>
          </div>
          { /*
          }
          {!!selectedPack?.sold && selectedPack?.total && (
            <div className="minter__bar">
              <div className="minter__total-bar">
                <div className="minter__selled-bar" style={{ width: `calc(${Math.round((selectedPack.sold * 100)
                 / selectedPack.total)}%)` }}></div>
              </div>
              <div className="minter__bar_amount">
                {selectedPack.sold}/{selectedPack.total}
              </div>
            </div>
          )}
          */
          }
          <div className="minter__description">
            <div className="minter__description_img">{selectImg(selectedPack)}</div>
            <div className="minter__description_txt new-line">{ ReactHtmlParser(DOMPurify.sanitize(selectedPack.description)) }</div>
          </div>
          <div className="minter__actions">
            {enableBuyButton && (
              <div className="minter__amount">
                <div className="minter__amount--plus btn btn-left btn-small btn-outline btn-plus-ten" onClick={() => substractAmount(10)}>-10</div>
                <div className="minter__amount--plus btn btn-left btn-small btn-outline" onClick={() => substractAmount(1)}>-</div>
                <div className="minter__amount-txt">{amount}</div>
                <div className="minter__amount-minus btn btn-right btn-small btn-outline" onClick={() => addAmount(1)}>+</div>
                <div className="minter__amount-minus btn btn-right btn-small btn-outline btn-plus-ten" onClick={() => addAmount(10)}>+10</div>
              </div>
            )}

            <div className='minter__bundle'>
            </div>

            {(user && enableBuyButton) && (
              <div className="minter__buy">
                {amount > 1 && (
                  <div className="minter__total">
                    <div>Total</div>
                    <div>{Math.round(amount * selectedPack.price.amount * 100) / 100}</div>
                    <div>{selectedPack.price.currency}</div>
                  </div>
                )}
                <div>
                  <div className="btn btn-medium btn-primary" id="minter-buy_button" onClick={buyBoosters}>
                    Buy
                    {loading && <ButtonLoader />}
                  </div>
                  <h2>Your balance: {balance?.balance} {balance?.symbol}</h2>
                </div>
              </div>
            )}

            {!user && <ConnectWalletButton id="minter-connect-button" />}
          </div>
        </div>
      )}
      <ResponseMinterModal showModal={showModal} setShowModal={setShowModal} response={response} />
    </div>
  )
}
