/* eslint-disable max-len */
import { Accordion } from 'components/Accordion/Accordion.component'
import Footer from 'components/Footer/Footer.component'

export default function TermsAndConditionsView() {
  return (
    <div className="tutorials terms">

      <h2 />
      <h1>TERMS OF USE</h1>
      <h2 />

      <Accordion showOnInit={true} title={'General'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>
              These terms and conditions (<strong>&quot;Terms&quot;</strong>) govern the use of the Website (defined below) and the Services (defined below). These Terms also include any guidelines, announcements, additional terms, policies, and disclaimers made available or issued by us from time to time. These Terms constitute a binding and enforceable legal contract between Muffin Group Limited and its affiliates (<strong>“Company”</strong> ,<strong>“Deviants Factions”</strong>,<strong>  “Deviants”</strong>, <strong>  “we”</strong>,<strong>  “us”</strong>) and you, an end user of the services (<strong>“you”</strong> or  <strong>“User”</strong>) at https://www.deviantsfactions.com/ (<strong>“Services”</strong>). By accessing, using or clicking on our website (and all related subdomains) or its mobile applications (<strong>“Website”</strong>) or accessing, using or attempting to use the Services, you agree that you have read, understood, and to are bound by these Terms and that you comply with the requirements listed herein. If you do not agree to all of these Terms or comply with the requirements herein, please do not access or use the Website or the Services. In addition, when using some features of the Services, you may be subject to specific additional terms and conditions applicable to those features. </p>
            <br />
            <p>
              We may modify, suspend or discontinue the Website or the Services at any time and without notifying you. We may also change, update, add or remove provisions of these Terms from time to time. Any and all modifications or changes to these Terms will become effective upon publication on our Website or release to Users. Therefore, your continued use of our Services is deemed your acceptance of the modified Terms and rules. If you do not agree to any changes to these Terms, please do not access or use the Website or the Services. We note that these Terms between you and us do not enumerate or cover all rights and obligations of each party, and do not guarantee full alignment with needs arising from future development. Therefore, our privacy policy, platform rules, guidelines and all other agreements entered into separately between you and us are deemed supplementary terms that are an integral part of these Terms and shall have the same legal effect. Your use of the Website or Services is deemed your acceptance of any supplementary terms too,
              <br />
              <br />
            </p>
            <p>
              The use of Deviants game and services may be in whole or in part inaccessible in countries and regions that are not officially included in the service regions. When attempting to use or using the services, your access may be blocked or suspended, we assume no responsibilities on any and all damages that may occur to you or other users of the Services against your or any other user’s unapproved access to the Game or the Service. Countries in which your use is available can be changed in the future.
              <br />
            </p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Protocol'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>
              The Site is an interface that enables you to interact with the Protocol, which is a decentralised autonomous smart-contract system deployed on certain blockchain networks (the <strong>“Protocol”</strong>). The Protocol shall be available on the Immutable X Blockchain or more blockchain networks, the list of such networks may evolve from to time. It is further expressly acknowledged that we neither control nor operate the Protocol. Information and materials about the Protocol available on the Site are provided for the information purposes only, are not binding and do not form a part of these Terms.
            </p>

            <br />
            <p>
              The Protocol is comprised of smart-contracts deployed on the blockchain network(s) that operate in a decentralised and autonomous manner. These smart-contracts can be reviewed, verified, used, copied, modified, and distributed by anyone (subject to the terms of the applicable license). Accordingly, there might be other interfaces enabling interaction with the Protocol that we neither control nor are affiliated with. Furthermore, anyone can interact directly with the Protocol bypassing Deviants and other interfaces. You should carefully and thoroughly review and assess the Protocol and related software before you use them, and any such use shall be at your own risk. You should always do your own research.
            </p>
            <br />

            <p>
              You further acknowledge that we do not control the Protocol, its underlying blockchain networks, and any software through which such network is formed. Deviants only provide the users with the Site being an interface to access the Protocol. Accordingly, in no event shall Deviants be responsible for or held liable in connection with the Protocol, underlying blockchain networks or software, their operation, functioning, implementation, or use, and you hereby assume and accept any and all related risks, including the risk of possible losses and damages that you may incur in connection with the use thereof.
              <br />
            </p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Your Digital Wallet'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>
              When using the Site, you may connect your Digital Wallet through one of the third-party software wallets compatible with Immutable X, or any other blockchain which may evolve from time to time. Software wallets constitute Third-Party Services and Deviants is not responsible for, does not endorse, shall not be held liable in connection with, and does not make any warranties, whether express or implied, as to the software digital wallets used by you with the Site or otherwise. When using third-party software wallets, you should review applicable terms and policies that govern your use of such software.

            </p>

            <br />
            <p>
              Deviants never receives access to or control over your Digital Wallet or Virtual Assets held in such Digital Wallet. Therefore, you are solely responsible for securing your Digital Wallet and credentials thereto (including private key, seed phrase, password, etc.) You may disconnect your Digital Wallet from the Site at any time.

            </p>
            <br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Marketplace'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>
            The Website includes a decentralized platform for Internet users functioning as Minter and marketplaces. It may allow you to buy, bid, trade and transfer digital assets in the form of Non-Fungible Token (<strong>“NFT”</strong>).
            </p>

            <br />
            <p>
              You understand that the Website provides an introductory platform only, and that our responsibilities are limited to facilitating the user functionality and availability of the Website. We are not a reseller of digital assets and NFT neither a party to any agreement entered into between a buyer and a seller. We have no control over the conduct of buyers, sellers and any other users of the Website. We accept no liability for any aspect of the buyer and seller interaction, including but not limited to the description of digital assets and NFT offered for sale and the delivery of them to you.
            </p>
            <br />
            <p>
              One or several payment methods shall be available on the Website, which may evolve from time to time. You may use any of the payment methods available to complete a transaction on the marketplace. Depending on the payment method used, fees may be applicable additionally to the digital asset or NFT purchase price. A limit on the maximum amount paid may be applicable on certain method of payment.
            </p>
            <br />
            <p>
              The purchase of digital asset or NFT on the Website may include the collection of a commission to be paid to the original creator of the digital asset or NFT (<strong>“Royalty”</strong>), such Royalty shall be a percentage of the total value of that transaction and/or a fixed fee as solely determined by the creator of the digital asset. In such case, you acknowledge and agree that the Royalty will be collected through the Immutable X Network, or other blockchain networks, as a part of the transaction and/or additionally to the purchase price.
            </p>
            <br />
            <p>
            Deviants Factions creator’s will receive a Royalty of 5% (five percent) of the NFT sale price.
            </p>
            <br />
            <p>
              Digital assets and NFT and collections that we deem inappropriate, disruptive, or illegal are prohibited on the Website. We reserve the rights to determine the appropriateness of listings on the Website  and discretionary remove any listing at any time. If you create or offer a digital asset, NFT or collection in violation of these policies, we will take corrective actions, as appropriate, including but not limited to removing the digital asset, NFT or collection, and permanently withholding commission and fees payment. Deviants cannot destroy or impound your digital assets, NFT or collection, but we reserve the right to destroy inappropriate metadata stored on our servers.
            </p>
            <br />
            <p>
              Digital assets and NFT and collections with a primary or substantial purpose in a game or application that violates international intellectual property laws, promotes suicide or self-harm, incites hate or violence against others, degrades or doxes another individual, depicts minors in sexually suggestive situations are prohibited on the Website and will be removed.
            </p>
            <br />

          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'The Opener services and NFT card packs'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>You may purchase on the Website different types of NFT card packs (e.g. the Starter Pack or the Booster Pack) for the price notified on the Website at the time you purchase the NFT card pack. In order to purchase the relevant NFT card pack, you will need to connect your digital wallet and pay the necessary fees and expenses, including any transaction/network fees.</p>
            <br />
            <p>Once you have purchased the relevant NFT card pack, you can choose, in your sole discretion, to keep it sealed or to open it and reveal the selection of NFT cards.  Deviants provides a service known as “The Opener” which is accessible to users already owning a sealed NFT card pack or to users purchasing a Booster Pack. Each card pack will contain a set of random NFT cards that have been determined by an algorithm based on probabilities calculated by the Company. The probabilities of receiving a particular rarity and version of an NFT card in the pack are discretionarily defined by us. We note that you will not be able to select the NFT cards included in the pack. You acknowledge that we cannot promise or guarantee the certain value of any NFTs that you receive following the purchase of the NFT card pack.</p>
            <br />
            <p>Each purchase results in a separate binding agreement between you and us for the supply of the NFT cards. Instructions will be provided on our Website. It is your responsibility to check the purchase details, including selected card packs and pricing, before you place a transaction through our Website. </p>
            <br />
            <p>You acknowledge and agree that we reserve the right to amend any terms related to the Opener services implemented by us at any time in our sole discretion. We shall not be liable for any losses due to your misunderstanding of (a) the terms and conditions of our NFT card packs, (b) the Opener service terms and/or (c) changes to such respective terms.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Play-to-earn'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>You acknowledge that the outcome of the games offered on the Website are directly related to the skill levels of each user participating.  We do not comment or have knowledge of the probability of one user winning a game, and makes no representations about a user’s chances of winning.  The Website may, from time to time, enable you to accumulate or earn digital assets or NFT. </p>
            <br />
            <p>You agree not to, or to authorise any third party on your behalf to, interact on the Website in a manner that is contrary to any rules or guidelines we may impose from time to time, or in a way that we determine, in our absolute discretion, to be cheating or otherwise acting in a manner that may negatively impact the enjoyment of others in connection with the Website. This includes, without limitation, using third party software (by injecting the software into the Website or otherwise) to give you or another use an unfair advantage, or to automate aspects of gameplay, creating and participating in win trading with other users and otherwise acting contrary to the spirit of the Website. For clarity, acting contrary to this clause will be considered a material breach of these Terms and may result in suspension and/or termination as set out below. </p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Tournaments'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Deviants permits users to interact with and participate in skill-based games online tournaments and with other users via the Website.</p><br />
            <p> User acknowledges that the outcome of the tournaments offered on the Website are directly related to the number of users participating, and the skill levels of each user participating. Deviants does not comment or have knowledge of the probability of one user winning a tournaments against  another user, and makes no representations about an individual user&apos;s chances of winning.</p><br />
            <p>User shall not engage in any practice that aims to manipulate the outcome of any tournaments. All users should play to the best of their abilities and any sort of match-fixing, win-trading, or colluding between users is strictly prohibited.</p><br />
            <p>Deviants reserves the right to discretionary determine an entry fee to be paid by user to participate in tournament. Entry fees represent a “platform fee” to Deviants made solely for facilities provided by the Website for the setting up and management of tournaments. By participating in tournaments on the Website and paying the fee to Deviants, each user acknowledges that these funds are held by Deviants for such services and that the member grants an irrevocable authority to Deviants to award the winner of the tournament the prize associated with such tournament.</p><br />
            <p>The prize in each tournament represent an amount determined by Deviants in its absolute discretion and is subject to change without further notice to you.</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Eligibility'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>By accessing, using or clicking on our Website and using or attempting to use our Services, you represent and warrant that:</p>
            <ol>
              <li>as an individual, legal person, or other organization, you have full legal capacity and authority to agree and bind yourself to these Terms;</li>
              <li>you are at least 18 or are of legal age to form a binding contract under applicable laws;</li>
              <li>your use of the Services is not prohibited by applicable law, and at all times compliant with applicable law, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing (<strong>“CTF”</strong>);</li>
              <li>you have not been previously suspended or removed from using our Services;</li>
              <li>if you act as an employee or agent of a legal entity, and enter into these Terms on their behalf, you represent and warrant that you have all the necessary rights and authorizations to bind such legal entity;</li>
              <li>you are solely responsible for use of the Services and, if applicable, for all activities that occur on or through your user account; and</li>
              <li>You are a citizen or resident of a country where skill-based tournament is legal.</li>
            </ol>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Identity Verification'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>We and our affiliates may, but are not obligated to, collect and verify information about you in order to keep appropriate record of our users, protect us and the community from fraudulent users, and identify traces of money laundering, terrorist financing, fraud and other financial crimes, or for other lawful purposes.</p><br />

            <p> We may require you to provide or verify additional information before permitting you to access, use or click on our Website and/or use or attempt to use our use or access any Service. We may also suspend, restrict, or terminate your access to our Website or any or all of the Services in the following circumstances: (a) if we reasonably suspect you of using our Website and Services in connection with any prohibited use or business; (b) your use of our Website or Services is subject to any pending litigation, investigation, or government proceeding and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your activity; or (c) you take any action that we deem as circumventing our controls, including, but not limited to, abusing promotions which we may offer from time to time.</p><br />

            <p>In addition to providing any required information, you agree to allow us to keep a record of that information during the period for which your account is active and within five (5) years after your account is closed. You also authorize us to share your submitted information and documentation to third parties to verify the authenticity of such information. We may also conduct necessary investigations directly or through a third party to verify your identity or protect you and/or us from financial crimes, such as fraud, and to take necessary action based on the results of such investigations. We will collect, use and share such information in accordance with our privacy policy.</p><br />

            <p> If you provide any information to us, you must ensure that such information is true, complete, and timely updated when changed. If there are any grounds for believing that any of the information you provided is incorrect, false, outdated or incomplete, we reserve the right to send you a notice to demand correction, directly delete the relevant information, and as the case may be, terminate all or part of the Services we provide for you. You shall be fully liable for any loss or expense caused to us during your use of the Services. You hereby acknowledge and agree that you have the obligation to keep all the information accurate, update and correct at all times.</p><br />

            <p>We reserve the right to confiscate any and all funds that are found to be in violation of relevant and applicable AML or CFT laws and regulations, and to cooperate with the competent authorities when and if necessary.</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'User Content'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>By transmitting or submitting any User Content ( Images, sounds, all the material, data, and information) while using the Service, you affirm that such transmission or submission is (a) accurate and not confidential; (b) not in violation of any applicable laws, contractual restrictions or other third-party rights, and that you have permission from any third party whose personal information or intellectual property is comprised in the User Content; (c) free of viruses, adware, spyware, or other malicious code.</p><br />

            <p>You agree that Deviants reserves the right in its sole discretion to monitor, delete or disable access to or otherwise make unavailable any User Content without notice for no reason at any time.</p><br />

            <p>You are solely responsible for the information that you post on and that you provide to others.</p><br />

            <p>You hereby grant to Deviants an irrevocable, perpetual, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, distribute, sell, license, sublicense, transfer, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, perform, and use and practice. You also hereby grant to Deviants the right to authorize others to exercise any of the rights granted to Deviants under these Terms of Service.</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Restrictions'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>You shall not access, use or click on our Website and/or use or attempt to use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, you may NOT:</p>
            <ol>
              <li> use our Website or use the Services in any dishonest or unlawful manner, for fraudulent or malicious activities, or in any manner inconsistent with these Terms;</li>
              <li> violate applicable laws or regulations in any manner;</li>
              <li> infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of Deviants;</li>
              <li> use our Website or use the Services to transmit any data or send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programmes or computer code designed to adversely affect the operation of any computer software or hardware;</li>
              <li> use any deep linking, web crawlers, bots, spiders or other automatic devices, programs, scripts, algorithms or methods, or any similar or equivalent manual processes to access, obtain, copy, monitor, replicate or bypass the Website or the Services;</li>
              <li> make any back-up or archival copies of the Website or any part thereof, including disassembling or de-compilation of the Website;</li>
              <li> violate public interests, public morals, or the legitimate interests of others, including any actions that would interfere with, disrupt, negatively affect, or prohibit other Users from using our Website and the Services;</li>
              <li>offer to sale on the Website digital assets, NFT and collections that we deem inappropriate, disruptive, or illegal;</li>
              <li>use the Services for market manipulation (such as pump and dump schemes, wash trading, self-trading, front running, quote stuffing, and spoofing or layering, regardless of whether prohibited by law);</li>
              <li> attempt to access any part or function of the Website without authorization, or connect to the Website or Services or any Company servers or any other systems or networks of any the Services provided through the services by hacking, password mining or any other unlawful or prohibited means;</li>
              <li> probe, scan or test the vulnerabilities of the Website or Services or any network connected to the properties, or violate any security or authentication measures on the Website or Services or any network connected thereto;</li>
              <li> reverse look-up, track or seek to track any information of any other Users or visitors of the Website or Services;</li>
              <li> take any actions that imposes an unreasonable or disproportionately large load on the infrastructure of systems or networks of the Website or Services, or the infrastructure of any systems or networks connected to the Website or Services;</li>
              <li> use any devices, software or routine programs to interfere with the normal operation of any transactions of the Website or Services, or any other person’s use of the Website or Services; or</li>
              <li> forge headers, impersonate, or otherwise manipulate identification, to disguise your identity or the origin of any messages or transmissions you send to Deviants or the Website.</li>
              <li> manipulate the price of a Digital Asset in any way, including bidding on your own items, preventing bidding, or using the Platform to conceal economic activity.</li>
            </ol>
            <p>By accessing the Services, you agree that we have the right to investigate any violation of these Terms, unilaterally determine whether you have violated these Terms, and take actions under relevant regulations without your consent or prior notice.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Fee And Payment'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Any purchases from the Website will be done through smart contracts on a blockchain using a Immutable X based wallet. Any financial transactions that you engage in will be conducted solely through the Blockchain via a wallet. We will have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. With that in mind, we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via the Website, or any other transactions that you conduct via the Immutable X network, or other blockchain networks.</p><br />
            <p> Immutable X, and other blockchain networks, may require the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the Immutable X network, or other blockchain networks. The Gas Fee funds the network of computers that run the decentralized Immutable X network, or other blockchain networks. This means that you may need to pay a Gas Fee for each blockchain transaction that occurs via the App.</p><br />
            <p> In addition to the Gas Fee, Deviants might implement the collection of a commission each time you utilize a Smart Contract to conduct a transaction with another user via the Website (“Commission”), such Commission might be a percentage of the total value of that transaction and/or a fixed fee as solely determine by Deviants. In such case, you acknowledge and agree that the Commission will be transferred directly to us through the Immutable X Network, or other blockchain networks, as a part of the transaction.</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Termination'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Deviants may terminate, suspend, or modify your access to Website and/or the Services, or any portion thereof, immediately and at any point, at its sole discretion. Deviants will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services. Upon termination of your access to the Services, these Terms shall terminate, except for those clauses that expressly or are intended to survive termination or expiry.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Advertiser'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>We allow advertisers to display their advertisements and other information in certain areas of the Website such as sidebar advertisements or banner advertisements. If you are an advertiser, you should take full responsibility for any advertisements you place on the Website, and any services provided on the Website, or products sold through those advertisements. Further, as an advertiser, you warrant and represent that you possess all rights and authority to place advertisements on the Website, including, but not limited to, intellectual property rights, publicity rights, and contractual rights. We simply provide the space to place such advertisements, and we have no other relationship with advertisers.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Disclaimers'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>   OUR SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED OR STATUTORY. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS OF THE SERVICES.</p><br />

            <p>   TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. DEVIANTS DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE WEBSITE, ANY PART OF THE SERVICES, INCLUDING MOBILE SERVICES, OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE AND WILL NOT BE LIABLE FOR ANY LOSSES RELATING THERETO. DEVIANTS DOES NOT REPRESENT OR WARRANT THAT THE WEBSITE, THE SERVICES OR ANY MATERIALS OF DEVIANTS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p><br />

            <p>   TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NONE OF DEVIANTS OR ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS WILL BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, INTANGIBLE OR CONSEQUENTIAL LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO:</p><br />

            <ol>
              <li>ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF DEVIANTS OR ITS AFFILIATES;</li>
              <li>ANY AUTHORIZED OR UNAUTHORIZED USE OF THE WEBSITE OR SERVICES, OR IN CONNECTION WITH THIS AGREEMENT;</li>
              <li>ANY INACCURACY, DEFECT OR OMISSION OF ANY DATA OR INFORMATION ON THE WEBSITE;</li>
              <li>ANY ERROR, DELAY OR INTERRUPTION IN THE TRANSMISSION OF SUCH DATA;</li>
              <li>ANY DAMAGES INCURRED BY ANY ACTIONS, OMISSIONS OR VIOLATIONS OF THESE TERMS BY ANY THIRD PARTIES; OR</li>
              <li>ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS WITHOUT AUTHORIZED BY DEVIANTS.</li>
            </ol>
            <p>EVEN IF DEVIANTS KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE, EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF OUR GROSS NEGLIGENCE, ACTUAL FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW OR EXCEPT IN JURISDICTIONS THAT DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES. THIS PROVISION WILL SURVIVE THE TERMINATION OF THESE TERMS.</p><br />

            <p>WE MAKE NO WARRANTY AS TO THE MERIT, LEGALITY OR JURIDICAL NATURE OF ANY TOKEN SOLD ON OUR PLATFORM (INCLUDING WHETHER OR NOT IT IS CONSIDERED A SECURITY OR FINANCIAL INSTRUMENT UNDER ANY APPLICABLE SECURITIES LAWS).</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Intellectual Property'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>All present and future copyright, title, interests in and to the Services, registered and unregistered trademarks, design rights, unregistered designs, database rights and all other present and future intellectual property rights and rights in the nature of intellectual property rights that exist in or in relation to the use and access of the Website and the Services are owned by or otherwise licensed to Deviants. Subject to your compliance with these Terms, we grant you a non-exclusive, non-sub license, and any limited license to merely use or access the Website and the Services in the permitted hereunder.</p><br />

            <p>Except as expressly stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to our or any other third party’s intellectual rights.</p><br />

            <p>If and to the extent that any such intellectual property rights are vested in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights back to us.</p><br />

            <p>You agree and acknowledge that all content on the Website must not be copied or reproduced, modified, redistributed, used, created for derivative works, or otherwise dealt with for any other reason without being granted a written consent from us.</p><br />

            <p>Third parties participating on the Website may permit us to utilise trademarks, copyrighted material, and other intellectual property associated with their businesses. We will not warrant or represent that the content of the Website does not infringe the rights of any third party.</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Independent Parties'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Deviants is an independent contractor but not an agent of you in the performance of these Terms. These Terms shall not be interpreted as facts or evidence of an association, joint venture, partnership or franchise between the parties. </p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'No Professional Advice'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>All information provided on the Website and throughout our Services is for informational purposes only and should not be construed as professional advice. We do not provide investment advice and any content contained on the Website should not be considered as a substitute for tailored investment advice. Investing in digital assets is highly risky and may lead to a total loss of investment. You must have sufficient understanding of cryptographic tokens, token storage mechanisms (such as token wallets), and blockchain technology to appreciate the risks involved in dealing in digital assets. You understand and agree that the value of digital assets can be volatile, and we are not in any way responsible or liable for any losses you may incur by using or transferring digital assets in connection with our Services. You should not take, or refrain from taking, any action based on any information contained on the Website. Before you make any financial, legal, or other decisions involving our Services, you should seek independent professional advice from an individual who is licensed and qualified in the area for which such advice would be appropriate. </p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Indemnification'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>You agree to indemnify and hold harmless Deviants and its affiliates and their respective shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers or contractors from and against any potential or actual claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to:</p>
            <ol>
              <li>your use of, or conduct in connection with, the Website or Services;</li>
              <li>your breach or our enforcement of these Terms; or</li>
              <li>your violation of any applicable law, regulation, or rights of any third party during your use of the Website or Services.</li>
            </ol>
            <p>If you are obligated to indemnify Deviants and its affiliates and their respective shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers or contractors pursuant to these Terms, Deviants will have the right, in its sole discretion, to control any action or proceeding and to determine whether Deviants wishes to settle, and if so, on what terms.</p>
            <p><br /></p>
            <p>Your obligations under this indemnification provision will continue even after these Terms have expired or been terminated.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Taxes'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>As between us, you will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, “Taxes”) associated with your use of the Services. Except for income taxes levied on the Company, you: (i) will pay or reimburse us for all national, federal, state, local, or other taxes and assessments of any jurisdiction, including value-added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (ii) shall not be entitled to deduct the amount of any such taxes, duties or assessments from payments made to us pursuant to these Terms.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Confidentiality'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>You acknowledge that the Services contain Deviants’ and its affiliates’ trade secrets and confidential information. You agree to hold and maintain the Services in confidence, and not to furnish any other person any confidential information of the Services or the Website. You agree to use a reasonable degree of care to protect the confidentiality of the Services. You will not remove or alter any of Deviants’ or its affiliates’ proprietary notices. Your obligations under this provision will continue even after these Terms have expired or been terminated.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Anti-Money Laundering'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Deviants expressly prohibits and rejects the use of the Website or the Services for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations. By using the Website or the Services, you represent that you are not involved in any such activity.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Force Majeure'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Deviants have no liability to you if it is prevented from or delayed in performing its obligations or from carrying on its Services and business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, failure of a utility service or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation, or direction.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Jurisdiction and Governing Law'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>    The parties shall attempt in good faith to mutually resolve any and all disputes, whether of law or fact, and of any nature whatsoever arising from or with respect to these Terms. These Terms and any dispute or claim arising out of or in connection with the Services or the Website shall be governed by, and construed in accordance with, the laws of the British Virgin Islands.</p><br />

            <p>Any dispute that is not resolved after good faith negotiations may be referred by either party for final, binding resolution by arbitration under the arbitration rules of the Hong Kong International Arbitration Centre (“HKIAC”) under the HKIAC Administered Arbitration Rules in force when the notice of arbitration is submitted. The law of this arbitration clause shall be Hong Kong law. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be one (1). The arbitration proceedings shall be conducted in English.</p><br />

            <p>Any Dispute arising out of or related to these Terms is personal to you and us and will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action or any other type of representative proceeding. There will be no class arbitration or arbitration in which an individual attempts to resolve a dispute as a representative of another individual or group of individuals. Further, a dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals. </p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Severability'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue in effect.</p><br />

            <p>If any unlawful or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).</p><br />
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Notices'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>All notices, requests, demands, and determinations for us under these Terms (other than routine operational communications) shall be sent to <a href="mailto:legal@deviantsfactions.com">legal@deviantsfactions.com</a>.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Assignment'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>You may not assign or transfer any right to use the Services or any of your rights or obligations under these Terms without prior written consent from Deviants, including any right or obligation related to the enforcement of laws or the change of control. Deviants may assign or transfer any or all of its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval. </p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Third Party Rights'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>No third party shall have any rights to enforce any terms contained herein.</p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Third Party Website Disclaimer'}>
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>Any links to third party websites from our Services does not imply endorsement by us of any product, service, information or disclaimer presented therein, nor do we guarantee the accuracy of the information contained on them. If you suffer loss from using such third party product and service, we will not be liable for such loss. In addition, since we have no control over the terms of use or privacy policies of third-party websites, you should carefully read and understand those policies. </p>
            <p><br /></p>
            <p><strong>BY MAKING USE OF OUR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT: (A) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF ENCRYPTED OR DIGITAL TOKENS OR CRYPTOCURRENCIES WITH A CERTAIN VALUE THAT ARE BASED ON BLOCKCHAIN AND CRYPTOGRAPHY TECHNOLOGIES AND ARE ISSUED AND MANAGED IN A DECENTRALIZED FORM (“DIGITIAL CURRENCIES”); (B) YOU SHALL ASSUME ALL RISKS RELATED TO THE USE OF THE SERVICES AND TRANSACTIONS OF DIGITAL CURRENCIES; AND (C) DEVIANTS SHALL NOT BE LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES. AS WITH ANY ASSET, THE VALUES OF DIGITAL CURRENCIES ARE VOLATILE AND MAY FLUCTUATE SIGNIFICANTLY AND THERE IS A SUBSTANTIAL RISK OF ECONOMIC LOSS WHEN PURCHASING, HOLDING OR INVESTING IN DIGITAL CURRENCIES.
            </strong></p>
          </div>
        </div>
      </Accordion>
      <Accordion showOnInit={true} title={'Referral Program'} >
        <div className="accordion-content-wrapper">
          <div className="accordion-content-description">
            <p>
              <strong>1. Introduction: </strong>
              The Referrer is a person (physical) who currently holds a Booster in their Immutable X wallet, and who is interested in referring Deviants&apos;Factions and its digital platform Deviantsfactions.com, through the promotion of its game mechanics to potential users who at the date of being contacted or directly influenced by the Referrer through an action previously defined in these Terms and Conditions attributable to the same, do not hold a user in the game or Boosters in their wallets (hereinafter the &quot;Potential Users&quot;).
              Through these Terms and Conditions, Deviants&apos;Factions describes the conditions offered to the Referrer to refer Potential Users to deviantsfactions.com. The Referrer agrees and undertakes to strictly adhere to the conditions and obligations contained in these Terms and Conditions for the referral.
              If the referred user does not create his/her user within 30 days after logging in with the Referrer&apos;s link or deletes Cookies during this time, at the time of purchase, They will not be considered as referred and will not entitle the Referrer to any commission.
              <br/>
              <br/>
              <strong>2. Purpose of the program: </strong>
              Deviants&apos;Factions authorizes the Referrer to refer Potential Users of Deviants&apos;Factions to purchase NFT within the platform, receiving compensation based on the purchases made by the Referred Users, as indicated in Annex I of this article. The relationship with the Referrer is not exclusive, so Deviants&apos;Factions may authorize other Referrers or may operate directly.
              It is hereby stated that the Referrer is not an agent or a legal representative of Deviants&apos;Faction and therefore acts on its own behalf, so that his/her actions and statements regarding the use of the platform are its own responsibility, and shall in no way be binding on Deviants&apos;Factions.
              Deviants&apos;Factions will decide freely, sovereignly and according to its internal policies, the Potential Users referred by the Referrer will be accepted as users. The refusal to accept one or more Potential Users shall not generate any liability for Deviants&apos;Factions and the Referrer shall have no right to make any claim in this regard.
              <br/>
              <br/>

              <strong>3. Deadline: </strong>
              In case of acceptance of these Terms and Conditions, a service agreement will be established between Deviants&apos;Factions and the Referrer, which will remain in force for an unlimited duration from the moment it is accepted by the Referrer.
              The potential user will be assigned to a cookie from the time they enter the referrer&apos;s link and will have 30 days from the time they enter the link to create a user and be considered as a referral. The Referrer is aware that if the potential user deletes cookies before creating a user, They won´t be considered as a referrer.
              If -in the sole judgment of Deviants&apos;Factions- the Referrer breaches any of the conditions contained in this agreement or incurs any other action or omission or the Referred Users incur any fraudulent action or omission aimed at obtaining remuneration for their own benefit or that of third parties, Deviants&apos;Factions may immediately terminate the agreement, notifying the Referrer of this decision through written notice.
              <br/>
              <br/>

              <strong>4. Deviants&apos;Factions Rights and Obligations: </strong>
              To provide the specific URL to assign to the Referrer so that the Referrer can refer Users, enable the creation of new users within Deviants&apos;Factions, and everything necessary for Users to be able to make effective purchases. Users who have been referred will not have any additional costs or commissions due to having been referred by the Referrer.
              To provide technical support to Users, through the Internet, under the Platform&apos;s Terms and Conditions.
              <br/>
              <br/>

              <strong>5. Referrer&apos;s Obligations: </strong>
              <ul>
                <li>In no event shall the Referrer make investment recommendations, offer financial instruments, or give investment advice on behalf of Deviants&apos;Factions.</li>
                <li>The Referrer and/or potential users are aware of the volatility and risks of trading cryptocurrencies, and it is forbidden to grant guarantees or promises of profitability or any other kind to the referred Users.</li>
                <li>The Referrer will not be entitled to demand a new payment to another indicated address, being understood that Deviants&apos;Factions payment obligation of Deviants&apos;Factions has been fulfilled in the account whose access was lost by the Referrer.</li>
                <li>Deviants&apos;Factions will apply all “Know Your Customer”, Anti-Money Laundering, and Anti-Terrorist Financing policies at the time of registration of new users, including those referred by the Referrer, and therefore there is a possibility that Deviants&apos;Factions may reject certain Potential User customers referred by the Referrer, suspend them or subsequently terminate them for not approving such policies, in which case Deviants&apos;Factions shall have no liability to the Referrer or the rejected Potential User.</li>
                <li>The Referrer declares to know that -unless expressly stated otherwise- any information of Deviants&apos;Factions they has been granted access due to the referral program, is part of the commercial secret of Deviants&apos;Factions. The Referrer agrees to maintain absolute confidentiality concerning any such information, which is an essential condition for the agreement that arises with the acceptance of these Terms and Conditions.</li>
                <li>Referrals must be made exclusively by contacting Potential Users verifiable humans, making sure that they use the unique URL at the time of the creation of account in Deviants Factions. After these 30 days or if cookies are deleted during this period, the referrer will not be considered as a referrer and will not be entitled to any commission.</li>
                <li>Deviants&apos;Factions does not promote, nor does it share any responsibility for any paid advertisement in social networks or other mass media mentioning Deviants&apos;Factions, nor does it publish its link or referrer code in online search engines, social networks, whether a personal or third party, in group messaging services or any mass media that allows the Referrer to offer the services of Deviants&apos;Factions to an undetermined person unless it has prior express written authorization from Deviants&apos;Factions.Deviants Factions is not responsable for third party behavior in this matter</li>
                <li>The Referrer is strictly forbidden to carry out the account creation process of the Potential Users on its behalf: the registration, subscriptions, transactions, and redemptions carried out on the platform shall be performed directly by the referred users, without the Referrer being involved in such process.</li>
                <li>Referrer may not misuse in any way the names or abbreviations of Deviant&apos;s Factions and/or any part of its logos, trademarks, service marks, or trade names.</li>
              </ul>
              If Referrer fails to comply with any of the obligations previously stated, they shall be obligated to indemnify and shield from and against all damages, claims, losses, penalties, fines, costs, expenses, and fees, of any kind or nature, present or future, including any legal or other costs or expenses.
              <br/>
              <br/>

              <strong>6. Referrer Compensation: </strong>
              Deviants&apos;Factions will deliver to the Referrer the commission percentage corresponding to 5% of the purchases made by the Referred. For the Referrer&apos;s commission to accrue, the referred Users must create their user on Deviantsfactions.com through the access or link provided by the platform to the Referrer for a period of 30 days.
              The Referrer agrees that if the referred user does not use the link provided in the previous paragraph to create their account, the account will not be associated with the Referrer, and therefore the possible transaction fee will not be calculated.
              The commission percentage payment will be effective, in the Referrer&apos;s wallet linked to his Deviantsfactions.com user used to provide the link, on the last day of the month, in USDT provided this payment is equal to or greater than $5 USDT. Each party shall be responsible for the fulfillment of its tax obligations in correspondence with this agreement.
              In the event of termination of this agreement due to non-compliance by the Referrer of any of the conditions set forth herein, Deviants&apos;Factions shall only be responsible for the payment of the eventual commissions owed to the Referrer, proportionally to the date of termination of the agreement. After the termination of the agreement, the Referrer shall not continue to accrue commissions for the users or participants referred by the Referrer.
              <br/>
              <br/>

              <strong>7. Intellectual Property: </strong>
              Deviants&apos;Factions guarantees that it has sufficient rights to permit transactions on the website, deviantsfactions.com. The Referrer, prior to the use of Deviants&apos;Factions branded material not supplied by the company, must request authorization from Deviants&apos;Factions. In case Deviants&apos;Factions does not authorize its use, the Commercial Representative must refrain from using it, under penalty of Deviants&apos;Factions to initiate the corresponding actions.

            </p>
            <p><br /></p>
          </div>
        </div>
      </Accordion>
      <Footer />
    </div>
  )
}
