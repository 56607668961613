import Button from 'components/Button/Button.component'
import VS from 'assets/images/vs.png'
import ButtonLoader from 'components/ButtonLoader/ButtonLoader.component'
import { TournamentMatchData } from '../TournamentDetail.view'

export default function TournamentMatch({ match }: {match: TournamentMatchData}) {
  return (
    <div className="tournament-match">
      <div className="tournament-match__left">
        <div className="tournament-match__info1">
          <div>PHASE {match.phase}</div>
          <div className="you-are-in">YOU&apos;RE IN: <span>ROUND {match.round}</span></div>
        </div>
        <div className="tournament-match__info2">
          NEXT MATCH <span>&gt;</span>
        </div>
      </div>

      <div className="tournament-match__center">
        <span>(you)</span>
        <div className="tournament-match__player">{match.player1}</div>
        <img src={VS} alt="VS" />
        <div className="tournament-match__player">{match.player2}</div>
        <span>(opponent)</span>
      </div>

      <div className="tournament-match__right">
        <div className="tournament-match__deck">
          Deck selected: <span>{match.player1Deck}</span>
        </div>

        <div className="tournament-match__play">
          {match.state !== 'ENDED'
            ? <Button variant="primary" size="medium" onClick={() => { window.location.href = `/play/?match=${match.id}` }}>Play</Button>
            : <div className="tournament-match__waiting">Waiting for next round <ButtonLoader/></div>
          }
        </div>

        {/* <div className="counter-container">
          <div className="counter-number">
            20<div className="counter-label">Hrs</div>
          </div>
          <div className="separator" />
          <div className="counter-number">
            20<div className="counter-label">Mins</div>
          </div>
          <div className="separator" />
          <div className="counter-number">
            20<div className="counter-label">Sec</div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
