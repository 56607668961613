/* eslint-disable */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { auto } from 'async'
import { concat, uniqBy } from 'lodash'
import classNames from 'classnames'
import Banner from 'components/Banner/Banner.component'
import Button from 'components/Button/Button.component'
import { FeatCard } from 'components/FeatCard/feat-card'
import Modal from 'components/Modal/Modal.component'
import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel'
import { useRecoilValueLoadable, useRecoilState } from 'recoil'
import { openedCardSelector, myBurnedBoosters, myBurnedBoostersTokenIds } from 'selectors/selectors'
import ErrorModal from 'components/ErrorModal/ErrorModal.component'
import getConfig from 'config.service'
import { faTimes } from '../../infraestructure/repositories/icons.repository'
import { ReactComponent as IconChevron } from '../../assets/icons/icon-chevron.svg'
import NFTItem from './starter-pack-item'
import CardsRepository from 'infraestructure/repositories/cards.repository'
import BoosterRepository from 'repositories/boosters.repository'
import { CardType } from "domain/card.model"
import Asset from "domain/asset.model"
import OpenerService from 'services/opener.service'
import ProviderService from '../../infraestructure/services/web3-provider/imx-provider.service'

// data: { id, name, image_url, status, token_address, token_id }
// metadata: { BoosterType }

type MintResponse = {
  tokenId: string
  tx: string
  templateId: string
}

const videosNameByType: Map<string, string> = new Map([
  ['SILVER', 'presalesilver'],
  ['STARTER', 'starter'],
  ['GOLD', 'presalegold'],
  ['DIAMOND', 'presalediamond'],
  ['SINGLESHOT', 'oneshot'],
  ['GOLD_BOOSTER', 'gold_st'],
  ['DIAMOND_BOOSTER', 'diamond_st'],
  ['SILVER_BOOSTER', 'silver_st'],
  ['TRICKORTREAT_SALE', 'tt'],
  ['TRICKORTREAT_AWAKEN_SALE', 'tt_awaken'],
  ['TRICKORTREAT_ENTROPY_SALE', 'tt_entropy'],
  ['TRICKORTREAT_INHUMAN_SALE', 'tt_inhuman'],
  ['TRICKORTREAT_OWNER_SALE', 'tt_owner'],
  ['SINGLESHOT_EC1', 'single_ec1'],
  ['STANDARD_EC1', 'standard_ec1'],
  ['DOUBLE_EC1', 'double_ec1'],
  ['SINGLESHOT_EC2', 'single_ec2'],
  ['STANDARD_EC2', 'standard_ec2'],
  ['DOUBLE_EC2', 'double_ec2'],
])

const videosTimeByType: Map<string, number> = new Map([
  ['STARTER', 10000],
  ['SILVER', 6000],
  ['GOLD', 6000],
  ['DIAMOND_BOOSTER', 6000],
  ['SILVER_BOOSTER', 6000],
  ['GOLD_BOOSTER', 6000],
  ['DIAMOND', 6000],
  ['SINGLESHOT', 4000],
  ['TRICKORTREAT_SALE', 4000],
  ['TRICKORTREAT_AWAKEN_SALE', 4000],
  ['TRICKORTREAT_ENTROPY_SALE', 4000],
  ['TRICKORTREAT_INHUMAN_SALE', 4000],
  ['TRICKORTREAT_OWNER_SALE', 4000],
  ['SINGLESHOT_EC1', 4000],
  ['STANDARD_EC1', 6000],
  ['DOUBLE_EC1', 4000],
  ['SINGLESHOT_EC2', 4000],
  ['STANDARD_EC2', 6000],
  ['DOUBLE_EC2', 4000],
])

const buildVideoUrl = (filename: string) => `https://devstatic.deviantsfactions.com/opening/${filename}.mp4?tx=h_600,q_80,f_auto`
const noVideo = { videoLoop: '', videoEnd: '' }
const getVideosUrlForBooster = (booster: any): { videoLoop: string, videoEnd: string } => {
  if (booster === undefined)
    return noVideo
  const videoName = videosNameByType.get(booster.metadata.BoosterType)
  if (videoName === undefined)
    return noVideo
  return {
    videoLoop: buildVideoUrl(`${videoName}_loop`),
    videoEnd: buildVideoUrl(`${videoName}_op`)
  }
}
const boosterImage = (booster: any | undefined) => {
  if (booster === undefined)
    return ''
  return booster.getImage()
}

const boosterName = (booster: any | undefined) => {
  if (booster === undefined)
    return ''
  return booster.getName()
}

const config = getConfig()
const { ENABLE_STORE } = config

const styles = {
  display: 'block',
}
export default function NftList({ nfts, onOpened }: { nfts: any[], onOpened: Function }) {
  const burnedBoosters = useRecoilValueLoadable(myBurnedBoosters)
  const [burnedBoostersTokenIds, setBurnedBoostersTokenIds] = useRecoilState(myBurnedBoostersTokenIds)
  const [selected, setSelected] = useState(undefined)
  const [error, setError] = useState<any>(undefined)
  const self: any = useRef()
  if (!self.current) self.current = {}

  const [openModal, setOpenModal] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false) // false
  const [skipAnimation, setSkipAnimation] = useState(false)
  const [showVideoItems, setShowVideoItems] = useState(false)
  const [showVideoEnd, setShowVideoEnd] = useState(false)
  const [card_items, setCardItems] = useState<any[]>([])
  const [openedInfo, setOpenedInfo] = useState<any>({})

  const allBoosters = () => uniqBy(concat(burnedBoosters.contents, nfts), (booster: Asset) => booster.getId())
  const [assets, setAssets] = useState(burnedBoosters.state !== 'hasValue' ? nfts : allBoosters())

  const resetModal = () => {
    setOpenModal(false)
    setShowVideoEnd(false)
    setShowVideoItems(false)
    setSkipAnimation(false)
    setStartAnimation(false)
  }

  const modalOpen = (pack: any) => {
    resetModal()
    setSelected(pack)
    setOpenModal(true)

    // save booster metadata
    const key = `booster${pack.getId()}`
    const val = localStorage.getItem(key)
    if (val === null)
      localStorage.setItem(key, JSON.stringify({ booster: pack, burnTx: null }))
  }

  // save booster burnTx
  const markAsBurned = (result: any, tokenId: any, callback: any) => {
    const key = `booster${tokenId}`
    const val = localStorage.getItem(key)
    if (val !== null) {
      const data = JSON.parse(val)
      if (data.burnTx === null) {
        data.burnTx = result.burnTx
        localStorage.setItem(key, JSON.stringify(data))

        // add to burned list
        const burnedSet = new Set(burnedBoostersTokenIds.concat([tokenId]))
        setBurnedBoostersTokenIds(Array.from(burnedSet))
      }
    }
    callback(null)
  }

  // remove from app
  const handleOpened = (result: any, tokenId: any, callback: any) => {
    const key = `booster${tokenId}`
    const val = localStorage.getItem(key)
    if (val !== null) {
      // remove booster
      localStorage.removeItem(key)

      // remove from burned
      const burnedSet = new Set(burnedBoostersTokenIds)
      burnedSet.delete(tokenId)
      setBurnedBoostersTokenIds(Array.from(burnedSet))
      setAssets(assets.filter((a: any) => a.getId() !== tokenId))
    }
    callback(null)
  }

  const modalClose = () => {
    resetModal()
    if (self.current.videoIn) {
      self.current.videoIn.pause()
      self.current.videoIn.currentTime = 0
    }
    if (self.current.videoOut) {
      self.current.videoOut.pause()
      self.current.videoOut.currentTime = 0
    }
    setCardItems([])
    setOpenedInfo({})
    onOpened()
  }

  // get burnTx
  const getBurnTx = async (tokenAddress: string, tokenId: string): Promise<string> => {
    const key = `booster${tokenId}`
    const boosterData = localStorage.getItem(key)
    if (boosterData !== null) {
      const { burnTx } = JSON.parse(boosterData)
      if (burnTx !== null)
        return burnTx
    }
    const providerService = new ProviderService()
    return providerService.burn(tokenAddress, tokenId)
  }

  const animationStart = async (booster: any) => {
    setStartAnimation(true)

    setTimeout(async () => {
      if (self.current.videoIn) self.current.videoIn.play()

      const { token_address: tokenAddress,
        token_id: tokenId,
      } = booster.data

      auto({
        burnTx: async (): Promise<string> => getBurnTx(tokenAddress, tokenId),
        markAsBurned: ['burnTx', (result, callback) => markAsBurned(result, tokenId, callback)],
        transferOk: ['burnTx', async (result: any): Promise<void> => OpenerService.checkTransferOk(result.burnTx)],
        assetOk: ['burnTx', async (): Promise<void> => OpenerService.checkAssetOk(tokenAddress, tokenId)],
        mint: ['markAsBurned', 'transferOk', 'assetOk', async (result: any): Promise<MintResponse[]> => BoosterRepository.open({ tokenAddress, tokenId, id: result.burnTx })],
        cardsIds: ['mint', async (result: any): Promise<string[]> => {
          const cardsIds = result.mint.map(({ templateId }: any) => templateId)
          const data = await CardsRepository.getByIds(cardsIds)
          const info = data.reduce((prev: any, curr: CardType) => {
            if (curr.CardType.toUpperCase() === 'CHARACTER') return { ...prev, chars: prev.chars + 1 }
            if (curr.CardType.toUpperCase() === "TECHNOLOGY") return { ...prev, techs: prev.techs + 1 }
          }, { chars: 0, techs: 0 })
          setOpenedInfo(info)
          return cardsIds
        }],
        showTime: ['cardsIds', async (result: any): Promise<void> => {
          setCardItems(result.cardsIds)
          endLoading(booster.metadata.BoosterType)
        }],
        markOpened: ['mint', (result, callback) => handleOpened(result, tokenId, callback)]
      }, (error: any) => {
        if (error) {
          const tokenInfo = `Token Id: ${tokenId}`
          if (error.isAxiosError) {
            if (error.response)
              setError({ message: `${error.response.data.message}. ${tokenInfo}` })
            else if (error.request)
              setError({ message: `${error.message}. ${tokenInfo}` })
          } else {
            setError(error)
          }
        }
      })
    }, 10)
  }

  const animationSkip = () => {
    setSkipAnimation(true)
  }

  const _className = classNames('opener-content', {
    skipped: skipAnimation,
  })

  const _items = card_items.map((c) => <CardElement id={c} key={c} />)
  let element: any

  const endLoading = (booster_type: string) => {
    setShowVideoEnd(true)
    if (self.current.videoOut) self.current.videoOut.play()
    setTimeout(() => {
      setShowVideoItems(true)
      if (self.current.skipTimeout) clearTimeout(self.current.skipTimeout)
      self.current.skipTimeout = setTimeout(() => {
        setSkipAnimation(true)
      }, videosTimeByType.get(booster_type))
    }, 500)
  }

  const { videoLoop, videoEnd } = getVideosUrlForBooster(selected)
  return (
    <div className="inventory-view-loaded">
      {/* <div className="inventory-view-loaded-title">
        <h1>Soon you will be able to open your Starter Packs</h1>
        <h1>meanwhile, get as many as you can!</h1>
        { ENABLE_STORE && <GetStarterPackButton text="Get More Starter Packs" id="inventory-get-packs"/>}
      </div> */}
      <ErrorModal title={''} description={error?.message || 'There was an error'} id={''} closeAction={() => {
        setError(undefined)
        setOpenModal(false)
      }} isActive={error} />
      <Modal isActive={openModal && !error} closeAction={modalClose} id="modal-opener" >
        {startAnimation
          ? (<div className={_className}>
            <div className='please-wait'>Please be patient, this process may take a few minutes.</div>
            <video key={'video-in'} ref={(video) => self.current.videoIn = video} loop muted className="video-loop" style={{ display: `${showVideoEnd || skipAnimation ? 'none' : 'flex'}` }} >
              <source src={videoLoop} type='video/mp4' />
            </video>
            <video key={'video-out'} ref={(video) => self.current.videoOut = video} muted className="video-loop" style={{ display: `${!showVideoEnd || skipAnimation ? 'none' : 'flex'}` }} >
              <source src={videoEnd} type='video/mp4' />
            </video>
            {
              showVideoItems ? (
                <>
                  <Button
                    variant={'outline'}
                    size={'small'}
                    right
                    className="skip-animation"
                    onClick={animationSkip}
                  >
                    Skip
                  </Button>

                  <div className="opener-content-card-list">
                    {_items}
                  </div>
                  <div className="cards-opened">
                    <Button
                      variant={'icon'}
                      size={'small'}
                      right
                      className="skipped-close-btn"
                      onClick={modalClose}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>

                    <div className="left-holder" />
                    <div className="right-holder" />
                    <div className="menu-box-card-amount">
                      <div className="menu-box-card-amount-decal" />
                      <div className="menu-box-card-amount-divider" />
                      <div className="menu-box-card-amount-total">
                        <p className="menu-box-card-amount-total-cards">
                          total cards:
                        </p>
                        <p className="menu-box-card-amount-total-amount">
                          {openedInfo.chars + openedInfo.techs}
                        </p>
                      </div>
                      <div className="menu-box-card-amount-total">
                        <p className="menu-box-card-amount-total-cards">
                          Character
                        </p>
                        <p className="menu-box-card-amount-total-amount">
                          {openedInfo.chars}
                        </p>
                      </div>
                      <div className="menu-box-card-amount-total">
                        <p className="menu-box-card-amount-total-cards">
                          Technology
                        </p>
                        <p className="menu-box-card-amount-total-amount">
                          {openedInfo.techs}
                        </p>
                      </div>

                    </div>
                    <div className="menu-box bevel">
                      <h1>{boosterName(selected)} opened</h1>
                      <div className="menu-box-bottom">
                        <div className="menu-box-bottom-option">
                          <p>Back</p>
                          <NavLink to="/boosters">
                            <Button variant={'outline'} size={'medium'} onClick={modalClose}>
                              Open Another
                            </Button>
                          </NavLink>
                        </div>
                        <div className="menu-box-bottom-option">
                          <p> Go see all your cards in your wallet</p>
                          <NavLink to="/my-cards">
                            <Button variant={'outline'} size={'medium'}>
                              see my cards
                            </Button>
                          </NavLink>
                        </div>
                        <div className="menu-box-bottom-option">
                          <p>Build your deck with these cards</p>
                          <NavLink to="/deck-builder">
                            <Button variant={'primary'} size={'medium'}>
                              build my deck
                            </Button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <AliceCarousel
                      activeIndex={0}
                      disableDotsControls
                      disableButtonsControls
                      mouseTracking
                      autoWidth
                      autoHeight
                      items={_items}
                      ref={(el) => { element = el }}
                    />
                    <div className="card-opened-actions">
                      <Button size="small" left variant="outline"
                        onClick={(e) => element.slidePrev(e)}
                      >
                        <IconChevron className="next-button" />
                      </Button>
                      <p> Total: {_items.length} Cards</p>
                      <Button size="small" right variant="outline"
                        onClick={(e) => element.slideNext(e)}
                      >
                        <IconChevron className="next-button" />
                      </Button>
                    </div>
                    <Button variant={'primary'} size={'medium'} className="build-my-deck-btn">
                      Build my deck
                    </Button>
                  </div>
                </>
              ) : undefined
            }
          </div>
          ) : (
            <div className="modal-nft">
              <p className="modal-nft_title"></p>
              <div className="modal-nft_image" style={{ backgroundImage: `url(${boosterImage(selected)})` }} />
              <div className="modal-nft_actions">
                <Button
                  variant={'outline'}
                  size={'medium'}
                  onClick={modalClose}
                >Cancel</Button>
                <Button
                  variant={'primary'}
                  size={'medium'}
                  onClick={() => animationStart(selected)}
                >Open</Button>
              </div>
            </div>
          )}

      </Modal>
      {/* <div style={{textAlign: 'center'}}>
            <h1  style={{color: 'yellow'}}>We are experiencing some technical difficulties. Booster Opening is temporarily disabled</h1>
      </div> */}
      <div className="nft_list">
        {assets.map((nft: any) => <NFTItem
          name={nft.data.name}
          description={nft.data.description}
          nftImage={boosterImage(nft)}
          key={nft.getId()}
          id={nft.getId()}
        >
          {config.ENABLE_OPENER && nft.getStatus() === 'imx' && <Button variant={'outline'} size={'medium'} onClick={() => modalOpen(nft)}>
            Open
          </Button>}
        </NFTItem>)}
      </div>
      {ENABLE_STORE && (
        <Banner text={'Get the best NFT cards'}>
          <a target="_blank" style={styles} href="/store" rel="noreferrer" id="starterpack-link-store" className="btn-shadow">
            <Button variant={'primary'} size={'medium'}>Get Boosters</Button>
          </a>
        </Banner>
      )}
    </div>
  )
}

const CardElement = ({ id }: any) => {
  const nr = id.toUpperCase().split('-')[0]

  const card = useRecoilValueLoadable(openedCardSelector(nr))

  if (card.state === 'loading') {
    return null
  }

  if (card.state === 'hasValue' && card.contents) {
    //     const variant = card.contents.extension.attributes.filter((t:any) => t.trait_type==="CardType").value
    return <FeatCard key={'card_1'} image={`${card.contents.Art}?tx=w_300,f_auto,q_80` || ''} name={card.contents.Name} variant={card.contents.CardType.toUpperCase() === 'CHARACTER' ? 'CHAR' : 'TECH'} />
  }
  return null
}
