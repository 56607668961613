/* eslint-disable class-methods-use-this */
import Asset from 'domain/asset.model'
import { CraftingCost, CraftingRecipe } from 'domain/crafting-recipe.model'
import { CraftingService, CraftingTransaction } from 'domain/services/crafting.service'

export default class MockCraftingService implements CraftingService {
  async getPendingTransactions(wallet: string): Promise<{ pending: number }> {
    if (!wallet) throw new Error('Wallet is required')
    return { pending: 0 }
  }

  checkAvailability(recipesIds: string[]): Promise<{ [key: string]: number }[]> {
    if (recipesIds.length === 0) {
      return Promise.resolve([])
    }
    return Promise.resolve([])
  }

  private mockCost: CraftingCost[] = [
    { amount: 1, currency: 'VOL_POINT' },
    { amount: 1, currency: 'DEV' },
  ]

  private data: CraftingRecipe[] = [
    {
      id: '1',
      name: 'recipe 1',
      ingredients: [
        {
          id: 'EC2015',
          name: 'VIGGO DANIELS',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/EC2015.png?tx=h_600,q_80,f_auto',
          type: 'Ticket',
          quantity: 1,
        },
        {
          id: 'EC2017',
          type: 'Card',
          quantity: 1,
          name: 'ARIEL BRIONES',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/EC2017.png?tx=h_600,q_80,f_auto',
        },
        {
          id: 'EC1005',
          type: 'Card',
          quantity: 1,
          name: 'BELEN HUMMING',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/EC1005.gif?tx=h_600,q_80,f_auto',
        },
      ],
      cost: this.mockCost,
      result: {
        id: 'EC1001',
        name: 'Odayakana Ame',
        art: 'https://deviants-factions.mo.cloudinary.net/cards/EC1001.gif?tx=h_600,q_80,f_auto',
      },
    },
    {
      id: '2',
      name: 'Ottavia Ricci',
      cost: this.mockCost,
      ingredients: [
        {
          id: 'OTK065',
          name: 'Poncho & Montgomery',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK065.gif?tx=h_600,q_80,f_auto',
          type: 'Card',
          quantity: 2,
        },
        {
          id: 'OTK115',
          type: 'Card',
          quantity: 1,
          name: 'Ottavia Ricci',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK115.png?tx=h_600,q_80,f_auto',
        },
        {
          id: 'OTK117',
          type: 'Card',
          quantity: 1,
          name: 'Ottavia Ricci',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK117.png?tx=h_600,q_80,f_auto',
        },
      ],
      result: {
        id: 'OTK118',
        art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK118.png?tx=h_600,q_80,f_auto',
        name: 'Ottavia Ricci',
      },
    },
    {
      id: '3',
      name: 'recipe 3',
      cost: this.mockCost,
      ingredients: [
        {
          id: 'UNLOCKER',
          name: 'Unlocker',
          art: 'https://image-resizer-cache.sandbox.immutable.com/url=aHR0cHM6Ly9pbWFnZWRlbGl2ZXJ5Lm5ldC9rQzkycjZfSVRuaWxXSndESDBNVkZnLzczYmIxMGRmLWVjYzUtNDFlYi1lZWEyLTkyZDZhMTBmY2YwMC9wdWJsaWM=&w=1600',
          type: 'Ticket',
          quantity: 1,
        },
        {
          id: 'EC1004',
          type: 'Card',
          quantity: 1,
          name: 'Junichiro',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/EC1004.gif?tx=h_600,q_80,f_auto',
        },
        {
          id: 'EC1008',
          type: 'Card',
          quantity: 1,
          name: 'Junichiro',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/EC1008.gif?tx=h_600,q_80,f_auto',
        },
      ],
      result: {
        id: 'OTK120',
        name: 'Clayton Harris, P.I',
        art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK120.png?tx=h_600,q_80,f_auto',
      },
    },
    {
      id: '4',
      name: 'recipe 4',
      cost: this.mockCost,
      ingredients: [
        {
          id: 'UNLOCKER',
          name: 'Unlocker',
          art: 'https://image-resizer-cache.sandbox.immutable.com/url=aHR0cHM6Ly9pbWFnZWRlbGl2ZXJ5Lm5ldC9rQzkycjZfSVRuaWxXSndESDBNVkZnLzczYmIxMGRmLWVjYzUtNDFlYi1lZWEyLTkyZDZhMTBmY2YwMC9wdWJsaWM=&w=1600',
          type: 'Ticket',
          quantity: 1,
        },
        {
          id: 'OTK114',
          type: 'Card',
          quantity: 1,
          name: 'Reinhold',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK114.png?tx=h_600,q_80,f_auto',
        },
        {
          id: 'OTK112',
          type: 'Card',
          quantity: 1,
          name: 'Fyodor Volkov',
          art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK112.png?tx=h_600,q_80,f_auto',
        },
      ],
      result: {
        id: 'OTK113',
        name: 'Junichiro',
        art: 'https://deviants-factions.mo.cloudinary.net/cards/OTK113.png?tx=h_600,q_80,f_auto',
      },
    },
  ]

  public async getRecipes(): Promise<CraftingRecipe[]> {
    return this.data
  }

  // eslint-disable-next-line no-empty-function, @typescript-eslint/no-unused-vars
  public async getAvailableIngredients(ingredients: string[]): Promise<Map<string, Asset[]>> {
    return new Map()
  }

  public async getRecipe(id: string): Promise<CraftingRecipe> {
    const recipe = this.data.find((r) => r.id === id)
    if (!recipe) throw new Error('Recipe not found')
    return recipe
  }

  public async craft(recipeId: string, wallet: string, txIds: number[]): Promise<CraftingTransaction> {
    if (recipeId === '1' && txIds.length === 3 && wallet === '0x0') {
      throw new Error('Error crafting')
    }
    throw new Error('Error crafting')
  }

  public async checkCrafteability(recipe: CraftingRecipe): Promise<boolean> {
    if (recipe.id === '1') return true
    return false
  }
}
