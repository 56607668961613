/* eslint-disable import/prefer-default-export */
import { CraftingRecipe } from 'domain/crafting-recipe.model'
import { selector } from 'recoil'
import getCraftingService from 'services/crafting'

const craftingService = getCraftingService()

export const craftingRecipesSelector = selector<CraftingRecipe[]>({
  key: 'CraftingRecipesSelector',
  get: async () => {
    try {
      const result = craftingService.getRecipes()
      return result || []
    } catch (err) {
      return []
    }
  },
})
