/* eslint-disable */
import { atom, selector, selectorFamily } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import CardsRepository from 'infraestructure/repositories/cards.repository'
import { ServiceConsumer } from '../infraestructure/services/service-consumer/service-consumer.service'
import { DeckType } from '../domain/deck.model'
import Wallet from '../domain/wallet.model'
import { getHost } from '../infraestructure/services/host-consumer/host-consumer.service'
import MyAssetsRepository from '../repositories/my-assets.repository'
import { ProfileRepository } from '../repositories/profile.repository'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'
import Asset from '../domain/asset.model'
import axios from "axios";
import DeckRepository from "../infraestructure/repositories/deck.repository";
import { CardType } from 'domain/card.model'
import RewardRepository from 'repositories/rewards.repository'
import TournamentTicketRepository from 'repositories/tournament-tickets.repository'
import ChallengesRepository from 'repositories/challenge.repository'
import AchievementsRepository from 'repositories/achievement.repository'
import BalanceRepository from 'repositories/balance.repository'
import AssetExchangeRepo from 'repositories/asset-exchange.repository'
import getConfig from 'config.service'
import TournamentsRepository, { SubscriptionState } from 'repositories/tournaments.repository'
import UserRepository from 'infraestructure/repositories/users.repository'
import deviantsAvatar from '../assets/deviantsAvatar.png'
import moment from 'moment'
import { random } from 'lodash'
import TasksRepository from 'repositories/tasks.repository'
import { League } from 'domain/league.type'

const checkPlayable = (newDeck: any) => {
  const hq = newDeck.cards.find((c: any) => c.CardType === 'HQ')
  const nonHQ = newDeck.cards.filter((c: any) => c.CardType !== 'HQ')
  return hq && nonHQ.length === 8
}

export const deckFormatsFetchAtom = atom({
  key: 'deckFormatsFetchAtom', default: 0, effects_UNSTABLE: undefined,
})

export const DeckFormatsSelector = selector({
  key: 'DeckFormatsSelector',
  get: async ({ get }: any) => {
    get(deckFormatsFetchAtom)
    return DeckRepository.getDeckFormats()
  },
})

export const ownerCardsFetchAtom = atom({
  key: 'ownerCardsFetchAtom', default: 0, effects_UNSTABLE: undefined,
})

export const OwnerCardsSelector = selector({
  key: 'OwnerCardsSelector',
  get: async ({ get }: any) => {
    get(ownerCardsFetchAtom)
    const wallet = get(connectedWallet)
    try {
      const result = await axios.get(`${getHost('cards')}/api/v1/cards/owner/${wallet?.address}`)
      return result?.data || []
    }
    catch (err) {
      return []
    }
  },
})

export const allCardsFetchAtom = atom({
  key: 'allCardsFetchAtom', default: 0, effects_UNSTABLE: undefined,
})

export const AllCardsSelector = selector({
  key: 'AllCardsSelector',
  get: async ({ get }: any) => {
    get(allCardsFetchAtom)
    return CardsRepository.getAllCardsData()
  },
})

export const AllCardsByIdSelector = selector({
  key: 'AllCardsByIdSelector',
  get: async ({ get }: any) => {
    const cards = get(AllCardsSelector)
    type IdToCard = Map<string, any>
    const cardsHash: IdToCard = cards.reduce((map: IdToCard, card: any) => {
      map.set(card.id, card)
      return map
    }, new Map())
    return cardsHash
  }
})

export const cardsFetchAtom = atom({
  key: 'cardsFetchAtom', default: 0, effects_UNSTABLE: undefined,
})

export const decksFetchAtom = atom({
  key: 'decksFetchAtom', default: 0, effects_UNSTABLE: undefined,
})

const localStorageEffect = (key: string) => ({ setSelf, onSet }: any) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue !== undefined && savedValue !== null) {
    setSelf(JSON.parse(savedValue))
  }

  onSet((newValue: any, _: any, isReset: any) => {
    if (isReset) localStorage.removeItem(key)
    else localStorage.setItem(key, JSON.stringify(newValue))
  })
}

export const connectedWallet = atom<Wallet | undefined>({
  key: 'connectedWallet',
  default: undefined,
  effects_UNSTABLE: [
    localStorageEffect('connectedWallet'),
  ],
})

export const HQSelector = selector({
  key: 'HQSelector',
  get: async ({ get }) => {
    const cards = get(AllCardsSelector)
    return cards.filter(c => c.CardType === 'HQ')
  },
})

export const currentDeckAtom = atom<DeckType | undefined>({
  key: 'currentDeckAtom', default: undefined, effects_UNSTABLE: undefined,
})

export const deckListState = selector({
  key: 'deckListState',
  get: async ({ get }: any) => {
    const wallet = get(connectedWallet)
    get(decksFetchAtom)

    if (!wallet?.address) return []

    const token = ValidateIfTokenIsPresent()
    if (!token) {
      console.error('There is not a valid session')
      return []
    }
    const result = await ServiceConsumer.get<{ decks: any }>({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet?.address}/decks`,
      headers: {
        'correlation-id': uuidv4(),
      },
    })

    return result ? result.decks : [] as DeckType[]
  },
})

export const hasAdeptDecksState = selector({
  key: 'hasAdeptDecksState',
  get: async ({ get }: any) => {
    const wallet = get(connectedWallet)

    if (!wallet?.address) return []

    const token = ValidateIfTokenIsPresent()
    if (!token) {
      console.error('There is not a valid session')
      return []
    }
    const result = await ServiceConsumer.get<{ result: boolean }>({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet?.address}/adeptdecks`,
      headers: {
        'correlation-id': uuidv4(),
      },
    })

    return result ? result : false as Boolean
  },
})

export const playableDeckListSelector = selector({
  key: 'playableDeckListSelect',
  get: async ({ get }: any) => {
    const decks = get(deckListState)
    get(decksFetchAtom)
    return decks ? decks.filter((deck: DeckType) => deck.playable === true) : [] as DeckType[]
  },
})

function getCurrentDeck(currentDeck: DeckType | undefined, deckList: DeckType[]): DeckType | undefined {
  if (currentDeck) return currentDeck
  if (deckList.length < 1) return undefined
  const cDeck = localStorage.getItem('currentDeck')
  let deck = deckList.find((d: any) => d.id === cDeck)
  if (deck) return deck
  deck = deckList[0]
  return deck
}

export const currentPlayableDeckSelector = selector<DeckType>({
  key: 'currentPlayableDeckSelector',
  get: async ({ get }) => {
    const deckList = get(playableDeckListSelector)
    const currentDeck = get(currentDeckAtom)
    return getCurrentDeck(currentDeck, deckList)!
  },
})

export const currentDeckSelector = selector<DeckType>({
  key: 'currentDeckSelector',
  get: async ({ get }) => {
    const deckList = get(deckListState)
    const currentDeck = get(currentDeckAtom)
    const deck = getCurrentDeck(currentDeck, deckList)
    if (deck) localStorage.setItem('currentDeck', deck.id)
    return deck!
  },
})

export const myCardsSellingSelector = selector({
  key: 'myCardsSellingSelector',
  get: async ({ get }) => {
    const wallet = get(connectedWallet)
    // TODO: GET USER SALES
    // if (wallet) {
    //     const { wasm } = new LCDClient({
    //         URL: wallet.network.lcd,
    //         chainID: wallet?.network.chainID || ''
    //     });

    //     const result = wallet ? await getAllRecursiveListings(wasm, config.cards(wallet.network.name), {
    //         "listed_tokens": { "owner": wallet.address }
    //     }, '') : [];

    //     return result.tokens || []
    // } else {
    //     return []
    // }
    return []
  },
})

export const playableDeckSelector = selector<boolean>({
  key: 'playableDeckSelector',
  get: ({ get }) => {
    const deck = get(currentDeckSelector)
    const myCards = get(myCardsSelector)
    if (!deck) return false
    if (deck.cards.length < 9) return false
    const isPlayable = checkPlayable(deck)
    const deckCardIds = deck.cards.map((card) => card.id)

    const deckCardData = myCards?.filter((card) => deckCardIds.includes(card.id))
    const allCardTokensListed = deckCardData?.some((card) => card.ListedTokens.length === card.TokenIds.length)
    const nftOnlyCollectionValidation = getConfig().ENABLE_NFT_CHECKING ? deck.cards?.some((card: any) => {
      if (card.Set === 'Echoes 1' && !['Common', 'Uncommon'].includes(card.Rarity)) {
        return false;
      }
      return !['Outbreak', 'Echoes 1'].includes(card.Set) && card.tokenIds?.length === 0
    }) : false

    return isPlayable && !allCardTokensListed && !nftOnlyCollectionValidation
  },
})

export const deckCardsSelector = selector({
  key: 'deckCardsSelector',
  get: async ({ get }) => {
    const deck = get(currentDeckSelector)
    const ownedCards = get(myCardsSelector)
    const wallet = get(connectedWallet)
    const result = await ServiceConsumer.get<{ cards: any }>({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet?.address}/decks/${deck.id}`,
      headers: {
        'correlation-id': uuidv4(),
      },
    })
    const cardIds = result.cards.map((c: any) => c.id) as any[]
    if (!cardIds) return []
    if (cardIds.length === 0) return []
    const allCards = get(AllCardsSelector)
    const deckCardsData = allCards.filter(card => cardIds.includes(card.id))
    const ownedIds = ownedCards.map(c => c.id)
    return deckCardsData.map((c: CardType) => {
      return {
        ...c,
        IsNft: ownedIds.includes(c.id)
      }
    })
  },
})

export const listingSelector = selectorFamily({
  key: 'listingSelector',
  get: (id) => async ({ get }) => {
    const wallet = get(connectedWallet)
    // TODO: Return information about a listing
    // const { wasm, tx } = new LCDClient({
    //      URL: wallet?.network.lcd ||'',
    //     chainID: wallet?.network.chainID || ''
    // });
    // const result: any = await wasm.contractQuery(config.cards(wallet?.network.name), { all_nft_info: { token_id: id } })
    // return result
    return {}
  },
})

export const myAssetsSelector = selector<Asset[]>({
  key: 'myAssets',
  get: async ({ get }) => {
    const wallet = get(connectedWallet)
    if (!wallet) return []
    const assets = await MyAssetsRepository.getAllByWallet(wallet)
    return assets || []
  }
})

export const myKVCardsSelector = selector({
  key: 'myKVCardsSelector',
  get: async ({ get }) => {
    const assets = get(myAssetsSelector)
    const cards = new Set(['Character', 'Technology'])
    const cardsHash = get(AllCardsByIdSelector)
    return assets.reduce((myCards: Map<string, any>, asset: Asset) => {
      if (!cards.has(asset.metadata?.AssetType)) return myCards
      let card = myCards.get(asset.metadata.id) || {
        ...cardsHash.get(asset.metadata.id),
        TokenIds: [],
        ListedTokens: []
      }
      card.TokenIds.push(asset.getMintNumber())
      myCards.set(asset.metadata.id, card)
      return myCards
    }, new Map<string, any>())
  }
})

export const myCardsSelector = selector({
  key: 'myCardsSelector',
  get: async ({ get }) => {
    const kvMyCards = get(myKVCardsSelector)
    return Array.from(kvMyCards.values())
  }
})

export const myBoostersSelector = selector({
  key: 'myBoostersSelector',
  get: async ({ get }) => {
    const wallet = get(connectedWallet)
    if (!wallet) return []
    const assets = await MyAssetsRepository.getBoosters(wallet, undefined, JSON.stringify({ AssetType: ['Booster'] }))
    return assets
  }
})

export const profileSelector = selector({
  key: 'profileSelector',
  get: async ({ get }) => {
    const wallet = get(connectedWallet)
    return ProfileRepository.getProfile(wallet)
  },
})

export const profileBalancesSelector = selector({
  key: 'profileBalancesSelector',
  get: async ({ get }) => {
    const wallet = get(connectedWallet)
    return wallet ? await ProfileRepository.fetchBalances(wallet!.address) : {}
  },
})

export const openHQPickerAtom = atom<boolean>({
  key: 'openHQPickerAtom', default: false, effects_UNSTABLE: undefined,
})

export const CharSelector = selector({
  key: 'CharSelector',
  get: async ({ get }) => {
    const ownedCards = get(myCardsSelector)
    const ownedCharacters = ownedCards.filter((card) => card.CardType === 'Character')
    const cards = get(OwnerCardsSelector)
    return cards.filter((c: any) => c.CardType === 'Character').map((c: CardType) => {
      const foundCard = ownedCharacters.find((o: any) => o.id === c.id)
      return {
        ...c,
        IsNft: foundCard !== undefined,
        TokenIds: foundCard?.TokenIds ? foundCard?.TokenIds : []
      }
    })
  },
})

export const TechSelector = selector({
  key: 'TechSelector',
  get: async ({ get }) => {
    const ownedCards = get(myCardsSelector)
    const ownedTechnologies = ownedCards.filter((card) => card.CardType === 'Technology')
    const cards = get(OwnerCardsSelector)
    return cards.filter((c: any) => c.CardType === 'Technology').map((c: CardType) => {
      const foundCard = ownedTechnologies.find((o: any) => o.id === c.id)
      return {
        ...c,
        IsNft: foundCard !== undefined,
        TokenIds: foundCard?.TokenIds ? foundCard?.TokenIds : []
      }
    })
  },
})

export const openedCardSelector = selectorFamily({
  key: 'openedCardSelector',
  get: (id: string) => async ({ get }) => {
    const cards = get(AllCardsSelector)
    return cards.find(card => card.id === id)
  },
})

export const userBalanceSelector = selector({
  key: 'userTicketBalance',
  get: async ({ get }) => {
    const user = get(connectedWallet)
    const balance = await RewardRepository.getBalance(user?.address)
    return balance.filter((b) => Boolean(b.ticketId))
  }
})

export const getDevBalance = selectorFamily({
  key: 'devBalance',
  get: (address?: string | undefined) => async ({ get }) => {
    const user = get(connectedWallet)
    if (!address && !user) return 0
    const balance = await BalanceRepository.getBalance(address || user!.address, 'dev')
    return balance.balance
  }
})

export const getTodayWithdraw = selectorFamily({
  key: 'todayAssetWithdraw',
  get: ({ address, asset }: { address: string | undefined, asset: string }) => async ({ get }) => {
    if (!address) return
    const withdraw = await AssetExchangeRepo.getTodayWithdraw(address, asset)
    return { ...withdraw, available: withdraw.dailyLimits - withdraw.withdraw }
  }
})

export const currencyConvertionSelector = selectorFamily({
  key: 'usdToXdev',
  get: (amount: number) => async ({ get }) => {
    const convertion = await RewardRepository.convert('USD', 'XDEV', amount)
    return convertion
  }
})

export const currencyConvertionsSelector = selector({
  key: 'currencyConvertions',
  get: async () => {
    const convertions = await RewardRepository.getCurrencyConvertions()
    return convertions
  }
})

export const challengesSelector = selector({
  key: 'challenges',
  get: async () => {
    const challenges = await ChallengesRepository.getChallenges()
    return challenges
  }
})

export const ticketCatalogSelector = selectorFamily({
  key: 'ticketCatalogSelector',
  get: (state?: string) => async ({ get }) => {
    const tickets = await TournamentTicketRepository.get(state)
    return tickets
  }
})

export const allTasksSelector = selectorFamily({
  key: 'allTasksSelector',
  get: (state?: string) => async () => {
    const tasks = await TasksRepository.getAllTasks()
    return tasks
  }
})

export const getBannedUser = selectorFamily({
  key: 'bannedUser',
  get: (address: string | undefined) => async () => {
    if (!address) return null
    const banned = await UserRepository.getBannedUser(address)
    return banned
  }
})

export const myBurnedBoosters = selector({
  key: 'myBurnedBoosters',
  get: async ({ get }) => {
    const user = get(connectedWallet)
    if (user === undefined) return []
    const tokenIds = get(myBurnedBoostersTokenIds)
    return tokenIds.reduce((boosters: any, tokenId: string) => {
      const boosterData = localStorage.getItem(`booster${tokenId}`)
      if (boosterData !== null) {
        const { booster } = JSON.parse(boosterData)
        const { data, metadata } = booster
        data.metadata = metadata
        const boosterAsset = new Asset(data)
        if (boosterAsset.getOwner().toLowerCase() === user.address.toLowerCase())
          return boosters.concat([boosterAsset])
      }
      return boosters
    }, [])
  },
})

export const myBurnedBoostersTokenIds = atom<string[]>({
  key: 'burnedBoostersiTokenIds',
  default: [],
  effects_UNSTABLE: [
    localStorageEffect('burnedBoostersTokenIds'),
  ],
})

export const selectedLeagueAtom = atom<string | undefined>({
  key: 'selectedLeagueAtom', default: getConfig().CURRENT_LEAGUE, effects_UNSTABLE: undefined,
})

export const selectLeague = selector({
  key: 'selectLeague',
  get: ({ get }) => (get(selectedLeagueAtom)),
  set: ({ set }, newValue) => set(selectedLeagueAtom, (newValue)),
});

export const allLeagues = selector({
  key: 'leagues',
  get: async () => {
    const leagues = await TournamentsRepository.getLeagues()
    return leagues.sort((a: League, b: League) => moment(a.startDate).diff(moment(b.startDate)))
  }
})

export const leagueScores = selector({
  key: 'leagueScores',
  get: async ({ get }) => {
    const selectedLeague = get(selectedLeagueAtom)
    const league = await TournamentsRepository.getLeagueScores(selectedLeague || getConfig().CURRENT_LEAGUE)
    const usersData = await UserRepository.getByIds(league.scores!.map((s: any) => s.userId))

    const randomString = (length: number) => {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength))
      }
      return result
    }

    const mappedScores = league.scores!.map((score: any) => {
      const profile = usersData.find((user: any) => user.id === score.userId)
      return {
        ...score,
        player: profile?.info.name || score.userId.slice(0, 10),
        avatar: profile?.info.avatar,
        address: randomString(10)
      }
    })

    for (let i = league.scores!.length; i < 100; i++) {
      mappedScores.push({
        player: '????????????',
        avatar: null,
        score: 0,
        userId: randomString(10),
        address: '0x0'
      })
    }
    return {
      ...league,
      league: {
        ...league.league,
        isEnded: moment().isAfter(moment(league.league.endDate)),
        isStarted: moment().isAfter(league.league.startDate),
      },
      scores: mappedScores
    }
  }
})

export const paginatedRewardsSelector = selectorFamily({
  key: 'paginatedRewardsSelector',
  get: ({ page, pageSize }: { page: number, pageSize: number }) => async ({ get }) => {
    const user = get(connectedWallet)
    return user ? await RewardRepository.getByUserAddressV2(user?.address, undefined, page, pageSize) : { page: 0, pageSize: 0, total: 0, data: [] }
  },
})


export const rewardsSelector = selector({
  key: 'rewardsSelector',
  get: async ({ get }) => {
    const user = get(connectedWallet)
    return user ? await Promise.all([
      RewardRepository.getByUserAddress(user?.address, undefined),
      TournamentTicketRepository.get('all'),
      AchievementsRepository.getAssignedAchievements(user?.address),
      ChallengesRepository.getAssignedChallenges(user?.address)
    ]) : [[], []]
  },
})

export const subscriptionStatusSelector = selectorFamily({
  key: 'subscriptionStatusSelector',
  get: (tournamentId?: string | undefined) => async ({ get }): Promise<{
    state: SubscriptionState,
    message?: string,
  } | undefined> => {
    const user = get(connectedWallet)
    if (!tournamentId || !user) return undefined
    return await TournamentsRepository.getSubscriptionState(tournamentId, user.address)
  },
})
