/* eslint-disable no-console */
// noinspection RedundantIfStatementJS

import { CardType } from '../domain/card.model'
import { DeckType } from '../domain/deck.model'

export type DeckFormatDto = {
  id: string
  name: string
  description: string
  maxCopies: number
  minCards: number
  maxCards: number
  minTechnologies: number
  maxTechnologies: number
  minCharacters: number
  maxCharacters: number
  minCommon: number
  maxCommon: number
  minUncommon: number
  maxUncommon: number
  minRare: number
  maxRare: number
  minLegendary: number
  maxLegendary: number
  minMale: number
  maxMale: number
  minFemale: number
  maxFemale: number
  minNB: number
  maxNB: number
  minUndeviant: number
  maxUndeviant: number
  minAwaken: number
  maxAwaken: number
  minEntropy: number
  maxEntropy: number
  minInhuman: number
  maxInhuman: number
  minOwner: number
  maxOwner: number
  allowedHQ?: number[]
}

export type DeckFormatsDto = {
  formats: DeckFormatDto[]
}

export type DeckFormatFiltersType = {
  Gender: {
    Male: number
    Female: number
    'Non Binary': number
    '': number
  }
  Rarity: {
    Common: number
    Legendary: number
    Rare: number
    Uncommon: number
    '': number
  }
  Faction: {
    Awaken: number
    Entropy: number
    Inhuman: number
    Owner: number
    Undeviant: number
    '': number
  }
  CardType: {
    HQ: number
    Character: number
    Technology: number
  }
}

export default abstract class DeckFormatsService {
  public static filterByDeckFormat(
    cards: CardType[],
    formats: DeckFormatsDto,
    deck: DeckType,
  ): CardType[] {
    const format = this.getFormat(formats, deck)
    if (!format) return cards
    const filters = this.getFilters(format, deck)
    const addedCards: string[] = deck.cards.map((c) => c.id!)
    return cards.filter((card) => {
      if (addedCards.includes(card.id!)) return true
      if (card.CardType === 'HQ') {
        if (
          format.allowedHQ
          && !format.allowedHQ.includes(card.CardNumber || 0)
        ) { return false }
        return true
      }
      if (!filters.Gender[card.Gender || '']) return false
      if (!filters.Rarity[card.Rarity || '']) return false
      if (!filters.Faction[card.Faction || '']) return false
      if (!filters.CardType[card.CardType]) return false
      return true
    })
  }

  public static getFormat(
    formats: DeckFormatsDto,
    deck: DeckType,
  ): DeckFormatDto | undefined {
    return formats.formats.find((format) => format.id === deck.format)
  }

  public static isValid(formats: DeckFormatsDto, deck: DeckType): boolean {
    const format = this.getFormat(formats, deck)
    if (!format) return true

    const filters = this.getFiltersCount(deck)

    const cards = deck.cards.map((card) => card.Name) || []
    const hasDuplicates = cards.some((item, idx) => cards.indexOf(item) !== idx)
    if (hasDuplicates) return false

    const keys: { [typeKey: string]: { [key: string]: [string, string] } } = {
      Gender: {
        Male: ['minMale', 'maxMale'],
        Female: ['minFemale', 'maxFemale'],
        'Non Binary': ['minNB', 'maxNB'],
      },
      Rarity: {
        Common: ['minCommon', 'maxCommon'],
        Legendary: ['minLegendary', 'maxLegendary'],
        Rare: ['minRare', 'maxRare'],
        Uncommon: ['minUncommon', 'maxUncommon'],
      },
      Faction: {
        Awaken: ['minAwaken', 'maxAwaken'],
        Entropy: ['minEntropy', 'maxEntropy'],
        Inhuman: ['minInhuman', 'maxInhuman'],
        Owner: ['minOwner', 'maxOwner'],
        Undeviant: ['minUndeviant', 'maxUndeviant'],
      },
      CardType: {
        Character: ['minCharacters', 'maxCharacters'],
        Technology: ['minTechnologies', 'maxTechnologies'],
      },
    }

    const filtersAny: any = filters
    const formatAny: any = format
    const error = Object.keys(keys).find((typeKey) => Object.keys(keys[typeKey]).find((key) => {
      const formatArray: [string, string] = keys[typeKey][key]
      const min: number = formatAny[formatArray[0]]
      const max: number = formatAny[formatArray[1]]
      const count: number = filtersAny[typeKey][key]
      const valid = count >= min && (max < 0 || count <= max)
      // if (!valid) console.error(`NO VALID ${typeKey} ${key}: ${min} < ${count} < ${max}`)
      return !valid
    }))
    return !error
  }

  private static getFiltersCount(deck: DeckType): DeckFormatFiltersType {
    const filters: DeckFormatFiltersType = {
      Gender: {
        Male: 0,
        Female: 0,
        'Non Binary': 0,
        '': 0,
      },
      Rarity: {
        Common: 0,
        Legendary: 0,
        Rare: 0,
        Uncommon: 0,
        '': 0,
      },
      Faction: {
        Awaken: 0,
        Entropy: 0,
        Inhuman: 0,
        Owner: 0,
        Undeviant: 0,
        '': 0,
      },
      CardType: {
        HQ: 0,
        Character: 0,
        Technology: 0,
      },
    }
    deck.cards.forEach((card) => {
      if (card.CardType === 'HQ') return
      filters.Gender[card.Gender || ''] += 1
      filters.Rarity[card.Rarity || ''] += 1
      filters.Faction[card.Faction || ''] += 1
      filters.CardType[card.CardType] += 1
    })
    return filters
  }

  private static getFilters(
    format: DeckFormatDto,
    deck: DeckType,
  ): DeckFormatFiltersType {
    const filters = this.getFiltersCount(deck)

    const keys: { [typeKey: string]: { [key: string]: string } } = {
      Gender: {
        Male: 'maxMale',
        Female: 'maxFemale',
        'Non Binary': 'maxNB',
      },
      Rarity: {
        Common: 'maxCommon',
        Legendary: 'maxLegendary',
        Rare: 'maxRare',
        Uncommon: 'maxUncommon',
      },
      Faction: {
        Awaken: 'maxAwaken',
        Entropy: 'maxEntropy',
        Inhuman: 'maxInhuman',
        Owner: 'maxOwner',
        Undeviant: 'maxUndeviant',
      },
      CardType: {
        Character: 'maxCharacters',
        Technology: 'maxTechnologies',
      },
    }

    filters.Gender[''] = 1
    filters.Rarity[''] = 1
    filters.Faction[''] = 1
    filters.CardType.HQ = 1
    const filtersAny: any = filters
    const formatAny: any = format
    Object.keys(keys).forEach((typeKey) => {
      Object.keys(keys[typeKey]).forEach((key) => {
        const formatKey: string = keys[typeKey][key]
        const max: number = formatAny[formatKey]
        filtersAny[typeKey][key] = max < 0 || filtersAny[typeKey][key] < max ? 1 : 0
      })
    })

    return filters
  }
}
