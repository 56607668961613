import getConfig from 'config.service'
import { Achievement, AssignedAchievement } from 'domain/assignedAchievement.model'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import { ServiceConsumer, ServiceData } from 'infraestructure/services/service-consumer/service-consumer.service'

const { hostURL } = getConfig()

export default class AchievementsRepository {
  public static async getAssignedAchievements(playerId: string): Promise<AssignedAchievement[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/achievements/assigned/player/${playerId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const assignedAchievements = await ServiceConsumer.get<{achievements: AssignedAchievement[]}>(requestData)
    return assignedAchievements.achievements
  }

  public static async getAchievements(): Promise<Achievement[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/achievements/achievements`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const achievements = await ServiceConsumer.get<{achievements: Achievement[]}>(requestData)
    return achievements.achievements
  }
}
