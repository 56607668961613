export default function Pagination({
  onChange,
  total,
  current,
}:
  { onChange: (page: number) => void, total: number, pageSize: number, current: number }) {
  return <div className="pagination">
    {Array.from(Array(Math.ceil(total)).keys()).map((page) => <div className={`btn btn-primary ${(page + 1) === current ? 'pagination__item--active' : ''}`} key={page} onClick={() => onChange(page + 1)}>
      {page + 1}
    </div>)}
  </div>
}
