/* eslint-disable */
// noinspection JSUnusedLocalSymbols

import axios from 'axios'
import { getHost } from '../infraestructure/services/host-consumer/host-consumer.service'
import { sessionExpired, ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'
import ReferralService from "../services/referral.service"
import RewardsService from "../services/balances.service"
import cookie from 'cookiejs'
import { Balance } from 'domain/balance';
import getConfig from "../config.service";

const config = getConfig()
const { ENABLE_BALANCE } = config

export type ProfileDataType = {
  id: string,
  firstLogin?: boolean,
  info: {
    avatar: string,
    name: string,
    isHolder?: boolean,
    createdAt: string,
    conditionsAccepted?: boolean
    tutorialCompleted?: boolean | number
    vol?: number
    tickets?: { [ticketId: string]: number }
    winrates?: { [type: string]: any }
  }
}

const win: any = window
export const ProfileRepository = {
  async getProfile(wallet: any | undefined): Promise<ProfileDataType | undefined> {
    if (!wallet || win.deviantsReloading) return undefined
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
    let user: any

    try {
      user = await ProfileRepository.fetchProfile(wallet.address, token)
    } catch (e: any) {
      if (e.message === 'Request failed with status code 401') sessionExpired()
      await ProfileRepository.createProfile(wallet.address, token)

      if (cookie.get('referral')) {
        await ReferralService.registerReferralCode(wallet.address, String(cookie.get('referral')))
      }
      user = await ProfileRepository.fetchProfile(wallet.address, token)
      user.firstLogin = true
    }
    if (user && user?.id && typeof win.deviantsShowWelcome === 'function') win.deviantsShowWelcome()
    return user
  },
  async fetchBalances(address: string) {
    const balances: any = { vol: 0, freerollTickets: 0, qualifierTicket: 0, halloweenTicket: 0, xdev: 0, tickets: {}, sdev: 0 }
    for (let i = 1; i < 40; i++) balances.tickets[i.toString()] = 0;

    if (!ENABLE_BALANCE) return balances
    const res = await RewardsService.offChainBalance(address)
    res?.forEach((item: Balance) => {
      if (item.offChainCurrency === 'VOL_POINT') balances.vol += item.balance
      if (item.offChainCurrency === 'FREE_ROLL_TICKET') {
        balances.tickets[item.ticketId] += item.balance
      }
      if (item.offChainCurrency === 'XDEV') balances.xdev += item.balance
      if (item.offChainCurrency === 'SDEV') balances.sdev += item.balance
    })
    return balances
  },
  async fetchProfile(address: string, token: string) {
    try {
      const url = `${getHost()}/api/v1/users-manager/user/${address}`
      const res = await axios.get(url)
      if (res.status === 401) sessionExpired()

      const profileData = res.data;
      const winrates: { [type: string]: any } = {};
      if (profileData.info && profileData.info.winrates) {
        for (const key in profileData.info.winrates) {
          if (profileData.info.winrates.hasOwnProperty(key)) {
            winrates[profileData.info.winrates[key].id] = profileData.info.winrates[key];
          }
        }
      }

      // console.log('winrates', winrates)

      return { ...profileData, info: { ...profileData.info, winrates } };

      // return res.data
    } catch (e: any) {
      if (e.status === 401) sessionExpired()
      throw e
    }

  },
  async createProfile(address: string, token: string) {
    const url = `${getHost()}/api/v1/users-manager/user`
    const res = await axios.post(
      url,
      { id: address },
    )
    return await res.data
  },
  async acceptTerms(address: string, token: string) {
    const url = `${getHost()}/api/v1/users-manager/user/${address}`
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      method: 'PATCH',
      body: JSON.stringify({
        id: address,
        info: {
          conditionsAccepted: true,
        },
      }),
    })
    if (res.status === 401) {
      sessionExpired()
    } else {
      return await res.json()
    }
  },
  async getAcceptTerms(wallet: any | undefined): Promise<ProfileDataType | undefined> {
    if (!wallet) return
    let user: any
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    try {
      await ProfileRepository.acceptTerms(wallet.address, token)
      user = await ProfileRepository.fetchProfile(wallet.address, token)
    } catch (e) {
      user = undefined
      console.error(e)
    }
    return user
  },
  async updateName(address: string, name: string) {
    const url = `${getHost()}/api/v1/users-manager/user/${address}`
    const token = ValidateIfTokenIsPresent()
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      method: 'PATCH',
      body: JSON.stringify({
        id: address,
        info: {
          name,
        },
      }),
    })
    // console.log(res)
    if (res.status === 401) {
      sessionExpired()
    } else {
      return await res.json()
    }
  },
}
