/* eslint-disable max-len */
import { Accordion } from 'components/Accordion/Accordion.component'

export default function TournamentRequirements() {
  return (
    <div className="tournament-requirements">
      <Accordion showOnInit={false} title={'REQUIREMENTS/SPECIFICATIONS'} counter="">
        <div className="accordion-content-wrapper">
          <div className="pic">
            <img src={''} alt="" className="example"/>
          </div>
          <div className="accordion-content-description">
            <ul>
              <li>You need to have at least 1 Deck and 1 Tournament Ticket crafted to enter.</li>
              <li>All cards from the OTK version are accepted to play in the tournament.</li>
              <li>Gameplay rules are the same of casual PVP matches</li>
              <li>You agree that any violation of any such terms and conditions may result in the cancellation of your registration or purchase.</li>
            </ul>
            </div>
        </div>
      </Accordion>
    </div>
  )
}
