import Flex from 'components/Flex/Flex'
import useTokenToDevConvertion from 'views/exchange/hooks/useTokenToDevConvertion.hook'

export default function DevReceived({ token, amount }: { token: string, amount: number }) {
  const mapTokenName = () => {
    switch (token) {
      case 'USDC':
        return 'usd-coin'
      case 'IMX':
        return 'immutable-x'
      case 'ETH':
        return 'ethereum'
      case 'GODS':
        return 'gods-unchained'
      default:
        return 'ethereum'
    }
  }

  const tokenEquivalence = useTokenToDevConvertion({
    token: mapTokenName(),
    amount,
  })
  return <Flex
    justifyContent='center' alignItems='center' flexDirection='row'
    gap='1ch' style={{ width: '100%', backgroundColor: 'rgba(0,0,0,0.4)', borderRadius: '4px' }}>
    <p>{tokenEquivalence.toFixed(4)}</p>
    <img src={'/images/currency/XDEV.png'} />
  </Flex>
}
