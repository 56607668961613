export type AssetExchangeState = 'PENDING' | 'COMPLETED'

export type AssetExchangeOperation = 'POINTS_TO_SOFT' | 'NFT_TO_SOFT'

export type ERC721 = 'BOOSTER' | 'TOURNAMENT_TICKETS' | 'VOL'

export type VolExchange = {
  id: string,
  address: string,
  operation: AssetExchangeOperation,
  assetId: string,
  amount: number,
  assetType: ERC721,
  assetTypeFieldKey: string,
  assetTypeFieldValue: string,
  txHash: string,
  state: AssetExchangeState
}

export const getNFTTypeKey = (type: string) => {
  if (type.toLocaleUpperCase() === 'BOOSTER') return 'BoosterType'
  if (type.toLocaleUpperCase() === 'PACK') return 'PackType'
  return type
}

export const getAssetType = (type: string) => {
  if (type.toLocaleUpperCase() === 'BOOSTER') return 'Booster'
  if (type.toLocaleUpperCase() === 'CURRENCY') return 'Currency'
  return type as string
}
