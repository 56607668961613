import Flex from 'components/Flex/Flex'
import { useRecoilValueLoadable } from 'recoil'
import { leaderboardSelector } from 'selectors/play2airdrop.selectors'
import { LeaderboardEntry } from 'domain/play2airdrop.types'
import LeaderboardListItem from './LeaderboardListItem.component'

export default function LeaderboardList() {
  const leaderBoard = useRecoilValueLoadable(leaderboardSelector)
  if (leaderBoard.state === 'loading') return <div>Loading...</div>
  return <div style={{ maxHeight: '560px', overflowY: 'auto' }} className='scroll'>
    <Flex flexDirection='column' gap='0px'>
      {leaderBoard.contents?.map((player: LeaderboardEntry) => <LeaderboardListItem
        position={player.position}
        key={player.userId}
        title={player.user?.info.name || 'unknown'} level={player.level} xp={player.xp} />)}
    </Flex>
  </div>
}
