import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InhumanSvg from 'components/Factions/svgs/inhuman.svg'
import AwakenSvg from 'components/Factions/svgs/awaken.svg'
import EntropySvg from 'components/Factions/svgs/entropy.svg'
import OwnerSvg from 'components/Factions/svgs/owner.svg'
import { faSearch } from '../../infraestructure/repositories/icons.repository'
import './my-cards.scss'

interface SearchFilterProps {
  searchAction: any;
  submitAction: any;
  value: string;
}

export default function SearchFilter(props: SearchFilterProps) {
  const { searchAction, submitAction, value } = props
  return (
    <div>
      <div style={{ margin: 'auto' }}>
          <form className="search-bar" onSubmit={submitAction}>
            <FontAwesomeIcon icon={faSearch} className="search-bar-icon" />
            <input type="text" placeholder="Search by name, faction, gender, id, ability..." value={value} onChange={(evt) => {
              searchAction(evt.target.value)
            }} />

            <input type={'submit'} style={{ display: 'none' }} />
          </form>
        </div>
        <div style={{ width: '30%', margin: 'auto' }}>
          <div style={{ display: 'flex' }}>
            <div className="faction-button" onClick={() => searchAction('awaken')}><AwakenSvg /></div>
            <div className="faction-button" onClick={() => searchAction('entropy')}><EntropySvg /></div>
            <div className="faction-button" onClick={() => searchAction('inhuman')}><InhumanSvg /></div>
            <div className="faction-button" onClick={() => searchAction('owner')}><OwnerSvg /></div>
          </div>
        </div>
    </div>
  )
}
