import { CSSProperties, ReactNode } from 'react'
import './box.component.scss'

export type BoxProps = {
  children: ReactNode | ReactNode[],
  style?: CSSProperties
  width?: string
}

export function Box({ children, style, width }: BoxProps) {
  return <div className='df-box' style={{ width, ...style }}>{children}</div>
}
