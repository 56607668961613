import LoadingPage from 'components/LoadingPage/LoadingPage.component'
import twitterService from 'infraestructure/services/twitter/twitter.service'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { connectedWallet } from 'selectors/selectors'

export default function TwitterOauthCallbackPage() {
  const code = new URLSearchParams(useLocation().search).get('code')
  const user = useRecoilValue(connectedWallet)
  const { origin } = window.location
  useEffect(() => {
    if (code && user?.address) {
      twitterService.validateCode(code, user?.address, `${origin}/play-2-airdrop`)
        .then(() => {
          window.location.href = `${origin}/play-2-airdrop`
        }).catch(() => {
          window.location.href = `${origin}/play-2-airdrop`
        })
    }
  }, [code])

  return (
    <LoadingPage />
  )
}
