import '../views.scss'
import './not-connected.scss'
import ConnectWalletButton from '../../components/connect-wallet-button/connect-wallet-button.component'

export default function UserNotConnectedView() {
  // <p className="description">It is essential to play and perform other actions within the page.</p>
  return (
    <div className="empty-state">
      <h2 />
      <h2 className={'title'}>It seems that you haven&apos;t connected your wallet yet.</h2>
      <h1>Please connect your Terra Wallet and make sure it has at least one Starter Pack.</h1>
      <ConnectWalletButton/>
      <h2 />
    </div>
  )
}
