import { selector } from 'recoil'
import TournamentsRepository from '../repositories/tournaments.repository'

export const AllTournamentsSelector = selector({
  key: 'AllTournamentsSelector',
  get: async () => {
    const tournaments = await TournamentsRepository.getAll()
    return tournaments
  },
})

export default AllTournamentsSelector
