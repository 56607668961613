import { Box } from 'components/Box/box.component'
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core' */
import Flex from 'components/Flex/Flex'
import Text from 'components/Text/Text'

type StakingTimeOptionProps = {
  title: string
  description: string
  selected: boolean
  select: (option:string) => void
  option: string
  amount: number
}

function getAPR(amount:number, option: string): string {
  switch (option) {
    case '30d':
      return `${(amount / 365).toFixed(4)} sDEV/day`
    case '6m':
      return `${((amount / 365) * 2).toFixed(4)} sDEV/day`
    case '12m':
      return `${((amount / 365) * 3).toFixed(4)} sDEV/day`
    default:
      return `${0} sDEV/day`
  }
}

export default function StakingTimeOptionComponent(props: StakingTimeOptionProps) {
  const {
    description, selected, select, option, amount,
  } = props
  return <Box width='9vw' style={ (selected) ? { textAlign: 'center', marginBottom: '20px', backgroundColor: '#666' } : { textAlign: 'center', marginBottom: '20px', backgroundColor: '#111' }}>
    <a href='#' onClick={() => select(option) }>
      <Flex flexDirection='column' gap={'2ch'}>
        <div>
          <h2 style={ (selected) ? { color: '#ff5c00' } : { color: 'white' }}>{description.split(' ')[0]}</h2>
        </div>
        <div style={ (selected) ? { height: '30px', color: 'white' } : { color: 'grey' }}>
          <Text fontWeight={'bold'} fontSize={ '14px' }>
            {description.split(' ').slice(1).join(' ')}
          </Text>
          <span style={{ fontSize: '12px' }}>{getAPR(amount, option)}</span>
        </div>
      </Flex>
    </a>
  </Box>
}
