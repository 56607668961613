const loader:any = undefined
export const LoaderRepository = {
  loader,
  refreshDecks: false,
  setLoading(value: boolean) {
    if (!LoaderRepository.loader) LoaderRepository.loader = document.getElementById('deviants-loader')
    if (!LoaderRepository.loader) return
    if (value) LoaderRepository.loader.classList.add('active')
    else LoaderRepository.loader.classList.remove('active')
  },
}
export default {}
