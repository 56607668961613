import { Box } from 'components/Box/box.component'
import { useState } from 'react'
import Flex from 'components/Flex/Flex'
import Text from 'components/Text/Text'
import { SDevStoreItem } from 'selectors/staking.selectors'
import Tooltip from 'components/Tooltip/Tooltip.component'
import IconsDEV from '../../../../assets/icons/icon-sdev.png'
import StakeStoreModalComponent from './stakestore-modal.component'

type StakeStoreItemProps = {
  title: string
  image: string
  price: number
  enabled?: boolean,
  item: SDevStoreItem
}

export default function StakeStoreItem(props: StakeStoreItemProps) {
  const {
    title, price, enabled,
  } = props
  const [showPurchaseModal, setShowPurchaseModal] = useState(true)
  const [selectedItem, setSelectedItem] = useState<SDevStoreItem | undefined>(undefined)
  return <Box
    style={{
      display: 'grid',
      placeContent: 'center',
      textAlign: 'center',
      marginBottom: '20px',
      backgroundColor: (enabled) ? '#666' : '#111',
      width: '150px',
    }}>
    <StakeStoreModalComponent
      item={selectedItem}
      isActive={showPurchaseModal}
      closeAction={() => {
        setShowPurchaseModal(false)
        setSelectedItem(undefined)
      }} />
    <a href='#' onClick={(e) => {
      e.preventDefault()
      if (enabled) {
        setSelectedItem(props.item)
        setShowPurchaseModal(true)
      }
    }} style={{ textDecoration: 'none' }}>
      <Tooltip enabled={!enabled} text="You dont have enough sDEV to buy this item">
        <Flex flexDirection='column' justifyContent='space-evenly' style={(enabled) ? { color: 'white' } : { color: 'grey' }}>
          <img src={props.image} alt={title} height={'200px'} style={!enabled ? { filter: 'grayscale(100%)' } : {}} />
          <Flex flexDirection='row' alignItems='center' justifyContent='center' gap="1ch">
            <img src={IconsDEV} alt={'aaa'} height="18px" />
            <h2 style={(enabled) ? { color: 'orange' } : { color: 'grey' }}>{price}</h2>
          </Flex>
          <div>
            <Text fontWeight={'bold'} fontSize={'14px'}>
              {title}
            </Text>
          </div>
        </Flex>
      </Tooltip>
    </a>
  </Box >
}
