import './staking.view.scss'
import Footer from '../../components/Footer/Footer.component'
import StakingComponent from './components/staking.component'

export default function StakingView() {
  return (
    <div className="user-staking-view">
      <StakingComponent />
      <Footer />
    </div>
  )
}
