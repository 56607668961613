import twitterService from 'infraestructure/services/twitter/twitter.service'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { connectedWallet } from 'selectors/selectors'

export default function useTwitterLink() {
  const [link, setLink] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const user = useRecoilValue(connectedWallet)
  useEffect(() => {
    if (!user) return
    twitterService.getLink(user?.address)
      .then(setLink)
      .finally(() => setLoading(false))
  }, [])
  return {
    link, loading,
  }
}
