import Select, { SingleValue } from 'react-select'
import { useEffect, useRef, useState } from 'react'
import ValidationSchemaRepository, { ValidationSchema } from 'repositories/validation-schema.repository'
import './economy-tools.view.scss'
import {
  allTasksSelector, challengesSelector, currencyConvertionsSelector, ticketCatalogSelector,
} from 'selectors/selectors'
// eslint-disable-next-line camelcase
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import TasksRepository from 'repositories/tasks.repository'
import AchievementsRepository from 'repositories/achievement.repository'
import Footer from '../../components/Footer/Footer.component'
import { customSelectStyles } from '../../components/deckbuilder/EditDeck/EditDeck.component'
import TournamentsRepository from '../../repositories/tournaments.repository'
import EconomyTournamentGrid from './economy-tournament-grid.component'
import EconomyTicketGrid from './economy-ticket-grid.component'
import EconomyCurrencyExchangeGrid from './economy-currency-exchange-grid.component'
import EconomyAchievementGrid from './economy-achievements-grid.component'
import EconomyChallengesGrid from './economy-challenges.component'
import EconomyTasksGrid from './economy-tasks.component'

export default function EconomyToolsView() {
  const [schemas, setSchemas] = useState<ValidationSchema[]>([])
  const [selectedSchema, selectSchema] = useState<ValidationSchema | undefined>(undefined)
  const [tournaments, setTournaments] = useState<any[]>([])
  const [achievements, setAchievements] = useState<any[]>([])
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  // eslint-disable-next-line no-undef
  const [errors, setErrors] = useState<{ message: string | undefined }>({ message: undefined })
  const editorRef = useRef<any>()
  const ticketRefresher = useRecoilRefresher_UNSTABLE(ticketCatalogSelector('all'))
  const tasksRefresher = useRecoilRefresher_UNSTABLE(allTasksSelector('all'))
  const exchangeRateRefresher = useRecoilRefresher_UNSTABLE(currencyConvertionsSelector)
  const challengesRefresher = useRecoilRefresher_UNSTABLE(challengesSelector)

  useEffect(() => {
    ValidationSchemaRepository.getVlidationSchemas()
      .then((s) => {
        setSchemas([...s, {
          id: 'tasks',
          name: 'Tasks Schema',
          schema: '',
        }])
      })
    TournamentsRepository.getAll('CREATED')
      .then((t) => setTournaments(t))
    AchievementsRepository.getAchievements()
      .then((a) => setAchievements(a))
  }, [])

  const clearInput = () => {
    editorRef.current.value = ''
  }

  const ErrorBox = ({ children }: { children?: string }) => <div className='economytools__error_box'>{children}</div>

  const refreshSchema = () => {
    if (selectedSchema?.name === 'Tournament Schema') {
      TournamentsRepository.getAll('CREATED')
        .then((t) => setTournaments(t))
    }
    if (selectedSchema?.name === 'Ticket Schema') ticketRefresher()
    if (selectedSchema?.name === 'Currency Convertion Schema') exchangeRateRefresher()
    if (selectedSchema?.name === 'Challenge Schema') challengesRefresher()
    if (selectedSchema?.name === 'Achievement Schema') {
      AchievementsRepository.getAchievements()
        .then(setAchievements)
    }
  }

  const process = (payload: string) => {
    if (!selectedSchema || !payload) return
    setProcessing(true)
    ValidationSchemaRepository.validate({ payload, schemaId: selectedSchema.id })
      .then((resp) => {
        if (!resp.result) {
          if (resp.errors.length > 0) {
            setErrors(resp.errors[0])
          }
        } else {
          refreshSchema()
          clearInput()
          setShowSuccess(true)
          setErrors({ message: undefined })
          setTimeout(() => setShowSuccess(false), 1500)
        }
      })
      .catch(() => setErrors({ message: 'unknown error' }))
      .finally(() => setProcessing(false))
  }

  const saveTasks = async (tasks: string) => {
    try {
      const json = JSON.parse(tasks)
      await TasksRepository.saveTasks(json)
      tasksRefresher()
      clearInput()
      setShowSuccess(true)
      setErrors({ message: undefined })
      setTimeout(() => setShowSuccess(false), 1500)
    } catch (error) {
      setErrors({ message: 'unknown error' })
    } finally {
      setProcessing(false)
    }
  }

  const changeSchema = (x: SingleValue<{ label: string, value: string }>) => {
    clearInput()
    selectSchema(schemas.find((s) => s.id === x?.value))
  }

  return (
    <div className="rewards-view">
      <div className='rewards-view__title'>
        <h3>Economy Tools</h3>
      </div>
      <div style={{ display: 'flex', gap: '1em', padding: '2em' }}>
        <div style={{ width: '40%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2ch' }}>
            <ErrorBox>
              {errors?.message}
            </ErrorBox>
            <Select
              onChange={(x) => changeSchema(x)}
              placeholder="Select schema"
              styles={{ ...customSelectStyles }}
              className={'deviants-select'}
              options={schemas.map((s: ValidationSchema) => ({ label: s.name, value: s.id }))}
            />
            <textarea rows={25} className='json-editor' ref={editorRef}></textarea>
            <div className='claim-button-wrapper'>
              <div className='btn btn-medium btn-primary'
                onClick={() => {
                  const json = editorRef.current.value
                  // eslint-disable-next-line no-debugger
                  if (!processing && selectedSchema?.id !== 'tasks') process(json)
                  if (!processing && selectedSchema?.id === 'tasks') saveTasks(json)
                }}>
                {processing ? 'Processing...' : 'Process'}
              </div>
            </div>
          </div>
        </div>
        <div style={{
          width: '60%',
          fontSize: '1.25rem',
          overflowY: 'scroll',
          height: '500px',
        }}>
          {showSuccess && <p>Data saved successfully</p>}
          {selectedSchema?.name === 'Tournament Schema' && <EconomyTournamentGrid tournaments={tournaments} />}
          {selectedSchema?.name === 'Ticket Schema' && <EconomyTicketGrid />}
          {selectedSchema?.name === 'Currency Convertion Schema' && <EconomyCurrencyExchangeGrid />}
          {selectedSchema?.name === 'Achievement Schema' && <EconomyAchievementGrid achievements={achievements} />}
          {selectedSchema?.name === 'Challenge Schema' && <EconomyChallengesGrid />}
          {selectedSchema?.name === 'Tasks Schema' && <EconomyTasksGrid />}
          {!selectedSchema && <p className='select-schema-message'>Please select a schema</p>}
        </div>
      </div>
      <div className='rewards-view__footer'> </div>
      <Footer />
    </div >
  )
}
