import axios from 'axios'
import getConfig from 'config.service'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'

class TwitterService {
  private readonly hostUrl = getConfig().hostURL

  async getLink(userId: string) {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    const { data } = await axios.get(`${this.hostUrl}/api/v1/users-manager/twitter/get-link/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.url
  }

  async validateCode(code: string, userId: string, callbackUrl?: string) {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    const { data } = await axios.post(`${this.hostUrl}/api/v1/users-manager/twitter/validate-code`, {
      code,
      userId,
      callbackUrl,
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  }
}

export default new TwitterService()
