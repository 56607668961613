import { useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import VolExchangeRepository from 'repositories/asset-exchange.repository'
import { connectedWallet } from 'selectors/selectors'

export type UseDevWithdrawProps = {
  onSuccess?: (tx: string) => void,
  onFail?: (error: string) => void,
}

export default function useDevWithdraw(props: UseDevWithdrawProps) {
  const [loading, setLoading] = useState(false)
  const wallet = useRecoilValueLoadable(connectedWallet)
  const withdraw = async (amount: number) => {
    setLoading(true)
    try {
      if (!wallet.contents.address) throw new Error('Wallet not connected')
      const exchange = await VolExchangeRepository.convertXdevToDev('6', amount, wallet.contents.address)
      props.onSuccess?.(exchange.txHash)
    } catch (error: any) {
      props.onFail?.(error.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    withdraw,
    loading,
  }
}
