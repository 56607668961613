/* eslint-disable camelcase */
import './tournaments.view.scss'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { AllTournamentsSelector } from 'selectors/tournamentsStore'
import Footer from 'components/Footer/Footer.component'
import MetalContainer from 'components/MetalContainer/MetalContainer.component'
// import LoadingPage from 'components/LoadingPage/LoadingPage.component'
import { connectedWallet, DeckFormatsSelector } from 'selectors/selectors'
import { useState } from 'react'
import moment from 'moment'
import { Tournament } from 'domain/tournament.model'
import useInterval from 'services/useInterval.hook'
import LoadingPage from 'components/LoadingPage/LoadingPage.component'
import TournamentItem, { TournamentFormatComponentDisplay } from './components/TournamentItem.component'
import { DeckFormatsDto } from '../../../services/deck-formats.service'
import FakeGrid from '../../../components/FakeGrid/FakeGrid.component'
import EntryFee from './components/EntryFee.component'
import TournamentsHead from './components/TournamentsHead.component'

export const TournamentsList = ({ list, deckFormats }: { list: any[], deckFormats?: DeckFormatsDto }): any => {
  if (!(list?.length > 0)) return <div className="tournaments-view__no-tournaments">No tournaments available</div>

  const wallet = useRecoilValue(connectedWallet)

  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState<any>({
    ongoing: { title: 'OnGoing', active: true },
    joined: { title: 'Joined', active: false },
    all: { title: 'All', active: false },
  })

  const [item, setItem] = useState<any>({})
  const columns = ['Name', 'State', 'Format', 'Entry', 'Players', 'Required Players', 'Starts at']

  const rowItem = (row: any, j: number, tournament: any) => (<a key={`row-${tournament.id}-${columns[j]}`} className={item.id === tournament.id ? 'selected' : ''} onClick={() => setItem(tournament)}>{row}</a>)

  const now = new Date()
  const startsAt = (startDate: any) => moment(startDate).utc().from(now)

  const selected = Object.keys(filters).find((key) => filters[key].active)
  const s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')// remove tildes

  function filterTournament(tournament: any) {
    if (!s || s === '') return true
    const name = tournament.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return name.indexOf(s) > -1
  }

  function filterJoined(tournament: any) {
    return tournament?.subscribedPlayers?.findIndex((player: any) => player?.playerId === wallet?.address) > -1
  }

  const getDataFromTournaments = () => {
    let data = list.filter((tournament) => {
      if (selected === 'all') return filterTournament(tournament)
      if (selected === 'joined') return filterJoined(tournament)
      return ['PUBLISHED', 'ONGOING', 'ANNOUNCED', 'SUBSCRIPTION_CLOSED'].includes(tournament?.state ?? '') && filterTournament(tournament)
    })
    if (!filters.all.active) {
      data = data.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
    }
    return data.map((tournament) => [
      rowItem(tournament.name, 0, tournament),
      rowItem(tournament.state, 1, tournament),
      rowItem(TournamentFormatComponentDisplay({ tournament, deckFormats }), 2, tournament),
      rowItem(<EntryFee tournament={tournament} onlyData={true} />, 3, tournament),
      rowItem(`${tournament.subscribedPlayers.length} / ${tournament.maxPlayers}`, 4, tournament),
      rowItem(tournament.minHumanPlayers, 5, tournament),
      rowItem(startsAt(tournament.startDate), 6, tournament),
    ])
  }

  const data = getDataFromTournaments()

  return (
    <div className="tournaments-content">
      <div className="tournaments-filters">
        <TournamentsHead filters={filters} setFilters={setFilters} search={search} setSearch={setSearch} />
      </div>
      <div className="tournaments-view__list">
        <div className="tournaments-view__table">
          <FakeGrid
            data={data}
            columns={columns}
          />
        </div>
        {
          item.id ? (
            <div className="tournaments-view__item">
              <TournamentItem
                tournament={item}
                key={item.id}
                deckFormats={deckFormats} />
            </div>
          ) : <></>
        }
      </div>
    </div>
  )
}

export default function TournamentsView() {
  const Tournaments = useRecoilValueLoadable<Tournament[]>(AllTournamentsSelector)
  const tournamentsRefresher = useRecoilRefresher_UNSTABLE(AllTournamentsSelector)
  useInterval(() => {
    tournamentsRefresher()
  }, (5000 * 60))

  const deckFormats = useRecoilValueLoadable(DeckFormatsSelector)
  if (Tournaments.state === 'loading' || deckFormats.state === 'loading') return <LoadingPage />
  if (Tournaments.state === 'hasError') return <div>Error {Tournaments.contents}</div>
  return (
    <div className="tournaments-view">
      {/* <TournamentsHead /> */}
      <MetalContainer>
        <h1>Tournaments</h1>
      </MetalContainer>
      <TournamentsList list={Tournaments.contents} deckFormats={deckFormats.contents} />
      <Footer />
    </div >
  )
}
