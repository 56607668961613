/* eslint-disable max-len */
import './TournamentStageInfoModal.component.scss'
import CrystalModal from 'components/CrystalModal/CrystalModal.component'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedStageAtom, isShowStageInfoModalAtom } from '../tournamentDetail.recoil'

function SwissInfo() {
  return (
    <div className="tournament-stage-info-modal">
      <p>A Swiss-system tournament is a non-eliminating tournament format that features a fixed number of rounds of competition, but considerably fewer than for a round-robin tournament; thus each competitor (team or individual) does not play all the other competitors. Competitors meet one-on-one in each round and are paired using a set of rules designed to ensure that each competitor plays opponents with a similar running score, but does not play the same opponent more than once. The winner is the competitor with the highest aggregate points earned in all rounds. With an even number of participants, all competitors play in each round.</p>
      <p>The Swiss system is used for competitions in which there are too many entrants for a full round-robin (all-play-all) to be feasible, and eliminating any competitors before the end of the tournament is undesirable. In contrast, all-play-all is suitable if there are a small number of competitors; whereas a single-elimination (knockout) tournament rapidly reduces the number of competitors, but the best competitor may not necessarily win, as good competitors might have a bad day or eliminate and exhaust each other if they meet in early rounds.</p>
      <p>Unlike group format or other systems in which all pairings are known from the beginning of the competition, in a Swiss system the match pairing for each round is done after the previous round has ended and depends on its results.</p>
      <p>The Swiss system seeks to provide a clear winner with a large number of competitors and a relatively small number of rounds of competition, without a single bad result terminating participation.</p>
      <p>The system was first employed at a chess tournament in Zurich in 1895 by Julius Müller, hence the name &apos;Swiss system&apos;.</p>
    </div>
  )
}

export default function TournamentStageInfoModal() {
  const stage = useRecoilValue(selectedStageAtom)
  const [isShowModal, setIsShowModal] = useRecoilState(isShowStageInfoModalAtom)
  function close() {
    setIsShowModal(false)
  }
  return (
    <CrystalModal title={stage.format} isActive={isShowModal} closeAction={close} id={'tournament-stage-info-modal'}>
      {stage.format === 'SWISS' && <SwissInfo />}
    </CrystalModal>
  )
}
