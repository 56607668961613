/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
import { BackgroundBrands } from 'components/BackgroundBrading/background-branding.component'
import Button from 'components/Button/Button.component'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { LoaderFallback, setLoader } from 'components/Loader/Loader.component'
import { resolveName } from 'domain/assignedAchievement.model'
import { AssignedChallenge } from 'domain/challenges.model'
import Reward from 'domain/reward.model'
import { TournamentTicket } from 'domain/tournament-ticket.model'
import { useEffect, useState } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import ChallengesRepository from 'repositories/challenge.repository'
import RewardRepository from 'repositories/rewards.repository'
import TournamentTicketRepository from 'repositories/tournament-tickets.repository'
import { connectedWallet, profileBalancesSelector, rewardsSelector } from 'selectors/selectors'
import ChallengeComponent from './Challenge.component'

function ChallengesComponent() {
  const user = useRecoilValue(connectedWallet)
  const [challenges, setChallenges] = useState<AssignedChallenge[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const refreshBalances = useRecoilRefresher_UNSTABLE(profileBalancesSelector)
  const refreshRepository = useRecoilRefresher_UNSTABLE(rewardsSelector)

  const [modalMessage, setModalMessage] = useState<string>('')
  const handleClickOutside = () => {
    setModalMessage('')
  }

  const combineChallengeReward = (
    assignedChallengesWithoutRewards: AssignedChallenge[],
    rewards: Reward[],
    ticketsCatalog: TournamentTicket[],
  ) => {
    const newAssignedChallenges = assignedChallengesWithoutRewards.map((assignedChallengeWithoutRewards) => {
      const rewardsChallenge = rewards.filter((e) => e.originId === assignedChallengeWithoutRewards.id)
      const newRewards = assignedChallengeWithoutRewards.rewards.map((r) => {
        const nameToDisplay = resolveName(r, ticketsCatalog)
        return { ...r, nameToDisplay }
      })
      return {
        ...assignedChallengeWithoutRewards,
        rewards: newRewards,
        rewardId: rewardsChallenge.map((r) => r.id),
        wasClaimed: rewardsChallenge.length > 0 && rewardsChallenge.every((r) => r.state === 'CLAIMED'),
      }
    })
    return newAssignedChallenges
  }

  const sortChallenges = (a: AssignedChallenge, b: AssignedChallenge) => {
    const cumulativeOrder = (x: AssignedChallenge) => {
      let accum = 0
      accum += (x.sumAccumulators === x.condition) ? 1 : -1
      accum += (!x.wasClaimed) ? 1 : -1
      accum += (x.sumAccumulators > 0) ? 0.5 : -1
      if (x.state === 'IN_PROGRESS') accum += 1
      if (x.state === 'NOT_STARTED') accum += 0.5
      if (x.state === 'COMPLETED') accum += 0.3
      return accum
    }
    return cumulativeOrder(b) - cumulativeOrder(a)
  }

  const getAssignedChallenges = (showLoading?: boolean) => {
    if (showLoading) setLoading(true)
    Promise.all([
      ChallengesRepository.getAssignedChallenges(user?.address ?? ''),
      RewardRepository.getByUserAddress(user?.address, 'CHALLENGE'),
      TournamentTicketRepository.get('all'),
    ])
      .then((result) => {
        setChallenges(combineChallengeReward(result[0], result[1], result[2]))
      })
      .finally(() => setLoading(false))
  }

  const claimReward = (rewardsId: string[], challengeId: string) => {
    if (rewardsId.length === 0) setModalMessage('The Reward is still processing. Please try again later.')
    setLoading(true)
    const claiming = rewardsId.map((id) => RewardRepository.claimReward(id.toString(), user?.address))
    Promise.any(claiming)
      .then(() => {
        setChallenges(
          challenges.map((a) => (a.id === challengeId ? { ...a, wasClaimed: true } : a)),
        )
        refreshRepository()
        refreshBalances()
      })
      .catch(() => {
        // setErrorClaiming(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const modal = (
    <Modal isActive={modalMessage !== ''} closeAction={handleClickOutside} id="modal">
      <div>
        <h2>ATTENTION!</h2>
        <br />
        <div className='modal-message'>
          {modalMessage}
        </div>
        <div className='modal-button'>
          <Button variant={'outline'} size={'small'} onClick={(event) => {
            event.preventDefault()
            setModalMessage('')
            handleClickOutside()
          }} >Close</Button>
        </div>
      </div>
    </Modal>
  )

  useEffect(() => {
    getAssignedChallenges(true)
  }, [])

  if (loading) return <LoaderFallback text={'Please wait ...'} />
  setLoader(false)

  return <div className='assigned-challenges'>
    <BackgroundBrands placement={'top'} />
    <div className='assigned-challenges__header'>
      <span>{'< < <  CHALLENGES  > > >'}</span>
    </div>
    {challenges
      .sort(sortChallenges)
      .map((challenge) => (
        <ChallengeComponent key={challenge.id} challenge={challenge} claimReward={claimReward} />
      ))}
      { modal }
  </div>
}

export default ChallengesComponent
