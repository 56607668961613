/* eslint-disable */
import {
  useCallback, memo, useRef, useState,
} from 'react'
import './WelcomeModal.scss'
import classNames from 'classnames'
import { useRecoilValueLoadable } from 'recoil'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { profileSelector } from 'selectors/selectors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { getSessionData, sessionExpired } from 'infraestructure/repositories/sessionVerifier.repository'
import { ProfileRepository } from 'repositories/profile.repository'

const Modal = ({ closeAction }: { closeAction: any }) => {
  const self: any = useRef()
  if (!self.current) self.current = { id: `${(new Date()).getTime()}`, page: 1 }

  const profile = useRecoilValueLoadable(profileSelector)
  const [page, setPage] = useState(1)
  const [acceptedTerms, setacceptedTerms] = useState<boolean>(false)
  const [isActive, setIsActive] = useState(true)

  const classNameOverlay = classNames('overlay', {
    'welcome-modal-wallet-connected': page === 1,
    'welcome-modal-profile': page === 2,
    show: isActive,
    hide: !isActive,
  })

  const nextPage = () => {
    setIsActive(false)
    setTimeout(() => {
      self.current.page = 2
      setPage(2)
      setTimeout(() => {
        setIsActive(true)
      }, 1)
    }, 100)
  }

  const closeModalBackground = () => {
    if (self.current.page === 1) nextPage()
    else {
      setIsActive(false)
      closeAction()
    }
  }

  const closeModal = useCallback(() => {
    if (self.current.page === 1) nextPage()
    else {
      setIsActive(false)
      closeAction()
    }
  }, [closeAction])

  // const keyFunction = useCallback((event) => {
  //   if(event.keyCode === 27) closeModal()
  // }, [closeModal]);

  // useEffect(() => {
  //   document.addEventListener("keydown", keyFunction, false)
  //   return () => {
  //     document.removeEventListener("keydown", keyFunction, false)
  //   };
  // });

  const toggleAccept = () => {
    setacceptedTerms(!acceptedTerms)
  }
  const acceptTYC = async () => {
    try {
      const session = getSessionData()
      await ProfileRepository.acceptTerms(session?.wallet as string, session?.token as string)
      closeModal()
    } catch (e:any) {
      alert(e.message)
    }
  }

  return (<>
    {
      page === 1 ? (
        <div className={classNameOverlay} id={self.current.id} onClick={closeModalBackground}>
          <div className="shadow" onClick={closeModal} />
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
            <div style={{
              display: 'block', position: 'absolute', top: 10, right: 50, padding: 5,
            }} className="close-btn" onClick={() => sessionExpired()}>
                <FontAwesomeIcon icon={faTimes as IconProp}/>
            </div>
              <div className="modal-box">
                <span>
                  Now that you&apos;ve connected your Wallet, you&apos;ll be<br />
                  assigned a <b>Username</b> and <b>Avatar</b> at random
                  </span>
                <br />
                <span style={{ padding: 10 }}>
                  <div style={{
                    display: 'inline-block', backgroundColor: acceptedTerms ? 'rgba(255, 92, 0, 1)' : 'transparent', border: '1px solid rgba(255, 92, 0, 1)', height: 15, width: 15, cursor: 'pointer', marginRight: 5,
                  }} onClick={() => { toggleAccept() }} ></div>
                    <span>I have read the <a href="terms-and-conditions">Terms and conditions</a></span>
                </span>
                <button className={`btn btn-primary btn-medium ${acceptedTerms ? '' : 'btn-disabled'}`} onClick={acceptTYC} disabled={!acceptedTerms}>Claim username & avatar</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNameOverlay} id={self.current.id} onClick={closeModalBackground}>
          <div className="shadow" onClick={closeModal} />
          <div className="modal">
            <div className="modal-content">
              <div className="avatar" style={{ backgroundImage: profile.state === 'hasValue' && profile.contents?.info?.avatar ? `url(${profile.contents?.info?.avatar})` : undefined }} />
              <div className="modal-box">
                <b style={{ textTransform: 'uppercase' }}>{profile.state === 'hasValue' && profile.contents?.info?.name ? profile.contents?.info?.name : ''}</b>
                <br />
                <button className="btn btn-primary btn-medium" onClick={closeModal}>Claim</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </>)
}
export default memo(Modal)
