import './profile.component.scss'
// eslint-disable-next-line camelcase
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  // eslint-disable-next-line camelcase
  useRecoilValueLoadable, useRecoilState, useRecoilRefresher_UNSTABLE, atom,
} from 'recoil'
import { profileSelector } from 'selectors/selectors'
import { useEffect, useState } from 'react'
import { ProfileRepository } from 'repositories/profile.repository'
import Modal from 'components/Modal/Modal.component'
import Flex from 'components/Flex/Flex'
import { Box } from 'components/Box/box.component'
import ReactECharts from 'echarts-for-react'
import Button from '../Button/Button.component'

export const showSetNameState = atom({
  key: 'showProfileSetName',
  default: false,
})

function getChartOptions(wr: any, labelText: string) {
  let colors = [[0.25, '#c16e48'], [0.5, '#D3D3D3'], [0.75, 'gold'], [1, '#05dbf3']]

  let daysValid = false
  const daysFromLastUpdate = (new Date().getTime() - new Date(wr.upadted).getTime()) / (1000 * 3600 * 24)
  if (wr.id === '1 day' && daysFromLastUpdate <= 1) daysValid = true
  if (wr.id === '1 week' && daysFromLastUpdate <= 7) daysValid = true
  if (wr.id === '1 month' && daysFromLastUpdate <= 30) daysValid = true
  if (wr.id === '100 matches' && wr.played >= 100) daysValid = true
  // console.log(wr.id, wr.winrate, wr.played, daysFromLastUpdate, daysValid)

  if (wr.played < 5 || !daysValid) colors = [[1, '#CCC']]
  return {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        center: ['50%', '60%'],
        radius: '100%',
        min: 0,
        max: 100,
        axisLine: {
          lineStyle: {
            width: 4,
            color: colors,
          },
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: '12%',
          width: 20,
          offsetCenter: [0, '-60%'],
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          length: 5,
          lineStyle: {
            color: 'auto',
            width: 1,
          },
        },
        title: {
          offsetCenter: [0, '-10%'],
          fontSize: 16,
          color: 'white',
        },
        detail: {
          fontSize: 32,
          offsetCenter: [0, '-36%'],
          valueAnimation: true,
          color: 'inherit',
          formatter: '{value}%',
        },
        data: [
          {
            value: (wr.played >= 5) ? (wr.winrate * 100).toFixed(2) : 0,
            name: labelText,
          },
        ],
      },
    ],
  }
}

const defaultWR = {
  id: '0',
  winrate: 0,
  played: 0,
  updated: new Date(),
}

const chartContainerStyle = { height: '200px', backgroundColor: 'black' }
const chartStyle = { height: '280px' }

export default function ProfileComponent() {
  const profile = useRecoilValueLoadable(profileSelector).contents
  const [showSetName, setShowSetName] = useRecoilState(showSetNameState)
  const [name, setName] = useState(profile?.info?.name.split('#')[0] || '')
  const [newName, setNewName] = useState(profile?.info?.name.split('#')[0] || '')

  const refreshProfile = useRecoilRefresher_UNSTABLE(profileSelector)

  useEffect(() => {
  }, [])

  const showNameEdit = () => {
    setNewName(name || '')
    setShowSetName(true)
  }

  const handleClickOutside = () => {
    setShowSetName(false)
  }

  const validateNameInput = (e: any) => {
    if (!/^[0-9a-zA-Z ]+$/.test(e.target.value.trimStart())) {
      const regex = /[^A-Za-z0-9 ]/g
      e.target.value = e.target.value.trimStart().replace(regex, '')
    }
    if (!(e.target.value.trimStart().length >= 3 && e.target.value.trimStart().length <= 10)) {
      e.target.setCustomValidity('Minimum 3 - Maximum 10 characters in your name, no special characters.')
    }
    e.target.setCustomValidity('')
    setNewName(e.target.value.trimStart())
  }

  const save = async () => {
    setShowSetName(false)
    if (!(newName.length >= 3 && newName.length <= 10)) {
      return
    }
    ProfileRepository.updateName(profile.id, newName)
      .then(() => {
        setName(newName)
        setNewName('')
        refreshProfile()
      })
  }

  return (
    <div className='profile-container'>
      <div className='profile-title-header'>
        <div className='title'>PROFILE</div>
      </div>
      <div className='profile'>
        <Flex flexDirection='row' gap="10px" justifyContent='center'>
          <Box width='220px'>
            <Flex flexDirection='column' gap='10px' justifyContent='center'>
              <div className='avatar-box'>
                {profile?.info?.avatar
                  ? <div className='avatar-img' style={{ backgroundImage: `url(${profile.info.avatar})` }} />
                  : <FontAwesomeIcon icon={faUserCircle as IconProp} size="3x" className="user-avatar-desktop" />}
              </div>
              <div className='name-container'>
                <div className="user-name">{name || 'PLAYER'}</div>
              </div>
              <Button variant={'primary_alt'} size={'medium'} onClick={showNameEdit}>Edit</Button>
              <div>
                {/* Played Games: {profile?.info?.played || 0} */}
              </div>
            </Flex>
          </Box>
          <Box width='50vw'>
            <h1>WINRATES</h1>
            <p style={{ textAlign: 'center' }}>You need to play at least 5 matches for your winrate to be calculated.</p>
            {Object.keys(profile?.info.winrates).length > 0 && <Flex flexDirection='row' gap="0px">
              <Box width='50%' style={chartContainerStyle}>
                <ReactECharts
                  option={getChartOptions(profile?.info?.winrates['1 day'], '1-day')}
                  notMerge={true}
                  style={chartStyle}
                  lazyUpdate={true}
                />
              </Box>
              {Object.keys(profile?.info.winrates).length > 0 && <Box width='50%' style={chartContainerStyle}>
                <ReactECharts
                  option={getChartOptions(profile?.info?.winrates['1 week'] || defaultWR, '7-day')}
                  notMerge={true}
                  style={chartStyle}
                  lazyUpdate={true}
                />
              </Box>}
            </Flex>}
            <Flex flexDirection='row' gap="0px">
              {Object.keys(profile?.info.winrates).length > 0 && <Box width='50%' style={chartContainerStyle}>
                <ReactECharts
                  option={getChartOptions(profile?.info?.winrates['1 month'] || defaultWR, '30-day')}
                  notMerge={true}
                  style={chartStyle}
                  lazyUpdate={true}
                />
              </Box>}
              {Object.keys(profile?.info.winrates).length > 0 && <Box width='50%' style={chartContainerStyle}>
                <ReactECharts
                  option={getChartOptions(profile?.info?.winrates['100 matches'] || defaultWR, 'Last 100 matches')}
                  notMerge={true}
                  style={chartStyle}
                  lazyUpdate={true}
                />
              </Box>}
            </Flex>
            {Object.keys(profile?.info.winrates).length > 0 && <div style={{ textAlign: 'center', color: 'white', lineHeight: '30px' }}>
              <h2>SHARE YOUR WINRATES!</h2>
              <p>Share your winrates with your friends and enemies!</p>
              <p>Take a screenshot and share it at our <strong>Show off</strong> channel at Discord</p>
              <p><a href="https://discord.gg/KAVBzhXYJ3" target='_blank' rel="noreferrer" style={{ color: 'orange' }}> Deviants Factions Discord Server</a></p>
            </div>}
          </Box>
        </Flex>
      </div>
      <Modal isActive={showSetName} closeAction={handleClickOutside} id="modal">
        <div className="search-bar">
          <h2>ENTER YOUR NAME</h2>
          {
            newName != null ? (
              <input type="text" placeholder="Enter name here" minLength={3} maxLength={10} pattern={'[a-zA-Z0-9 ]+'} onChange={validateNameInput} value={newName} onKeyUpCapture={(event) => {
                event.preventDefault()
                if (event.key === 'Enter') {
                  save()
                }
              }
              } />
            ) : <></>
          }
          <p><strong>Min 3 - Max 10 characters</strong> in your name, no special characters.</p>
          {/* <p>You can only change your profile name once every 30 days</p> */}

          {/* <div className='editdeck-actions' style={{ width: '100%' }}> */}
          <Flex flexDirection='row' justifyContent='center' gap="10px" style={{ marginTop: '10px' }}>
            <Button variant={'primary_alt'} style={{ backgroundColor: 'gray' }} size={'small'} onClick={(event) => {
              event.preventDefault()
              setNewName('')
              handleClickOutside()
            }} >Cancel</Button>
            <Button variant={'primary_alt'} size={'small'} onClick={save}>Save</Button>
          </Flex>
        </div>
        {/* </div> */}

      </Modal>
    </div >
  )
}
