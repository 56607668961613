import './footer.scss'
import { NavHashLink } from 'react-router-hash-link'
import fingerprint from './assets/footer-divider.png'
import leftHolder from './assets/footer-left.png'
import rightHolder from './assets/footer-right.png'

type FooterType = {
 id?: string
}

export default function Footer({
  id,
}: FooterType) {
  return (
    <footer className="footer" id={id}>
    <div className=" footer-wrapper">
      <div className="footer-divider-left"/>
      <span className="footer-line left"/>
      <img src={fingerprint} alt="" className="fingerprint" />
      <span className="footer-line right"/>
      <div className="footer-divider-right"/>
      <div className="footer-logo"/>
      <div className="footer-about">
        <h6 className="footer-title">About</h6>
        <ul>
          <li><a target="_blank" href="/pdf/LITEPAPER_DEVIANTS_FACTIONS_2024.pdf" rel="noreferrer" id="footer-litepaper">Litepaper</a></li>
          <li><a target="_blank" href="https://medium.com/@deviantsnft/a-bridge-to-immutable-x-4830f475b9fa" rel="noreferrer" id="footer-project-info">Project Info</a></li>
          <li><a target="_blank" href="https://deviantsnft.medium.com/the-lore-of-deviants-factions-2cba353ee5ee" rel="noreferrer" id="footer-factions-info">Factions Info</a></li>
        </ul>
      </div>
      <div className="footer-customer-service">
        <h6 className="footer-title">Customer Service</h6>
        <ul>
          <li><a target="_blank" href="https://discord.gg/D6MN4DFP4n" rel="noreferrer" id="footer-discord-channel">Discord support channel</a></li>
          <li><a target="_blank" href="https://cerebralgames.freshdesk.com/support/tickets/new" rel="noreferrer" id="footer-contact-us">Contact us</a></li>
        </ul>
      </div>
      <div className="footer-policies">
        <h6 className="footer-title">Policies</h6>
        <ul>
          <li><a href="https://cerebralgames.freshdesk.com/support/solutions/103000014311" rel="noreferrer" target="_blank" id="footer-faqs">FAQS</a></li>
          <li>
            <NavHashLink to="/privacy">
              Privacy
            </NavHashLink>
          </li>
          <li>
            <NavHashLink to="/terms-and-conditions">
              Terms and conditions
            </NavHashLink>
          </li>
        </ul>
      </div>
      <div className="footer-social-networks">
        <h6 className="footer-title">Social Network</h6>
        <div className="footer-social-networks-list">
          <a target="_blank" className="btn-shadow" href="https://twitter.com/DeviantsNFT/" rel="noreferrer" >
            <div className="btn btn-icon btn-small btn-left">
              <span className="footer-icon icon-twitter" id="footer-twitter"/>
            </div>
          </a>
          <a target="_blank" className="btn-shadow" href="https://discord.gg/D6MN4DFP4n" rel="noreferrer" >
            <div className="btn btn-icon btn-small btn-right">
              <span className="footer-icon icon-discord" id="footer-discord"/>
            </div>
          </a>
          <a target="_blank" className="btn-shadow" href="https://t.me/deviantsNFT" id="footer-telegram" rel="noreferrer">
            <div className="btn btn-icon btn-small btn-left">
              <span className="footer-icon icon-telegram" id="footer-telegram"/>
            </div>
          </a>
          <a target="_blank" className="btn-shadow" href="https://deviantsnft.medium.com/" id="footer-medium" rel="noreferrer">
            <div className="btn btn-icon btn-small btn-right">
              <span className="footer-icon icon-medium" id="footer-medium"/>
            </div>
          </a>
        </div>
      </div>
      </div>
    <div className="footer-holder">
      <img src={leftHolder} className="footer-holder-left" alt="" />
      <span className="footer-holder-fill">
        <p className="copyright">© Deviants’ Factions {(new Date()).getFullYear()}</p>
      </span>
      <img src={rightHolder} className="footer-holder-right" alt="" />
    </div>
  </footer>
  )
}
