/* eslint-disable no-use-before-define */
import { flattenRewards } from 'components/Achievements/achievement.component'
import ProgressBarComponent from 'components/ProgressBar/progressBar.component'
import { AssignedChallenge } from 'domain/challenges.model'
import moment from 'moment'
import { ReactElement, useEffect, useState } from 'react'
import './challenges.component.scss'

export default function ChallengeComponent({ challenge, claimReward }: ChallengeComponentProps) {
  return <div className='challenge__item'>
    <div className="reward__content-img">
      <img className="reward__img" src="/images/MOSHED.png" alt="reward" />
      <span className="reward__line"></span>
    </div>
    <div className='challenge__item__body'>
      <div className='challenge-item-title'>
        <div>
          <span className='challenge-text-title'>{challenge.name}</span>
          <span className='challenge-text-subtitle'>[{challenge.description}]</span>
        </div>
        <div className='challenge-step-counter'>{challenge.sumAccumulators}/{challenge.condition}</div>
      </div>
      <div className='challenge-item-progress'>
        <ProgressBarComponent total={challenge.condition} progress={challenge.sumAccumulators} />
      </div>
      <TimeLeftTimer endDate={challenge.endDate} />
    </div>
    <ChallengeRewardClaim challenge={challenge} claimReward={claimReward} />
  </div>
}

const ChallengeRewardClaim = ({ challenge, claimReward }: { challenge: AssignedChallenge, claimReward: any }) => {
  const { sumAccumulators, condition, wasClaimed } = challenge
  return <div className='challenge-rewards'>
    <span className="reward__name">{flattenRewards(challenge.rewards)}</span>
    {!challenge.wasClaimed
        && condition === sumAccumulators
        && <div className="btn-convert btn btn-primary" onClick={() => claimReward(challenge.rewardId, challenge.id)}>Claim Reward</div>}
    {(!wasClaimed && condition !== sumAccumulators)
       && <div className="btn-convert btn disabled">Claim Reward</div>}
    {wasClaimed && <div className="btn-convert btn disabled">Claimed</div>}
  </div>
}

export const TimeLeftTimer = ({ endDate }: { endDate: string }) => {
  const [timeleft, setTimeleft] = useState<any>(undefined)

  useEffect(() => {
    updateTime()
    const interval = setInterval(() => {
      updateTime()
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  const updateTime = () => {
    const daysLeft = moment(endDate).diff(moment(), 'days')
    const hoursLeft = moment(endDate).subtract(daysLeft, 'days').diff(moment(), 'hours')
    const minutesLeft = moment(endDate)
      .subtract(daysLeft, 'days').subtract(hoursLeft, 'hours')
      .diff(moment(), 'minutes')
    setTimeleft(styleTime(`${daysLeft}d${hoursLeft}h${minutesLeft}m`))
  }

  const styleTime = (s: string) => {
    const styledTime: ReactElement[] = [];
    [...s].forEach((letter: string, i: number) => {
      if (!/\d/.test(letter)) styledTime.push(<span key={i} style={{ fontFamily: 'Rajdhani', fontWeight: 600, opacity: 0.5 }}>{letter}</span>)
      else styledTime.push(<span key={i} style={{ fontWeight: 700, fontFamily: 'Tomorrow' }}>{letter}</span>)
    })
    return styledTime
  }
  return <div className='challenge-time-left'>
    {timeleft}
  </div>
}

export type ChallengeComponentProps = {
  challenge: AssignedChallenge,
  claimReward: any
}
