import { CraftingService } from 'domain/services/crafting.service'
import ApiCraftingService from './api-crafting.service'
import MockCraftingService from './mock-crafting.service'

export default function getCraftingService(isMock?: boolean): CraftingService {
  if (isMock) {
    return new MockCraftingService()
  }
  return new ApiCraftingService()
}
