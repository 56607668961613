import './feat-card.scss'

export type CardType = {
  image: string;
  name: string;
  variant: 'HQ' | 'CHAR' | 'TECH';
}

export function FeatCard({
  image,
  variant,
  name,
}: CardType) {
  return (
  <div className="card_container">
    <img alt={name} className={`card card-${variant}`} src={image} />
  </div>
  )
}
