import './investors.scss'

import Ri01 from './assets/logos/Ri01.png'
import Ri03 from './assets/logos/Ri03.png'
import R06 from './assets/logos/Ri010-MOB.png'
import R07 from './assets/logos/Ri011-MOB.png'
import R08 from './assets/logos/Ri012-MOB.png'
import R05 from './assets/logos/Ri013-MOB.png'
import Rivc from './assets/logos/ivc.png'
import Ryggsea from './assets/logos/yggsea.png'

export default function Investors() {
  return (
  <div className="investors-section" >
    <div className="investors-section-list">
      <a target="_blank" href="https://www.animocabrands.com/" rel="noreferrer" className="investors-section-brand">
        <img src={R06} alt="Animoca Brands" id="partner-animoca"/>
      </a>
      <a target="_blank" href="https://www.spartangroup.io/" rel="noreferrer" className="investors-section-brand">
        <img src={R07} alt="Spartan Group" id="partner-spartan-group"/>
      </a>
      <a target="_blank" href="https://doublepeak.io/" rel="noreferrer" className="investors-section-brand">
        <img src={R08} alt="Double Peak" id="partner-doublepeak"/>
      </a>
      <a target="_blank" href="https://twitter.com/gtcapital_" rel="noreferrer" className="investors-section-brand" >
        <img src={Ri01} alt="GT Capital" id="investors-ctcapital"/>
      </a>
      <a target="_blank" href="https://dweb3.io/" rel="noreferrer" className="partners-section-brand">
        <img src={R05} alt="DWEB3" id="partner-dweb3"/>
      </a>
      <a target="_blank" href="https://www.skyvisioncapital.com/" rel="noreferrer" className="investors-section-brand" >
        <img src={Ri03} alt="Skyvision Capital" id="investors-skyvision-capital"/>
      </a>
      <a target="_blank" href="https://www.ivcrypto.io/" rel="noreferrer" className="investors-section-brand" >
        <img style={{ maxWidth: '90%' }} src={Rivc} alt="Infinity Ventures Crypto" id="infinity-ventures-crypto"/>
      </a>
      <a target="_blank" href="https://yggsea.io/" rel="noreferrer" className="investors-section-brand" >
        <img style={{ maxWidth: '90%' }} src={Ryggsea} alt="YGG Sea" id="ygg-sea"/>
      </a>
    </div>
  </div>
  )
}
