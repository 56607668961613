import './TournamentQuitModal.component.scss'
import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import CrystalModal from 'components/CrystalModal/CrystalModal.component'
import Button from 'components/Button/Button.component'
import TournamentsRepository from 'repositories/tournaments.repository'
import { connectedWallet } from 'selectors/selectors'
import ButtonLoader from 'components/ButtonLoader/ButtonLoader.component'
import { currentTournamentAtom, isShowQuitModalAtom } from '../tournamentDetail.recoil'

export default function TournamentQuitModal() {
  const [isShow, setIsShow] = useRecoilState(isShowQuitModalAtom)
  const [loadQuit, setLoadQuit] = useState(false)
  const tournament = useRecoilValue(currentTournamentAtom)
  const user = useRecoilValue(connectedWallet)

  function close() {
    setIsShow(false)
  }

  async function quit() {
    if (!(tournament && user)) return
    setLoadQuit(true)
    TournamentsRepository.quit(tournament?.id, user?.address)
      .finally(() => {
        // eslint-disable-next-line
        alert('Not implemented')
        setLoadQuit(false)
        setIsShow(false)
      })
  }

  return (
    <CrystalModal title="Wanna quit?" isActive={isShow} closeAction={close} id={'quit-tournament-modal'}>
      <div className="tournament-quit-modal">
        <div className="tournament-quit-modal__title">Leave Tournament?</div>
        <div className="tournament-quit-modal__buttons">
          <Button variant={'primary'} size={'medium'} onClick={close}>
            Go Back
          </Button>
          <Button variant={'least'} size={'medium'} onClick={quit} disabled={loadQuit}>
            Quit {loadQuit && <ButtonLoader/>}
          </Button>
        </div>
      </div>
    </CrystalModal>
  )
}
