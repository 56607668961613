/* eslint-disable */
import { CardType } from 'domain/card.model'
import { ServiceConsumer } from '../services/service-consumer/service-consumer.service'
import { getHost } from '../services/host-consumer/host-consumer.service'

export default abstract class CardsRepository {
  static async getByIds(cards: (string | undefined)[]): Promise<CardType[]> {
    const response = await ServiceConsumer.get<CardType[]>({
      url: `${getHost('cards')}/api/v1/cards?ids=${cards.join(',')}`,
    })
    return response || []
  }
  static async getAllCardsData(): Promise<CardType[]> {
    return ServiceConsumer.get({
      url: `${getHost('cards')}/api/v1/cards`,
    })
  }

  static async countAllCardsExceptHQ(): Promise<{ cards: number }> {
    return ServiceConsumer.get({
      url: `${getHost('cards')}/api/v1/cards/count`,
    })
  }
}
