import { useState } from 'react'
import ModalComponent from 'components/Modal/Modal.component'
import ExchangeItem from '../ExchangeItem.component'
import useBalance from '../../hooks/useBalance.hook'
import useTokenToDevConvertion from '../../hooks/useTokenToDevConvertion.hook'
import BuyMoreButton from './BuyMoreButton.component'
import TokenToDevExchange from '../TokenExchange/TokenToDevExchange.component'

export default function BuyEthModal() {
  const { balance: eth } = useBalance('eth')
  const [isOpen, setIsOpen] = useState(false)
  const tokenEquivalence = useTokenToDevConvertion({
    token: 'ethereum',
    amount: (eth),
  })
  return <>
    <ModalComponent closeAction={() => setIsOpen(false)} isActive={isOpen} id={'exchange-eth'}>
      <p>Exchange modal</p>
    </ModalComponent>
    <ExchangeItem>
      <img width={32} src='/images/currency/ETH.png' />
      <ExchangeItem.Title text='ETH' />
      <ExchangeItem.Value text={(eth).toString()} />
      <ExchangeItem.Equivalence text={`DEV ${tokenEquivalence.toFixed(4)}`} />
      <TokenToDevExchange token='ETH' />
      <BuyMoreButton token='ETH' />
    </ExchangeItem >
  </>
}
