import './styles.scss'

const Tooltip = ({
  children, text, down,
  enabled,
}: any) => {
  if (!enabled) return children
  return <div className="tooltip">
    {children}
    <span className={`${down && 'tooltip-bottom'} tooltiptext `}>
      {text}
    </span>
  </div>
}

export default Tooltip
