/* eslint-disable */
import Button from 'components/Button/Button.component'
import { useState } from 'react'
import { CardType } from '../../../domain/card.model'
import { ModalWithCard } from '../../deckbuilder/Modal/Modal.component'
import './card-detail.scss'

export default function CardDetailModal(props: { card: CardType | null, closeAction: () => void }) {
  const { card, closeAction } = props

  const [selectedAbility, setAbility] = useState<any>(null)
  if (!card || !card.CardNumber) return <></>

  // const image = `OTK${card.CardNumber < 100 ? '0' : ''}${card.CardNumber < 10 ? '0' : ''}${card.CardNumber}`;

  return (
    <ModalWithCard isActive={Boolean(card)} closeAction={closeAction} id={card?.Name} image={card.Art} {...card} className="card-modal">
      <div className="card-detail-in-modal">
        <div>
          <div>
            <b>
              {card?.Name}
            </b>
            <br /><br />
          </div>
          <div>
            <b>
              Type:&nbsp;
            </b>
            {card?.CardType}
          </div>
          <div>
            <b>
              Faction:&nbsp;
            </b>
            {card?.Faction}
          </div>
          <div>
            <b>
              Rarity:&nbsp;
            </b>
            {card?.Rarity}
          </div>
          {
            card?.Gender && <div>
              <b>
                Gender:&nbsp;
              </b>
              {card?.Gender}
            </div>
          }

          <div style={{ marginTop: 10 }}>
            <b>Description:</b>
            <div>{card?.Description}</div>
            <br />
          </div>
          {Number(card?.Ability?.length) > 0 ? (
            <div className={'scroll-style'} style={{
              marginTop: 20,
              maxHeight: '45vh',
              overflowY: 'scroll',
            }}>
              <b>Abilities</b>
              {card?.Ability?.map((a: any, i: number) => (<div key={`a_${i}`} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => setAbility(a)}>
                  <img src={`/images/abilities/${a.toLowerCase()}.png`} alt={''} />
                </div>
                <div>
                  <b>{a}</b>
                  <div>{getAbilityText(a)}</div>
                </div>
              </div>

              ))}
            </div>) : <></>}

        </div>
        {/* <div style={{flexDirection:'column'}}>
                    <div>des</div>
                    {card?.ability.map((a:any)=> (
                      <div style={{cursor:'pointer'}} onClick={()=> setAbility(a)}>
                      <img src={`/images/abilities/${a.toLowerCase()}.png`}/>
                      </div>

                    ))}

                  </div> */}
      </div>
      <Button
        variant={'outline'}
        size={'medium'}
        onClick={closeAction}
      >CLOSE</Button>
    </ModalWithCard>
  )
}

const getAbilityText = (ability: any) => {
  if (ability === 'BERSERK') return "Gets +X% Attack, where X% is the amount of damage received by that Character, over that Character's total HP, expressed as a percentage. When a Character with Berserk heals up to their initial HP, they die."
  if (ability === 'FRIGHTEN') return 'Whenever Frighten triggers, the frightened Character moves to the last slot, leaving their own slot empty.'
  if (ability === 'GETS') return "A card acquires a modification to their stats or abilities. Get can be either a triggered ability or an ongoing effect. If it's a trigger, the effect is permanent. If it's an ongoing effect, that effect will cease when the card providing it leaves the playfield. "
  if (ability === 'GRIP') return 'Whenever a Character with Grip moves from one slot to another, it does so with their attached Technology. If necessary, other cards will move to make room for them.'
  if (ability === 'HEAL') return 'Whenever Heal triggers, the healed Character recovers X Health Points. A Character cannot gain HP over their initial HP.'
  if (ability === 'OBEY') return 'Whenever Obey triggers, the obeying Character exchanges places with the Character on the first lane. No other Characters move.'
  if (ability === 'RIOT') return 'Whenever Riot triggers, the rioting Character moves X slots to the specified direction. When necessary, Characters move a slot to their right to make room for the rioting Character.'
  if (ability === 'SWAP') return 'Whenever Swap triggers, swapping Characters exchange slots.'
  if (ability === 'TOUGHNESS') return 'A Character with Toughness receives X less damage whenever they are attacked. If a Character with Toughness X gets Toughness Y through any method, they consider the highest number +1.'
  return 'UNKNOWN'
}
