import { cloneElement, useState } from 'react'
import ReactDom from 'react-dom'
import Dialog from './Dialog.component'

type Props = {
  text?: string
}

export default function useConfirmDialog(props?: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const confirm = (): Promise<boolean> => new Promise((resolve) => {
    const close = () => {
      setIsOpen(false)
      const portal = document.querySelector('.confirm-dialog-overlay')
      if (portal) {
        document.body.removeChild(portal)
      }
    }

    setIsOpen(true)
    const portal = document.createElement('div')
    portal.className = 'confirm-dialog-overlay'
    document.body.appendChild(portal)
    ReactDom.render(cloneElement(Dialog({
      onConfirm: () => {
        resolve(true)
        close()
      },
      onCancel: () => {
        resolve(false)
        close()
      },
      text: props?.text,
    }), { key: Math.random().toString() }), portal)
  })
  return {
    isOpen,
    confirm,
  }
}
