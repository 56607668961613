import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useEffect, useState } from 'react'
import './AdeptModal.scss'
import cookie from 'cookiejs'
import Button from 'components/Button/Button.component'
import Checkbox from 'components/Checkbox/Checkbox.component'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import {
  useRecoilValue,
} from 'recoil'
import { connectedWallet } from 'selectors/selectors'

export default function AdeptModal() {
  const [modalState, setModalState] = useState(true)
  const [showModalCheck, setShowModalCheck] = useState(false)
  const [notShowAgain, setNotShowAgain] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const wallet = useRecoilValue(connectedWallet)

  const onClose = () => {
    if (notShowAgain) cookie.set('adeptdecks', 'true', { expires: 365 })
    setModalState(false)
  }
  useEffect(() => {
    // const userSetting = localStorage.getItem('showAdeptModal')
    // if (cookie.get('adeptdecks')) {
    //   setModalState(userSetting === '1')
    // } else {
    //   setModalState(true)
    // }
  }, [])

  // const setModalStateAndSave = (state: boolean) => {
  //   localStorage.setItem('showAdeptModal', state ? '0' : '1')
  // }

  const handleCheckboxChange = () => {
    setShowModalCheck(!showModalCheck)
    // setModalStateAndSave(!showModalCheck)
    setNotShowAgain(!notShowAgain)
  }

  return (
    <Modal isActive={modalState} closeAction={onClose} id='adept-modal'>
      <div id='adept-modal__tools'>
        <div className='close btn btn-small btn-primary' onClick={() => onClose()}>&#x2715;</div>
      </div>
      <img src='/images/newformat.gif' alt='adept decks' height={'400px'} />
      <div id='adept-modal__buttons'>
          <Button variant='primary' size='medium' disabled={waiting} onClick={async () => {
            if (wallet) {
              setWaiting(true)
              await DeckRepository.claimAdeptDecks(wallet.address)
              setModalState(false)
              cookie.set('adeptdecks', 'true', { expires: 365 })
              window.location.reload()
              // console.log(result)
            }
          }}>{(waiting) ? '...' : 'Claim Adept Decks'}</Button>
      </div>
      <Checkbox onClick={() => handleCheckboxChange()} text="Do not show this again" checked={showModalCheck} disabled={waiting} />
    </Modal>)
}
