// @ts-ignore
import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import getConfig from '../config.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

export default class MinterService {
  // eslint-disable-next-line max-len
  public static async register(boosterType: string, amount: number, paymentId: string, userAddress: string, referral? : string)
  : Promise<{message: string}> {
    const config = getConfig()
    const token = ValidateIfTokenIsPresent()
    const requestData : ServiceData = {
      url: `${config.hostURL}/api/v1/minter/purchases`,
      body: {
        purchase: {
          paymentId: paymentId.toString(),
          type: boosterType,
          quantity: amount,
          wallet: userAddress,
          tokenType: 'booster',
          referral,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<{message: string}>(requestData)
    return response
  }
}
