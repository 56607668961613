/* eslint-disable max-len */
import './views.scss'
import Footer from 'components/Footer/Footer.component'
import icolinkedin from '../assets/icons/PC/ico-linkedin.svg'
import icotwitter from '../assets/icons/PC/ico-twitter.svg'
import icobehance from '../assets/icons/PC/ico-behance.svg'
import icogithub from '../assets/icons/PC/ico-github.svg'

export default function ProjectInfoView() {
  return (
    <div className="project-info">
      <h1 className="project-info-title">Project Info</h1>
      <div className="project-info-section">
        <h2 className="project-info-section-title"> How deviants factions started</h2>
        <div className="project-info-section-content">
        <img src="https://picsum.photos/id/237/300/100" alt="" className="project-info-section-content-picture" />
        <p className="project-info-section-content-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
      </div>

      <div className="project-info-section">
        <h2 className="project-info-section-title"> How deviants factions started</h2>
        <div className="project-info-section-content reversed">
        <img src="https://picsum.photos/id/237/300/100" alt="" className="project-info-section-content-picture" />
        <p className="project-info-section-content-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
      </div>

      <h2 className="the-team">
        The Team
      </h2>
      <div className="team-list">

        <div className="team-list-profile-box">
          <img src="https://picsum.photos/id/237/200/300" alt="" className="profile-picture" />
          <p className="name">name</p>
          <div className="rs-list">
            <a className="linkedin">
              <img src={icolinkedin} alt="" />
            </a>
            <a className="twitter">
              <img src={icotwitter} alt="" />
            </a>
            <a className="github">
              <img src={icogithub} alt="" />
            </a>
            <a className="behance">
              <img src={icobehance} alt="" />
            </a>
          </div>
        </div>

        <div className="team-list-profile-box">
          <img src="https://picsum.photos/id/237/200/300" alt="" className="profile-picture" />
          <p className="name">name</p>
          <div className="rs-list">
            <a className="linkedin">
              <img src={icolinkedin} alt="" />
            </a>
            <a className="twitter">
              <img src={icotwitter} alt="" />
            </a>
            <a className="github">
              <img src={icogithub} alt="" />
            </a>
            <a className="behance">
              <img src={icobehance} alt="" />
            </a>
          </div>
        </div>

        <div className="team-list-profile-box">
          <img src="https://picsum.photos/id/237/200/300" alt="" className="profile-picture" />
          <p className="name">name</p>
          <div className="rs-list">
            <a className="linkedin">
              <img src={icolinkedin} alt="" />
            </a>
            <a className="twitter">
              <img src={icotwitter} alt="" />
            </a>
            <a className="github">
              <img src={icogithub} alt="" />
            </a>
            <a className="behance">
              <img src={icobehance} alt="" />
            </a>
          </div>
        </div>

        <div className="team-list-profile-box">
          <img src="https://picsum.photos/id/237/200/300" alt="" className="profile-picture" />
          <p className="name">name</p>
          <div className="rs-list">
            <a className="linkedin">
              <img src={icolinkedin} alt="" />
            </a>
            <a className="twitter">
              <img src={icotwitter} alt="" />
            </a>
            <a className="github">
              <img src={icogithub} alt="" />
            </a>
            <a className="behance">
              <img src={icobehance} alt="" />
            </a>
          </div>
        </div>

        <div className="team-list-profile-box">
          <img src="https://picsum.photos/id/237/200/300" alt="" className="profile-picture" />
          <p className="name">name</p>
          <div className="rs-list">
            <a className="linkedin">
              <img src={icolinkedin} alt="" />
            </a>
            <a className="twitter">
              <img src={icotwitter} alt="" />
            </a>
            <a className="github">
              <img src={icogithub} alt="" />
            </a>
            <a className="behance">
              <img src={icobehance} alt="" />
            </a>
          </div>
        </div>

        <div className="team-list-profile-box">
          <img src="https://picsum.photos/id/237/200/300" alt="" className="profile-picture" />
          <p className="name">name</p>
          <div className="rs-list">
            <a className="linkedin">
              <img src={icolinkedin} alt="" />
            </a>
            <a className="twitter">
              <img src={icotwitter} alt="" />
            </a>
            <a className="github">
              <img src={icogithub} alt="" />
            </a>
            <a className="behance">
              <img src={icobehance} alt="" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
