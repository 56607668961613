import { useRecoilValueLoadable } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import ExchangeItem from './ExchangeItem.component'
import useDevConvertion from '../hooks/useDevConvertion.hook'
import WithdrawDevModal from './DevExchange/WithdrawDevModal.component'

export default function InGameDev() {
  const inGameDev = useRecoilValueLoadable(profileBalancesSelector).contents?.xdev || 0
  const usd = useDevConvertion(inGameDev)
  return <ExchangeItem>
    <img width="32px" src='/images/currency/icon-dev-new.png' />
    <ExchangeItem.Title text='IN GAME' />
    <ExchangeItem.Value text={inGameDev} />
    <ExchangeItem.Equivalence text={`${usd.toFixed(4)} $`} />
    <WithdrawDevModal />
  </ExchangeItem>
}
