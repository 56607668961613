import { useState } from 'react'
import Button from 'components/Button/Button.component'
import Flex from 'components/Flex/Flex'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import LayerSwapBuyLink from './LayerSwapBuyLink.component'
/* import CreditCardBuyLink from './CreditCardLink.component' */

export default function BuyMoreButton({ token }: { token: string }) {
  const [isOpen, setIsOpen] = useState(false)
  return <>
    <Modal id={`buy-more-modal-${token}`} isActive={isOpen} closeAction={() => setIsOpen(false)}>
      <Flex flexDirection='row' gap="1ch" alignItems='center'>
        <h1>ADD FUNDS ({token})</h1>
        <img src={`/images/currency/${token}.png`} width={32} height={32} />
      </Flex>
      <Flex flexDirection='column' gap="1ch" style={{ width: '400px' }}>
        <LayerSwapBuyLink token={token.toUpperCase()} />
        {/* <CreditCardBuyLink /> */}
      </Flex>
    </Modal>
    <Button size='medium' variant='primary_alt' onClick={() => setIsOpen(true)}>ADD FUNDS</Button>
  </>
}
