import { Box } from 'components/Box/box.component'
import Flex from 'components/Flex/Flex'

type AirdropStatsProps = {
  title: string
  xp: number
}

export default function AirdropStatsItem(props: AirdropStatsProps) {
  const { title, xp } = props
  return <Box>
  <Flex flexDirection='row' gap='5px'>
    <div style={{ width: '70%', fontSize: '12px' }}>
    { title }
    </div>
    <div style={{ width: '30%', textAlign: 'right' }}>
      { xp } <span style={{ fontSize: '14px', color: 'grey' }}>XP</span>
    </div>
  </Flex>
</Box>
}
