import './TournamentRules.component.scss'
import InfoHex from 'assets/icons/infohexagon.svg'
import { useRecoilState, useRecoilValue } from 'recoil'
// import Button from 'components/Button/Button.component'
import {
  currentTournamentAtom, isShowStageInfoModalAtom, selectedStageAtom,
  // isShowQuitModalAtom,
} from '../tournamentDetail.recoil'

export default function TournamentRules() {
  const tournament = useRecoilValue(currentTournamentAtom)
  const [, setIsShowStageInfoModal] = useRecoilState(isShowStageInfoModalAtom)
  const [, setSelectedStage] = useRecoilState(selectedStageAtom)
  // const [, setIsShowQuitModal] = useRecoilState(isShowQuitModalAtom)

  function showInfo(stage: any) {
    setSelectedStage(stage)
    setIsShowStageInfoModal(true)
  }

  // function showQuitModal() {
  //   setIsShowQuitModal(true)
  // }

  function traslateWinningType(winningType: string):string {
    if (winningType === '1d1') return 'Bo1'
    if (winningType === '2d3') return 'Bo3'
    if (winningType === '3d5') return 'Bo5'
    return 'Bo1'
  }

  return (
    <div className="tournament-rules">
      <div className="tournament-rules__title">Tournament Rules</div>
      <div className="tournament-rules__content">
        {tournament?.stages?.map((stage) => (
        <div className="tournament-rules__content__item" key={`stage-${stage.format}`}>
          <div className="tournament-rules__content__item__title">{stage.format}</div>
          <div className="tournament-rules__content__item__content">
            <div className="tournament-rules__content__item__content__win-mode">
              {traslateWinningType(stage.winningType)}
            </div>
            <div className="tournament-rules__content__item__content__info" onClick={() => showInfo(stage)}>
              info<img src={InfoHex} />
            </div>
          </div>
        </div>))}
        {/* <Button variant={'least'} size={'medium'} onClick={showQuitModal}>Quit Tournament</Button> */}
      </div>
    </div>
  )
}
