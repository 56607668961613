import { AddressZero } from '@ethersproject/constants'
import { Link, ERC721TokenType } from '@imtbl/imx-sdk'
import {
  Transfer,
  Asset as ImxAsset,
  ImmutableX,
} from '@imtbl/core-sdk'
import Asset from 'domain/asset.model'
import getConfig from 'config.service'
import ProviderService from '../../../domain/services/provider.service'

const signatureDescription = 'Hello there. You are going to sign with DF now'

type BatchNftTransferResult = {
  type: string,
  toAddress: string,
  tokenId: string,
  tokenAddress: string,
  status: string,
  txId: number
}
export default class ImxProvider extends ProviderService {
  private client: ImmutableX

  constructor() {
    const config = getConfig()
    const link = new Link(config.LINK_ADDRESS)
    super(link)
    this.client = new ImmutableX(config.IMX_CONFIG)
  }

  async sign(message: string): Promise<any> {
    const signature = await this.provider.sign({ message, description: signatureDescription })
    return signature
  }

  async login(): Promise<any> {
    const account = await this.provider.setup({ providerPreference: 'none' })
    return account
  }

  async burn(tokenAddress: string, tokenId: string): Promise<string> {
    const result: any = await this.provider.transfer([
      {
        type: ERC721TokenType.ERC721,
        tokenAddress,
        tokenId,
        toAddress: AddressZero,
      },
    ])
    return result.result[0].txId.toString()
  }

  async batchNftTransfer(assets: Asset[]): Promise<BatchNftTransferResult[]> {
    const p = this.provider as Link
    const nftsToTransfer = assets.map((asset) => ({
      type: ERC721TokenType.ERC721,
      tokenAddress: asset.getTokenAddress(),
      tokenId: asset.getId(),
      toAddress: AddressZero,
    }))
    const { result } = await p.batchNftTransfer(nftsToTransfer) as { result: BatchNftTransferResult[] }
    return result
  }

  async getTransfer(id: string): Promise<Transfer> {
    return this.client.getTransfer({ id })
  }

  async getAsset(tokenAddress: string, tokenId: string): Promise<ImxAsset> {
    return this.client.getAsset({ tokenAddress, tokenId })
  }

  async offRamp() {
    const link = this.provider as Link
    await link.onramp({ })
  }
}

export const imxProviderInstance = new ImxProvider()
