export default function EntropySvg() {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px"
        viewBox="0 0 640 640" enableBackground="new 0 0 640 640">
        <g>
          <path fill="#FFFFFF" d="M319.7,3.8c-0.1,0.2-0.3,0.4-0.4,0.6c-19.6,49.4-39.1,98.8-58.7,148.3c-0.3,0.8-0.3,1.9,0,2.8
    c19.5,54.1,39,108.2,58.5,162.3c0.2,0.5,0.4,0.9,0.7,1.5c0.3-1,0.5-1.7,0.7-2.4c19.4-53.8,38.8-107.5,58.1-161.3
    c0.3-0.9,0.3-2.2,0-3.1C363.7,115,348.8,77.4,334,39.8C329.2,27.8,324.5,15.9,319.7,3.8z M319.7,635.7c0.3-1,0.6-1.7,0.9-2.4
    c19.3-48.8,38.6-97.6,58-146.4c0.5-1.2,0.5-2.1,0.1-3.3c-13.5-37.5-27-75-40.5-112.5c-6.1-17-12.2-33.9-18.4-50.9
    c-0.2,0.2-0.3,0.3-0.4,0.5c-19.6,54.4-39.2,108.8-58.7,163.2c-0.3,0.9-0.2,2.1,0.1,2.9c19,48.1,38.1,96.2,57.1,144.3
    C318.5,632.6,319.1,634.1,319.7,635.7z M53.2,489.5c0.9-0.1,1.3-0.1,1.8-0.2c10.4-2,20.8-4.1,31.2-6.1
    c42.3-8.3,84.6-16.5,126.8-24.7c1.6-0.3,2.6-1,3.6-2.2c33.5-44.5,67.1-88.9,100.6-133.4c0.4-0.5,0.7-1,1-1.4
    c-0.1-0.1-0.1-0.2-0.2-0.3c-51.2,24.1-102.5,48.2-153.8,72.4c-6.7-13.6-13.2-27-19.9-40.7C113.9,398.4,83.7,443.8,53.2,489.5z
     M321.4,321.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.3,0.4,0.6,0.9,1,1.3c13.1,17.3,26.1,34.6,39.2,51.9c20.6,27.3,41.2,54.6,61.8,81.9
    c0.5,0.7,1.4,1.5,2.3,1.6c15.2,3,30.4,6,45.7,9c35.2,6.9,70.4,13.7,105.6,20.6c3,0.6,6,1.1,9.5,1.8
    c-30.5-45.8-60.8-91.2-91.1-136.8c-6.7,13.6-13.3,27.1-19.9,40.7C423.8,369.3,372.6,345.2,321.4,321.1z M318.4,319.6
    c0.1-0.2,0.2-0.3,0.3-0.5c-0.4-0.5-0.8-1.1-1.2-1.6c-15.2-19.9-30.5-39.8-45.7-59.7c-18.7-24.4-37.4-48.8-56.1-73.2
    c-0.8-1.1-1.8-1.7-3.1-2c-4.2-0.8-8.4-1.8-12.6-2.7c-45.8-9.7-91.6-19.4-137.4-29.1c-2.7-0.6-5.4-1.1-8.6-1.8
    c29.8,45.3,59.3,90.2,89,135.4c2.5-14.1,5-27.7,7.5-41.6C206.7,268.5,262.6,294.1,318.4,319.6z M320.6,319.1
    c0.1,0.2,0.2,0.3,0.3,0.5c55.9-25.5,111.8-51,167.9-76.7c2.5,13.9,4.9,27.4,7.5,41.6c29.8-45.3,59.3-90.2,89-135.4
    c-1,0.2-1.6,0.3-2.1,0.4c-22.3,4.7-44.5,9.4-66.8,14.2c-29.9,6.3-59.8,12.7-89.7,19c-1.3,0.3-2.2,0.8-3,1.9
    c-34,44.4-68,88.8-102,133.2C321.2,318.1,320.9,318.6,320.6,319.1z"/>
          <path d="M319.7,3.8c4.8,12,9.5,24,14.3,36c14.9,37.6,29.8,75.1,44.6,112.7c0.4,0.9,0.4,2.2,0,3.1c-19.3,53.8-38.7,107.5-58.1,161.3
    c-0.2,0.7-0.4,1.4-0.7,2.4c-0.3-0.6-0.5-1.1-0.7-1.5c-19.5-54.1-39-108.2-58.5-162.3c-0.3-0.8-0.3-2,0-2.8
    c19.5-49.4,39.1-98.8,58.7-148.3C319.4,4.2,319.6,4,319.7,3.8z"/>
          <path d="M319.7,635.7c-0.7-1.6-1.3-3.1-1.8-4.6c-19-48.1-38.1-96.2-57.1-144.3c-0.3-0.9-0.5-2.1-0.1-2.9
    c19.5-54.4,39.1-108.8,58.7-163.2c0.1-0.2,0.2-0.3,0.4-0.5c6.1,17,12.3,34,18.4,50.9c13.5,37.5,27,75,40.5,112.5
    c0.4,1.2,0.4,2.2-0.1,3.3c-19.4,48.8-38.7,97.6-58,146.4C320.3,634,320.1,634.7,319.7,635.7z"/>
          <path d="M53.2,489.5c30.5-45.7,60.7-91.1,91.1-136.7c6.7,13.6,13.2,27,19.9,40.7c51.3-24.2,102.6-48.3,153.8-72.4
    c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0.5-0.7,1-1,1.4c-33.5,44.5-67.1,88.9-100.6,133.4c-0.9,1.3-2,1.9-3.6,2.2
    c-42.3,8.2-84.6,16.5-126.8,24.7c-10.4,2-20.8,4.1-31.2,6.1C54.5,489.4,54.1,489.4,53.2,489.5z"/>
          <path d="M321.4,321.1c51.2,24.1,102.4,48.2,153.8,72.3c6.6-13.6,13.2-27,19.9-40.7c30.4,45.6,60.6,91,91.1,136.8
    c-3.5-0.7-6.5-1.2-9.5-1.8c-35.2-6.9-70.4-13.7-105.6-20.6c-15.2-3-30.4-5.9-45.7-9c-0.8-0.2-1.7-0.9-2.3-1.6
    c-20.6-27.3-41.2-54.6-61.8-81.9c-13.1-17.3-26.1-34.6-39.2-51.9c-0.3-0.4-0.7-0.9-1-1.3C321.3,321.3,321.3,321.2,321.4,321.1z"/>
          <path d="M318.4,319.6c-55.9-25.5-111.8-51-167.9-76.7c-2.5,13.8-4.9,27.4-7.5,41.6c-29.8-45.3-59.3-90.1-89-135.4
    c3.2,0.6,5.9,1.2,8.6,1.8c45.8,9.7,91.6,19.4,137.4,29.1c4.2,0.9,8.4,1.8,12.6,2.7c1.4,0.3,2.3,0.9,3.1,2
    c18.7,24.4,37.4,48.8,56.1,73.2c15.2,19.9,30.5,39.8,45.7,59.7c0.4,0.5,0.8,1.1,1.2,1.6C318.7,319.2,318.5,319.4,318.4,319.6z"/>
          <path d="M320.6,319.1c0.3-0.5,0.6-1,1-1.4c34-44.4,68-88.8,102-133.2c0.8-1.1,1.7-1.6,3-1.9c29.9-6.3,59.8-12.7,89.7-19
    c22.3-4.7,44.5-9.4,66.8-14.2c0.6-0.1,1.1-0.2,2.1-0.4c-29.7,45.2-59.2,90.1-89,135.4c-2.5-14.1-5-27.7-7.5-41.6
    c-56.1,25.6-112,51.1-167.9,76.7C320.8,319.4,320.7,319.3,320.6,319.1z"/>
        </g>
      </svg>
    </div>
  )
}
