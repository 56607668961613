/* eslint-disable no-param-reassign,max-len */
import './card-markup.component.scss'
import { CardType as CardTypeModel } from '../../../domain/card.model'

function isBalanced(functionStr: string, l1:string, l2:string): boolean {
  const letters = functionStr.split('')
  let counter = 0
  letters.forEach((letter) => {
    if (letter === l1) counter += 1
    if (letter === l2) counter -= 1
  })
  return counter === 0
}

function splitIn(part:string, count:number, max?:number) {
  const parts:string[] = []
  for (let i = 0; i < count - 1; i += 1) {
    const mid = Math.round(part.length / (count - i))
    let pos = part.indexOf(' ', mid)
    if (pos - mid > 10) pos = part.indexOf(' ', mid - 10)
    let lastpart = part.substring(0, pos)
    if (!isBalanced(lastpart, '(', ')')) {
      pos = lastpart.lastIndexOf('(') - 1
      lastpart = part.substring(0, pos)
    }
    if (!isBalanced(lastpart, '¡', '!')) {
      pos = lastpart.lastIndexOf('¡') - 1
      lastpart = part.substring(0, pos)
    }
    if (!isBalanced(lastpart, '[', ']')) {
      pos = lastpart.lastIndexOf('[') - 1
      lastpart = part.substring(0, pos)
    }
    if (max && lastpart.length > max) {
      const lastParts = splitIn(lastpart, 2)
      parts.push(...lastParts)
    } else parts.push(lastpart)
    part = part.substring(pos + 1)
  }
  parts.push(part)
  return parts.filter((p) => p !== '')
}

function parseDescription(description:string, sizes:number[]) {
  if (!sizes) sizes = [50, 100, 160]
  let desc = description

  if (desc.toLowerCase() === 'textless') desc = ''
  else if (desc.toLowerCase() === 'promo.') desc = '<div class="desc-inside"><div class="promo"></div></div>'
  else {
    const genders:{[gender:string]:string} = {
      m: 'male',
      f: 'female',
      nb: 'non-binary',
    }
    desc = desc.substring(0, 1).toUpperCase() + desc.substring(1)
    let parts:string[] = []
    const split = desc.split('\n')
    split.forEach((part) => {
      if (split.length === 1 && part.length > sizes[1]) {
        parts.push(...splitIn(part, 3))
      } else if (part.length > sizes[0] * 2) {
        parts.push(...splitIn(part, 3))
      } else if (part.length > sizes[0]) {
        parts.push(...splitIn(part, 2))
      } else {
        parts.push(part)
      }
    })
    parts = parts.filter((p) => !!p).map((d) => {
      d = d.replace(/ ?¡(.+?)!(:?) ?/ig, (_0, _1, _2) => ` <div class="card-desc-arrow">${_1.toUpperCase()}</div>${_2} `)
      d = d.replace(/ ?\[(m|f|nb)](:?) ?/ig, (_0, _1, _2) => ` <div class="card-desc-gender"><div class="icon-gender ${genders[_1.toLowerCase()] ?? ''}"></div></div>${_2} `)
      d = d.replace(/ ?\[?(awaken|entropy|inhuman|owner)]? ?/ig, (_0, _1) => ` <div class="card-desc-faction"><div class="cd-icon-faction ${_1.toLowerCase()}"></div></div> `)
      d = d.replace(/ ?\[(.+?)](:?) ?/ig, (_0, _1, _2) => ` <div class="card-desc-step">${_1.toUpperCase()}</div>${_2} `)
      d = d.replace(/(automated|toughness|heals|heal[^a-zA-Z]|grips|grip|obeys|obey|riots|riot|berserking|berserks|berserk)([^a-zA-Z])/ig, '<b>$1</b>$2')
      return d
    })
    desc = `<div class="line">${parts.filter((p) => !!p).join('</div><div class="line">')}</div>`
  }
  return `<div class="desc-inside">${desc}</div>`
}

const sizes:any = {
  HQ: [90, 200, 320],
  Character: [55, 100, 180],
  Technology: [50, 100, 180],
}

function applyMarkup(el:HTMLElement|any, card: CardTypeModel) {
  if (!el || !card.Description) return
  el.innerHTML = parseDescription(card.Description, sizes[card.CardType])
}

export default function CardMarkupComponent(card: CardTypeModel) {
  return <div ref={(ref) => applyMarkup(ref, card)} className="description"></div>
}
