import Button from 'components/Button/Button.component'
import Flex from 'components/Flex/Flex'
import Input from 'components/Input/Input.component'
import Text from 'components/Text/Text'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useCallback, useState } from 'react'
import useBalance from 'views/exchange/hooks/useBalance.hook'
import VolExchangeRepository from 'repositories/asset-exchange.repository'
import useTokenToDevExchange from 'views/exchange/hooks/useTokenToDevExchange'
import Toast from 'components/Toast/Toast.component'
import DevReceived from './DevReceived.component'
import arrowForward from '../../assets/arrow-forward.svg'

type TokenToDevExchangeProps = {
  token: 'USDC' | 'IMX' | 'ETH' | 'GODS'
}

export default function TokenToDevExchange(props: TokenToDevExchangeProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState(0)
  const [tx, setTx] = useState<string | undefined>()
  const { balance, refresh } = useBalance(props.token.toLowerCase() as 'usdc' | 'imx' | 'eth' | 'gods')
  const { exchange } = useTokenToDevExchange({
    token: props.token,
    async onSuccess(txId: string) {
      await VolExchangeRepository.processTokenExchange(txId)
      setAmount(0)
      setTx(txId)
      setIsOpen(false)
      refresh()
    },
  })

  const isValid = useCallback(() => amount > 0 && amount <= balance, [amount])

  const setMax = () => {
    setAmount(balance || 0)
  }

  return <>
    <Toast duration={12000} id='exchange-toast' isActive={tx !== undefined} onClose={() => setTx(undefined)}>
      <Text fontSize='1.2rem' fontWeight='bold'>Transaction ID: {tx}</Text>
    </Toast>
    <Modal id={`token-modal-${props.token}`} isActive={isOpen}
      closeAction={() => setIsOpen(false)}>
      <h2>Exchange {props.token}</h2>
      <Flex flexDirection='row' gap="5px">
        <img width={'48px'} src={`/images/currency/${props.token}.png`} />
        <img width={'48px'} src={arrowForward} />
        <img width={'48px'} src={'/images/currency/XDEV.png'} />
      </Flex>
      <Flex flexDirection='column' gap="1ch">
        <Flex flexDirection='row' gap="0.5ch">
          <Text fontWeight={'bold'} fontSize='0.8rem'>Balance: {balance}</Text>
          <img width={'16px'} src={`/images/currency/${props.token}.png`} />
        </Flex>
        <Flex justifyContent='center' flexDirection='row' gap="1ch" style={{ width: '100%', height: '60px' }}>
          <Input
            step={0.1}
            value={amount}
            onChange={(e) => setAmount(e.target.valueAsNumber || 0)}
            max={balance || 0} type='number'
            placeholder='Amount'
            style={{ width: '200px', fontSize: '1.2rem', textAlign: 'center' }} />
          <Button onClick={() => setMax()} variant='ghost' size='medium' style={{ padding: '5px 25px' }}>
            MAX
          </Button>
        </Flex>
        <Text fontWeight={'bold'} fontSize='0.8rem'>You will recieve</Text>
        <Flex justifyContent='center' flexDirection='row' gap="1ch" style={{ width: '100%', height: '60px' }}>
          <DevReceived amount={amount} token={props.token} />
        </Flex>
        <Button disabled={!isValid()} variant='primary_alt' size='medium' onClick={() => exchange(amount)}>EXCHANGE</Button>
      </Flex>
      <Text fontSize={'0.8rem'}>*The exact amount will be calculated after the transaction is confirmed.</Text>
    </Modal>
    <Button disabled={balance === 0} variant='primary_alt' size='medium' onClick={() => setIsOpen(true)}>EXCHANGE</Button>
  </>
}
