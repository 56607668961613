/* eslint-disable camelcase */
// noinspection JSDeprecatedSymbols

import './TournamentDetail.view.scss'
import Footer from 'components/Footer/Footer.component'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useInterval from 'services/useInterval.hook'
import { connectedWallet } from 'selectors/selectors'
import TournamentsRepository from 'repositories/tournaments.repository'
import {
  useRecoilState, useRecoilValue,
} from 'recoil'
import moment from 'moment'
import { Tournament } from 'domain/tournament.model'
import { Match } from 'domain/match.model'
import UserRepository from 'infraestructure/repositories/users.repository'
import LoadingPage from 'components/LoadingPage/LoadingPage.component'
import { User } from 'domain/user.model'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import TournamentTimer from 'components/Timer/TournamentTimer.component'
import TournamentDetailHero from './components/TournamentDetailHero.component'
// import TournamentRewardsTable from './components/TournamentRewardsTable.component'
import TournamentMatch from './components/TournamentMatch.component'
import TournamentRanking from './components/TournamentRanking.component'
import TournamentRequirements from './components/TournamentRequirements.component'
import TournamentRounds from './components/TournamentRounds.component'
import TournamentJoinModal from './components/TournamentJoinModal.component'
import { currentTournamentAtom, isShowJoinModalAtom, tournamentUserDecksAtom } from './tournamentDetail.recoil'
import TournamentJoinSuccessModal from './components/TournamentJoinSuccessModal.component'
import TournamentRules from './components/TournamentRules.component'
import TournamentStageInfoModal from './components/TournamentStageInfoModal.component'
import TournamentQuitModal from './components/TournamentQuitModal.component'
import TournamentRewardsTable from './components/TournamentRewardsTable.component'

export type TournamentMatchData = {
  player1: string
  player2: string
  player1Deck?: string
  id: string
  state: string
  round: number
  rounds: number
  phase: number
}

function selectPlayerMatch(playerId: string | undefined, tournament: Tournament, users: User[], deck?: string)
  : TournamentMatchData | null {
  if (!playerId) return null
  if (!(tournament?.currentStage)) return null
  const { matches } = tournament.currentStage
  const match = matches?.find((m: any) => (m.playerAId === playerId || m.playerBId === playerId) && m.state !== 'ENDED')
  if (!match) return null
  const player2Id = (match.playerAId === playerId ? match.playerBId : match.playerAId)
  const player2 = users.find((u) => u.id === player2Id)?.info.name
  return {
    player1: users.find((u) => u.id === playerId)?.info.name ?? playerId?.substr(-10),
    player2: player2 ?? player2Id?.substr(-10),
    player1Deck: deck,
    id: match.id,
    state: match.state,
    round: tournament.currentStage.matches.reduce((total, m) => (total > m.round ? total : m.round), 0),
    rounds: tournament.currentStage.rounds,
    phase: tournament.currentStageIndex + 1,
  }
}

function completeTournamentWithUsers(tournament: Tournament, users?: User[]): Tournament {
  if (!users) return tournament
  const subscribedPlayers = tournament?.subscribedPlayers?.map((p: any) => {
    const usr = users.find((u) => u.id === p.playerId)
    return { ...p, name: usr?.info.name }
  })
  const players = tournament?.currentStage?.players?.map((p: any) => {
    const usr = users.find((u) => u.id === p.id)
    return { ...p, name: usr?.info.name }
  })
  return {
    ...tournament,
    currentStage: players && tournament.currentStage ? { ...tournament.currentStage, players } : undefined,
    subscribedPlayers,
  }
}

function getDeckId(tournament: Tournament, playerId: string): string | undefined {
  const player = tournament?.currentStage?.players?.find((p: any) => p.id === playerId)
  return player?.deckId
}

export default function TournamentDetailView() {
  const { id } = useParams<{ id: string }>()
  const [match, setMatch] = useState<TournamentMatchData | null>(null)
  const [tournament, setTournament] = useRecoilState(currentTournamentAtom)
  const [users, setUsers] = useState<User[]>([])
  const [isShowJoinModal, setIsShowJoinModal] = useRecoilState(isShowJoinModalAtom)
  const [deck, setDeck] = useState<string>('')
  const decks = useRecoilValue(tournamentUserDecksAtom)
  const user = useRecoilValue(connectedWallet)
  const [reloading, setReloading] = useState(false)

  function loadData(): void {
    if (tournament?.id !== id) {
      setTournament(undefined)
    }
    TournamentsRepository.getById(id)
      .then((theTournament: Tournament) => {
        const fixedTournament = completeTournamentWithUsers(theTournament, users)
        setTournament(fixedTournament)
        const theMatch = selectPlayerMatch(user?.address, fixedTournament, users, deck)
        setMatch(theMatch)
        const userIds = fixedTournament?.subscribedPlayers?.map((p: any) => p.playerId) ?? []
        if (userIds.length <= 0) return
        if (!fixedTournament?.subscribedPlayers.some((player) => !player.name)) return
        UserRepository.getByIds(userIds).then((usersR: User[]) => {
          setUsers(usersR)
          setTournament(completeTournamentWithUsers(fixedTournament, usersR))
          const matchR = selectPlayerMatch(user?.address, fixedTournament, usersR, deck)
          setMatch(matchR)
        }).catch((error: any) => {
          // eslint-disable-next-line
          console.error(error)
        })
        const deckId = getDeckId(fixedTournament, user?.address ?? '')
        if (deckId) {
          DeckRepository.getById(user!.address.toLocaleLowerCase(), deckId).then((deckR: any) => {
            setDeck(deckR.name)
            const matchR = selectPlayerMatch(user?.address, tournament!, users, deck)
            setMatch(matchR)
          })
        }
      }).catch((error: any) => {
        // eslint-disable-next-line
        console.error(error)
      })
  }

  function getTimerText(): string {
    if (!tournament) return ''
    let result = ''
    if (tournament.state === 'ANNOUNCED') result = 'Register in'
    else if (tournament.state === 'PUBLISHED') result = 'Registration ends in'
    else if (tournament.state === 'SUBSCRIPTION_CLOSED') result = 'Starts in'
    return result
  }

  function getNextTime(): moment.Moment {
    if (!tournament) return moment()

    // console.log(moment(new Date().toUTCString()).toISOString(), tournament.state)

    if ((tournament.state === 'ANNOUNCED') && (moment(tournament?.publicationDate) < moment(new Date().toUTCString())) && !reloading) {
      setReloading(true)
      loadData()
    }

    if (tournament.state === 'ANNOUNCED') return moment(tournament?.registrationCloseDate)

    // console.log('state is ', tournament.state, moment(tournament?.startDate).toISOString())
    return moment(tournament?.startDate)
  }

  useInterval(() => {
    if (['PUBLISHED', 'ONGOING', 'ANNOUNCED', 'SUBSCRIPTION_CLOSED'].includes(tournament?.state ?? '')) {
      loadData()
    }
  }, ['PUBLISHED', 'ONGOING'].includes(tournament?.state ?? '') ? 5000 : 30000)

  useInterval(() => {
    getNextTime()
  }, 1000)

  const round = tournament?.currentStage?.matches
    ?.reduce((total: number, m: Match) => (total > m.round ? total : m.round), 0) ?? 0

  useEffect(() => {
    loadData()
  }, [])

  return !tournament ? <LoadingPage /> : (
    <div className="tournament-detail">
      <TournamentDetailHero tournament={tournament} userId={user?.address} />
      {(tournament.state === 'ANNOUNCED' || tournament.state === 'PUBLISHED' || tournament.state === 'SUBSCRIPTION_CLOSED')
        && <div className='tournament-timer-container'>
          <span className='tournament-timer-startsin'>{getTimerText()} </span>
          <TournamentTimer tournamentDate={getNextTime()} />
        </div>}
      <TournamentRounds rounds={tournament.currentStage?.rounds ?? 1} currentRound={round - 1} />
      {match && <TournamentMatch match={match} />}
      <TournamentRequirements />
      <div className="tournament-detail-data">
        <div className="tournament-detail-data__container">
          <TournamentRewardsTable tournament={tournament ?? []} />
          <TournamentRanking tournament={tournament} />
        </div>
      </div>
      <TournamentRules />
      <Footer />
      {tournament.state === 'PUBLISHED' && user
        && (
          <TournamentJoinModal
            isActive={isShowJoinModal}
            onClose={() => setIsShowJoinModal(false)}
            decks={decks}
            userId={user?.address}
          />)
      }
      <TournamentJoinSuccessModal />
      <TournamentStageInfoModal />
      <TournamentQuitModal />
    </div>
  )
}
