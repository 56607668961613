import ProgressBar from 'components/ProgressBar/progressBar.component'
import { AssignedAchievement, RewardAchievement } from 'domain/assignedAchievement.model'
import './achievement.component.scss'

interface IAssignedAchievement {
  assignedAchievement: AssignedAchievement
  claimReward: any
}

export const flattenRewards = (rewards: RewardAchievement[]) => {
  const flattenedReward = rewards.reduce((acc: string, curr: RewardAchievement) => `${acc} / ${curr.nameToDisplay}`, '')
  return flattenedReward.substring(2)
}

export default function AchievementComponent({ assignedAchievement, claimReward }: IAssignedAchievement) {
  return (
    <div className="reward">
      <div className="reward__header">
        <div className="reward__content-img">
          <img className="reward__img" src="/images/MOSHED.png" alt="reward" />
          <span className="reward__line"></span>
        </div>

        <div className="achievement">
          <div className="reward__achievement-content-text">
            <div className='reward__achievement'>
              <div className="reward__achievement-title">{assignedAchievement.name}</div>
              <div className="reward__achievement-description">{assignedAchievement.description}</div>
            </div>
            <span className="reward__progress">{`${assignedAchievement.sumAccumulators}/${assignedAchievement.condition}`}</span>
          </div>
          <ProgressBar total={assignedAchievement.condition} progress={assignedAchievement.sumAccumulators} />
        </div>
      </div>

      <div className="reward__claim">
        <span className="reward__name">{flattenRewards(assignedAchievement.rewards)}</span>
        {!assignedAchievement.wasClaimed
          && assignedAchievement.condition === assignedAchievement.sumAccumulators
          && <div className="btn btn-medium btn-primary" onClick={() => claimReward(assignedAchievement.rewardId, assignedAchievement.id)}>Claim Reward</div>}
        {(!assignedAchievement.wasClaimed && assignedAchievement.condition !== assignedAchievement.sumAccumulators)
          && <div className="btn btn-medium btn-outline disabled">Claim Reward</div>}
        {assignedAchievement.wasClaimed && <div className="btn btn-medium btn-outline disabled">Claimed</div>}
      </div>
    </div>
  )
}
