/* eslint-disable max-len */
import './tournamentTermsAndConditions.view.scss'

export default function TournamentsTermsAndConditionsView() {
  return (
    <div className="tournament-info">
      <h1>Tournaments Terms and Conditions</h1>
      <p>Deviants permits users to interact with and participate in skill-based games online tournaments and with other users via the Website.</p>
      <p>User acknowledges that the outcome of the tournaments offered on the Website are directly related to the number of users participating, and the skill levels of each user participating. Deviants does not comment or have knowledge of the probability of one user winning a tournaments against another user, and makes no representations about an individual user&apos;s chances of winning.</p>
      <p>User shall not engage in any practice that aims to manipulate the outcome of any tournaments. All users should play to the best of their abilities and any sort of match-fixing, win-trading, or colluding between users is strictly prohibited.</p>
      <p>Deviants reserves the right to discretionary determine an entry fee to be paid by user to participate in tournament. Entry fees represent a &quot;platform fee&quot; to Deviants made solely for facilities provided by the Website for the setting up and management of tournaments. By participating in tournaments on the Website and paying the fee to Deviants, each user acknowledges that these funds are held by Deviants for such services and that the member grants an irrevocable authority to Deviants to award the winner of the tournament the prize associated with such tournament.</p>
      <p>The prize in each tournament represent an amount determined by Deviants in its absolute discretion and is subject to change without further notice to you.</p>
    </div>
  )
}
