/* eslint-disable no-undef */
import {
  CSSProperties,
  ReactElement,
  ReactNode, cloneElement, createContext, useContext, useState,
} from 'react'
import './Select.component.scss'
import ChevronDown from '../../assets/icons/icon-chevron.svg'

export type SelectorProps = {
  children: ReactNode[] | ReactNode,
  onSelected?: (value: any) => void
  style?: CSSProperties
}

type SelectorContextProps = {
  selected: number,
  setSelected: (key: number, value: any) => void
}

const SelectorContext = createContext<SelectorContextProps>({
  selected: 0,
  setSelected: () => { },
})

export default function Select(props: SelectorProps) {
  const { children, onSelected } = props
  const [selected, setSelected] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(!isOpen)

  return <SelectorContext.Provider
    value={{
      selected,
      setSelected(key, value) {
        setSelected(key)
        setIsOpen(false)
        if (onSelected) onSelected(value)
      },
    }}>
    <div style={props.style} className='df-selector' onClick={() => handleOpen()} onBlur={() => setIsOpen(false)}>
      <div className='df-selector-title'>
        {children && (children as ReactElement[])[selected]}
        <img src={ChevronDown} width={'32'} />
      </div>
      <div className='df-selector-content-wrapper'>
        <div className={`df-selector-content df-selector-content${isOpen ? '__opened' : '__closed'}`}>
          {/* {Children.map(children, (child, index) => cloneElement(child as ReactElement, { key: index }))} */}
          {children && (children as ReactNode[])!.map((child, index) => cloneElement(child as ReactElement, { index }))}
        </div>
      </div>
    </div>
  </SelectorContext.Provider>
}

function Option(props: any) {
  const { selected, setSelected } = useContext(SelectorContext)
  const { children, value, index } = props
  return <div className={`df-selector-item df-selector-item__${selected === index ? 'selected' : 'unselected'}`}
    onClick={() => index !== undefined && setSelected(index as number, value)}>{children}</div>
}

Select.Option = Option
