// @ts-nocheck
import { Transfer, Asset } from '@imtbl/core-sdk'
import { doWhilst } from 'async'
import ProviderService from '../infraestructure/services/web3-provider/imx-provider.service'

const getTransfer = (transferTx: string, callback: any) => {
  const providerService = new ProviderService()
  providerService.getTransfer(transferTx)
    .then((transfer: Transfer) => callback(null, transfer))
    .catch((error) => {
      if (error.code === 'resource_not_found_code') callback(null, undefined)
      else callback(error)
    })
}

const getAsset = (tokenAddress: string, tokenId: string, callback: any) => {
  const providerService = new ProviderService()
  providerService.getAsset(tokenAddress, tokenId)
    .then((asset: Asset) => callback(null, asset))
    .catch(() => callback(null, undefined))
}

export default class OpenerService {
  public static checkTransferOk(transferTx: string): Promise<void> {
    return doWhilst(
      (cb) => getTransfer(transferTx, cb),
      (transfer: Transfer|undefined, cb): void => {
        cb(null, transfer === undefined)
      },
    )
  }

  public static checkAssetOk(tokenAddress: string, tokenId: string): Promise<void> {
    return doWhilst(
      (cb) => getAsset(tokenAddress, tokenId, cb),
      (asset: Asset|undefined, cb): void => {
        cb(null, asset === undefined || asset.status !== 'burned')
      },
    )
  }
}
