import './SubscriptionState.component.scss'
import { useRecoilValueLoadable } from 'recoil'
import { subscriptionStatusSelector } from 'selectors/selectors'

export default function SubscriptionStateComponent(props: { tournamentId: string }) {
  const { contents } = useRecoilValueLoadable(subscriptionStatusSelector(props.tournamentId))
    || { state: undefined, message: undefined }

  if (contents?.state === 'not-subscribed') {
    if (contents?.message) {
      return <div className='subscription-error-alert'>
        <h2>Something went wrong</h2>
        <p style={{ fontWeight: '400' }}>{contents.message}</p>
      </div>
    }
  }
  return <></>
}
