/* eslint-disable @typescript-eslint/semi */
import { TournamentTicket } from './tournament-ticket.model'

export type DareState = 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED'
export type RewardType = 'MONEY' | 'ITEM' | 'BOOSTER' | 'NFT_TICKET'
export type CurrencyType = 'UST' | 'DEV' | 'uusd' | 'USDC' | 'XDEV' | 'DEV' | 'VOL_POINT' | 'FREE_ROLL_TICKET'

export type MoneyDto = {
  amount: number
  currency: CurrencyType
}

export type RewardAchievement = {
  type: RewardType
  amount?: number
  key?: string
  money?: MoneyDto
  in?: CurrencyType
  nameToDisplay: string
}

export type Accumulators = {
  key: string
  value: number
  procesable: string
}

export type AssignedAchievement = {
  id?: string
  name: string
  description: string
  state?: DareState
  playerId: string
  condition: number
  sumAccumulators: number
  rewards: RewardAchievement[]
  rewardId: string[]
  wasClaimed: boolean
}

export type Achievement = {
  id?: string
  key: string
  nextId?: number
  isRoot: boolean
  name: string
  accumulators: Accumulators[]
  condition: number
  rewards: RewardAchievement[]
  description: string
}

export const resolveName = (
  r: RewardAchievement,
  ticketsCatalog: TournamentTicket[],
): string => {
  if (r.type === 'MONEY') {
    if (r.money?.currency === 'FREE_ROLL_TICKET') {
      const ticket = ticketsCatalog.find((t) => t.internalName === r.key)
      return `${r.money?.amount} ${ticket?.name}`
    }
    return `${r.money?.amount} ${r.money?.currency.replace('_', ' ')}`
  }
  if (['ITEM', 'BOOSTER'].includes(r.type)) {
    return `${r.amount} ${r.type} ${r.key}`
  }
  if (['ITEM', 'NFT_TICKET'].includes(r.type)) {
    return 'UNL0CK3R NFT'
  }
  return ''
}
