import MoneyComponent from 'components/Money/Money.component'
import { Key } from 'react'
import TournamentsRepository from '../../../../repositories/tournaments.repository'

function getRewardDetails(position: number) {
  switch (position) {
    case 0: return '1st'
    case 1: return '2nd'
    case 2: return '3rd'
    default: return `${position + 1}th`
  }
}

function getRewardItem(item: any, i: Key, showConvertions?: boolean) {
  if (item.type === 'MONEY') {
    return (
      <div className='reward-item__position__piece' key={i}>
        <MoneyComponent convert={item.money.currency !== item.in}
          showConvertions={showConvertions} money={{
            amount: item.money.amount,
            currency: item.in,
            rawAmount: item.money.amount,
          }} />
      </div>
    )
  } if (item.type === 'BOOSTER') {
    return (
      <div className='reward-item__position__piece' key={i}>
        <img src={`/images/boosters/${item.key}.png`} className='reward-item__position__piece-icon' alt="booster reward" /><span>x{item.amount}</span>
      </div>
    )
  } if (item.type === 'FREE_ROLL_TICKET') {
    return (
      <div className='reward-item__position__piece' key={i}>
        <img src={TournamentsRepository.getTicketIcon(item?.value?.assetType)} className='reward-item__position__piece-icon' alt="ticket reward" /><span>x{item.amount}</span>
      </div>
    )
  }
  return <></>
}

export default function RewardTableItem(
  { position, reward, showConvertions }: { position: number, reward: any, showConvertions?: boolean },
) {
  const fixedPosition = getRewardDetails(position)
  return (
    <div className="reward-item">
      <div className="reward-item__position"><span className={`position--${fixedPosition}`}>{fixedPosition}</span></div>
      <div className="money">
        {reward.map((item: { money: { currency: any } }, i: Key) => getRewardItem(item, i, showConvertions))}
      </div>
    </div>
  )
}
