import { useRecoilValueLoadable } from 'recoil'
import { getStakedDevSelector } from 'selectors/staking.selectors'
import ExchangeItem from './ExchangeItem.component'

export default function StakedDev() {
  const sDev = useRecoilValueLoadable(getStakedDevSelector)
  return <ExchangeItem>
    <img width="32px" src='/images/currency/icon-dev-new.png' />
    <ExchangeItem.Title text='STAKED' />
    <ExchangeItem.Value text={sDev.valueMaybe()?.toString() || '0'} />
    {/* <ExchangeItem.Equivalence text='' /> */}
  </ExchangeItem>
}
