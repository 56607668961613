import Tooltip from 'components/Tooltip/Tooltip.component'
import { useRecoilValue } from 'recoil'
import { currentDeckSelector, DeckFormatsSelector, playableDeckSelector } from 'selectors/selectors'
import iconCheck from 'assets/icons/icon-check-deck.svg'
import iconWarning from 'assets/icons/icon-warning-deck.svg'
import DeckFormatsService from '../../../../services/deck-formats.service'

const DeckWarning = () => {
  let playable = useRecoilValue(playableDeckSelector)
  const deckFormats = useRecoilValue(DeckFormatsSelector)
  const deck = useRecoilValue(currentDeckSelector)

  const valid = DeckFormatsService.isValid(deckFormats, deck)
  playable = playable && valid

  return <Tooltip down={!playable} text={playable ? 'THIS DECK IS PLAYABLE' : (<>
    THIS DECK
    <br />
    IS NOT PLAYABLE
    <br />
    - 8 Cards and 1 HQ is needed
    <br />
    - Make sure you are not selling the cards on your deck
    <br />
    - Make sure you still have the nft listed in the deck
  </>
  )}>

    <img src={playable ? iconCheck : iconWarning} className='icon-status' alt={playable ? 'This deck is playable' : 'This deck is not playable'} />
  </Tooltip>
}

export default DeckWarning
