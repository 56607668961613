import { Link } from 'react-router-dom'
import '../views.scss'

export default function UserNotStarterPackView() {
  return (
    <div className="empty-state">
      <h2 />
      <h2 className={'title'}>It seems that you don&apos;t have any Boosters to play.</h2>
      <h1>You can go to &quot;My Boosters&quot; to get one and start playing!</h1>
      <Link to="/store" className="btn-shadow">
        <button className="btn btn-primary btn-medium">BUY BOOSTERS</button>
      </Link>
      <h2 />
    </div>
  )
}
