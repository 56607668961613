import { useRecoilValueLoadable } from 'recoil'
import { profileSelector } from 'selectors/selectors'
import Footer from '../components/Footer/Footer.component'
import UserNotConnectedView from './user/user-not-connected.view'
import UserWelcome from './user/user-welcome.component'

const Profile = () => {
  const profile = useRecoilValueLoadable(profileSelector)
  if (profile.state === 'hasError') return <UserNotConnectedView />
  if (profile.state === 'hasValue') {
    return (!profile.contents) ? <UserNotConnectedView /> : <UserWelcome />
  }
  return <div className="connect-wallet message-alert"><h2/><h1>loading profile...</h1><h2/></div>
}

const UserView = () => (
    <div className="userprofile-view">
      <Profile />
      <Footer/>
    </div>
)

export default UserView
