import { rewardsTableStyle } from 'components/rewards/rewards.component'
import { Grid } from 'gridjs-react'
import './economy-achievements-grid.component.scss'
import { TaskDto } from 'domain/task.model'
import { useRecoilValueLoadable } from 'recoil'
import { allTasksSelector } from 'selectors/selectors'

export default function EconomyTasksGrid() {
  const tasks = useRecoilValueLoadable(allTasksSelector('all'))
  if (tasks.state === 'loading') return <p>Loading...</p>
  return <Grid
    columns={[
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'NAME' },
      { id: 'isActive', name: 'IS ACTIVE' },
      { id: 'condition', name: 'CONDITION' },
    ]}
    style={{
      ...rewardsTableStyle,
      table: {
        width: '100%',
      },
      td: {
        ...rewardsTableStyle.td,
        fontSize: '0.85em',
      },
    }}
    data={tasks.contents.tasks.map((task: TaskDto) => [
      task.id,
      task.name,
      task.isActive ? 'YES' : 'NO',
      `${task.operator.replaceAll('_', ' ')} ${task.condition}`,
    ])}
    sort={true} />
}
