import ExchangeItem from './ExchangeItem.component'
import DepositDevModal from './DevExchange/DepositDevModal.component'
import useBalance from '../hooks/useBalance.hook'
import useDevConvertion from '../hooks/useDevConvertion.hook'

export default function InWalletDev() {
  const { balance: dev } = useBalance('dev')
  const usd = useDevConvertion(dev)
  return <ExchangeItem>
    <img width="32px" src='/images/currency/icon-dev-new.png' />
    <ExchangeItem.Title text='IN WALLET' />
    <ExchangeItem.Value text={`DEV ${dev}`} />
    <ExchangeItem.Equivalence text={`${usd.toFixed(4)} $`} />
    <DepositDevModal />
  </ExchangeItem>
}
