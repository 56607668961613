/* eslint-disable no-empty */
import './library.component.scss'
import { useState } from 'react'
import { CardType } from 'domain/card.model'
import SearchFilter from 'views/marketplace/searchFilter.component'
import LibraryCharacter from './LibraryCharacter.component'
import LibraryTechnology from './LibraryTechnology.component'
import LibraryTitle from './LibraryTitle.component'

export function filterCardsBy(search: string, cards: CardType[]): CardType[] {
  const s = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')// remove tildes
  if (!s || s === '') return cards
  return cards.filter((card) => {
    if (s === 'nft') return card.IsNft
    if (s === 'male' && card.Gender) return card.Gender === 'Male'
    const name = card.Name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    let valid: boolean = name.indexOf(s) > -1
    if (!valid) valid = card.Faction.toLowerCase().indexOf(s) > -1
    if (!valid) valid = !!(card.Gender && card.Gender.toLowerCase().indexOf(s) > -1)
    if (!valid) valid = !!(card.id && card.id.toLowerCase().indexOf(s) > -1)
    if (!valid) valid = !!(card.Ability && card.Ability.find((ability) => ability.toLowerCase().indexOf(s) > -1))
    return valid
  })
}

export default function Library({ onAddCard, onViewCard }: { onAddCard: Function, onViewCard: Function }) {
  const submitSearch = (evt: any) => {
    evt.stopPropagation()
    evt.preventDefault()
  }
  const [search, setSearch] = useState('')

  return (
    <section className="library">
      <div style={{ width: '100%', margin: 'auto' }}>
        <SearchFilter searchAction={setSearch} submitAction={submitSearch} value={search} />
      </div>
      <LibraryTitle title={'Character'} />
      <LibraryCharacter
        add={onAddCard}
        onViewCard={onViewCard}
        search={search}
      />
      <LibraryTitle title={'technology'} />
      <LibraryTechnology onViewCard={onViewCard} search={search} />
    </section>
  )
}

export const allowNftOnly = (card: CardType): boolean => {
  let session: any = localStorage.getItem('session')
  if (typeof session === 'string') {
    try {
      session = JSON.parse(session)
    } catch (e) { }
  }
  if (session?.wallet) {
    // hack
    if (['0x85e1a88c38797981c5811776710f930c43f50dc5', '0x796785c8e52ec5c1c37c730aaa924b01bba8997d', '0x08dd12a9664a94e5f4562fdb936fd7fbd3307083', '0x0aecc9aaa9dbe856aad765a691f86b44694c31b6'].includes(session.wallet.toLowerCase())) {
      return false
    }
  }
  if (!card.SetAndNumber) return false
  if (/^OTK/.test(card.SetAndNumber)) return false
  if (/^EC1/.test(card.SetAndNumber) && !['Common', 'Uncommon'].includes(card.Rarity!)) {
    return card.TokenIds?.length === 0
  }
  return (/^EC2/.test(card.SetAndNumber) || /^ULK/.test(card.SetAndNumber)) && (card.TokenIds?.length ?? 0) === 0
}
