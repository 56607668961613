import Flex from 'components/Flex/Flex'
import Text from 'components/Text/Text'
import Tooltip from 'components/Tooltip/Tooltip.component'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { Staking } from 'repositories/staking.repository'

type StakedDailyRewardComponentProps = {
  staking: Staking
}

export default function StakedCurrentRewardComponent(props: StakedDailyRewardComponentProps) {
  const { staking } = props
  const dailyReward = (staking.amount / 365) * staking.multiplier
  const startDate = moment(staking.startDate).utc()
  const [todayEarnings, setTodayEarnings] = useState(0)

  useEffect(() => {
    const count = () => {
      const rightNow = moment().utc()
      const minutes = rightNow.clone().diff(startDate, 'minutes')
      setTodayEarnings(((dailyReward / 24) / 60) * minutes)
    }
    count()
    const interval = setInterval(() => count(), 60000)
    return () => clearInterval(interval)
  }, [])

  return <Flex flexDirection='row' gap='1ch' alignItems='center'>
    <Text>{dailyReward.toFixed(4)}</Text>
    {todayEarnings < 0 ? <Tooltip text={`Starting in ${startDate.clone().diff(moment().utc(), 'minutes')} minutes`}>
      <Text>
        ⏳ {startDate.clone().diff(moment().utc(), 'minutes')} min
      </Text>
    </Tooltip>
      : <Text fontWeight={600} color='green'>(+ {todayEarnings.toFixed(4)})</Text>}
  </Flex>
}
