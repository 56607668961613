import './league-ranking-item.prize.scss'

export type LeagueRewards = {
  key: string
  type: string
  amount: number
}

export const RewardItem = (props: { reward: LeagueRewards }) => {
  const title = props.reward.key.replace(/_/g, ' ')
  return <div className="league-ranking-prize" key={1} title={title}>
    <img height={'48px'} width={'32px'} src={`/images/boosters/${props.reward.key}.png`} alt={'BOOSTER'} />
    <div style={{ fontSize: '14px' }}>&#x2715;</div>
    <div style={{ fontSize: '16px' }}>{props.reward.amount}</div>
  </div>
}

const RewardsPopup = (props: { rewards: LeagueRewards[] }) => <div className='league-ranking-prize__preview--popup'>
  <p className='league-ranking-prize__preview--popup--title'>PRIZES</p>
  {props.rewards.map((r: any, i: number) => <div key={i} >
    <RewardItem reward={r} />
    <p className='league-ranking-prize__preview--popup--name'>{r.key.replace(/_/g, ' ')}</p>
  </div>)}
</div>

export default function LeagueRankingItemPrize(props: { position: number, rewards: LeagueRewards[] }) {
  const hasPlus = props.rewards.length > 1
  return <div className='league-ranking-prize__preview'>
    <RewardItem reward={props.rewards[0]} />
    {hasPlus && <RewardsPopup rewards={props.rewards} />}
    {hasPlus && <div className='league-ranking-prize--n'>{props.rewards.length - 1}+...</div>}
  </div >
}
