import { useState } from 'react'
import ModalComponent from 'components/Modal/Modal.component'
import ExchangeItem from '../ExchangeItem.component'
import useBalance from '../../hooks/useBalance.hook'
import useTokenToDevConvertion from '../../hooks/useTokenToDevConvertion.hook'
/* import BuyMoreButton from './BuyMoreButton.component' */
import TokenToDevExchange from '../TokenExchange/TokenToDevExchange.component'

export default function BuyGodsModal() {
  const { balance: gods } = useBalance('gods')
  const [isOpen, setIsOpen] = useState(false)
  const tokenEquivalence = useTokenToDevConvertion({
    token: 'gods-unchained',
    amount: (gods),
  })
  return <>
    <ModalComponent closeAction={() => setIsOpen(false)} isActive={isOpen} id={'exchange-gods'}>
      <p>Exchange modal</p>
    </ModalComponent>
    <ExchangeItem>
      <img width={32} src='/images/currency/GODS.png' />
      <ExchangeItem.Title text='GODS' />
      <ExchangeItem.Value text={(gods).toString()} />
      <ExchangeItem.Equivalence text={`DEV ${tokenEquivalence.toFixed(4)}`} />
      <TokenToDevExchange token='GODS' />
      {/* <BuyMoreButton token='GODS' /> */}
    </ExchangeItem >
  </>
}
