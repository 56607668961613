import useBalance from 'views/exchange/hooks/useBalance.hook'
import useTokenToDevConvertion from 'views/exchange/hooks/useTokenToDevConvertion.hook'
import ExchangeItem from '../ExchangeItem.component'
import BuyMoreButton from './BuyMoreButton.component'
import TokenToDevExchange from '../TokenExchange/TokenToDevExchange.component'

export default function BuyUsdcModal() {
  const { balance: usdc } = useBalance('usdc')
  const tokenEquivalence = useTokenToDevConvertion({
    token: 'usd-coin',
    amount: (usdc),
  })
  return <ExchangeItem>
    <img width={32} src='/images/currency/USDC.png' />
    <ExchangeItem.Title text='USDC' />
    <ExchangeItem.Value text={usdc.toString()} />
    <ExchangeItem.Equivalence text={`DEV ${tokenEquivalence.toFixed(4)}`} />
    <TokenToDevExchange token='USDC' />
    <BuyMoreButton token='USDC' />
  </ExchangeItem >
}
