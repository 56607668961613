import './achievements.component.scss'
import { useEffect, useState } from 'react'
// eslint-disable-next-line camelcase
import { useRecoilValue, useRecoilRefresher_UNSTABLE } from 'recoil'
import { connectedWallet, profileBalancesSelector, rewardsSelector } from 'selectors/selectors'
import AchievementsRepository from 'repositories/achievement.repository'
import { AssignedAchievement, resolveName } from 'domain/assignedAchievement.model'
import { LoaderFallback, setLoader } from 'components/Loader/Loader.component'
import Reward from 'domain/reward.model'
import RewardRepository from 'repositories/rewards.repository'
import TournamentTicketRepository from 'repositories/tournament-tickets.repository'
import { TournamentTicket } from 'domain/tournament-ticket.model'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import Button from 'components/Button/Button.component'
import AchievementComponent from './achievement.component'

export default function AchievementsComponent() {
  const user = useRecoilValue(connectedWallet)
  const [assignedAchievements, setAssignedAchievements] = useState<AssignedAchievement[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const refreshBalances = useRecoilRefresher_UNSTABLE(profileBalancesSelector)
  const refreshRepository = useRecoilRefresher_UNSTABLE(rewardsSelector)

  const [modalMessage, setModalMessage] = useState<string>('')
  const handleClickOutside = () => {
    setModalMessage('')
  }

  const combineAchievementReward = (
    assignedAchievementsWithoutRewards: AssignedAchievement[],
    rewards: Reward[],
    ticketsCatalog: TournamentTicket[],
  ) => {
    const newAssignedAchievements = assignedAchievementsWithoutRewards.map((assignedAchievementWithoutRewards) => {
      const rewardsAchievement = rewards.filter((e) => e.originId === assignedAchievementWithoutRewards.id)
      const newRewards = assignedAchievementWithoutRewards.rewards.map((r) => {
        const nameToDisplay = resolveName(r, ticketsCatalog)
        return { ...r, nameToDisplay }
      })
      return {
        ...assignedAchievementWithoutRewards,
        rewards: newRewards,
        rewardId: rewardsAchievement.map((r) => r.id),
        wasClaimed: rewardsAchievement.length > 0 && rewardsAchievement.every((r) => r.state === 'CLAIMED'),
      }
    })
    return newAssignedAchievements
  }

  const getAssignedAchievements = (showLoading?: boolean) => {
    if (showLoading) setLoading(true)
    Promise.all([
      AchievementsRepository.getAssignedAchievements(user?.address ?? ''),
      RewardRepository.getByUserAddress(user?.address, 'ACHIEVEMENT'),
      TournamentTicketRepository.get('all'),
    ])
      .then((result) => {
        setAssignedAchievements(combineAchievementReward(result[0], result[1], result[2]))
      })
      .finally(() => setLoading(false))
  }

  const claimReward = (rewardsId: string[], achievementId: string) => {
    if (rewardsId.length === 0) setModalMessage('The Reward is still processing. Please try again later.')
    setLoading(true)
    const claiming = rewardsId.map((id) => RewardRepository.claimReward(id.toString(), user?.address))
    Promise.any(claiming)
      .then(() => {
        setAssignedAchievements(
          assignedAchievements.map((a) => (a.id === achievementId ? { ...a, wasClaimed: true } : a)),
        )
        refreshRepository()
        refreshBalances()
      })
      .catch(() => {
        // setErrorClaiming(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const sortAchievements = (a: AssignedAchievement, b: AssignedAchievement) => {
    const cumulativeOrder = (x: AssignedAchievement) => {
      let accum = 0
      accum += (x.sumAccumulators === x.condition) ? 1 : -1
      accum += (!x.wasClaimed) ? 1 : -1
      accum += (x.sumAccumulators > 0) ? 0.5 : -1
      if (x.state === 'IN_PROGRESS') accum += 1
      if (x.state === 'NOT_STARTED') accum += 0.5
      if (x.state === 'COMPLETED') accum += 0.3
      return accum
    }
    return cumulativeOrder(b) - cumulativeOrder(a)
  }

  const modal = (
    <Modal isActive={modalMessage !== ''} closeAction={handleClickOutside} id="modal">
      <div>
        <h2>ATTENTION!</h2>
        <br />
        <div className='modal-message'>
          {modalMessage}
        </div>
        <div className='modal-button'>
          <Button variant={'outline'} size={'small'} onClick={(event) => {
            event.preventDefault()
            setModalMessage('')
            handleClickOutside()
          }} >Close</Button>
        </div>
      </div>
    </Modal>
  )

  useEffect(() => {
    getAssignedAchievements(true)
  }, [])

  if (loading) return <LoaderFallback text={'Please wait ...'} />
  setLoader(false)

  return (
    <div className='achievements-container'>
      <div className='achievements-title-header'>
        <div className='achievements-title'>ACHIEVEMENTS</div>
      </div>
      <div className="achievements">
        {assignedAchievements
          .sort(sortAchievements)
          .map((assignedAchievement) => (
            <AchievementComponent
              key={assignedAchievement.name}
              assignedAchievement={assignedAchievement}
              claimReward={claimReward} />
          ))}
      </div>
      { modal }
    </div>
  )
}
