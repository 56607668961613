export default function InhumanSvg() {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px"
        viewBox="0 0 640 640" enableBackground="new 0 0 640 640">
        <g>
          <path d="M414.9,390.1c-31.7,68.4-63.2,136.6-94.9,205.1c-31.7-68.5-63.2-136.7-94.8-205.1c-0.3,0.4-0.5,0.7-0.6,0.9
    c-13.8,26.3-27.5,52.6-41.3,79c-9.8,18.7-19.5,37.3-29.3,56c-3.7,7.1-7.5,14.3-11.1,21.4c-0.5,0.9-1,1.3-2.1,1.3
    c-41.2,0-82.3,0-123.5,0c-0.3,0-0.7,0-1.4-0.1c1.6-1.3,2.9-2.3,4.1-3.3c23.9-18.9,47.8-37.9,71.6-56.9c1.5-1.2,2.5-3.3,3.4-5
    c4.2-7.8,8.3-15.7,12.4-23.6c4.2-7.9,8.3-15.9,12.5-23.8c22-42.3,44.1-84.5,66.2-126.8c0.7-1.3,0.7-2.2,0.1-3.5
    c-18.1-39-36.1-78-54.1-117c-0.5-1-0.8-2-1.4-3.3c33.8,12.4,67.2,24.7,100.9,37.1c29.4-56.2,58.9-112.5,88.5-169.1
    c29.6,56.5,59.1,112.8,88.5,169.1c33.6-12.4,67-24.7,100.9-37.1c-0.4,1.3-0.7,2.2-1.1,3c-14.3,31-28.6,61.9-43,92.9
    c-3.8,8.3-7.7,16.6-11.5,24.9c-0.5,1.1-0.5,1.9,0.1,2.9c20.1,38.3,40.2,76.7,60.3,115.1c3.4,6.6,6.7,13.3,10.1,19.9
    c2.5,4.8,5.2,9.5,7.7,14.3c3,5.7,6,11.5,8.9,17.3c1.8,3.5,3.6,7.1,5.5,10.5c0.4,0.8,1.1,1.6,1.8,2.2c24.9,19.8,49.8,39.6,74.7,59.3
    c0.3,0.2,0.5,0.4,1.1,0.9c-0.7,0.1-1.2,0.1-1.7,0.1c-41,0-82,0-122.9,0c-1.1,0-1.7-0.3-2.3-1.3c-8.7-16.7-17.4-33.3-26.1-50
    c-9.1-17.5-18.3-35-27.4-52.5c-9.3-17.7-18.5-35.4-27.8-53.1C415.6,391.4,415.4,390.9,414.9,390.1z M319.9,441.9
    c0.5-0.7,0.8-0.9,0.9-1.3c1-2,1.9-4.1,3-6.1c2.3-4.3,4.7-8.6,7-12.9c2.5-4.8,5-9.6,7.5-14.5c1.9-3.6,3.7-7.3,5.6-10.9
    c2.3-4.3,4.6-8.6,6.9-12.9c2.3-4.4,4.5-8.9,6.8-13.3c3.3-6.5,6.7-12.9,10-19.4c0.3-0.5,0.2-1.3,0-1.9c-1.7-3.6-3.5-7.1-5.4-10.6
    c-4.5-8.6-9.1-17.2-13.6-25.8c-4-7.6-8-15.3-11.9-23c-4.4-8.5-8.8-16.9-13.2-25.3c-1.1-2-2.2-4-3.4-6.2c-0.5,1-1,1.9-1.4,2.7
    c-2.6,5-5.1,9.9-7.7,14.9c-3.8,7.3-7.6,14.5-11.4,21.8c-2.4,4.6-4.8,9.2-7.2,13.8c-2.7,5.1-5.4,10.2-8.1,15.3
    c-3.2,6.2-6.4,12.4-9.7,18.5c-1.4,2.8-3.3,5.5-0.7,8.6c0.1,0.2,0.2,0.4,0.3,0.6c4.2,8,8.3,16,12.5,24c3.5,6.8,7,13.6,10.5,20.4
    c2.5,4.8,5.1,9.6,7.6,14.4c1.6,3.1,3.2,6.3,4.8,9.4c2.3,4.5,4.7,8.9,7.1,13.4C317.6,437.6,318.7,439.6,319.9,441.9z"/>
        </g>
      </svg>
    </div>
  )
}
