import Axios from 'axios'
import getConfig from 'config.service'
import { BannedUser, User } from 'domain/user.model'
import { ValidateIfTokenIsPresent } from './sessionVerifier.repository'

const { hostURL } = getConfig()
const baseUrl = `${hostURL}/api/v1/users-manager/user`

export default abstract class UserRepository {
  public static async getByIds(users: string[]): Promise<User[]> {
    try {
      const token = ValidateIfTokenIsPresent()
      const res = await Axios.post(`${baseUrl}/get-by-ids`, { users }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data
    } catch (error: any) {
      // eslint-disable-next-line
      console.error(error)
      return []
    }
  }

  public static async getBannedUser(address: string): Promise<BannedUser | null> {
    try {
      const token = ValidateIfTokenIsPresent()
      // eslint-disable-next-line no-undef
      const res = await Axios.get<{ banned: BannedUser | null }>(`${baseUrl}/banned/${address}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return res.data.banned
    } catch (error: any) {
      // eslint-disable-next-line
      console.error(error)
      return null
    }
  }
}
