export default function AwakenSvg() {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px"
        viewBox="0 0 640 640" enableBackground="new 0 0 640 640">
        <g>
          <path d="M324.2,620.6c-16,0-31.8-1.1-47.6-3.6c-39.3-6.2-75.9-19.4-109.9-40.2c-29-17.7-54.3-39.8-75.7-66.2
        c-8.1-10-5.8-23.7,4.8-29.8c8.1-4.7,18.5-2.7,24.5,4.6c9.6,11.9,20,23,31.4,33.1c24.9,22,53,38.6,84.3,49.8
        c21.2,7.6,42.9,12.3,65.3,14.2c18.1,1.6,36.2,1.2,54.2-1.1c13-1.7,25.9-4,38.4-8c6.3-2,12-5,15.1-11.1c3.5-6.8,2.5-16.5-6.5-19.9
        c-4.5-1.7-9.3-2.1-14-1.2c-6.7,1.2-13.4,2.7-20,4.3c-19.4,4.5-39.1,6-59,5.1c-19-0.8-37.6-4.1-55.8-9.8
        c-36.3-11.3-67.8-30.5-94.4-57.6c-19.8-20.2-35.2-43.4-46.2-69.4c-3.6-8.6-10-13.8-19.2-14.9c-3.7-0.5-7.6,0.3-11.4,0.9
        c-6.2,1-12.4,2.3-18.6,3.3c-5,0.8-9.9-0.2-14.4-2.3c-3.2-1.5-6.3-3.3-9.2-5.2c-6-4-9.2-10-10.5-17c-4.2-21.8-5.8-43.9-5-66
        c0.5-15.9,2.4-31.6,5.6-47.2c6.5-32.4,17.9-62.8,34.5-91.4c15.8-27.2,35.5-51.3,58.9-72.3c22.8-20.4,48.2-36.9,76.1-49.4
        c24.9-11.2,50.8-18.8,77.7-22.8c11.4-1.7,22.8-3,34.3-3.1c9.9,0,17.9,4.5,20.7,14.6c2.8,9.8-2.8,19.7-12.5,22.9
        c-6.5,2.1-13.2,2.6-19.9,3.2c-17.6,1.5-34.8,4.9-51.6,10c-39.3,11.9-73.9,32-103.7,60.3c-24.8,23.5-44.1,51-58,82.1
        c-8.8,19.7-14.9,40.2-18.7,61.4c-0.4,2.4-0.9,4.8-1,7.1c-0.1,7.3,5.6,14.5,14,13.2c5-0.8,9.5-2.4,12.9-6.4c2.4-2.8,3.7-6,4.6-9.4
        c2.2-8.2,4.2-16.5,6.5-24.7c3.6-12.8,8.7-25,14.8-36.8c18-34.8,43.2-63.1,75.8-84.7c26.2-17.4,54.8-28.7,85.8-34.1
        c15.8-2.8,31.7-3.8,47.7-3.1c8.2,0.4,16.4,1.6,24.5,2.3c7.8,0.6,14.9-1.3,20.6-6.7c2.9-2.7,4.9-6.5,5.9-10.6
        c1.9-7.7,3.6-15.4,5.8-23.1c1-3.7,2.6-7.3,4.6-10.5c2.9-4.8,7.7-7.4,13.1-8.6c10.1-2.3,19.9-0.8,29.4,3.1
        c43.1,17.6,80.2,43.8,111.1,78.6c29.3,33.1,50.1,70.9,62.6,113.2c5.8,19.6,9.5,39.5,11.1,59.8c1.5,18.6,1.3,37.3-0.7,55.8
        c-2,19-5.8,37.7-11.7,55.9c-3.6,11.1-8,21.9-12.2,32.7c-1.1,2.9-2.9,5.6-4.4,8.4c-4.2,7.9-14.1,9.7-20.5,7.6
        c-10.5-3.4-15.3-12-11.4-22.8c3.1-8.7,6.8-17.2,10-25.8c4.5-12.2,7.8-24.7,10.3-37.4c4.2-22.1,5.6-44.3,4.1-66.8
        C578.8,270.4,569,236,552,203.7c-13.6-25.8-31.1-48.6-52.4-68.4c-1.7-1.6-3.9-2.8-6.1-3.6c-4.4-1.7-8.9-3.1-13.7-1.9
        c-11.5,2.7-16.2,10-14.8,21.2c1.9,2.9,3.4,6.2,5.9,8.5c17.4,16.1,32.1,34.2,43.9,54.7c12.8,22.4,21.6,46.3,26.2,71.7
        c3.1,16.7,4.1,33.5,3.4,50.4c-0.8,19-4.1,37.6-9.7,55.8c-7.6,24.9-19.3,47.6-34.8,68.5c-3.4,4.7-6.2,9.8-7,15.6
        c-0.2,1.6-0.3,3.4-0.1,5c0.9,7.9,3.8,14.7,10.1,20.1c4.5,3.9,8.5,8.3,12.8,12.5c2.9,2.8,4.6,6.2,5.3,10.2
        c1.2,6.9,0.7,13.6-2.8,19.7c-2,3.5-4.5,6.6-7.7,9.2c-32,26.1-67.8,44.8-107.5,56.1c-19.5,5.5-39.3,9.1-59.6,10.4
        C336.8,620,330.5,620.3,324.2,620.6z M321,515.5c104.8-0.4,186.8-84.5,187.3-186.2c0.5-102.7-81.9-188-186.9-188.1
        c-104.1-0.1-187,83.8-187.3,186.7C133.7,431.2,217.5,515.3,321,515.5z"/>
          <path d="M179.6,331.2c0.5-79.9,64.5-142.3,142-142.7c80.6-0.3,143.4,64.3,143.7,142c0.3,79.9-63.5,143.3-142.1,143.7
        C243.5,474.6,179.5,410.5,179.6,331.2z"/>
        </g>
      </svg>
    </div>
  )
}
