import { rewardsTableStyle } from 'components/rewards/rewards.component'
import { Tournament } from 'domain/tournament.model'
import { Grid } from 'gridjs-react'
import moment from 'moment'

export default function EconomyTournamentGrid({ tournaments }: { tournaments: Tournament[] }) {
  return <Grid
    columns={[
      { id: 'id', name: 'ID' },
      { id: 'toId', name: 'TO ID' },
      { id: 'name', name: 'NAME' },
      { id: 'startDate', name: 'START DATE' },
      { id: 'announcementDate', name: 'ANN. DATE' },
      { id: 'maxPlayers', name: 'MAX PLY' },
    ]}
    style={{
      ...rewardsTableStyle,
      table: {
        width: '100%',
      },
      td: {
        ...rewardsTableStyle.td,
        fontSize: '0.85em',
      },
    }}
    data={tournaments.map((t) => [
      t.id,
      t.toId,
      t.name,
      moment(t.startDate).utc().format('YYYY-MM-DD HH:mm'),
      moment(t.announcementDate).utc().format('YYYY-MM-DD HH:mm'),
      t.maxPlayers,
    ])} />
}
