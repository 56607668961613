import './LoadingPage.component.scss'
import { Spinner } from 'components/Loader/Loader.component'

export default function LoadingPage() {
  return (
    <div className="overlay show loading-page">
      <div className="shadow">
        <Spinner />
      </div>
    </div>
  )
}
