import './card.component.scss'
import { CardType as CardTypeModel } from 'domain/card.model'
import Card from './Card.component'

// noinspection JSUnusedLocalSymbols
export default function NewCard(props: { card: CardTypeModel, onClick?: () => void, disabled?: boolean }) {
  const { card, onClick } = props
  return (<div style={{ width: 300, height: 388 }}>

    <Card
      disabled={props.disabled}
      {...card}
      onClick={onClick}
    />
  </div>
  )
}
