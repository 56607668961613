/* eslint-disable max-len */
import './tournamentInfo.view.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export default function TournamentsInfoView() {
  return (
    <div className="tournament-info">
      <div onClick={() => { window.history.back() }} className='tournament-hero-top__back' style={{ marginLeft: 0 }}>
        <FontAwesomeIcon icon={faChevronLeft as IconProp} />
        Back
      </div>
      <h1>Tournaments Information</h1>
      <h2>Tournaments Information</h2>
      <p>Welcome to deviants factions, the tournaments in deviants factions are most important in our ecosystem, in them you can play competitively and also win guaranteed prizes for the top players</p>
      <p>Tournaments are designed so that everyone can have a chance to win, but in order to participate you must have an entry ticket which is specified in the entry of each tournament. To create tickets you must collect a required amount of &quot;VOL Points&quot; (our offchain currency) and attach an amount of our $DEV governance tokens.</p>
      <p>For this welcoming tournament you will only need to collect 12 &quot;VOL Points&quot; in casual games and to create a Freeroll tournament ticket, which does not require any $DEV tokens.</p>
      <p>It is important for you to keep in mind that the modality of these welcoming tournaments will only be in &quot;Swiss&quot; format. This format is very similar to the one used in classic games tournaments such as chess, so it is very important for you to know that even if you loose your first match, you should continue the tournament since you still have chances of getting into the paying positions as it is a balanced system.</p>
      <p>Not all players win prizes, only those who have practiced the most and have taken care to develop a competitive strategy. This does not force you to have the most expensive deck, but rather allows you to deploy your full potential as a collectible card game player, since deviants factions is designed for competitive players but does not exclude those who wish to try new challenges by having an exquisite balance of cards.</p>

      <h2>Rules:</h2>
      <p>Games are standard PVP format.</p>
      <p>Players who do not connect will be considered W.O. and the player who is connected will win.</p>
      <p>A maximum of 3 minutes will be waited for the player to reconnect, in other case the player will be considered W.O.</p>
      <p>If neither of the two players joins, the game will be considered W.O.</p>
      <p>This tournament requires a minimum of 8 players to start.</p>
      <p>The prize is only for the 1st place and consists of a booster called &quot;Single Shot&quot;.</p>
      <p>All players are paired according to the classic Swiss system of scoring by rounds.</p>
      <p>Rounds are best of 1 game played (1v1)</p>

      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  )
}
