import { useContext } from 'react'
import { getCurrencyImage } from 'components/Money/Money.component'
import { CraftingCost } from 'domain/crafting-recipe.model'
import { useRecoilValueLoadable } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import { CraftingContext } from './crafting-context.component'

export function mapCraftingCostCurrencies(currency: 'VOL_POINT' | 'XDEV') {
  const map = { VOL_POINT: 'vol', XDEV: 'xdev' }
  return map[currency]
}

function CostItem({ cost, balance }: { cost: CraftingCost, balance: any }) {
  const currencyAmount = balance[mapCraftingCostCurrencies(cost.currency as any)]
  const isMoneySatisfied = currencyAmount >= cost.amount

  return <div className='cost-item'>
    <h1 style={isMoneySatisfied ? {} : { color: 'darkred', fontWeight: '500' }}>{cost.amount}</h1>
    <h2>x</h2>
    {getCurrencyImage({ amount: cost.amount, currency: cost.currency })}
  </div>
}

export default function CraftingCostComponent() {
  const { selectedRecipe } = useContext(CraftingContext)
  const balance = useRecoilValueLoadable(profileBalancesSelector)
  if (balance.state === 'loading') return <></>
  return <>
    <div style={{ alignSelf: 'start', padding: '0px 5%' }}>
      <h2 style={
        { ...selectedRecipe ? {} : { opacity: 0.5, textDecoration: 'line-through' } }
      }>
        Cost
      </h2>
    </div>
    <div className='crafting-cost'>
      {!selectedRecipe && <div className='crafting-cost-placeholder'>000000000</div>}
      {selectedRecipe?.cost.map((cost) => <CostItem balance={balance.contents} cost={cost} key={cost.currency} />)}
    </div>
  </>
}
