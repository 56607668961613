import Button from 'components/Button/Button.component'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useContext } from 'react'
import { CraftingContext } from './crafting-context.component'

function CraftingSuccessModal() {
  const { craftingTransaction, setCraftingTransaction, selectRecipe } = useContext(CraftingContext)
  const handleClose = () => {
    setCraftingTransaction(undefined)
    selectRecipe(undefined)
  }

  return <Modal isActive={(!!craftingTransaction)} closeAction={handleClose} id='crafting-success'>
    <h1>SUCCESS</h1>
    <div className='modal-content'>
      <h2>Crafting is in process, it may take a few minutes to complete.</h2>
    </div>
    <div className='modal-button'>
      <Button variant={'outline'} size={'medium'} onClick={(event) => {
        event.preventDefault()
        handleClose()
      }} >Done</Button>
    </div>
  </Modal>
}

function CraftingErrorModal() {
  const { isError, setIsError, selectRecipe } = useContext(CraftingContext)
  const handleClose = () => {
    setIsError(false)
    selectRecipe(undefined)
  }

  return <Modal isActive={(isError)} closeAction={handleClose} id='crafting-error'>
    <h1>CRAFTING ERROR</h1>
    <div className='modal-content'>
      <h2>An error has ocurred</h2>
    </div>
    <div className='modal-button'>
      <Button variant={'outline'} size={'medium'} onClick={(event) => {
        event.preventDefault()
        handleClose()
      }}>Ok</Button>
    </div>
  </Modal>
}

export default function CraftingResultModal() {
  return <>
    <CraftingSuccessModal />
    <CraftingErrorModal />
  </>
}
