import './NFTlist.scss'

type NFTItemt = {
  nftImage: string,
  id: string,
  children?: any,
  name?: string,
  description?: string
}

const shortHash = (hash: string): string => {
  if (!hash.startsWith('0x')) return hash
  const len = hash.length
  return `${hash.substr(0, 6)}...${hash.substr(len - 4, len - 1)}`
}

const NFTItem = ({
  name,
  description,
  nftImage,
  id,
  children,
}: NFTItemt) => (
  <div className="nft_card glass">
    <p className="nft_title" title={description}>[{shortHash(id)}] {name}</p>
    <div className="nft_image" id={id} style={{ backgroundImage: `url(${nftImage})` }}></div>
    <div className="nft_actions" title={description}>
      {children}
    </div>
    <div className="nft_background"></div>
  </div>
)

export default NFTItem
