/* eslint-disable */
import { memo, Suspense } from 'react'
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
} from 'react-router-dom'
import HomeView from 'views/home.view'
import PlayView from 'views/play.view'
import ProjectInfoView from 'views/project-info.view'
import ErrorView from 'views/errors/error.view'
import MaintenanceView from 'views/errors/maintenance.view'

import UserView from 'views/user.view'
import { useRecoilValueLoadable } from 'recoil'

import { NavHashLink } from 'react-router-hash-link'
import HeaderDropdown from 'components/Header/HeaderDropdown.component'

import { profileSelector } from 'selectors/selectors'
import { LoaderFallback } from './components/Loader/Loader.component'
import getConfig from './config.service'
import TermsAndConditionsView from './views/terms-and-conditions.view'
import Privacy from './views/privacy.view'
/* import BridgeView from './views/bridge.view' */
import { DeckBuilderView } from './views/deckbuilder/deck-builder.view'
import AllCardsView from './views/all-cards.view'
import CardsView from './views/marketplace/my-cards.view'

import StarterPackView from './views/starter-pack.view'
import Header from './components/Header/Header.component'
import security from './assets/images/shall_not_pass.jpeg'
import StoreView from "./views/store/store.view";
import PurchasesView from "./views/purchases/purchases.view";
import RewardsView from "./views/rewards/rewards.view";
import GridExample from "./views/grid-example/grid.view";
import TutorialsView from "./views/tutorials/tutorials.view";
import TournamentsView from 'views/tournaments/tournaments/tournaments.view'
import TournamentDetailView from 'views/tournaments/tournamentDetail/TournamentDetail.view'
import TournamentsInfoView from 'views/tournaments/tournamentsInfo/tournamentInfo.view'
import PlayerCashierView from 'views/player-cashier/player-cashier.view'
import ReferralView from "./views/referral/referral.view";
import CookieConsent from "react-cookie-consent";
import TournamentsTermsAndConditionsView from 'views/tournaments/tournamentsTermsAndConditions/tournamentTermsAndConditions.view'
import EconomyToolsView from 'views/economy-tools/economy-tools.view'
import ProfileView from 'views/profile/profile.view'
import AchievementsView from 'views/achievements/achievements.view'
import ChallengesView from 'views/challenges/challenges.view'
import PreloadCardAssets from "./components/deckbuilder/Card/preload-card-assets";
import CraftingView from 'views/crafting/crafting.view'
import LeaguesView from 'views/leagues/leagues.view'
import ExchangeView from 'views/exchange/exchange.view'
import AirdropView from 'views/airdrop/airdrop.view'
import TwitterOauthCallbackPage from 'views/social-login/callback/twitter'
import StakingView from 'views/staking/staking.view'

const config = getConfig()
const { ENABLE_MY_CARDS, ENABLE_DECK_BUILDER, ENABLE_BRIDGE,
  ENABLE_STORE, ENABLE_MY_BOOSTERS, ENABLE_TICKETS, ENABLE_TOURNAMENTS,
  ENABLE_ACHIEVEMENTS, ENABLE_CHALLENGES, ENABLE_CRAFTING } = config
const myInventory = [
  {
    isActive: ENABLE_MY_BOOSTERS,
    to: '/boosters',
    text: 'My boosters',
  },
  {
    isActive: ENABLE_MY_CARDS,
    to: '/my-cards',
    text: 'My NFT cards',
  },
  {
    isActive: ENABLE_DECK_BUILDER,
    to: '/deck-builder',
    text: 'My decks',
  },
  /*   {
      isActive: ENABLE_BRIDGE,
      to: '/bridge',
      text: 'Bridge',
    }, */
  {
    isActive: ENABLE_TICKETS,
    to: '/tickets-nft-tokens',
    text: 'Tickets & NFT Tokens',
  },
  {
    isActive: true,
    to: '/dev-exchange',
    text: '$DEV Exchange',
  },
  {
    isActive: true,
    to: '/staking',
    text: '$DEV Staking',
  },
  {
    isActive: ENABLE_CRAFTING,
    to: '/crafting',
    text: 'Crafting',
  }
]

const LoadIfAuth = ({ children }: { children: any }) => {
  const profile = useRecoilValueLoadable(profileSelector)

  if (!profile.contents?.info) {
    return <div className="connect-wallet message-alert" style={{
      width: '100%',
      height: 'calc(100vh - 13px)',
      marginTop: -130,
      display: 'flex',
      background: `linear-gradient(180deg, #000 0.26%, rgba(0,0,0, 0) 89%),url(${security})`,
      backgroundSize: 'cover',
    }}><h2 /><h1 style={{
      filter: 'drop-shadow(0px 2px 1px black)',
    }}>Hi there Deviant, you must be logged in to see this page.</h1><h2 style={{ filter: 'drop-shadow(0px 2px 1px black)' }}>Please click the connect button to login.</h2>
      {/* <ConnectWalletButton onlyConnect/> */}
    </div>
  }

  return <>{children}</>
}

const App = memo(() => {
  // const released = config.devFeatures

  // const marketPlace = config.ENABLE_MY_SALES && config.ENABLE_PURCHASE && (
  //   <HeaderDropdown sectionTitle={'Marketplace'} >
  //     <NavLink
  //       to="/store"
  //       className="navigation-link "
  //     >
  //       <span>Store</span>
  //     </NavLink>
  //     <NavLink
  //       to="my-sales"
  //       className="navigation-link "
  //     >
  //       <span>my sales</span>
  //     </NavLink>
  //   </HeaderDropdown>
  // )

  {/* <NavLink
      to="/deck-select"
      activeClassName="navigation-link-selected"
      className="navigation-link"
    >
      <span id="header-inventory">play</span>
    </NavLink> */}

  const play = config.showPlayButton ? (
    <HeaderDropdown sectionTitle={'Play'}>

      <NavLink
        to="/deck-select"
        activeClassName="navigation-link-selected"
        className="navigation-link"
      >
        <span id="header-inventory">Casual Play</span>
      </NavLink>

      <NavLink
        to="/tournaments"
        activeClassName="navigation-link-selected"
        className="navigation-link"
      >
        <span id="header-tournaments">Tournaments</span>
      </NavLink>

      <NavLink
        to="/play-2-airdrop"
        activeClassName="navigation-link-selected"
        className="navigation-link"
      >
        <span id="header-tournaments">Play2Airdrop</span>
      </NavLink>

      <a
        href="/leagues"
        /* activeClassName="navigation-link-selected" */
        className="navigation-link"
      >
        <span id="header-league">Leagues</span>
      </a>


      {
        config.tutorials ? (
          <NavLink
            to="/tutorials"
            className="navigation-link "
          >
            <span>How to play</span>
          </NavLink>
        ) : null
      }
    </HeaderDropdown >
  ) : <></>


  // const guilds = released ? (
  //   <NavLink
  //     to=""
  //     className="navigation-link disabled"
  //   >
  //     <span id="header-inventory">guilds</span>
  //   </NavLink>
  // ) : <></>

  return (
    <Router>
      <Header urlLogo="/">
        {/* {items.map(item => HeaderMenuItemFactory.create(item))} */}

        <HeaderDropdown sectionTitle={'Game info'}>
          <NavHashLink
            to="/#featured"
            className="navigation-link "
          >
            <span>Cards</span>
          </NavHashLink>
          <NavHashLink
            to="/#factions"
            className="navigation-link "
          >
            <span>factions</span>
          </NavHashLink>
          <NavHashLink
            smooth
            to="/#partners"
            activeClassName="navigation-link-selected"
            className="navigation-link"
          >
            <span>Our Partners</span>
          </NavHashLink>
          <NavHashLink
            smooth
            to="/all-cards"
            activeClassName="navigation-link-selected"
            className="navigation-link"
          >
            <span>all cards</span>
          </NavHashLink>
          {
            config.tutorials ? (
              <NavLink
                to="/tutorials"
                className="navigation-link "
              >
                <span>Tutorials</span>
              </NavLink>
            ) : null
          }
        </HeaderDropdown>
        {/* marketPlace */}
        {ENABLE_STORE &&
          <NavHashLink
            smooth
            to="/store"
            activeClassName="navigation-link-selected"
            className="navigation-link"
          >
            <span id="buy-starter-packs">Store</span>
          </NavHashLink>
        }
        {/* {ENABLE_TOURNAMENTS &&
          <NavHashLink
            smooth
            to="/tournaments"
            activeClassName="navigation-link-selected"
            className="navigation-link"
          >
            <span id="tournaments">Tournaments</span>
          </NavHashLink>
        } */}
        {play}

        { /* guilds */}

        {(myInventory.filter(({ isActive }) => isActive).length > 1) ? (
          <HeaderDropdown sectionTitle={'my inventory'}>
            {myInventory.filter((item) => item.isActive).map((item) => (
              <NavHashLink
                smooth
                to={item.to}
                key={item.to}
                activeClassName="navigation-link-selected"
                className="navigation-link"
              >
                <span>{item.text}</span>
              </NavHashLink>
            ))}
          </HeaderDropdown>
        ) : (
          myInventory.filter((item) => item.isActive).map((item) => (
            <NavLink
              to={item.to}
              key={item.to}
              activeClassName="navigation-link-selected"
              className="navigation-link"
            >
              <span id="header-inventory">{item.text}</span>
            </NavLink>
          ))
        )}

        <HeaderDropdown sectionTitle={'About'}>
          <a
            href="https://docs.deviantsfactions.com"
            className="navigation-link"
            target="_blank" rel="noreferrer"
          >
            <span>Rulebook</span>
          </a>
          <a
            href="https://cerebralgames.freshdesk.com/support/solutions/103000014311"
            className="navigation-link"
            target="_blank" rel="noreferrer"
          >
            <span>Faq</span>
          </a>

          <a
            href="https://cerebralgames.freshdesk.com/support/solutions/folders/103000035532"
            className="navigation-link"
            target="_blank" rel="noreferrer"
          >
            <span>about us</span>
          </a>

          <NavHashLink
            smooth
            to="/#footer"
            activeClassName="navigation-link-selected"
            className="navigation-link"
          >
            <span>social networks</span>
          </NavHashLink>
          <a
            href="https://cerebralgames.freshdesk.com/support/tickets/new"
            className="navigation-link"
            target="_blank" rel="noreferrer"
          >
            <span>contact</span>
          </a>
          {
            config.tutorials ? (
              <NavLink
                to="/tutorials"
                className="navigation-link "
              >
                <span>Tutorials</span>
              </NavLink>
            ) : null
          }
        </HeaderDropdown>
      </Header>

      <main id="homepage-initialization">
        <Switch>
          <Route exact path="/play-2-airdrop">
            <AirdropView />
          </Route>
          <Route path="/deck-builder">
            <LoadIfAuth>
              <DeckBuilderView />
            </LoadIfAuth>
          </Route>
          <Route path="/project-info">
            <ProjectInfoView />
          </Route>
          <Route path="/boosters">
            <LoadIfAuth>
              <StarterPackView />
            </LoadIfAuth>
          </Route>

          <Route path='/oauth/callback/x'>
            <LoadIfAuth>
              <TwitterOauthCallbackPage />
            </LoadIfAuth>
          </Route>

          <Route path="/maintenance">
            <MaintenanceView service={'GAME'} />
          </Route>
          <Route exact path="/all-cards">
            <AllCardsView />
          </Route>
          <Route exact path="/referral">
            <LoadIfAuth>
              <ReferralView />
            </LoadIfAuth>
          </Route>
          <Route exact path="/store">
            <StoreView />
          </Route>
          <Route exact path="/purchases">
            <LoadIfAuth>
              <PurchasesView />
            </LoadIfAuth>
          </Route>
          <Route exact path="/rewards">
            <LoadIfAuth>
              <RewardsView />
            </LoadIfAuth>
          </Route>
          <Route exact path="/grid-example">
            <GridExample />
          </Route>
          <Route exact path="/my-cards">
            <LoadIfAuth>
              <CardsView />
            </LoadIfAuth>
          </Route>
          <Route exact path="/profile">
            <UserView />
          </Route>
          <Route exact path="/deck-select">
            <LoadIfAuth>
              <Suspense fallback={<LoaderFallback text={'loading ...'} />}>
                <PlayView />
              </Suspense>
            </LoadIfAuth>
          </Route>
          <Route exact path="/tutorials">
            <TutorialsView />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditionsView />
          </Route>
          <Route exact path="/privacy">
            <Privacy />s
          </Route>
          <Route exact path="/tournaments" >
            <TournamentsView />
          </Route>
          <Route exact path="/tournaments/info" >
            <TournamentsInfoView />
          </Route>
          <Route exact path="/tournaments/terms-and-conditions" >
            <TournamentsTermsAndConditionsView />
          </Route>
          <Route exact path="/tournaments/:id" >
            <TournamentDetailView />
          </Route>
          <Route exact path="/" >
            <HomeView />
          </Route>
          {/* <Route path="/bridge">
            <LoadIfAuth>
              <BridgeView />
            </LoadIfAuth>
          </Route> */}
          <Route path="/tickets-nft-tokens">
            <LoadIfAuth>
              <PlayerCashierView />
            </LoadIfAuth>
          </Route>
          <Route path="/dev-exchange">
            <LoadIfAuth>
              <ExchangeView />
            </LoadIfAuth>
          </Route>
          <Route path="/crafting">
            <LoadIfAuth>
              <CraftingView />
            </LoadIfAuth>
          </Route>
          <Route path="/enchiladas">
            <LoadIfAuth>
              <EconomyToolsView />
            </LoadIfAuth>
          </Route>
          <Route path="/user-profile">
            <LoadIfAuth>
              <ProfileView />
            </LoadIfAuth>
          </Route>
          {ENABLE_ACHIEVEMENTS &&
            <Route path="/achievements">
              <LoadIfAuth>
                <AchievementsView />
              </LoadIfAuth>
            </Route>
          }
          {ENABLE_CHALLENGES &&
            <Route path="/challenges">
              <LoadIfAuth>
                <ChallengesView />
              </LoadIfAuth>
            </Route>
          }

          <Route path="/leagues">
            <LoadIfAuth>
              <LeaguesView />
            </LoadIfAuth>
          </Route>

          <Route path="/staking">
            <LoadIfAuth>
              <StakingView />
            </LoadIfAuth>
          </Route>

          <Route path="*">
            <ErrorView title={'Page not available'} type={'404 not found'} description={'This page is not available, please check if you are entering the correct link.'} />
            {/* <Redirect to="/" /> */}
          </Route>
        </Switch>
      </main>
      <CookieConsent
        style={{ background: "#111110" }}
        buttonStyle={{ backgroundColor: "#FF5C00", color: "#FFFFFF", fontSize: "13px" }}

      >This website uses cookies to enhance the user experience.</CookieConsent>
      <PreloadCardAssets />
    </Router >
  )
})

App.displayName = 'Deviants Factions'

export default App
