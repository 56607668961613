// noinspection UnnecessaryLocalVariableJS

import './rewards.component.scss'
// eslint-disable-next-line camelcase
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil'
import {
  connectedWallet,
  paginatedRewardsSelector,
  profileBalancesSelector,
} from 'selectors/selectors'
import { Grid, _ } from 'gridjs-react'
import { /* useEffect, */ useState } from 'react'
import RewardRepository from 'repositories/rewards.repository'
import Reward from 'domain/reward.model'
import moment from 'moment'
import { LoaderFallback, setLoader } from 'components/Loader/Loader.component'
import Pagination from './pagination.component'
/* import { TournamentTicket } from 'domain/tournament-ticket.model'
import { AssignedAchievement } from 'domain/assignedAchievement.model'
import { AssignedChallenge } from 'domain/challenges.model' */

export const rewardsTableStyle = {
  td: {
    padding: '10px 5px',
    alignContent: 'center',
    fontSize: '1.15em',
    borderBottom: '1px solid #303130',
    fontWeight: '600',
  },
  th: {
    textAlign: 'start',
    padding: '0px',
    color: '#ff5c00',
    fontSize: '1.3em',
  },
  table: {
    'font-family': 'Rajdhani',
  },
}

export default function RewardsComponent() {
  const user = useRecoilValue(connectedWallet)
  const [rewards, setRewards] = useState<Reward[]>([])
  const [claiming, setClaiming] = useState<boolean>(false)
  /* const [loading, setLoading] = useState<boolean>(false) */
  const [errorClaiming, setErrorClaiming] = useState<boolean>(false)
  const [pagination, setPagination] = useState<{ page: number; pageSize: number }>({ page: 1, pageSize: 8 })

  const repository = useRecoilValueLoadable(paginatedRewardsSelector(pagination))
  const refreshRepository = useRecoilRefresher_UNSTABLE(paginatedRewardsSelector(pagination))
  const refreshBalances = useRecoilRefresher_UNSTABLE(profileBalancesSelector)

  /*   const resolveRewardName = (
      rewardsArray: Reward[],
      ticketsCatalog: TournamentTicket[],
      achievements: AssignedAchievement[],
      challenges: AssignedChallenge[],
    ): Reward[] => {
      const newRewards = rewardsArray.map((r) => {
        r.setOriginToDisplay(r.origin)
        if (r.origin === 'TOURNAMENT') {
          r.setOriginToDisplay(`TOURNAMENT ${r.originId}`)
        }
        if (r.origin === 'ACHIEVEMENT') {
          const achievement = achievements.find((a) => a.id === r.originId)
          r.setOriginToDisplay(`ACHIEVEMENT - ${achievement?.name ?? ''}`)
        }
        if (r.origin === 'CHALLENGE') {
          const challenge = challenges.find((c) => c.id === r.originId)
          r.setOriginToDisplay(`CHALLENGE - ${challenge?.name ?? ''}`)
        }
        if (r.currency === 'FREE_ROLL_TICKET') {
          const ticket = ticketsCatalog.find((t) => t.internalName === r.assetTypeFieldValue)
          r.setNameToDisplay(ticket?.name ?? '')
          return r
        }
        r.setNameToDisplay(`${r.currency.replace(/_/g, ' ')} ${r.assetTypeFieldValue ? r.assetTypeFieldValue : ''}`)
        return r
      })
      return newRewards
    } */

  /*  useEffect(() => {
     setLoading(true)
     if (repository.state === 'hasValue') {
       setRewards(resolveRewardName(
         repository.contents[0].map((r) => r.clone()),
         repository.contents[1],
         repository.contents[2],
         repository.contents[3],
       ))
       setLoading(false)
     }
   }, [repository]) */

  const claimReward = (rewardId: string) => {
    setClaiming(true)
    setErrorClaiming(false)
    RewardRepository.claimReward(rewardId.toString(), user?.address)
      .then((updatedReward) => {
        setRewards(rewards.map((r) => (r.id === updatedReward.id ? updatedReward : r)))
        refreshRepository()
        refreshBalances()
      })
      .catch(() => {
        setErrorClaiming(true)
      })
      .finally(() => {
        setClaiming(false)
      })
  }

  const getStateComponent = (reward: Reward, key: string | number) => {
    const { state, id, txHash } = reward
    if (state === 'PENDING' && !txHash) {
      return _(<div className='claim-button-wrapper'>
        <div key={key} id={`claim-${id}`} className="btn btn-medium btn-primary" onClick={() => claimReward(id)}>Ready to claim!</div>
      </div>)
    }
    return _(<div className='claim-button-wrapper'>
      <div key={key} className="btn btn-medium btn-outline disabled">CLAIMED</div>
    </div>)
  }

  const claimCompare = (elementA: any, elementB: any) => {
    const valueA = elementA.props.element.props.children.props.children
    const valueB = elementB.props.element.props.children.props.children
    return valueB > valueA ? 1 : -1
  }

  if (claiming) return <LoaderFallback text={'Please wait ...'} />
  setLoader(false)

  return (
    <div className="rewards">
      {errorClaiming && <h2>Error Claiming. Please retry later...</h2>}
      <Grid
        data={repository.contents?.data?.map((reward: any, i: number) => [
          `${reward.currency.replace(/_/g, ' ').replace(/XDEV'/g, 'DEV')} ${reward.assetTypeFieldValue}`.toUpperCase(),
          reward.amount,
          moment(reward.date).utc().format('YYYY-MM-DD'),
          reward.origin,
          reward.txHash ?? '-',
          getStateComponent(reward, i),
        ]) || []}
        columns={[
          { id: 'reward', name: 'REWARD' },
          { id: 'qty', name: 'QUANTITY' },
          { id: 'date', name: 'DATE' },
          { id: 'origin', name: 'ORIGIN' },
          { id: 'txid', name: 'TRANSFER ID' },
          { id: 'status', name: 'STATUS', sort: { compare: claimCompare } },
        ]}
        style={rewardsTableStyle}
        search={false}
        pagination={false}
        sort={true}
      />
      {repository.contents.data
        && <Pagination current={pagination.page} pageSize={pagination.pageSize} total={repository.contents.totalPages}
          onChange={(page) => setPagination({ page, pageSize: pagination.pageSize })} />}
    </div >
  )
}
