import './TournamentRewardsTable.component.scss'
import { Tournament, TournamentAssetReward, TournamentMoneyReward } from 'domain/tournament.model'
// import moment from 'moment'
import RewardTableItem from './TournamentRewardsTableItem.component'

export default function TournamentRewardsTable({ tournament }: { tournament: Tournament }) {
  const rewards: (TournamentAssetReward | TournamentMoneyReward)[][] = tournament?.rewardPool?.rewards || []
  // const endsHoursAgo = moment(tournament.startDate).diff(moment.now(), 'hours')
  if (rewards) {
    return (
      <div className="rewards-table" >
        <div className="rewards-table__title">Rewards</div>
        <div className='rewards-table__items scroll'>
          {rewards.map((reward, i) => (
            <RewardTableItem showConvertions={false} position={i} reward={reward} key={`reward-item-${i}`} />
          ))}
        </div>
      </div>
    )
  }

  return <></>
}
