import { useState } from 'react'
import './header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/Button/Button.component'
import ConnectWalletButton from 'components/connect-wallet-button/connect-wallet-button.component'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useMediaQuery } from 'react-responsive'
import desktop from '../../assets/logo.png'
import leftholder from './assets/header-left-bg.png'
import rightholder from './assets/header-right-bg.png'
import mobiletholder from './assets/header-mobile-bg.png'
import iconmenu from '../../assets/icons/icon-menu.svg'

type HeaderT = {
  children?: any;
  className?: string;
  urlLogo?: string;
}

export default function Header({ children, urlLogo }: HeaderT) {
  const [showMenu, setShowMenu] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' })
  const closeMenu = () => {
    setShowMenu((value) => !value)
  }

  return (
    <header>
      <div
        className="header-left-bg header-holder"
        style={{ backgroundImage: `url(${leftholder})` }}
      ></div>
      {isTabletOrMobile && <nav className={` ${showMenu ? 'show' : 'hide'}`} >
        <div onClick={closeMenu}>
        {children}
        </div>
        <ConnectWalletButton
          className="btn-connect-mobile"
          id="header-wallet-connect"
        >
          <p id="header-wallet-connect">Connect</p>
        </ConnectWalletButton>
      </nav>}
      <div className="logo">
        {urlLogo ? (
          <a href={urlLogo}>
            <img className="logo" alt="Deviants Factions" src={desktop} />
          </a>
        ) : (
          <img className="logo" alt="Deviants Factions" src={desktop} />
        )}
      </div>
      <div className="menu">
        <nav>{children}</nav>
      </div>
      <div className="button-holder" onClick={closeMenu}>
        <Button size="small" variant="outline" className="btn-menu" right>
          <img src={iconmenu} className={` ${showMenu ? 'hide' : 'show'}`} />
          <FontAwesomeIcon
            id="close-menu"
            icon={faTimes as IconProp}
            className={` ${showMenu ? 'show' : 'hide'}`}
          />
        </Button>
      </div>

      <ConnectWalletButton className="btn-connect" id="header-wallet-connect">
        <p id="header-wallet-connect">Connect</p>
      </ConnectWalletButton>

      <div
        className="header-right-bg header-holder"
        style={{ backgroundImage: `url(${rightholder})` }}
      ></div>

      <div
        className="header-mobile-bg"
        style={{ backgroundImage: `url(${mobiletholder})` }}
      ></div>
    </header>
  )
}
