type Type = 'ERC20' | 'ERC721'

export default class Token {
  private fungible: boolean

  private type: Type

  constructor(type: Type, fungible: boolean) {
    this.type = type
    this.fungible = fungible
  }
}
