import './TournamentJoinSuccessModal.component.scss'
import Button from 'components/Button/Button.component'
import CrystalModal from 'components/CrystalModal/CrystalModal.component'
import { useRecoilState } from 'recoil'
import { isShowJoinSuccessModalAtom } from '../tournamentDetail.recoil'

export default function TournamentJoinSuccessModal() {
  const [isShow, setIsShow] = useRecoilState(isShowJoinSuccessModalAtom)

  function close() {
    setIsShow(false)
  }

  return (
    <CrystalModal title="SUBMITTED!" isActive={isShow} closeAction={close} id={'join-sucess-modal'}>
      <div className="tournament-join-success-modal">
        <div className="tournament-join-success-modal__title">Subscription in process!</div>
        <Button variant={'primary'} size={'medium'} onClick={close}>Yeah!</Button>
      </div>
    </CrystalModal>
  )
}
