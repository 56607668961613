import Rectangle from 'components/Rectangle/Rectangle.component'
import Flex from 'components/Flex/Flex'
import { useRecoilValueLoadable } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import useDevConvertion from 'views/exchange/hooks/useDevConvertion.hook'
import useBalance from 'views/exchange/hooks/useBalance.hook'
import IconDEV from '../../../assets/icons/icon-dev-new.png'
import IconsDEV from '../../../assets/icons/icon-sdev.png'

type StakingMyDevProps = {
  stakeAction: () => void
}

export default function StakingMyDev(props: StakingMyDevProps) {
  const { stakeAction } = props

  const inGameDev = useRecoilValueLoadable(profileBalancesSelector).contents?.xdev || 0
  const usd = useDevConvertion(inGameDev)

  const { balance: inWalletDev } = useBalance('dev')
  const inWalletDevUsd = useDevConvertion(inWalletDev)

  return <>
    <h1>MY $DEV</h1>
    <Flex flexDirection='row' gap='10px'>
      <Rectangle classname='rectangle' width='400px'>
        <Flex flexDirection='column' gap="10px">
          <h2 className="staking-view-subtitle">MY $DEV</h2>
          <Flex flexDirection='row' gap="10px">
            <div style={{ width: '40px' }}><img src={IconDEV} alt={'aaa'} height="40px" /></div>
            <div style={{ width: '220px' }}>
              <p><span>In Game</span> {inGameDev} (${usd.toFixed(2)})</p>
              <p><span>In Wallet</span> {inWalletDev} (${inWalletDevUsd.toFixed(2)})</p>
            </div>
            <div style={{ width: '90px' }}>
              <div className='manage-button-wrapper'>
                <div key={'aa'} className="btn-convert btn btn-primary" onClick={() => { window.location.href = '/dev-exchange' }}>MANAGE</div>
              </div>
            </div>
          </Flex>
        </Flex>
      </Rectangle>
      <Rectangle classname='rectangle' width='400px'>
        <Flex flexDirection='column' gap="10px">
          <h2 className="staking-view-subtitle">Why Staking DEV?</h2>
          <Flex flexDirection='row' gap="10px">
            <div style={{ width: '40px' }}><img src={IconsDEV} alt={'aaa'} height="40px" /></div>
            <div style={{ width: '220px' }}>
              <p>When you stake, you get <strong>sDEV</strong> yield to exchange for rewards!</p>
            </div>
            <div style={{ width: '90px', fontWeight: 'bold' }}>
              <div className='manage-button-wrapper'>
                <div key={'aa'} className="btn-convert btn btn-primary" onClick={() => stakeAction()}>STAKE 🚀</div>
              </div>
            </div>
          </Flex>
        </Flex>
      </Rectangle>
    </Flex>
  </>
}
