import './challenges.view.scss'
import ChallengesComponent from 'components/Challenges/Challenges.component'
import Footer from '../../components/Footer/Footer.component'

export default function ChallengesView() {
  return (
    <div className="challenges-view">
      <ChallengesComponent />
      <div className='challenges-view__footer'> </div>
      <Footer />
    </div>
  )
}
