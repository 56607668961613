import './banner.scss'

export type BannerType = {
  text: string,
  children?: any,
}

export default function Banner({
  text,
  children,
}: BannerType) {
  return (
  <div className="banner">
    <div className="banner-left"></div>
    <div className="banner-container">
      <p className="banner-container__text">
        {text}
      </p>
      <span className="banner-container__decal"></span>
      {children}
    </div>
    <div className="banner-right"></div>
  </div>
  )
}
