import Button from 'components/Button/Button.component'
import Footer from 'components/Footer/Footer.component'

type MaintenanceViewType = {
  service: string,
}

export default function MaintenanceView({
  service,
}:MaintenanceViewType) {
  return (
    <div className="maintenance-view">
      <div className="maintenance-content">
        <h1 className="maintenance-title">Deviant&apos;s Faction&apos;s {service} under maintenance</h1>
        <p className="maintenance-description">we will be back soon, sorry for the inconvenience</p>
        <div className="maintenance-actions">
        <Button variant={'outline'} size={'medium'}>contact</Button>
        <Button variant={'primary'} size={'medium'}>back to home</Button>
        </div>
        <p className="maintenance-message">
        Please come back later or check our social networks
        </p>
        <div className="footer-social-networks">
        <h6 className="maintenance-social-title">Social Network</h6>
        <div className="footer-social-networks-list">
          <a target="_blank" className="btn-shadow" href="https://twitter.com/DeviantsNFT/" rel="noreferrer" >
            <div className="btn btn-icon btn-small btn-left">
              <span className="footer-icon icon-twitter" id="footer-twitter"></span>
            </div>
          </a>
          <a target="_blank" className="btn-shadow" href="https://discord.gg/ZkKDCUeZXB" rel="noreferrer" >
            <div className="btn btn-icon btn-small btn-right">
              <span className="footer-icon icon-discord" id="footer-discord"></span>
            </div>
          </a>
          <a target="_blank" className="btn-shadow" href="https://t.me/deviantsNFT" rel="noreferrer" id="footer-telegram">
            <div className="btn btn-icon btn-small btn-left">
              <span className="footer-icon icon-telegram" id="footer-telegram"></span>
            </div>
          </a>
          <a target="_blank" className="btn-shadow" href="https://deviantsnft.medium.com/" rel="noreferrer" id="footer-medium">
            <div className="btn btn-icon btn-small btn-right">
              <span className="footer-icon icon-medium" id="footer-medium"></span>
            </div>
          </a>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  )
}
