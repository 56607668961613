import { useEffect, useState } from 'react'
import BalanceRepository from 'repositories/balance.repository'
import useDevConvertion from './useDevConvertion.hook'

export default function useTokenToDevConvertion({
  amount,
  token,
}: { amount: number, token: string }) {
  const [usd, setUsd] = useState(0)
  const devPriceInUsd = useDevConvertion(1)

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const convert = async () => {
      if (amount === 0) return setUsd(0)
      const inGameTokenUsdEquivalence = await BalanceRepository.convertToken(token)
      setUsd(inGameTokenUsdEquivalence)
    }
    convert()
  }, [amount, token])
  return (amount * usd) / (devPriceInUsd)
}
