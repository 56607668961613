import { rewardsTableStyle } from 'components/rewards/rewards.component'
import { Grid } from 'gridjs-react'
import { useRecoilValueLoadable } from 'recoil'
import { ExchangeRate } from 'repositories/rewards.repository'
import { currencyConvertionsSelector } from 'selectors/selectors'

export default function EconomyCurrencyExchangeGrid() {
  const rates = useRecoilValueLoadable<ExchangeRate[]>(currencyConvertionsSelector)

  if (rates.state === 'loading') return <p>Loading...</p>

  return <Grid
    columns={[
      { id: 'from', name: 'FROM' },
      { id: 'to', name: 'TO' },
      { id: 'rate', name: 'RATE' },
      { id: 'date', name: 'DATE' },
    ]}
    style={{
      ...rewardsTableStyle,
      table: {
        width: '100%',
      },
      td: {
        ...rewardsTableStyle.td,
        fontSize: '0.8em',
      },
    }}
    data={rates.contents.map((t: ExchangeRate) => [
      t.from,
      t.to,
      `1 ${t.from} = ${t.rate} ${t.to}`,
      t.date,
    ])} />
}
