import { motion } from 'framer-motion/dist/framer-motion'
import Card from 'components/deckbuilder/Card/Card.component'
import { CardType } from '../../domain/card.model'

const AnimatedCard = ({ cardItem, onClick }: { cardItem: CardType, onClick: Function }) => {
  const { TokenIds, ListedTokens } = cardItem as any
  return <motion.div
    className="card-layout"
    variants={{
      hidden: {
        opacity: 0,
        y: -50,
        rotate: -50,
        scale: 0.1,
      },
      visible: (i: any) => ({
        opacity: 1,
        y: 0,
        rotate: 0,
        scale: 1,
        transition: {
          delay: i * 0.05,
        },
      }),
    }}
    transition='visible'
    initial='hidden'
    animate='visible'
    exit='hidden'
    custom={cardItem.id}
    style={{
      height: 'fit-content',
    }}
    layout
    layoutId={cardItem.id}
    onClick={() => onClick(cardItem)}
    key={`nft_card${cardItem.id}`}
  >
    <Card {...cardItem} />
    <div className="nft-amount">{TokenIds.length - ListedTokens.length}</div>
    <div className="nft-background" />
  </motion.div>
}

export default AnimatedCard
