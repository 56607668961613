import './TournamentDetailHero.component.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarAlt, faUsers, faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import MetalContainer from 'components/MetalContainer/MetalContainer.component'
import InfoHex from 'assets/icons/infohexagon.svg'
import { Tournament } from 'domain/tournament.model'
import { Link } from 'react-router-dom'
import PrizePool from 'views/tournaments/tournaments/components/PrizePool.component'
import EntryFee from 'views/tournaments/tournaments/components/EntryFee.component'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import JoinTournamentButton from './JoinTournamentButton.component'
import { currentTournamentAtom } from '../tournamentDetail.recoil'
// eslint-disable-next-line import/extensions
import { DeckFormatsSelector } from '../../../../selectors/selectors'
import { DeckFormatDto } from '../../../../services/deck-formats.service'
import SubscriptionStateComponent from './SubscriptionState.component'

function getTopInfo(tournament: Tournament) {
  if (tournament.state === 'ONGOING') return `Phase ${tournament.currentStageIndex + 1}`
  return <>Starts at <FontAwesomeIcon icon={faCalendarAlt as IconProp} />{moment(tournament.startDate).format('MMMM DD, YYYY z HH:mm')}</>
}

function getStateOrAction(tournament: Tournament, userId: string) {
  if (tournament.state === 'ANNOUNCED') return <div className="tournament-hero-center__state tournament-hero-center__state--announced">Coming Soon</div>
  if (tournament.state === 'ENDED') return <div className="tournament-hero-center__state tournament-hero-center__state--ended">Finished</div>
  if (tournament.state === 'CREATED') return <div className="tournament-hero-center__state tournament-hero-center__state--created">Created</div>
  const isFull = tournament.subscribedPlayers?.length >= tournament.maxPlayers
  if (tournament.state === 'PUBLISHED' && isFull) return <div className="tournament-hero-center__state tournament-hero-center__state--full">It is Full</div>
  const userIsAlreadyRegistered = tournament.subscribedPlayers?.some((p) => p.playerId === userId)
  if (tournament.state === 'PUBLISHED' && userIsAlreadyRegistered) return <div className="tournament-hero-center__state tournament-hero-center__state--registered">Registered</div>
  if (tournament.state === 'PUBLISHED') return <JoinTournamentButton tournamentId={tournament.id} userId={userId} />
  if (tournament.state === 'ONGOING' && userIsAlreadyRegistered) return <div className="tournament-hero-center__state tournament-hero-center__state--ongoing">Registered</div>
  if (tournament.state === 'SUBSCRIPTION_CLOSED') return <div className="tournament-hero-center__state tournament-hero-center__state--ongoing">Subscriptions closed</div>
  if (tournament.state === 'ABORTED') return <div className="tournament-hero-center__state tournament-hero-center__state--ongoing">Aborted</div>
  return <></>
}

export default function TournamentDetailHero({ tournament, userId }: { tournament?: Tournament, userId?: string }) {
  const [, setTournament] = useRecoilState(currentTournamentAtom)
  const deckFormats = useRecoilValueLoadable(DeckFormatsSelector)

  function clearTournament() {
    setTournament(undefined)
  }

  const formatComponentDisplay = () => {
    if (deckFormats && deckFormats.contents && Array.isArray(deckFormats.contents.formats)) {
      const format = deckFormats.contents.formats.find(
        (df: DeckFormatDto) => df.id === tournament?.deckValidations?.deckFormat,
      )
      if (format) {
        return (<p style={{ marginTop: -20, marginBottom: 20 }}>
          {format.name} (Min {tournament?.deckValidations?.minNftCards} NFT)
        </p>)
      }
    }
    return <></>
  }

  if (!tournament) return <></>
  return (
    <MetalContainer>
      <div className="tournament-hero">
        <div className="tournament-hero-top">
          <Link to="/tournaments" className='tournament-hero-top__back' onClick={clearTournament}>
            <FontAwesomeIcon icon={faChevronLeft as IconProp} style={{ color: 'e5e5e5' }} />
            Back to tournaments
          </Link>
          <Link to="/tournaments/info" className='tournament-hero-top__info'>
            Tournaments info <img src={InfoHex} alt={'info-hex'} />
          </Link>
        </div>

        <div className="tournament-hero-center">
          <div className='tournament-hero-center__info'>
            {getTopInfo(tournament)}
          </div>
          <h1>{tournament.name}</h1>
          {formatComponentDisplay()}
          <div className="tournament-hero-center__state-or-action">
            {getStateOrAction(tournament, userId ?? '')}
          </div>
          <SubscriptionStateComponent tournamentId={tournament.id} />
        </div>

        <div className="tournament-hero-bottom">
          <EntryFee tournament={tournament} />
          <div className="tournament-hero-bottom__booking">
            <FontAwesomeIcon icon={faUsers as IconProp} />
            {tournament.subscribedPlayers?.length ?? 0}/{tournament.maxPlayers}
            <br />
            {tournament.minHumanPlayers > 0 && `Min ${tournament.minHumanPlayers} player(s) required to start`}
          </div>
          <PrizePool tournament={tournament} />
        </div>
      </div>
    </MetalContainer>
  )
}
