import { useContext, useMemo } from 'react'
import { CraftingIngredient } from 'domain/crafting-recipe.model'
import { CraftingContext } from '../crafting-context.component'

export default function useIsIngredientCompleted(ingredientId: string) {
  const { selectedRecipe, ingredientsAvailable, pickedNfts } = useContext(CraftingContext)
  const isCompleted = useMemo(() => {
    const ingredients = ingredientsAvailable?.get(ingredientId)
    const ingredientNeeded = selectedRecipe?.ingredients.find((ingredient) => ingredient.id === ingredientId)
    if (!ingredients || !ingredientNeeded) return false
    let quantityNeeded = ingredientNeeded?.quantity
    ingredients.forEach((ingredient) => {
      if (pickedNfts.includes(ingredient.getId())) {
        quantityNeeded -= 1
      }
    })
    return quantityNeeded === 0
  }, [pickedNfts.length, selectedRecipe])
  return isCompleted
}

export const useAreAllIngredientsCompleted = (ingredients: CraftingIngredient[]) => {
  const { selectedRecipe, ingredientsAvailable, pickedNfts } = useContext(CraftingContext)
  const isCompleted = (ingredientId: string) => {
    const ingredientsAvailables = ingredientsAvailable?.get(ingredientId)
    const ingredientNeeded = selectedRecipe?.ingredients.find((ingredient) => ingredient.id === ingredientId)
    if (!ingredientsAvailables || !ingredientNeeded) return false
    let quantityNeeded = ingredientNeeded?.quantity
    ingredientsAvailables.forEach((ingredient) => {
      if (pickedNfts.includes(ingredient.getId())) {
        quantityNeeded -= 1
      }
    })
    return quantityNeeded === 0
  }

  const isCompletedIngredients = ingredients.map((ingredient) => isCompleted(ingredient.id))
  const isAllCompleted = isCompletedIngredients.every((isIngredientCompleted) => isIngredientCompleted)
  return isAllCompleted
}
