import { CSSProperties, ReactNode } from 'react'
import './rectangle.component.scss'

export type RectangleProps = {
  children: ReactNode | ReactNode[],
  classname?: 'rectangle-df' | 'rectangle',
  width?: string,
  height?: string,
  style?: CSSProperties,
  direction?: 'left' | 'right'
}

export default function Rectangle({
  classname = 'rectangle-df',
  width,
  height,
  children,
  style,
}: RectangleProps) {
  return <div
    className={classname}
    style={{
      width,
      height,
      padding: '15px',
      ...style,
    }}>
    {children}
  </div>
}
