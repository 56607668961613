import { useEffect, useState } from 'react'
import BalanceRepository from 'repositories/balance.repository'

export default function useDevConvertion(dev?: number) {
  const [usd, setUsd] = useState(0)

  useEffect(() => {
    const convert = async () => {
      const inGameDevUsdEquivalence = await BalanceRepository.convertToken('deviantsfactions')
      setUsd(inGameDevUsdEquivalence)
    }
    convert()
  }, [dev])
  return usd * (dev || 0)
}
