/* eslint-disable import/prefer-default-export */
import { TaskDto } from 'domain/task.model'
import { selector } from 'recoil'
import Play2AirdropService from 'services/play2airdrop.service'
import UserRepository from 'infraestructure/repositories/users.repository'
import { AccumulatedXpByTask, LeaderboardEntry } from 'domain/play2airdrop.types'
// eslint-disable-next-line import/extensions
import { connectedWallet } from './selectors'

export const totalTaskLogsSelector = selector<AccumulatedXpByTask[]>({
  key: 'taskLogs',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return []
      const logs = await Play2AirdropService.getCompletedTotalTasks(address?.address)
      return logs
    } catch (err) {
      return []
    }
  },
})

export const dailyTaskLogsSelector = selector<AccumulatedXpByTask[]>({
  key: 'dailyTaskLogs',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return []
      const logs = await Play2AirdropService.getCompletedDailyTasks(address?.address)
      return logs
    } catch (err) {
      return []
    }
  },
})

export const tasksSelector = selector<TaskDto[]>({
  key: 'tasks',
  get: async ({ get }) => {
    try {
      const active = await Play2AirdropService.getActiveTasks()
      const address = get(connectedWallet)
      if (!address?.address) return []
      const completedTasks = await Play2AirdropService.getCompletedTasks(address?.address)
      return active.map((task) => ({
        ...task,
        isCompleted: completedTasks.some((completedTask) => completedTask.id === task.id),
      }))
    } catch (err) {
      return []
    }
  },
})

export const dailyXpSelector = selector<number>({
  key: 'daily-xp',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return 0
      const xp = await Play2AirdropService.getDailyXp(address?.address)
      return xp
    } catch (err) {
      return 0
    }
  },
})

export const xpSelector = selector<number>({
  key: 'xp',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return 0
      const xp = await Play2AirdropService.getTotalXp(address?.address)
      return xp
    } catch (err) {
      return 0
    }
  },
})

export const leaderboardSelector = selector<LeaderboardEntry[]>({
  key: 'leaderboard',
  get: async () => {
    try {
      const leaderboard = await Play2AirdropService.getLeaderboard()
      const userIds = leaderboard.map((entry) => entry.userId)
      const amountOver4500XP = leaderboard.filter((entry) => entry.xp >= 4500).length
      const users = await UserRepository.getByIds(userIds)
      const totalSegment = Math.floor(amountOver4500XP / 5)
      const getLevel = (i: number) => {
        if (i < totalSegment) return 'diamond'
        if (i < totalSegment * 2) return 'gold'
        if (i < totalSegment * 3) return 'silver'
        if (i < totalSegment * 4) return 'bronze'
        if (i < totalSegment * 5) return 'copper'
        return ''
      }
      const mappedLb = leaderboard.map((entry, i: number) => ({
        ...entry,
        position: i + 1,
        level: getLevel(i),
        user: users.find((user) => user.id === entry.userId),
      }) as LeaderboardEntry)
      return mappedLb
    } catch (err) {
      return []
    }
  },
})
