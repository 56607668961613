/* eslint-disable camelcase */
import Button from 'components/Button/Button.component'
import ButtonLoader from 'components/ButtonLoader/ButtonLoader.component'
import { DeckType } from 'domain/deck.model'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import { useState } from 'react'
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState, useRecoilValueLoadable,
} from 'recoil'
import { subscriptionStatusSelector } from 'selectors/selectors'
import useInterval from 'services/useInterval.hook'
import { isShowJoinModalAtom, tournamentUserDecksAtom } from '../tournamentDetail.recoil'

export default function JoinTournamentButton(
  { userId, tournamentId }: { userId: string, tournamentId: string },
) {
  const [, setIsShowJoinModal] = useRecoilState(isShowJoinModalAtom)
  const [isLodingJoinModal, setIsLodingJoinModal] = useState(false)
  const [, setDecks] = useRecoilState<DeckType[]>(tournamentUserDecksAtom)
  const subscriptionState = useRecoilValueLoadable(subscriptionStatusSelector(tournamentId))
  const subscriptionStateRefresh = useRecoilRefresher_UNSTABLE(subscriptionStatusSelector(tournamentId))

  function showJoinModal() {
    setIsLodingJoinModal(true)
    DeckRepository.getByWallet(userId).then((myDecks) => {
      setIsLodingJoinModal(false)
      setIsShowJoinModal(true)
      setDecks(myDecks)
    }).finally(() => {
      setIsLodingJoinModal(false)
    })
  }

  useInterval(() => {
    if (subscriptionState.contents?.state === 'in-progress') {
      subscriptionStateRefresh()
    }
  }, 1000)

  if (subscriptionState.contents?.state === 'subscribed') {
    return <></>
  }

  if (subscriptionState.contents?.state === 'in-progress' || subscriptionState.state === 'loading') {
    return <Button variant={'outline'} size={'medium'}>
      {subscriptionState.contents?.state === 'in-progress' && 'Subscribing...'}
      {subscriptionState.state === 'loading' && '...'}
    </Button>
  }

  return (
    <div className="join-tournament-button">
      <Button variant={'primary'} size={'medium'} onClick={showJoinModal} disabled={isLodingJoinModal || !userId}>
        Join Now! {isLodingJoinModal && <ButtonLoader />}
      </Button>
    </div>
  )
}
