import './partners.scss'

import Ri04 from './assets/logos/immutable_x.png'
import Ri07 from './assets/logos/starware.png'
import Ri08 from './assets/logos/token_trove.png'

export default function Partners() {
  return (
  <div className="partners-section" >
    <div className="partners-section-list" >
      <a target="_blank" href="https://www.immutable.com/" rel="noreferrer" className="investors-section-brand" >
        <img src={Ri04} alt="Immutable X" id="investors-IMMUTABLE"/>
      </a>
      <a target="_blank" href="https://starkware.co/" rel="noreferrer" className="investors-section-brand" >
        <img src={Ri07} alt="Starkware" id="investors-starkware"/>
      </a>
      <a target="_blank" href="https://tokentrove.com/" rel="noreferrer" className="investors-section-brand" >
        <img src={Ri08} alt="Token Trove" id="investors-tokentrove"/>
      </a>
    </div>
  </div>
  )
}
