import Rectangle from 'components/Rectangle/Rectangle.component'
import Flex from 'components/Flex/Flex'
import { useRecoilValueLoadable } from 'recoil'
import { stakedDevPoolSelector } from 'selectors/staking.selectors'
import StakingMyStakeItem from './mystake-item.component'

export default function StakingMyStake() {
  const stakes = useRecoilValueLoadable(stakedDevPoolSelector)
  return <>
    <Flex flexDirection='column' gap='10px' style={{ marginTop: '20px' }}>
      <h1>MY STAKED $DEV</h1>
      <Rectangle classname='rectangle'>
        <Flex flexDirection='column' gap="20px">
          {stakes.valueMaybe()?.pool.map((stake, index) => <StakingMyStakeItem key={index} staking={stake} />)}
        </Flex>
      </Rectangle>
    </Flex>
  </>
}
