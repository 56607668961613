/* eslint-disable camelcase */
import Flex from 'components/Flex/Flex'
import { Staking } from 'repositories/staking.repository'
import { useState } from 'react'
import moment from 'moment'
import Toast from 'components/Toast/Toast.component'
import Text from 'components/Text/Text'
import Button from 'components/Button/Button.component'
import { useHistory } from 'react-router-dom'
import useQuery from 'hooks/useQueryParams'
import { useRecoilRefresher_UNSTABLE } from 'recoil'
import { stakedDevPoolSelector } from 'selectors/staking.selectors'
import IconDEV from '../../../assets/icons/icon-dev-new.png'
import IconsDEV from '../../../assets/icons/icon-sdev.png'
import StakedCurrentRewardComponent from './staked-current-reward.component'
import useUnstake from '../hooks/useUnstake.hook'

type StakingMyStakeItemProps = {
  staking: Staking
}

export default function StakingMyStakeItem(props: StakingMyStakeItemProps) {
  const daysPassed = moment().utc().diff(moment(props.staking.startDate).utc().startOf('day'), 'days')
  const totalDays = moment(props.staking.endDate).utc().diff(moment(props.staking.startDate).utc().startOf('day'), 'days')
  const isUnstakeable = daysPassed > totalDays
  const params = useQuery()
  const history = useHistory()
  const [toast, setToast] = useState<'success' | 'error' | undefined>(params.get('unstaking_toast') as 'success' | 'error' | undefined)
  const refreshStaked = useRecoilRefresher_UNSTABLE(stakedDevPoolSelector)

  const { unstake, loading } = useUnstake({
    onError() {
      setToast('error')
    },
    onSuccess() {
      // window.location.href = '/staking?unstaking_toast=success'
      refreshStaked()
      history.push('/staking?unstaking_toast=success')
      setToast('success')
    },
  })

  return <>
    <Toast isActive={toast === 'error'} id='error-toast-unstaking' style={{ background: 'red' }} onClose={() => setToast(undefined)}>
      <Text fontWeight={600}>
        An error has ocurred, please reload the page and try again.
      </Text>
    </Toast>
    <Toast duration={5000} isActive={toast === 'success'} id='success-toast-unstaking' style={{ background: '#4e9e2c' }} onClose={() => setToast(undefined)}>
      <Text fontWeight={600} textShadow='1px 1px 4px #000'>
        Unstaked successfully! Please go to the <a style={{ color: '#fff', textDecoration: 'underline', fontWeight: 'bold' }} href='/rewards'>Rewards Section</a> in order to claim your DEV.
      </Text>
    </Toast>
    <Flex flexDirection='row' gap='5px' alignItems='center' justifyContent='space-evenly'>
      <div style={{ fontSize: '14px', color: 'white', width: '25px' }}>
        <img src={IconDEV} alt={'SDEV'} height="24px" />
      </div>
      <div style={{ width: '100px' }}>
        <Text>{props.staking.amount} $DEV</Text>
      </div>
      <div style={{ width: '100px' }}>
        <Text>{daysPassed > totalDays ? totalDays : daysPassed}/{totalDays} days</Text>
      </div>
      <div style={{ width: '100px' }}>
        <Text>{props.staking.multiplier * 100}% APY</Text>
      </div>
      <Flex flexDirection='row' gap="1ch" style={{ width: '310px' }} alignItems='center'>
        <Text>Daily Reward</Text>
        <img src={IconsDEV} alt={'aaa'} height="16px" />
        <StakedCurrentRewardComponent staking={props.staking} />
      </Flex>
      <div className='manage-button-wrapper' style={{ width: '100px' }}>
        {!loading && <Button disabled={!isUnstakeable || props.staking.unstaked} variant='primary_alt' size='medium' onClick={() => {
          unstake(props.staking.id)
        }}>UNSTAKE</Button>}
        {loading && <Text>Unstaking, please wait...</Text>}
      </div>
    </Flex>
  </>
}
