type TextProps = {
  children?: any,
  fontSize?: string | number,
  fontWeight?: string | number,
  color?: string,
  lineHeight?: string | number,
  textAlign?: string,
  textTransform?: string,
  letterSpacing?: string | number,
  textDecoration?: string,
  whiteSpace?: string,
  wordBreak?: string,
  wordWrap?: string,
  fontFamily?: string,
  fontStyle?: string,
  textShadow?: string,
  textOverflow?: string,
  title?: string,
}

export default function Text(props: TextProps) {
  const style = { ...props } as any
  delete style.children
  return <p className='df-text' title={props.title} style={{ ...style }}>{props.children}</p>
}
