import { useState, useEffect } from 'react'
import classNames from 'classnames'

import './checkbox.component.scss'

type CheckboxType = {
  id?: string;
  text?: string;
  disabled: boolean;
  checked: boolean;
  onChange?: Function;
  onClick?: Function;
  className?: string;
}

function Checkbox({
  id,
  text,
  disabled,
  checked,
  onChange, onClick,
  className,
}: CheckboxType) {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  const labelClassName = classNames('checkbox', {
    [`${className}`]: className,
  })

  const onChangeHandler = (event: any) => {
    const { checked: newCondition } = event.target
    if (onChange) onChange(newCondition)
    setIsChecked(newCondition)
  }

  const onClickHandler = (event: any) => {
    const { checked: newCondition } = event.target
    if (onClick) onClick(newCondition)
    setIsChecked(newCondition)
  }

  useEffect(() => {
    onChangeHandler({ target: { checked } })
  }, [checked])

  return (
    <label id={id} className={labelClassName}>
      <input type="checkbox" checked={isChecked} onClick={onClickHandler} onChange={onChangeHandler} disabled={disabled} />
      <span className="checkbox--checkmark"></span>
      {text && <p className="checkbox--text">{text}</p>}
    </label>
  )
}

export default Checkbox
