/* eslint-disable no-undef */
import axios from 'axios'
import getConfig from 'config.service'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'

export type LockTime = '30d' | '6m' | '12m'

export interface Staking {
  id: number;
  address: string;
  amount: number;
  startDate: Date;
  endDate: Date;
  multiplier: number;
  lockTime: LockTime;
  unstaked: boolean;
}

export type StakeDevProps = {
  wallet: string,
  amount: number
  lockTime: LockTime
}

export type StakingPool = {
  address: string;
  amount: number;
  pool: Staking[];
}

const { hostURL } = getConfig()

class StakingRepository {
  static async stakeDev(props: StakeDevProps) {
    const token = ValidateIfTokenIsPresent()
    await axios.post<any>(`${hostURL}/api/v1/rewards/staking`, props, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  static async getStakedDev(address: string) {
    const token = ValidateIfTokenIsPresent()
    const response = await axios.get<{ stakingPool: StakingPool }>(`${hostURL}/api/v1/rewards/staking/${address}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.stakingPool
  }

  static async unstake(id: number, address: string) {
    const token = ValidateIfTokenIsPresent()
    await axios.post<any>(`${hostURL}/api/v1/rewards/staking/unstake`, {
      wallet: address,
      stakingId: id,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  static async buyItem(id: string, address: string) {
    const token = ValidateIfTokenIsPresent()
    await axios.post<any>(`${hostURL}/api/v1/rewards/staking/store/buy/${id}`, {
      wallet: address,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  static async getStore() {
    const token = ValidateIfTokenIsPresent()
    const response = await axios.get<any>(`${hostURL}/api/v1/rewards/staking/store`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }

  static async getLockTime(itemId: string, wallet: string) {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line max-len
    const response = await axios.get<any>(`${hostURL}/api/v1/rewards/staking/store/lock-time-left/${itemId}/${wallet}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }

  static async getStakedDevAmount(wallet: string) {
    const token = ValidateIfTokenIsPresent()
    const response = await axios.get<any>(`${hostURL}/api/v1/rewards/staking/staked-dev/${wallet}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }

  static async getYesterdaysEarnings(wallet: string) {
    const token = ValidateIfTokenIsPresent()
    const response = await axios.get<any>(`${hostURL}/api/v1/rewards/staking/earnings/${wallet}/yesterday`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  }
}
export default StakingRepository
