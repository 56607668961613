import './page-header.component.scss'
import { ReactNode } from 'react'

type PageHeaderProps = {
  children: ReactNode | ReactNode[],
  imageSrc: any,
  overlayColor?: string,
  overlay?: boolean
}

export default function PageHeader(props: PageHeaderProps) {
  return <div className='page-header'>
    <img className='page-header__hero' src={props.imageSrc} alt='image-hero' />
    {props.overlay && <div className='page-header__overlay' />}
    {/* <img className='page-header__hero-curve' src='/images/curve.png' alt='page-hero-curve' /> */}
    {props.children}
  </div>
}
