import './achievements.view.scss'
import AchievementsComponent from 'components/Achievements/achievements.component'
import Footer from '../../components/Footer/Footer.component'

export default function AchievementsView() {
  return (
    <div className="achievements-view">
        <AchievementsComponent />
      <div className='achievements-view__footer'> </div>
      <Footer />
    </div>
  )
}
