import { DeckType } from 'domain/deck.model'
import { Tournament } from 'domain/tournament.model'
import { atom } from 'recoil'

export const isShowJoinModalAtom = atom({
  key: 'isShowJoinModal',
  default: false,
})

export const isShowJoinSuccessModalAtom = atom({
  key: 'isShowJoinSuccessModal',
  default: false,
})

export const tournamentUserDecksAtom = atom({
  key: 'tournamentUserDecks',
  default: [] as Array<DeckType>,
})

export const currentTournamentAtom = atom<Tournament | undefined>({
  key: 'currentTournament',
  default: undefined,
})

export const selectedStageAtom = atom({
  key: 'selectedStage',
  default: {} as any,
})

export const isShowStageInfoModalAtom = atom({
  key: 'isShowStageInfoModal',
  default: false,
})

export const isShowQuitModalAtom = atom({
  key: 'isShowQuitModal',
  default: false,
})
