import './views.scss'
import { DeckSelectorDropDown } from 'components/deckbuilder/DeckSelector/DeckSelector.component'
import {
  useRecoilCallback, useRecoilValue, useRecoilValueLoadable, useSetRecoilState,
  // useSetRecoilState,
} from 'recoil'
import { useHistory, NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import CardDetailModal from 'components/Modal/card-detail/card-detail.modal'
import NewCard from 'components/deckbuilder/Card/new-card.component'
import { CardType } from 'domain/card.model'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Flex from 'components/Flex/Flex'
import {
  connectedWallet,
  currentPlayableDeckSelector,
  decksFetchAtom,
  profileSelector,
  // currentDeckAtom,
  playableDeckSelector, currentDeckAtom, getBannedUser,
} from 'selectors/selectors'
import Text from 'components/Text/Text'
import Button from '../components/Button/Button.component'
import { ProfileRepository } from '../repositories/profile.repository'
import UserServerProblemView from './user/user-server-problem.view'
import UserNotStarterPackView from './user/user-not-starter-pack.view'
import UserNotConnectedView from './user/user-not-connected.view'
import { setLoader } from '../components/Loader/Loader.component'
import { getHost } from '../infraestructure/services/host-consumer/host-consumer.service'
import Footer from '../components/Footer/Footer.component'
import discordIcon from '../assets/icons/icon-discord.svg'
/*
  import getConfig from '../config.service'
  const config = getConfig()
 */

function typeW(card: CardType): number {
  return card.CardType.toUpperCase() === 'CHARACTER' ? 1 : 2
}

function orderCardsByType(cards: CardType[]): CardType[] {
  return cards.sort((a, b) => typeW(a) - typeW(b))
}

export default function PlayView() {
  const history = useHistory()
  const [loaded, setLoaded] = useState(false)
  const [selectedCard, setCard] = useState<any>(null)
  const playable = useRecoilValueLoadable(playableDeckSelector)
  const [openConditions, setOpenConditions] = useState(false)

  const wallet = useRecoilValue(connectedWallet)
  const profile = useRecoilValue(profileSelector)
  const deck = useRecoilValue(currentPlayableDeckSelector)

  const banned = useRecoilValueLoadable(getBannedUser(wallet?.address))

  const isAbleToPlay = () => {
    if ((playable.state === 'hasValue' && !playable.contents) || playable.state !== 'hasValue') return false
    if (banned.state === 'hasValue' && banned.contents) return false
    return true
  }

  const setCurrentDeck = useSetRecoilState(currentDeckAtom)
  const goPlay = () => {
    if ((playable.state === 'hasValue' && !playable.contents) || playable.state !== 'hasValue') return
    if (banned.state === 'hasValue' && banned.contents) return
    if (!profile?.info.conditionsAccepted) setOpenConditions(true)
    else window.location.href = `${getHost()}/play/?deck-id=${deck.id}`
  }

  const goEdit = () => {
    setCurrentDeck(deck)
    history.push('/deck-builder')
  }

  const closeAction = () => {
    setOpenConditions(false)
  }

  const acceptTerms = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain()
    try {
      setLoader(true)
      const snapshotWallet = await snapshot.getPromise(connectedWallet)
      const walletProfile = await ProfileRepository.getAcceptTerms(snapshotWallet)
      if (walletProfile?.info.conditionsAccepted) window.location.href = `${getHost()}/play/?deck-id=${deck.id}`
      else closeAction()
      setLoader(false)
    } catch (e) {
      release()
    }
  })

  const resetDeck = useRecoilCallback(({ snapshot, set }) => async () => {
    const release = snapshot.retain()
    try {
      const r = await snapshot.getPromise(decksFetchAtom)
      set(decksFetchAtom, () => r + 1)
    } catch (e) {
      release()
    }
  })

  useEffect(() => {
    resetDeck().then(() => setTimeout(() => setLoaded(true), 500))
  }, [])
  if (!deck || !profile?.info?.isHolder) {
    setLoader(false)
    if (!deck) {
      return (
        <div className='empty-deck'>
          <h2>No valid decks!</h2>
          <h3>You have no valid decks</h3>
          <p>Check the deck builder</p>
          <div className='actions'>
            <Button variant={'outline'} size={'medium'} onClick={() => history.push('deck-builder')}>Build decks</Button>
            <Button variant={'primary'} size={'medium'} onClick={() => history.push('store')}>Get Cards</Button>

          </div>
        </div>
      )
    }
    let view = !loaded ? <div><h2 /><h2 /><h2 /></div> : <UserNotConnectedView />
    if (profile && !profile?.info?.isHolder) view = <UserNotStarterPackView />
    else if (wallet?.address) view = <UserServerProblemView />
    return <div className="userprofile-view">
      {view}
      <Footer />
    </div>
  }
  const hq = deck.cards.find((c) => c.CardType === 'HQ')
  const cards = deck.cards.filter((c) => c.CardType !== 'HQ')

  if (deck && Array.isArray(deck.cards) && deck.cards.length > 0) setLoader(false)
  // noinspection HtmlUnknownTarget
  return deck && Array.isArray(deck.cards) && deck.cards.length > 0 ? (
    <div className='play-view'>
      <h1>Prepare for match</h1>
      <div className="faction-section__top">
        <DeckSelectorDropDown className="faction-deckselect" />
        <div style={{ flex: 1 }} />
        <NavLink to='/tutorials' className='btn btn-medium btn-outline'>Tutorials</NavLink>
      </div>
      {banned.contents && <div className="ban-message">
        <h1>Your account has been suspended</h1>
        <Text fontSize={'18px'} fontWeight={'600'}>Reason</Text>
        <Text>{banned.valueMaybe()?.reason}</Text>
        <br />
        <a target="_blank" className="btn-shadow" href="https://discord.gg/D6MN4DFP4n" rel="noreferrer" >
          <Flex flexDirection='row' gap='1ch' justifyContent='center' alignItems='center'>
            <Text color='white'>If you think this was a mistake, please let us now at our</Text>
            <Text textDecoration='underline' fontWeight={'bold'} color='white'>Discord</Text>
            <img src={discordIcon} alt="Discord" />
          </Flex>
        </a>
        <br />
        <a target="_blank" className="btn-shadow" href="https://discord.gg/D6MN4DFP4n" rel="noreferrer" >
          <Flex flexDirection='row' gap='1ch' justifyContent='center' alignItems='center'>
            <Text textDecoration='underline' color='white'>Important rules</Text>
          </Flex>
        </a>
      </div>}
      <div className='faction-section__container'>
        <div className="deck-selector__header">
          {hq && <div className="hq">
            <NewCard
              card={hq}
              onClick={() => setCard(hq)}
            />
          </div>}
          <div className="deck-selector__detail">
            <h2 className="deck-selector__title">{deck.name}</h2>
            <div className="deck-selector__buttons">
              {deck?.id && (<>
                <a
                  className={`btn btn-medium btn-primary ${!isAbleToPlay() ? 'btn-disabled' : ''}`} onClick={goPlay}>
                  play
                </a>
                <NavLink to='/deck-builder' className='btn btn-medium btn-outline' onClick={() => goEdit()}>Edit</NavLink>
              </>
              )}
            </div>
          </div>
        </div>
        <div className="deck__cards">
          {orderCardsByType(cards).map((card: CardType, i) => {
            const overridenCard = card as unknown as CardType & { tokenIds: string[] }
            return (
              <NewCard
                disabled={card.Set !== 'Outbreak' && overridenCard.tokenIds!.length === 0}
                key={`playcard_${i}_${card.CardNumber}`}
                card={card}
                onClick={() => setCard(card)}
              />
            )
          })}
        </div>
      </div>
      <CardDetailModal card={selectedCard} closeAction={() => setCard(null)} />
      <Footer />
      <Modal isActive={openConditions} closeAction={closeAction} id="conditions-modal">
        <FontAwesomeIcon icon={faExclamationCircle as IconProp} size='3x' className='error-icon' />
        <h1 className="error-title">ATTENTION</h1>
        <p className="error-description" style={{ textTransform: 'initial' }}>
          By connecting your Wallet you&apos;re agreeing with our <a target="_blank" href="/terms-and-conditions">Terms & Conditions</a> and <a target="_blank" href="/privacy">Privacy Policies</a>. Some bugs may show up during this early stage, so report them in our website to speed up development! Keep in mind that during the Alpha version you will be playing against our AIs and no $DEV will be awarded during gameplay.
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            variant={'outline'}
            size={'medium'}
            onClick={closeAction}
          >
            Cancel
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant={'primary'}
            size={'medium'}
            onClick={acceptTerms}
          >
            Accept
          </Button>
        </div>
      </Modal>
    </div >
  ) : null
}
