import '../views.scss'
import './not-connected.scss'

export default function UserServerProblemView() {
  // <p className="description">It is essential to play and perform other actions within the page.</p>
  return (
    <div className="empty-state">
      <h2 />
      <h2 className={'title'}>Please try again.</h2>
      <h1>There was a problem creating your user, please reconnect your wallet.</h1>
      <h2 />
    </div>
  )
}
