/* eslint-disable */
import './library.component.scss'
import { CardType } from 'domain/card.model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CharSelector,
  cardsFetchAtom,
  connectedWallet,
  currentDeckAtom,
  currentDeckSelector,
  deckCardsSelector,
  DeckFormatsSelector,
} from 'selectors/selectors'
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil'
import { checkPlayable } from 'views/deckbuilder/deck-builder.view'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import { useState } from 'react'
import { Spinner } from 'components/Loader/Loader.component'
import Card from '../Card/Card.component'
import { faPlus, faEye } from '../../../infraestructure/repositories/icons.repository'
import Button from '../Button/Button.component'
import IconNft from '../../../assets/icons/nft.png'
import DeckFormatsService from "../../../services/deck-formats.service";
import { allowNftOnly, filterCardsBy } from "./Library.component";
import getConfig from 'config.service'
export default function LibraryCharacter({
  search,
  onViewCard,
}: { add: Function, onViewCard: Function, search: string }) {
  const config = getConfig()
  const { ENABLE_ECHOES } = config
  const characterCards = useRecoilValueLoadable(CharSelector) // CharSelector
  const deck = useRecoilValueLoadable(currentDeckSelector)
  const deckFormats = useRecoilValueLoadable(DeckFormatsSelector)
  const [loading, setLoading] = useState<string>('')
  
  const addCardToDeck = useRecoilCallback(({ snapshot, set }) => async (card: CardType) => {
    const release = snapshot.retain()
    try {
      const currentDeck = await snapshot.getPromise(currentDeckSelector)
      const deckCards = await snapshot.getPromise(deckCardsSelector)
      const wallet = await snapshot.getPromise(connectedWallet)
      const newDeck = {
        ...currentDeck,
        cards: card.CardType === 'HQ'
          ? [
            { ...card },
            ...deckCards.filter((c: any) => c.CardType !== 'HQ'),
          ]
          : [
            ...deckCards,
            { ...card },
          ],
      }
      if (wallet) {
        const isPlayable = checkPlayable(newDeck)
        setLoading(card.id as string)
        const res = await DeckRepository.update({ ...newDeck, playable: isPlayable }, wallet.address)
        set(currentDeckAtom, res)
      }
      const r = await snapshot.getPromise(cardsFetchAtom)
      set(cardsFetchAtom, () => r + 1)
      setLoading('')
    } catch (e) {
      setLoading('')
      release()
    }
  }, [deck.contents])

  if (characterCards.state === 'loading') return <>loading..</>
  if (characterCards.state === 'hasError') {
    alert('error getting character cards')
    console.error(characterCards.contents)
    return <></>
  }
  const charCards: CardType[] = filterCardsBy(search, DeckFormatsService.filterByDeckFormat(characterCards.contents, deckFormats.contents, deck.contents))
  if (charCards.length < 1) return <></>
  const addedCards = deck.state === 'hasValue' ? deck.contents.cards.map((c: CardType) => c.Name) : []

  return (
    <div className="library-character-card-list">
      {(charCards)
        .filter((card: CardType) => ENABLE_ECHOES ? true : !['Echoes 1', 'Echoes 2', 'Unlocked'].includes(card.Set!))
        .map((card: CardType) => (
          <div className={`library-character-card-list-space ${addedCards.includes(card.Name) || loading === card.id ? 'added' : ''}`} key={`card_${card.id}`}>
            {addedCards.includes(card.Name) && (<div>Added</div>)}
            {loading === card.id && (<div style={{
              width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
            }}>
              <Spinner />
              loading...</div>)}
            <Card disabled={allowNftOnly(card)} {...card} />
            <div className="hover-overlay" />
            <div className="hover-options ">
              <div className="hover-buttons">
                {!allowNftOnly(card) && <Button variant={'primary'} size={'small'} left className="add-btn"
                  onClick={() => { addCardToDeck(card).then() }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>}

                <Button variant={'primary'} size={'medium'} className="view-btn" onClick={() => onViewCard(card)} >
                  <FontAwesomeIcon icon={faEye} />
                  View card
                </Button>
              </div>
              <div className="hover-status">
                {/* <Button variant={"primary"} size={"small"} left className="fav-btn">
                  <FontAwesomeIcon icon={faStar} />
                </Button> */}
                {/* <Button variant={"primary"} size={"small"} disabled right className="count-btn">1</Button> */}
              </div>
            </div>
            {Array.isArray(card.TokenIds) && card.TokenIds.length > 0 && (<div className="nft-amount">{card.TokenIds.length}</div>)}
            {card.IsNft && (<div className='is-nft'><img src={IconNft} alt={"nft"} /></div>)}

          </div>
        ))}
    </div>
  )
}
