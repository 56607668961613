// noinspection UnnecessaryLocalVariableJS

import { v4 as uuidv4 } from 'uuid'
import { DeckType } from 'domain/deck.model'
import getConfig from 'config.service'
import { ServiceConsumer } from '../services/service-consumer/service-consumer.service'
import { getHost } from '../services/host-consumer/host-consumer.service'
import { ValidateIfTokenIsPresent } from './sessionVerifier.repository'
import { DeckFormatsDto } from '../../services/deck-formats.service'

const DeckRepository = {
  getByWallet: async (wallet: string) => {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    const result = await ServiceConsumer.get<{ decks: DeckType[] }>({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet}/decks`,
      headers: {
        'correlation-id': uuidv4(),
        // "Authorization":`Bearer ${token}`
      },
    })
    return result.decks
  },

  getById: async (wallet: string, deckId: string) => {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    const result = await ServiceConsumer.get({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet}/decks/${deckId}`,
      headers: {
        'correlation-id': uuidv4(),
        // "Authorization": `Bearer ${token}`
      },
    })
    return result
  },

  update: async (deck: any, wallet: string): Promise<any> => {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    await ServiceConsumer.patch({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet}/decks/${deck.id}`,
      body: deck,
      headers: {
        'correlation-id': uuidv4(),
        // "Authorization": `Bearer ${token}`
      },
    })
    return deck
  },

  claimAdeptDecks: async (wallet: string): Promise<any> => {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    await ServiceConsumer.post({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet}/adeptdecks`,
      headers: {
        'correlation-id': uuidv4(),
        // Authorization: `Bearer ${token}`,
      },
    })
  },

  save: async (deck: any, wallet: string): Promise<DeckType> => {
    const token = ValidateIfTokenIsPresent()
    if (!token) throw new Error('There is not a valid session')
    const res = await ServiceConsumer.post<{ currentDeck: DeckType }>({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet}/decks`,
      body: deck,
      headers: {
        'correlation-id': uuidv4(),
      },
    })
    return res.currentDeck
  },

  deleteDeck: async (deckId: string, wallet: string) => {
    await ServiceConsumer.delete({
      url: `${getHost('deck')}/api/v1/deck-manager/user/${wallet}/decks/${deckId}`,
      headers: {
        'correlation-id': uuidv4(),
      },
    })
  },

  getDeckFormats: async () => {
    if (!getConfig().ENABLE_DECK_FORMATS) return { formats: [] } as DeckFormatsDto
    try {
      const res = await ServiceConsumer.get({
        url: `${getHost('deck')}/api/v1/deck-manager/deck-formats`,
        headers: {
          'correlation-id': uuidv4(),
          // "Authorization": `Bearer ${token}`
        },
      })
      return res as DeckFormatsDto
    } catch (e) {
      return { formats: [] } as DeckFormatsDto
    }
  },
}

export default DeckRepository
