import Flex from 'components/Flex/Flex'
import { useRecoilValueLoadable } from 'recoil'
import { tasksSelector } from 'selectors/play2airdrop.selectors'
import { TaskDto } from 'domain/task.model'
import AirdropTaskItem from './AirdropTaskItem.component'

export default function AirdropTaskList() {
  const tasks = useRecoilValueLoadable(tasksSelector)

  return <Flex flexDirection='row' gap='2%' alignContent='center' justifyContent='center'>
    {tasks.state === 'hasValue' && (
      tasks.contents.map((task: TaskDto) => <AirdropTaskItem
        key={task.id}
        title='Play'
        completed={task.isCompleted || false}
        description={task.name}
      />)
    )}
  </Flex>
}
