import { Money } from 'domain/money.model'
import UstDenom from 'assets/images/ust_denom.png'
import DevDenom from 'assets/images/xdev_denom.png'
import { useRecoilValueLoadable } from 'recoil'
import { currencyConvertionSelector } from 'selectors/selectors'
import { Convertion } from 'repositories/rewards.repository'
import './money.component.scss'

export function getCurrencyImage(money: Money) {
  if (money.currency.toUpperCase() === 'USD') return <img src={UstDenom} />
  if (money.currency.toUpperCase() === 'DEV') return <img src={DevDenom} />
  if (money.currency.toUpperCase() === 'XDEV') return <img src={'/images/currency/XDEV.png'} />
  if (money.currency.toUpperCase() === 'VOL_POINT') return <img src={'/images/currency/VOL_POINT.png'} />
  return money.currency
}

export function formatAmount(money: Money) {
  const amount = Math.round(money.amount * 100) / 100
  return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

export default function MoneyComponent({ money, showConvertions, convert }: {
  money: Money, showConvertions?: boolean, convert?: boolean
}) {
  const convertion = useRecoilValueLoadable<Convertion>(currencyConvertionSelector(money.amount))
  const isDEV = ['XDEV', 'DEV'].includes(money.currency)
  if (convertion.state !== 'hasValue') return <></>
  return (<div className="money">
    <div className="money__currency">{getCurrencyImage(money)}</div>
    <div className="money__amount">{`${formatAmount({ ...money, amount: (isDEV && convert) ? convertion.contents.value : money.amount })}`}
      {(isDEV && showConvertions) && <span className='money__equivalence-amount'>(${money.amount})</span>}
    </div>
  </div>)
}
