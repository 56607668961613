/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import Button from 'components/Button/Button.component'
import Flex from 'components/Flex/Flex'
import Input from 'components/Input/Input.component'
import Text from 'components/Text/Text'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useState } from 'react'
import Toast from 'components/Toast/Toast.component'
import {
  useRecoilRefresher_UNSTABLE, useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil'
import { connectedWallet, profileBalancesSelector } from 'selectors/selectors'
import useStake from 'views/staking/hooks/useStake'
import { stakedDevPoolSelector } from 'selectors/staking.selectors'
import useConfirmDialog from 'hooks/confirm/useConfirmDialog.hook'
import StakingTimeOptionComponent from './stakingtime-option.component'

type modalProps = {
  isActive: boolean
  closeAction: () => void
}

export default function AddstakingModalComponent(props: modalProps) {
  const { isActive, closeAction } = props
  const [option, setOption] = useState('')
  const [dev, setDev] = useState(0)
  const user = useRecoilValue(connectedWallet)
  const inGameDev = useRecoilValueLoadable(profileBalancesSelector).contents?.xdev || 0
  const balanceRefresher = useRecoilRefresher_UNSTABLE(profileBalancesSelector)
  const stakingRefresher = useRecoilRefresher_UNSTABLE(stakedDevPoolSelector)
  const [toast, setToast] = useState(false)
  const [error, setError] = useState(false)

  const { confirm } = useConfirmDialog({ text: 'Are you sure you want to stake?' })

  const { stake, loading } = useStake({
    onSuccess() {
      setToast(true)
      balanceRefresher()
      stakingRefresher()
      closeAction()
      setOption('')
      setDev(0)
    },
    onError() {
      setError(true)
    },
  })

  const handleStake = async () => {
    if (!user) return
    const confirmed = await confirm()
    if (confirmed) {
      await stake({
        amount: dev,
        lockTime: option as '30d' | '6m' | '12m',
        wallet: user.address,
      })
    }
  }

  return <>
    <Toast style={{ background: '#f54242' }} id='stake-dev-toast-error' isActive={error} duration={4000} onClose={() => {
      setError(false)
    }}>
      <Flex flexDirection='column' gap="1ch">
        <Text fontWeight={'bold'} fontSize='0.8rem'>
          <Text fontWeight={'bold'} fontSize='0.8rem'>🫣 Something went wrong.</Text>
        </Text>
        <Text fontWeight={'bold'} fontSize='0.8rem'>Please, refresh the page and try again.</Text>
      </Flex>
    </Toast>
    <Toast id='stake-dev-toast' isActive={toast} duration={3000} onClose={() => { setToast(false) }}>
      <Flex flexDirection='column' gap="1ch">
        <Text fontWeight={'bold'} fontSize='0.8rem'>
          <Text fontWeight={'bold'} fontSize='0.8rem'>🚀 Stake successful!</Text>
        </Text>
        <Text fontWeight={'bold'} fontSize='0.8rem'>Come back in a few hours to see your accumulated reward!</Text>
      </Flex>
    </Toast>
    <Modal id="withdraw-dev" isActive={isActive} closeAction={() => closeAction()} style={{ minWidth: '35vw' }}>
      <Flex flexDirection='column' gap="0.4ch">
        <h1>STAKE $DEV</h1>
        <Text fontWeight={'500'} textAlign='center'>
          Select the amount of <b> DEV </b> you want to stake
          and a lock time. The more time you lock your DEV, the higher the reward.
        </Text>
        <Flex justifyContent='center' flexDirection='row' gap="1ch" style={{ width: '100%', height: '60px' }}>
          <Input step={0.1} value={dev} onChange={(e) => {
            e.preventDefault()
            setDev(Number(e.target.value))
          }} max={inGameDev} type='number' placeholder='Amount' style={{ width: '280px', fontSize: '1.2rem', textAlign: 'center' }} />
          <Button onClick={() => setDev(inGameDev)} variant='ghost' size='medium' style={{ padding: '5px 25px' }}>MAX</Button>
        </Flex>
        <Text fontWeight={'bold'} fontSize='0.8rem'>IN GAME: {inGameDev} DEV</Text>
      </Flex>
      <Flex flexDirection='row' gap='5px'>
        <StakingTimeOptionComponent title='Stake' description='100%APR 30 Day lock' selected={option === '30d'} select={() => setOption('30d')} option='30d' amount={dev} />
        <StakingTimeOptionComponent title='Stake' description='200%APR🔥 6 month lock' selected={option === '6m'} select={() => setOption('6m')} option='6m' amount={dev} />
        <StakingTimeOptionComponent title='Stake' description='300%APR🚀 12 month lock' selected={option === '12m'} select={() => setOption('12m')} option='12m' amount={dev} />
      </Flex>
      <Button disabled={(dev === 0 || option === '' || dev > inGameDev || loading)} style={{ width: '290px', height: '50px' }} variant='primary_alt' size='medium' onClick={() => {
        handleStake()
      }}>{loading ? '...' : 'STAKE'}</Button>
      <Flex flexDirection='row' flexWrap='wrap' gap="0.5ch" justifyContent='center' alignItems='center'>
        <Text>
          By staking your $DEV, you are accepting the
        </Text>
        <a target='_blank' rel='noreferrer' className='df-text' style={{ textDecoration: 'underline' }} href="https://docs.deviantsfactions.com/deviants-factions-rulebook/dev-staking">[STAKING RULES]</a>
      </Flex>
    </Modal>
  </>
}
