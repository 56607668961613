import getConfig from 'config.service'
import { TournamentTicket } from 'domain/tournament-ticket.model'
import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

const { hostURL } = getConfig()
export default abstract class TournamentTicketRepository {
  public static async get(state?: string): Promise<TournamentTicket[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/tickets/catalog`,
      params: { state },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const tournamentTickets = await ServiceConsumer.get<TournamentTicket[]>(requestData)
    return tournamentTickets
  }

  public static async craftTicket(ticketId: string, address?: string): Promise<Boolean> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/rewards/tickets/craft`,
      body: {
        address,
        ticketId,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<Boolean>(requestData)
    return response
  }
}
