/* eslint-disable */
import { Suspense, useEffect, useState } from 'react'
import './editdeck.component.scss'
import {
  deckListState,
  currentDeckAtom,
  playableDeckSelector,
  currentDeckSelector,
  connectedWallet,
  decksFetchAtom,
  DeckFormatsSelector,
  getBannedUser,
} from 'selectors/selectors'
import {
  useRecoilValue,
  useRecoilState,
  atom,
  useRecoilCallback,
  useSetRecoilState,
  useRecoilValueLoadable,
  useRecoilRefresher_UNSTABLE,
} from 'recoil'
import DeckRepository from 'infraestructure/repositories/deck.repository'
import Select from 'react-select'
import Button from '../Button/Button.component'
import { getHost } from '../../../infraestructure/services/host-consumer/host-consumer.service'
import DeckWarning from './DeckWarning/DeckWarning.component'
import { LoaderRepository } from "../../../infraestructure/repositories/loader.repository";
import TournamentsRepository from 'repositories/tournaments.repository'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from 'infraestructure/repositories/icons.repository'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Tournament } from 'domain/tournament.model'
import getConfig from 'config.service'
import ModalContainer from "../../Modal/ModalContainer.component";
import { DeckFormatDto } from "../../../services/deck-formats.service";
import { DeckType } from "../../../domain/deck.model";

export const customSelectStyles: any = {
  option: (provided: any, state: any) => ({
    ...provided,
    ':active': {
      backgroundColor: '#FFFFFF',
      color: '#FF5207',
    },
    fontWeight: 'bold',
    fontFamily: 'Rajdhani',
    background: state.isSelected ? '#FF5207' : 'linear-gradient(90deg, #0b0806 0%, #1b1f1e 50%, #0b0806 100%)',
    color: state.isFocused && !state.isSelected ? '#FF5207 !important' : '#FFFFFF !important',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    textIndent: '10px',
  }),
  /*
  control: () => ({}),
  */
}

export const showSetNameState = atom({
  key: 'showDeckSetName',
  default: false,
})

export const newDeckNameState = atom({
  key: 'newDeckName',
  default: '',
})

export default function EditDeck({
  onlyNew,
}: any) {
  const currentDeck = useRecoilValueLoadable(currentDeckSelector)
  const setCurrentDeck = useSetRecoilState(currentDeckAtom)
  const deckList = useRecoilValue(deckListState)
  const [showSetName, setShowSetName] = useRecoilState(showSetNameState)
  const [newDeckName, setNewDeckName] = useState(currentDeck?.contents ? currentDeck?.contents.name : '')
  const [newDeckFormat, setNewDeckFormat] = useState(currentDeck.contents ? currentDeck?.contents.format : 'standard')
  const wallet = useRecoilValue(connectedWallet)
  const deck = useRecoilValueLoadable(currentDeckSelector)
  const playable = useRecoilValueLoadable(playableDeckSelector)
  const [action, setAction] = useState('none')
  const [deckBusyModal, setDeckBusyModal] = useState<Tournament[]>([])
  const refresh = useRecoilRefresher_UNSTABLE(deckListState)
  const deckFormats = useRecoilValueLoadable(DeckFormatsSelector)
  const [isDeckBusy, setIsDeckBusy] = useState(true)
  const banned = useRecoilValueLoadable(getBannedUser(wallet?.address))

  const isPlayDisabled = (playable.state === 'hasValue' && !playable.contents) || playable.state !== 'hasValue' || banned.state === 'hasValue' && banned.contents !== null

  useEffect(() => {
    if (!onlyNew) {
      setNewDeckName(currentDeck?.contents.name || '')
      setNewDeckFormat(currentDeck?.contents.format || 'standard')
    }
    if (currentDeck && currentDeck.state === 'hasValue') checkDeckState(currentDeck?.contents.id)
  }, [(!onlyNew && currentDeck.contents) ? currentDeck?.contents.name : false])

  const selectDeck = (deckName: string) => {
    if (deckList) {
      const selectedDeck = deckList.find((deckListed: any) => deckListed.name === deckName)
      setCurrentDeck(selectedDeck)
    }
  }
  const playDeck = async () => {
    // await refresh(currentDeckSelector)
    // const deck = await snapshot.get(currentDeckSelector)
    const url = `${getHost()}/play/?deck-id=${deck.contents.id}`
    window.open(url, '_self')
  }

  const showDeckEdit = () => {
    setAction('edit')
    setNewDeckName(currentDeck?.contents.name || '')
    setNewDeckFormat(currentDeck?.contents.format || 'standard')
    setShowSetName(true)
  }
  const showDeckNew = () => {
    setNewDeckName('')
    setNewDeckFormat('standard')
    setAction('new')
    setShowSetName(true)
  }

  const handleClickOutside = () => {
    setAction('none')
    setShowSetName(false)
  }

  // @ts-ignore
  const validateNameInput = (e: any) => {
    if (!/^[0-9a-zA-Z ]+$/.test(e.target.value.trimStart())) {
      const regex = /[^A-Za-z0-9 ]/g
      e.target.value = e.target.value.trimStart().replace(regex, '')
    }
    if (!(e.target.value.trimStart().length >= 4 && e.target.value.trimStart().length <= 22)) {
      e.target.setCustomValidity('Maximum 22 characters in your name, no special characters.')
    }
    e.target.setCustomValidity('')
    setNewDeckName(e.target.value.trimStart())
  }

  const save = useRecoilCallback(({ snapshot, set }) => async () => {
    setShowSetName(false)
    const release = snapshot.retain()
    try {
      if (!(newDeckName.length >= 1 && newDeckName.length <= 22)) {
        return
      }
      if (wallet) {
        const currentData = action === 'edit' ? currentDeck.contents : {}
        const newData = action === 'new' ? {
          owner: wallet?.address,
          cards: [],
        } : {}
        const repositoryAction = action === 'edit' ? 'update' : 'save'
        try {
          const newDeck = await DeckRepository[repositoryAction]({
            ...currentData,
            ...newData,
            name: newDeckName,
            format: newDeckFormat,
          }, wallet?.address.toString() ?? '')

          set(currentDeckAtom, newDeck)
        } catch (e: any) {
          if (e?.response?.status === 409) {
            alert(`You already have a deck named ${newDeckName}`)
          }
          setShowSetName(true)
          return
        }
      }
      const d = await snapshot.getPromise(decksFetchAtom)
      // const c = await snapshot.getPromise(cardsFetchAtom);

      set(decksFetchAtom, () => d + 1)
      // set(cardsFetchAtom, () => d + 1);
      setNewDeckName('')
      setNewDeckFormat('standard')
      setAction('none')
    } catch (e) {
      release()
    }
  })

  const checkDeckState = (deckId: string) => {
    if (!wallet || !getConfig().ENABLE_DECK_LOCK) return
    TournamentsRepository.getDeckState(wallet?.address, deckId)
      .then(({ isBusy, tournaments }) => {
        if (isBusy) {
          setDeckBusyModal(tournaments)
          setIsDeckBusy(true)
          setCurrentDeck(undefined)
        } else {
          setIsDeckBusy(false)
        }
      })
  }

  const getDeckFormat = (deckFormat: string): DeckFormatDto | undefined => {
    if (!Array.isArray(deckFormats?.contents?.formats)) return undefined
    return deckFormats.contents.formats.find((df: DeckFormatDto) => df.id === deckFormat)
  }

  const getDeckFormatName = (deckFormat: string): string => {
    const item = getDeckFormat(deckFormat)
    return item ? item.name : ''
  }

  const getDeckFormatDescription = (deckFormat: string): string => {
    const item = getDeckFormat(deckFormat)
    return item ? item.description : ''
  }

  const getDeckFormatLabel = (deck: DeckType): any => {
    let label: any = deck.name ? deck.name : ''
    const deckFormat = getDeckFormat(deck.format ? deck.format : '')
    if (deckFormat) label = <><b>{label}</b>&nbsp;<span>{deckFormat.name}</span></>
    return label
  }

  const getDeckList = (): { label: string, value: string }[] => {
    return deckList ? deckList.map((deck: DeckType) => {
      return { label: getDeckFormatLabel(deck), value: deck.id }
    }) : []
  }

  return <> {(!onlyNew && deckList) ? (
    <section className='editdeck'>
      <ModalContainer isActive={deckBusyModal.length > 0} closeAction={() => setDeckBusyModal([])} id="conditions-modal">
        <FontAwesomeIcon icon={faExclamationCircle as IconProp} size='3x' className='error-icon' />
        <h1 className="error-title">ATTENTION</h1>
        <p className="error-description" style={{ textTransform: 'initial', fontWeight: '600', marginBottom: '0.5ch' }}>
          You won't be able to edit or delete this deck, because is being used in {deckBusyModal.length > 1 ? 'these tournaments' : 'this tournament'}
        </p>
        <div className='busy-deck-tournament-list'>
          {deckBusyModal.map(t => <div key={t.id}>
            <a href={`/tournaments/${t.id}`}>{t.name}</a>
          </div>)}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            variant={'outline'}
            size={'medium'}
            onClick={() => setDeckBusyModal([])}
          >
            Ok
          </Button>
        </div>
      </ModalContainer>
      <div className='editdeck-status'>
        <span>Deck</span>
        <div className='deck-selector'>
          <Select
            styles={customSelectStyles}
            className={'deviants-select'}
            options={getDeckList()}
            value={{ label: getDeckFormatLabel(deck.contents), value: deck.contents.id }}
            defaultValue={{ label: getDeckFormatLabel(deck.contents), value: deck.contents.id }}
            onChange={(opt) => {
              const val = opt?.value
              const newDeck = deckList.find((d: any) => d.id === val)
              localStorage.setItem('currentDeck', newDeck.id)
              selectDeck(newDeck.name)
              checkDeckState(newDeck.id)
              if (LoaderRepository.refreshDecks) {
                LoaderRepository.refreshDecks = false
                refresh()
              }
            }} />
        </div>
        <Suspense fallback={<></>}>
          <DeckWarning />
        </Suspense>
      </div>
      <div className='editdeck-actions'>
        <div className='editdeck-actions__edit-new'>
          <Button variant={'outline'} size={'medium'} onClick={showDeckEdit}>edit </Button>
          <Button variant={'outline'} size={'medium'} onClick={showDeckNew}>new </Button>
        </div>
        <div className='editdeck-actions__play'>
          <Button variant={'primary'} size={'medium'} disabled={isPlayDisabled} onClick={playDeck}>Play</Button>
        </div>
      </div>
    </section>)
    : (<Button variant={'primary'}
      size={'medium'}
      onClick={showDeckNew}
    >build new deck</Button>)
  }
    <ModalContainer isActive={showSetName} closeAction={handleClickOutside} id="modal">
      <div className="search-bar" // onSubmit={(event: any) => { event.preventDefault(); save() }}
      >
        <h2>ENTER YOUR DECK NAME</h2>
        {
          newDeckName != null ? (
            <input type="text" placeholder="Enter deck name here" minLength={1} maxLength={22} pattern={'[a-zA-Z0-9 ]+'} onChange={validateNameInput} value={newDeckName} onKeyUpCapture={(event) => {
              event.preventDefault()
              if (event.key === 'Enter') save().then()
            }
            } />
          ) : <></>
        }
        <p><strong>Maximum 20 characters</strong> in your name, no special characters.</p>

        {
          deckFormats.state !== 'hasValue' || deckFormats.contents.formats.length < 1 ? <></> : (
            <>
              <p>&nbsp;</p><p>&nbsp;</p>
              <h2>SELECT YOUR DECK FORMAT</h2>
              <p>&nbsp;</p>
              <div className='deck-selector' style={{ width: '80%' }}>
                <Select
                  styles={customSelectStyles}
                  className={'deviants-select'}
                  options={deckFormats.contents.formats ? deckFormats.contents.formats.map((d: DeckFormatDto) => ({ label: d.name, value: d.id })) : []}
                  value={{ label: getDeckFormatName(newDeckFormat), value: newDeckFormat }}
                  defaultValue={{ label: getDeckFormatName(newDeckFormat), value: newDeckFormat }}
                  onChange={(opt) => {
                    const val = opt?.value
                    if (val) setNewDeckFormat(val)
                  }} />
              </div>
              <p>&nbsp;</p>
              <p>{getDeckFormatDescription(newDeckFormat)}</p>
              <p>&nbsp;</p>
            </>
          )
        }

        <div className='editdeck-actions'>
          <Button variant={'outline'} size={'small'} onClick={(event) => {
            event.preventDefault()
            setNewDeckName('')
            setNewDeckFormat('standard')
            setAction('none')
            handleClickOutside()
          }} >Cancel</Button>
          <Button variant={'primary'} size={'small'} onClick={save}>Save</Button>
        </div>
      </div>
    </ModalContainer>
  </>
}
