import { rewardsTableStyle } from 'components/rewards/rewards.component'
import { mapBalanceIcon } from 'components/UserBalance/UserBalance.component'
import { TicketPrice, TournamentTicket } from 'domain/tournament-ticket.model'
import { Grid, _ } from 'gridjs-react'
import { useRecoilValueLoadable } from 'recoil'
import { ticketCatalogSelector } from 'selectors/selectors'

export default function EconomyTournamentGrid() {
  const tickets = useRecoilValueLoadable<TournamentTicket[]>(ticketCatalogSelector('all'))

  if (tickets.state === 'loading') return <p>Loading...</p>

  const TicketPriceItem = ({ price }: { price: TicketPrice }) => (
    <div className='ec-ticket-price-container'>
      <div>{price.amount}</div>
      <img width={16} height={16} src={mapBalanceIcon({ currency: price.currency })}></img>
    </div>
  )

  const TicketPrices = ({ prices }: { prices: TicketPrice[] }) => (
    <div className='ec-ticket-prices-container'>
      {prices.map((price, i) => <TicketPriceItem price={price} key={`${i}-${price.currency}`} />)}
    </div>
  )

  const ticketPriceGridEl = (ticketPrices: TicketPrice[]) => _(<TicketPrices prices={ticketPrices} />)
  const isAvailableGridEl = (isAvailable: boolean) => _(<div style={{ color: isAvailable ? 'GREEN' : 'RED' }}>{isAvailable ? 'YES' : 'NO'}</div>)

  return <Grid
    columns={[
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'NAME' },
      { id: 'price', name: 'COST' },
      { id: 'available', name: 'AVAILABLE' },
    ]}
    style={{
      ...rewardsTableStyle,
      table: {
        width: '100%',
      },
      td: {
        ...rewardsTableStyle.td,
        fontSize: '0.85em',
      },
    }}
    data={tickets.contents.map((t: TournamentTicket) => [
      t.id,
      t.name,
      ticketPriceGridEl(t.price),
      isAvailableGridEl(t.available),
    ])} />
}
