import './marquee.scss'
import marqueeTopLeft from './assets/marquee-top-left.png'
import marqueeBottomLeft from './assets/marquee-bottom-left.png'
import marqueeTopRight from './assets/marquee-top-right.png'
import marqueeBottomRight from './assets/marquee-bottom-right.png'

type MarqueeType = {
 text: string,
 id?: string,
}

export default function Marquee({
  text,
  id,
}: MarqueeType) {
  return (
  <div className="marquee-section" id={id} >
    <img src={marqueeTopLeft} className="marquee-section-top-left holder"/>
    <img src={marqueeBottomLeft} className="marquee-section-bottom-left holder"/>
    <div className="marquee-section-wrapper">
      <span className="marquee-section-text">
        {text} / {text} / {text}
      </span>
    </div>
    <img src={marqueeTopRight} className="marquee-section-top-right holder"/>
    <img src={marqueeBottomRight} className="marquee-section-bottom-right holder"/>
  </div>
  )
}
