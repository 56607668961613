// @ts-ignore
import cookie from 'cookiejs'
import LeagueModal from 'components/LeagueModal/LeagueModal.component'
import Hero from '../components/Hero/Hero.component'
import Footer from '../components/Footer/Footer.component'
import Marquee from '../components/Marquee/Marquee.component'
import Partners from '../components/Partners/Partners.component'
import Investors from '../components/Investors/Investors.component'
import FeatCards from '../components/FeatCards/FeatCards.component'
import FactionSection from '../components/Factions/Faction.component'
import ErrorModal from '../components/ErrorModal/ErrorModal.component'
import getConfig from '../config.service'

const qs = require('query-string')

const config = getConfig()
export default function HomeView() {
  const parsed = qs.parse(window.location.search)
  const active = parsed.error === '401'

  function registerReferral(): void {
    if (parsed.referral && !cookie.get('user')) {
      cookie.set('referral', parsed.referral, { expires: 14 })
    }
  }

  const registerNewUser = () => {
    if (!cookie.get('user') && !cookie.get('referral')) {
      cookie.set('user', 'true', { expires: 365 })
    }
  }
  registerReferral()
  registerNewUser()
  return (
    <div>
      {
        config.showPlayButton ? (
          <Hero title="JOIN THE CONFLICT AND SHAPE HISTORY" subTitle="Mint some Boosters, build your NFT Deck and Play &amp; Earn" />
        ) : (
          <Hero title="NEXT GENERATION CARD GAME" subTitle="GET READY TO DISCOVER THE NEW AND EXCITING WORLD OF DEVIANTS FACTIONS!" />
        )
      }
      <FeatCards title='Featured cards' subtitle='just in!' />
      <FactionSection />
      <Marquee text="our investors" id="investors" />
      <Investors />
      <Marquee text="our partners" id="partners" />
      <Partners />
      <Footer id="footer" />
      <ErrorModal title={'Session expired or invalid'} description={'Please login again to continue playing'} id={'a'} closeAction={() => { window.location.href = '/' }} isActive={active} />
      {!active && <LeagueModal />}
    </div>
  )
}
