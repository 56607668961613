import { Purchase } from 'domain/purchase.model'
import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import getConfig from '../config.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'

export default abstract class PurchaseRepository {
  public static async getByUserAddress(userAddress?: string): Promise<Purchase[]> {
    const config = getConfig()
    const token = ValidateIfTokenIsPresent()
    const requestData : ServiceData = {
      url: `${config.hostURL}/api/v1/minter/purchases/${userAddress}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.get<{purchases: Purchase[]}>(requestData)
    return response.purchases
  }
}
