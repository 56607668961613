import { useContext } from 'react'
import { CraftingContext } from './crafting-context.component'
import CardMarkupComponent from '../../components/deckbuilder/Card/card-markup.component'
import Cleft from './assets/cleft.svg'

export default function CraftingInfo() {
  const { cardResult, loadingResultInfo, selectedRecipe } = useContext(CraftingContext)
  return <div className='crafting-info'>
    <div className='craft-name-cleft' style={{ backgroundImage: Cleft }}>
      {<h1>{selectedRecipe?.name}</h1>}
    </div>
    {loadingResultInfo && <h1>Loading...</h1>}
    {!cardResult && !loadingResultInfo && <h1>Click a recipe to see more info</h1>}
    {cardResult && <CardMarkupComponent {...cardResult} />}
  </div>
}
