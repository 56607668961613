import ExchangeItem from '../ExchangeItem.component'
import useBalance from '../../hooks/useBalance.hook'
import useTokenToDevConvertion from '../../hooks/useTokenToDevConvertion.hook'
import BuyMoreButton from './BuyMoreButton.component'
import TokenToDevExchange from '../TokenExchange/TokenToDevExchange.component'

export default function BuyImxModal() {
  const { balance: imx } = useBalance('imx')
  const tokenEquivalence = useTokenToDevConvertion({
    token: 'immutable-x',
    amount: imx,
  })
  return <ExchangeItem>
    <img width={32} src='/images/currency/IMX.png' />
    <ExchangeItem.Title text='IMX' />
    <ExchangeItem.Value text={(imx).toString()} />
    <ExchangeItem.Equivalence text={`DEV ${tokenEquivalence.toFixed(4).toString()}`} />
    <TokenToDevExchange token='IMX' />
    <BuyMoreButton token='IMX' />
  </ExchangeItem >
}
