import './CrystalModal.component.scss'
import { useEffect, useCallback, memo } from 'react'
import { ModalType } from 'components/Modal/Modal.component'
import classNames from 'classnames'
import useScrollBlock from '../Modal/scrollBlock'

function CrystalModal({
  children, isActive, closeAction, id, title,
}: ModalType) {
  const classNameOverlay = classNames('overlay', {
    show: isActive,
    hide: !isActive,
  })

  const [blockScroll, allowScroll] = useScrollBlock()
  const closeModalBackground = (event: any) => {
    if (event.target.id === id) {
      closeAction()
    }
  }

  const closeModal = useCallback(() => {
    closeAction()
  }, [closeAction])

  useEffect(() => {
    if (isActive) blockScroll()
    else allowScroll()
    return () => {
      allowScroll()
    }
  }, [isActive])

  return (
    <div className={classNameOverlay} id={id} onClick={(event) => closeModalBackground(event)}>
      <div className="shadow" onClick={closeModal}></div>
      <div className="crystal-modal">
        <div className="crystal-modal__close" onClick={closeAction}>&#x2715;</div>
        <div className="crystal-modal-header">
          <span>{title}</span>
        </div>
        <div className="crystal-modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default memo(CrystalModal)
