import { CSSProperties, MouseEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import './button.scss'
import { faChevronLeft } from '../../infraestructure/repositories/icons.repository'

type ButtonType = {
  children?: any;
  className?: string;
  variant: 'primary' | 'secondary' | 'least' | 'outline' | 'icon' | 'back' | 'danger' | 'primary_alt' | 'ghost';
  size: 'small' | 'medium';
  left?: boolean;
  right?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id?: string,
  style?: CSSProperties
}

function ButtonGhost({
  children,
  disabled,
  onClick,
  id,
  style,
}:
  {
    children: any, disabled?: boolean,
    onClick?: (event: any) => void, id?: string,
    style?: CSSProperties
  }) {
  return (
    <button
      style={{ ...style }}
      className={'ghost'}
      disabled={disabled}
      onClick={onClick}
      id={id}
    >
      {children}
    </button>
  )
}

function ButtonPrimaryAlt({
  children,
  disabled,
  onClick,
  id,
  style,
}:
  {
    children: any, disabled?: boolean,
    onClick?: (event: any) => void, id?: string,
    style?: CSSProperties
  }) {
  return (
    <button
      style={{ ...style }}
      className={'primary-alt'}
      disabled={disabled}
      onClick={(e) => !disabled && onClick && onClick(e)}
      id={id}
    >
      {children}
    </button>
  )
}

export default function Button({
  children,
  className,
  variant,
  size,
  left,
  right,
  disabled,
  onClick,
  id,
  style,
}: ButtonType) {
  const buttonClass = classNames('btn', `btn-${variant}`, `btn-${size}`, `${className}`, {
    'btn-left': left,
    'btn-right': right,
    'btn-disabled': disabled,
  })
  const preOnClick = (evt: any) => {
    if (disabled) return
    if (onClick) onClick(evt)
  }
  if (variant === 'ghost') return <ButtonGhost style={style} onClick={onClick} id={id}>{children}</ButtonGhost>
  if (variant === 'primary_alt') return <ButtonPrimaryAlt disabled={disabled} style={style} onClick={onClick} id={id}>{children}</ButtonPrimaryAlt>
  return (
    <>
      {variant !== 'back'
        && <div className={`btn-shadow ${className}`}>
          <button className={buttonClass} onClick={preOnClick} id={id}>
            {children}
          </button>
        </div>
      }
      {variant === 'back'
        && <button onClick={preOnClick} id={id} className={'btn-back'}>
          <FontAwesomeIcon icon={faChevronLeft} />
          {children}
        </button>
      }
    </>

  )
}
