import {
  faChevronLeft as iconFaChevronLeft,
  faPlus as iconFaPlus,
  faTimes as iconFaTimes,
  faStar as iconFaStar,
  faChevronUp as iconFaChevronUp,
  faChevronDown as iconFaChevronDown,
  faChevronRight as iconFaChevronRight,
  faArrowRight as iconFaArrowRight,
  faEye as iconFaEye,
  faCheckCircle as iconFaCheckCircle,
  faExclamationCircle as iconFaExclamationCircle,
  faSpinner as iconFaSpinner,
  faSearch as iconFaSearch,
  faFilter as iconFaFilter,

} from '@fortawesome/free-solid-svg-icons'

export const faChevronLeft:any = iconFaChevronLeft
export const faPlus:any = iconFaPlus
export const faTimes:any = iconFaTimes
export const faStar:any = iconFaStar
export const faChevronUp:any = iconFaChevronUp
export const faChevronDown:any = iconFaChevronDown
export const faChevronRight:any = iconFaChevronRight
export const faArrowRight:any = iconFaArrowRight
export const faEye:any = iconFaEye
export const faCheckCircle:any = iconFaCheckCircle
export const faExclamationCircle:any = iconFaExclamationCircle
export const faSpinner:any = iconFaSpinner
export const faSearch:any = iconFaSearch
export const faFilter:any = iconFaFilter
