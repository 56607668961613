import './Loader.css'
import './Loader.scss'

export function setLoader(active: boolean) {
  const loader = document.getElementById('deviants-loader')
  if (active) {
    loader?.classList.add('active')
    loader?.classList.add('started')
  } else {
    loader?.classList.remove('active')
    loader?.classList.add('started')
  }
}

export const Spinner = () => <div className="deviants-loader">
    <div className='bg' />
    <div className='loader-cont'>
      <div className='loader pie__segment' />
    </div>
    <div className='logo' />
  </div>

export function Loader() {
  return <div id="deviants-loader">
    <Spinner />
  </div>
}

export function LoaderFallback({ text }: { text: string }) {
  setLoader(true)
  return <div className="connect-wallet message-alert"><h2 /><h1>{text}</h1><h2 /></div>
}
