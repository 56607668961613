import { Booster } from 'domain/booster.model'
import { Link, ERC20TokenType } from '@imtbl/imx-sdk'
import getConfig from '../config.service'
import MinterService from './minter.service'

export default abstract class PaymentService {
  public static async buyPack(pack: Booster, amount: number, userAddress: string, referral?: string)
  : Promise<{message: string, paymentId: string}> {
    let paymentId
    try {
      paymentId = await this.transfer(amount * pack.price.amount)
      if (!paymentId) throw new Error('Transfer cancelled by user')
      const response = await MinterService.register(pack.type, amount, paymentId, userAddress, referral)
      return { ...response, paymentId }
    } catch (e: any) {
      e.paymentId = paymentId
      throw e
    }
  }

  public static async transfer(amount: number): Promise<string> {
    try {
      const config = getConfig()
      const link = new Link(config.LINK_ADDRESS)
      const toAddress = config.SALES_RECIPIENT_ADDRESS
      const usdcTokenAddress = config.USDC_TOKEN_ADDRESS
      const result : any = await link.transfer([
        {
          amount: amount.toString(),
          type: ERC20TokenType.ERC20,
          symbol: 'USDC',
          tokenAddress: usdcTokenAddress,
          toAddress,
        },
      ])
      return result.result[0].txId
    } catch (e: any) {
      if (!e) throw new Error('Transfer was cancelled')
      throw e
    }
  }
}
