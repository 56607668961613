import '../views.scss'

export default function UserWelcome() {
  return (
    <div className="empty-state">
      <h2 className={'title'}>Welcome, Deviant!</h2>
      <h1>You&apos;ve been randomly assigned a nickname and an avatar.</h1>
      <p className="description">During this stage of Alpha testing, you&apos;ll be able to play against our AI with any of the 12 preconstructed decks we built for you. You only need 1 Starter Pack to participate! Remember that $DEV rewards have not been implemented yet.</p>
    </div>
  )
}
