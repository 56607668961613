import { ServiceConsumer, ServiceData } from '../infraestructure/services/service-consumer/service-consumer.service'
import { ValidateIfTokenIsPresent } from '../infraestructure/repositories/sessionVerifier.repository'
import getConfig from '../config.service'

export default class ReferralService {
  public static async getReferralCode(playerId: string): Promise<any> {
    const token = ValidateIfTokenIsPresent()
    const config = getConfig()
    const requestData : ServiceData = {
      url: `${config.hostURL}/api/v1/users-manager/referrals/${playerId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.get<{data: any}>(requestData)
    return response
  }

  public static async registerReferralCode(playerId: string, referralCode: string): Promise<string> {
    const token = ValidateIfTokenIsPresent()
    const config = getConfig()
    const requestData : ServiceData = {
      url: `${config.hostURL}/api/v1/users-manager/referrals`,
      body: {
        referralCode,
        playerId,
      },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await ServiceConsumer.post<{message: string}>(requestData)
    return response.message
  }
}
