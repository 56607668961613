import axios from 'axios'
import getConfig from 'config.service'
import { AccumulatedXpByTask, LeaderboardRawEntry } from 'domain/play2airdrop.types'
import { TaskDto } from 'domain/task.model'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import moment from 'moment'

class Play2AirdropService {
  private static url = `${getConfig().hostURL}/api/v1/achievements/tasks`

  public static readonly minXp = getConfig().AIRDROP_MIN_XP || 0

  static async getCompletedDailyTasks(address: string) {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ tasks: AccumulatedXpByTask[] }>(`${this.url}/${address}/logs/daily`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.tasks
  }

  static async getCompletedTotalTasks(address: string) {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ tasks: AccumulatedXpByTask[] }>(`${this.url}/${address}/logs/total`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.tasks
  }

  static async getActiveTasks() {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ tasks: TaskDto[] }>(`${this.url}/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.tasks
  }

  static async getDailyXp(address: string) {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ xp: number }>(`${this.url}/${address}/xp/daily`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.xp
  }

  static async getTotalXp(address: string) {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ xp: number }>(`${this.url}/${address}/xp`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.xp
  }

  static async getLeaderboard() {
    const token = ValidateIfTokenIsPresent()
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ leaderboard: LeaderboardRawEntry[] }>(`${this.url}/leaderboard`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.leaderboard
  }

  static async getCompletedTasks(address: string) {
    const token = ValidateIfTokenIsPresent()
    const today = moment().format('YYYY-MM-DD')
    // eslint-disable-next-line no-undef
    const { data } = await axios.get<{ tasks: TaskDto[] }>(`${this.url}/${address}/completed/${today}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.tasks
  }
}

export default Play2AirdropService
