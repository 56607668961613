import { useState } from 'react'
import StakingRepository, { StakeDevProps } from 'repositories/staking.repository'

type UseStakeProps = {
  onSuccess?: () => void
  onError?: (error: any) => void
}

export default function useStake({ onSuccess, onError }: UseStakeProps) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const stake = async (props: StakeDevProps) => {
    try {
      setLoading(true)
      await StakingRepository.stakeDev(props)
      onSuccess?.()
    } catch (e: any) {
      setError(e)
      onError?.(e)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    stake,
    error,
  }
}
