import { SVGProps, useContext } from 'react'
import { CraftingContext } from './crafting-context.component'
import RecipeIngredients from './crafting-recipe-ingredients.component'
import CraftingTableFooter from './crafting-table-footer.component'
import CraftingCost from './crafting-cost.component'
import CraftingInfo from './crafting-info.component'
import { ReactComponent as Union } from './assets/union.svg'

export const UnionsDecorImage = (props: SVGProps<SVGSVGElement>) => <Union {...props} />
function CraftingResult() {
  const { selectedRecipe } = useContext(CraftingContext)
  return <div className='crafting-result'>
    {selectedRecipe && <img className='recipe-image' src={selectedRecipe?.result.art} alt={selectedRecipe?.result.name} />}
    {!selectedRecipe && <img src='/icon.png' className='no-recipe-image' />}
  </div>
}

export default function CraftingTable() {
  return <div className='crafting-table'>
    <UnionsDecorImage className='union union__a' />
    <UnionsDecorImage className='union union__b' />
    <div className='crafting-table-details'>
      <div className='recipe-info'>
        <CraftingInfo />
        <CraftingCost />
      </div>
      <CraftingResult />
    </div>
    <RecipeIngredients />
    <CraftingTableFooter />
  </div>
}
