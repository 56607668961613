import getConfig from 'config.service'
import { AssignedChallenge, Challenge } from 'domain/challenges.model'
import { ValidateIfTokenIsPresent } from 'infraestructure/repositories/sessionVerifier.repository'
import { ServiceConsumer, ServiceData } from 'infraestructure/services/service-consumer/service-consumer.service'

const { hostURL } = getConfig()
export default class ChallengesRepository {
  public static async getChallenges(): Promise<Challenge[]> {
    const token = ValidateIfTokenIsPresent()
    const { challenges } = await ServiceConsumer.get<{ challenges: Challenge[] }>({
      url: `${hostURL}/api/v1/achievements/challenges`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return challenges
  }

  public static async getAssignedChallenges(playerId: string): Promise<AssignedChallenge[]> {
    const token = ValidateIfTokenIsPresent()
    const requestData: ServiceData = {
      url: `${hostURL}/api/v1/achievements/challenges/assigned/player/${playerId}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const assignedAchievements = await ServiceConsumer.get<{ assignedChallenges: AssignedChallenge[] }>(requestData)
    return assignedAchievements.assignedChallenges
  }
}
