/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import Select from 'react-select'
import { currentDeckAtom, currentDeckSelector, playableDeckListSelector } from 'selectors/selectors'
import './deckselector.component.scss'

const customStyles: any = {
  option: (provided: any, state: any) => ({
    ...provided,
    ':active': {
      backgroundColor: '#FFFFFF',
      color: '#FF5207',
    },
    background: state.isSelected ? '#FF5207' : 'linear-gradient(90deg, #0b0806 0%, #1b1f1e 50%, #0b0806 100%)',
    color: state.isFocused && !state.isSelected ? '#FF5207 !important' : '#FFFFFF !important',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    textIndent: '10px',
  }),
}

export function DeckSelectorDropDown({ className }: { className?: string }) {
  const availableDecks = useRecoilValueLoadable(playableDeckListSelector)
  const currentDeck = useRecoilValueLoadable(currentDeckSelector)
  const selectDeck = useSetRecoilState(currentDeckAtom)

  const [selected, setSelected] = useState<any>(null)
  const [options, setOptions] = useState<any>(null)

  useEffect(() => {
    if (currentDeck.state === 'hasValue' && availableDecks.state === 'hasValue') {
      let deckSelected = null
      const deckOptions = availableDecks.contents.map((deck: any) => {
        const opt = { value: deck.id, label: deck.name }
        if (deck.id === currentDeck.contents.id) deckSelected = opt
        return opt
      })

      setOptions(deckOptions)
      setSelected(deckSelected)
    }
  }, [currentDeck, availableDecks])

  return (
    <div style={{ marginBottom: 20 }} className={className}>
      <h1 style={{ fontSize: '1em', textAlign: 'left' }}>Choose a Deck</h1>

      {
        options ? (
          <Select
            styles={customStyles}
            className={'deviants-select'}
            options={options}
            value={selected}
            onChange={(opt) => {
              const val = opt.value
              const deck = availableDecks.contents.find((d: any) => d.id === val)
              localStorage.setItem('currentDeck', deck.id)
              selectDeck(deck)
            }} />
        ) : (
          <div> loading</div>
        )
      }
    </div>
  )
}
