import { rewardsTableStyle } from 'components/rewards/rewards.component'
import { Challenge } from 'domain/challenges.model'
import { Grid } from 'gridjs-react'
import moment from 'moment'
import { useRecoilValueLoadable } from 'recoil'
import { challengesSelector } from 'selectors/selectors'
import './economy-achievements-grid.component.scss'

export default function EconomyChallengesGrid() {
  const challenges = useRecoilValueLoadable<Challenge[]>(challengesSelector)
  if (challenges.state === 'loading') return <p>Loading...</p>
  if (challenges.state === 'hasError') return <p>An error has ocurred</p>
  return <Grid
    columns={[
      { id: 'id', name: 'ID' },
      { id: 'name', name: 'NAME' },
      { id: 'startDate', name: 'START' },
      { id: 'endDate', name: 'END' },
    ]}
    style={{
      ...rewardsTableStyle,
      table: {
        width: '100%',
      },
      td: {
        ...rewardsTableStyle.td,
        fontSize: '0.85em',
      },
    }}
    data={challenges?.contents.map((a: Challenge) => [
      a.id,
      a.name,
      moment(a.startDate).utc().format('YYYY-MM-DD HH:mm'),
      moment(a.endDate).utc().format('YYYY-MM-DD HH:mm'),
    ])}
    sort={true} />
}
