import { CSSProperties, ReactNode } from 'react'

export type FlexProps = {
  children: ReactNode | ReactNode[],
  flexDirection: 'row' | 'column',
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly',
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch',
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'stretch',
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
  flexGrow?: number,
  flexShrink?: number,
  flexBasis?: string,
  flex?: string,
  gap?: string,
  style?: CSSProperties,
  className?: string
}

export default function Flex(props: FlexProps) {
  const styles = { ...props, ...props.style }
  delete styles.children
  return <div style={{ display: 'flex', ...styles }} className={props.className}>
    {props.children}
  </div>
}
