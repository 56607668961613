import {
  Asset as iAsset,
  ListAssetsResponse,
  ImmutableX,
} from '@imtbl/core-sdk'
import Wallet from '../domain/wallet.model'
import Asset from '../domain/asset.model'
import getConfig from '../config.service'

export default class MyAssetsRepository {
  static LOADING: boolean = false

  static async listAssets(
    wallet: Wallet,
    nextCursor: string | undefined,
    metadata?: string,
    collection?: string,
    pageSize?: number,
  )
    : Promise<ListAssetsResponse> {
    const { address: user } = wallet
    const config = getConfig().IMX_CONFIG
    const client = new ImmutableX(config)
    // const collection = getConfig().collectionContract(ethNetwork)
    return client.listAssets({
      pageSize,
      user,
      cursor: nextCursor,
      sellOrders: true,
      metadata,
      collection,
    })
  }

  static async getBoosters(wallet: Wallet, nextCursor: string | undefined, metadata?: string): Promise<Asset[]> {
    const ethNetwork = getConfig().ETH_NETWORK
    const collection = getConfig().collectionContract(ethNetwork)
    const { result, remaining, cursor } = await MyAssetsRepository.listAssets(wallet, nextCursor, metadata, collection)
    const assets: Asset[] = result.map((asset: iAsset): Asset => new Asset(asset))
    if (remaining === 0) return assets
    const remainingAssets = await MyAssetsRepository.getBoosters(wallet, cursor, metadata)
    return assets.concat(remainingAssets)
  }

  static async getAllByWallet(wallet: Wallet): Promise<Asset[]> {
    if (MyAssetsRepository.LOADING) return []
    MyAssetsRepository.LOADING = true
    const assets: Asset[] = await MyAssetsRepository.getAssetsFor(wallet, undefined)
    MyAssetsRepository.LOADING = false
    return assets
  }

  static async getAssetsFor(wallet: Wallet, nextCursor: string | undefined, metadata?: string): Promise<Asset[]> {
    const { result, remaining, cursor } = await MyAssetsRepository.listAssets(wallet, nextCursor, metadata)
    const assets: Asset[] = result.map((asset: iAsset): Asset => new Asset(asset))
    if (remaining === 0) return assets
    const remainingAssets = await MyAssetsRepository.getAssetsFor(wallet, cursor, metadata)
    return assets.concat(remainingAssets)
  }

  static async previewAssetsQuantity(wallet: Wallet, metadata: string): Promise<{ qty: number, hasMore: boolean }> {
    const { address: user, ethNetwork } = wallet
    const config = getConfig().IMX_CONFIG
    const client = new ImmutableX(config)
    const collection = getConfig().collectionContract(ethNetwork)
    const assets = await client.listAssets({
      user,
      sellOrders: true,
      metadata,
      pageSize: 20,
      collection,
    })
    return { qty: assets.result.length, hasMore: assets.remaining > 0 }
  }

  static async countAssets(wallet: Wallet, metadata: string): Promise<number> {
    const assets = await MyAssetsRepository.getAssetsFor(wallet, undefined, metadata)
    return assets.length
  }
}
