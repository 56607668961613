import UserBalance from 'components/UserBalance/UserBalance.component'
import './player-cashier.view.scss'
import getConfig from 'config.service'
import VolExchangeComponent from 'components/VolExchange/VolExchange.component'
import { useState } from 'react'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import Button from 'components/Button/Button.component'
/* import DevExchangeComponent from 'components/DevExchange/DevExchange.component' */
// import PlayerCashierComponent from '../../components/TournamentTickets/TournamentTickets.component'
import Footer from '../../components/Footer/Footer.component'

export default function PlayerCashierView() {
  const config = getConfig()
  const { ENABLE_VOL_EXCHANGE } = config
  const [modalMessage, setModalMessage] = useState<string[]>(['', ''])
  const handleClickOutside = () => {
    setModalMessage(['', ''])
  }
  return (
    <>
      <UserBalance />
      <div className="player-cashier-view">
        <div className="player-cashier-container">
          {ENABLE_VOL_EXCHANGE && <VolExchangeComponent setModalMessage={setModalMessage} />}
          {/* <PlayerCashierComponent /> */}
          {/* <DevExchangeComponent setModalMessage={setModalMessage} /> */}
        </div>
        <Modal isActive={modalMessage[0] !== ''} closeAction={handleClickOutside} id="modal">
          <div>
            <h2>{modalMessage[0]}</h2>
            <br />
            <div className='modal-message'>
              {modalMessage[1]}
            </div>
            <div className='modal-button'>
              <Button variant={'outline'} size={'small'} onClick={(event) => {
                event.preventDefault()
                setModalMessage(['', ''])
                handleClickOutside()
              }} >Close</Button>
            </div>
          </div>
        </Modal>
        <Footer />
      </div>
    </>
  )
}
