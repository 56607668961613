// noinspection HtmlUnknownTarget

import './tutorials.view.scss'
import Footer from 'components/Footer/Footer.component'
import { useRecoilValueLoadable } from 'recoil'
import { profileSelector } from 'selectors/selectors'
import { setLoader } from '../../components/Loader/Loader.component'

type TutorialDto = {
  id: number
  title: string
  description?: string
  extra?: string
  url: string
  tutorial?: number
  btn?: string
  target?: string
}

const tutorials: TutorialDto[] = [
  {
    id: 1,
    title: '01: Basics Tutorial',
    description: 'Game Goal and how to play Deviants Factions',
    url: '/play/tutorial-01',
    tutorial: 1,
  },
  {
    id: 2,
    title: '02: Abilities Tutorial',
    description: 'How and when they work',
    url: '/play/tutorial-02',
    tutorial: 2,
  },
  {
    id: 3,
    title: '03: Triggered Mechanics Tutorial',
    description: 'Riot, Swap, Obey, Frighten and Heal',
    url: '/play/tutorial-03',
    tutorial: 3,
  },
  {
    id: 4,
    title: '04: Continuous Mechanics Tutorial',
    description: 'Berserk, Thoughness and Grip',
    extra: 'Complete and get 20 TOURNAMENT TICKETS',
    url: '/play/tutorial-04',
    tutorial: 4,
  },
  {
    id: 5,
    title: '05: Casual Play Experience',
    description: 'Play 10 casual games with the beginners decks and get a brand new NFT Booster Pack',
    extra: 'Claim your NFT!',
    btn: 'Play Now!',
    url: '/deck-select',
    tutorial: 5,
  },
  {
    id: 6,
    title: '06: Tournament Experience',
    description: 'Play 1 Playground Daily Tournament to get extra tokens and NFT Booster Packs!',
    extra: 'Claim your rewards!',
    btn: 'Play Now!',
    url: '/tournaments',
    tutorial: 6,
  },
  {
    id: 7,
    title: '07: Craft a Card',
    description: 'Crafting unlocks a new level for your NFT cards',
    btn: 'Craft now!',
    url: '/crafting',
    tutorial: 7,
  },
  {
    id: 8,
    title: '08: Upgrade your deck',
    description: 'Add your new crafted card to one of your decks to make it more powerful',
    btn: 'Build now!',
    url: '/deck-builder',
    tutorial: 8,
  },
  {
    id: 9,
    title: '09: Get to the top!',
    description: 'Check out our different tournament tiers and compete in Deviants League',
    btn: 'Learn more',
    url: '/leagues',
    tutorial: 9,
  },
]

export default function TutorialsView() {
  const profile = useRecoilValueLoadable(profileSelector)
  setLoader(profile.state === 'loading')

  function numberCompleted(): number {
    if (!profile) return 0
    return profile.contents?.info?.tutorialCompleted || 0
  }

  function tutorialIsValid(t: TutorialDto) {
    if (t.tutorial) {
      if (t.tutorial === 1) return true
      if (profile.state === 'hasValue') {
        if (typeof profile.contents?.info.tutorialCompleted === 'number') {
          return t.tutorial - 1 <= profile.contents?.info.tutorialCompleted
        }
        return false
      }
      return false
    }
    return true
  }

  return (
    <div className="tutorials">
      <h2 />
      <h1>TUTORIALS</h1>
      <h2>Complete these steps to mint a free nft booster pack and get free tournament tickets!</h2>

      <div className='items'>
        {
          tutorials.map((t) => (
            <div key={`tutorial-${t.id}`} className={`item tutorial-${t.id}`}>
              <h1>{t.title}</h1>
              <br />
              {
                t.description ? (
                  <>
                    <p>{t.description}</p>
                  </>
                ) : null
              }
              <div className="sep" />
              {
                tutorialIsValid(t) ? (
                  <a href={t.url} target={t.target} className="hero-button btn-rulebook btn-shadow">
                    <div className="btn btn-medium btn-primary" id="hero-rulebook">
                      {t.btn ? t.btn : 'Play Tutorial Now!'}
                    </div>
                  </a>
                ) : (
                  <a className="hero-button btn-rulebook btn-shadow btn-disabled">
                    <div className="btn btn-medium btn-primary" id="hero-rulebook">
                      {t.btn ? t.btn : 'Play Tutorial Now!'}
                    </div>
                  </a>
                )
              }
              {(t.id === 4 || t.id === 5 || t.id === 6) && (
                <div className='tutorial-reward'>
                  <a href={`${(numberCompleted() >= t.id) ? '/achievements' : '#'}`} className="hero-button btn-shadow">
                    <div className={`btn btn-medium btn-primary ${(numberCompleted() < t.id) ? 'btn-disabled' : ''}`} id="tutorials-reward">
                      Claim your reward!
                    </div>
                  </a>
                </div>)}
            </div>
          ))
        }
      </div>
      <p className='rules-link'>
        Click <a href="https://docs.deviantsfactions.com/deviants-factions-rulebook/goal-of-the-game">here</a> for a better understanding of the rules
      </p>
      <Footer />
    </div >
  )
}
