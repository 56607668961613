import './rewards.view.scss'
import RewardsComponent from '../../components/rewards/rewards.component'
import Footer from '../../components/Footer/Footer.component'

export default function PurchasesView() {
  return (
    <div className="rewards-view">
      <div className='rewards-view__title'>
        <h3>My Rewards</h3>
      </div>
      <RewardsComponent />
      <div className='rewards-view__footer'> </div>
      <Footer />
    </div>
  )
}
