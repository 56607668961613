/* eslint-disable */
import axios from "axios"
import { sessionExpired } from "../../repositories/sessionVerifier.repository"

export class ServiceConsumer {
  static async post<T>(data: ServiceData): Promise<T> {
    try {
      const { url, body, headers } = data
      const response = await axios.post<T>(url, body, { headers })
      return response.data
    } catch (e) {
      const err: any = e
      if (err?.response?.status === 401) {
        sessionExpired()
      }
      throw e
    }
  }

  static async patch<T>(data: ServiceData): Promise<T> {
    try {
      const { url, body, headers } = data
      const response = await axios.patch<T>(url, body, { headers })
      return response.data
    } catch (e) {
      const err: any = e
      if (err?.response?.status === 401) {
        sessionExpired()
      }
      throw e
    }
  }

  static async get<T>(data: ServiceData): Promise<T> {
    try {
      const { url, headers, params } = data
      const response = await axios.get<T>(url, { headers, params })
      return response.data
    } catch (e) {
      const err: any = e
      if (err?.response?.status === 401) {
        sessionExpired()
      }
      throw e
    }
  }
  static async delete<T>(data: ServiceData): Promise<T> {
    try {
      const { url, headers } = data
      const response = await axios.delete<T>(url, { headers })
      return response.data
    } catch (e) {
      const err: any = e
      if (err?.response?.status === 401) {
        sessionExpired()
      }
      throw e
    }
  }
}

export interface ServiceData {
  url: string,
  body?: any,
  headers?: any
  params?: any
}
