import { useRecoilValueLoadable } from 'recoil'
import { leagueScores } from 'selectors/selectors'
import './leagues.view.scss'
import LoadingPage from 'components/LoadingPage/LoadingPage.component'
import LeagueRanking from './league-ranking/league-ranking.component'
import LeagueRankingItem from './league-ranking/league-ranking-item.component'
import LeagueHeader from './league-header/league-header.component'

export default function LeaguesView() {
  const leagueData = useRecoilValueLoadable(leagueScores)
  return (
    <div className='league-view-container' id='leagues'>
      {leagueData.state === 'hasValue' && <LeagueHeader leagueData={leagueData.contents} />}
      {(leagueData.state === 'loading') && (<LoadingPage />)}
      {(leagueData.state === 'hasError') && (<div>Error: something went wrong, try again later...</div>)}
      <div className='league-view-layout'>
        {leagueData.state === 'hasValue' && <LeagueRanking>
          <LeagueRanking.Header />
          <LeagueRanking.Body>
            {leagueData.contents?.scores.map((score: any, index: number) => (
              <LeagueRankingItem
                address={score.address}
                rewards={leagueData.contents.league.rewards}
                key={score.userId}
                avatar={score.avatar}
                player={score.player}
                position={index + 1}
                score={score.score} />
            ))}
          </LeagueRanking.Body>
        </LeagueRanking>}
      </div>
    </div>
  )
}
