import { useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import BalanceRepository from 'repositories/balance.repository'
import { connectedWallet } from 'selectors/selectors'

export default function useBalance(tokenAddress: 'dev' | 'imx' | 'usdc' | 'eth' | 'gods') {
  const [balance, setBalance] = useState(0)
  const user = useRecoilValueLoadable(connectedWallet)
  const fetch = async () => {
    if (tokenAddress === 'eth') {
      const b = await BalanceRepository.getEthBalance(user.contents.address)
      setBalance(b / (10 ** 18))
    } else {
      const b = await BalanceRepository.getBalance(user.contents.address, tokenAddress)
      setBalance(b.balance)
    }
  }
  useEffect(() => {
    fetch()
      .catch(() => {
        setBalance(0)
      })
  }, [tokenAddress])

  return { balance, refresh: fetch }
}
