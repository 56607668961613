import getConfig from 'config.service'
import { OffChainBalance } from 'domain/offchain-rewards.model'
import { TournamentTicket } from 'domain/tournament-ticket.model'
import { useEffect, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { ticketCatalogSelector, userBalanceSelector } from 'selectors/selectors'
import './userBalance.component.scss'

const config = getConfig()
const {
  ENABLE_BALANCE,
} = config

export const mapBalanceIcon = ({ ticketId, assetName, currency }:
  { ticketId?: string, assetName?: string, currency?: string }) => {
  if (currency) return `/images/currency/${currency}.png`
  if (ticketId) return `/images/tickets/${ticketId}.png`
  if (assetName) return `/images/currency/${assetName}.png`
  return '/images/currency/1.png'
}

const UserBalance = () => {
  const balances = useRecoilValueLoadable<OffChainBalance[]>(userBalanceSelector)
  const ticketCatalog = useRecoilValueLoadable<TournamentTicket[]>(ticketCatalogSelector('all'))
  const [balancesWithTickets, setBalancesWithTickets] = useState<any>([])

  const buildUserBalance = async () => {
    const rawBalances = await balances.toPromise()
    const rawTickets = await ticketCatalog.toPromise()
    return rawBalances.map((b) => {
      if (b.ticketId) {
        const ticket = rawTickets.find((t) => t.id.toString() === b.ticketId)
        return { ...b, ticketName: ticket?.name }
      }
      return b
    })
  }

  useEffect(() => { buildUserBalance().then(setBalancesWithTickets) }, [])

  if (!ENABLE_BALANCE) return <></>
  if (balances.state !== 'hasValue') return <></>

  return <div className="user-balances-wrapper">
    <div className='user-balances-wrapper__tickets'>
      <div className='user-balances-wrapper__tickets-container-title'>TICKET INVENTORY</div>
      <div className='user-balances-wrapper__tickets-container'>
        {balancesWithTickets.filter((balance: OffChainBalance) => balance.balance > 0)
          .map((balance: OffChainBalance & { ticketName: string }) => (
            <div className="user-balances-wrapper__item" key={`${balance.offChainCurrency}${balance.balance}`}>
              <div>
                <img width="24" height="24" src={mapBalanceIcon({ ticketId: balance.ticketId, assetName: balance.offChainCurrency })} className="user-balances-wrapper__icon" />
                <span className='user-balances-wrapper__item__badge'>{balance.balance || '0'}</span>
              </div>
              <div className='user-balances-wrapper__name'>{balance.ticketName}</div>
            </div>
          ))}
      </div>
    </div>
  </div>
}

export default UserBalance
