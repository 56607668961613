/* eslint-disable max-len */
import './PrizePool.component.scss'
import { Tournament, TournamentAssetReward, TournamentMoneyReward } from 'domain/tournament.model'
import MoneyComponent from 'components/Money/Money.component'

export default function PrizePool({ tournament }: { tournament: Tournament }) {
  const { rewardPool } = tournament
  if (!rewardPool) return null
  return (
    <div className="prize-pool">
      {
        rewardPool.strategy === 'FIXED' && (<>
          <div>First Place</div>
          <div className='prize-pool__rewards'>
            {rewardPool.rewards?.[0]?.map((r: any, i: number) => {
              if (r.type === 'MONEY') {
                const moneyReward = r as TournamentMoneyReward
                return <MoneyComponent
                  convert={moneyReward.in !== moneyReward.money.currency}
                  showConvertions={false}
                  money={{
                    amount: moneyReward.money.amount,
                    currency: moneyReward.in,
                    rawAmount: moneyReward.money.amount,
                  }} key={`reward-pool-${i}`} />
              }
              if (r.type === 'BOOSTER') {
                const rw = r as TournamentAssetReward
                return <div className="prize-pool__booster" key={`reward-pool-${i}`}>
                  <img src={`/images/boosters/${rw.key}.png`} alt={rw.type} /><span>&#x2715;</span>{rw.amount}</div>
              }
              return null
            })
            }
          </div>
        </>)
      }
    </div>
  )
}
