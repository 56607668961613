import {
  CSSProperties,
  ReactNode, useEffect, useState,
} from 'react'
import './toast.component.scss'
import { createPortal } from 'react-dom'

type ToastProps = {
  id: string
  isActive: boolean
  children: ReactNode,
  onClose?: () => void
  duration?: number,
  style?: CSSProperties
}

export default function Toast(props: ToastProps) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (props.isActive) {
      setIsOpen(true)
      setTimeout(() => {
        props.onClose?.()
        setIsOpen(false)
      }, props.duration || 3000)
    }
  }, [props.isActive])

  return createPortal(<div className='toast-wrapper'>
    <div className='toast' style={{ visibility: isOpen ? 'visible' : 'hidden', ...props.style }}>{props.children}</div>
  </div>, document.body, props.id)
}
