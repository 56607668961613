import Flex from 'components/Flex/Flex'
import Select from 'components/Select/Select.component'
import Text from 'components/Text/Text'

type TokenSelectorProps = {
  onChange: (token: string) => void
}

export default function TokenSelector({ onChange }: TokenSelectorProps) {
  const tokens = ['ETH', 'GODS', 'IMX', 'USDC']

  return <Select style={{ width: '370px' }} onSelected={(calue: string) => onChange(calue)}>
    {tokens.map((token) => (
      <Select.Option value={token} key={token}>
        <Flex key={token} flexDirection='row' alignItems='center' gap="0.75ch">
          <img width={'32px'} src={`/images/currency/${token}.png`} />
          <Text>{token}</Text>
        </Flex>
      </Select.Option>
    ))}
  </Select>
}
