import { useContext } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { profileBalancesSelector } from 'selectors/selectors'
import { CraftingContext } from '../crafting-context.component'
import { mapCraftingCostCurrencies } from '../crafting-cost.component'

export default function useCraftingCostSatisfied(): boolean {
  const { selectedRecipe } = useContext(CraftingContext)
  const balance = useRecoilValueLoadable(profileBalancesSelector)
  if (balance.state !== 'hasValue') return false
  if (!selectedRecipe) return false
  const { cost } = selectedRecipe
  const isSatisfied = cost.every(({ amount, currency }) => {
    const balanceAmount = Object.keys(balance.contents)
    const balanceCurrency = mapCraftingCostCurrencies(currency as 'XDEV' | 'VOL_POINT')
    const hasCurrencyInBalance = balanceAmount.find((b: string) => b === balanceCurrency)
    if (!hasCurrencyInBalance) return false
    return balance.contents[balanceCurrency] >= amount
  })
  return isSatisfied
}
