import { useState } from 'react'
import useInterval from 'services/useInterval.hook'
import moment, { Moment } from 'moment'
import { sum, padStart } from 'lodash'
import './TournamentTimer.scss'

type TournamentTimerType = {
  tournamentDate: Moment
}

const TournamentTimer = ({
  tournamentDate,
}: TournamentTimerType) => {
  const updateClock = (targetDate: Moment) => {
    // console.log('target', targetDate.toISOString())
    let diff = targetDate.diff(moment(new Date().toUTCString()), 'seconds')
    if (diff < 0) return [0, 0, 0]
    const clockHours = Math.floor(diff / 60 / 60)
    diff -= clockHours * 60 * 60
    const clockMinutes = (Math.floor(diff / 60))
    diff -= clockMinutes * 60
    return [clockHours, clockMinutes, diff]
  }

  const [endDate] = useState(tournamentDate)
  const [clock, setClock] = useState(updateClock(endDate))

  useInterval(() => {
    if (sum(clock) > 0) {
      setClock(updateClock(endDate))
    }
  }, 1000)

  return <div className="tournament-timer">
    <div className="container">
      <span className='hours'>{padStart(clock[0].toString(), 2, '0')}</span><span className='description'>hrs</span>
      <span className='separator'></span>
      <span className='minutes'>{padStart(clock[1].toString(), 2, '0')}</span><span className='description'>mins</span>
      <span className='separator'></span>
      <span className='seconds'>{padStart(clock[2].toString(), 2, '0')}</span><span className='description'>sec</span>
    </div>
  </div>
}
export default TournamentTimer
