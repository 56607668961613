import './purchases.component.scss'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { connectedWallet } from 'selectors/selectors'
import PurchaseRepository from 'repositories/purchases.repository'
import { Purchase } from 'domain/purchase.model'
import { Loader } from 'components/Loader/Loader.component'
import ResponseMinterModal from 'components/minter/modal/response-minter-modal.component'
import ButtonLoader from 'components/ButtonLoader/ButtonLoader.component'
import MinterService from 'services/minter.service'
import cookie from 'cookiejs'

export default function PurchasesComponent() {
  const [purchases, setPurchases] = useState<Purchase[]>([])
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({ message: '', type: '', paymentId: undefined })
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase | undefined>(undefined)
  const [showModal, setShowModal] = useState(false)

  const user = useRecoilValue(connectedWallet)

  function loadPurchases() {
    if (!user) return
    setLoading(true)
    PurchaseRepository.getByUserAddress(user?.address).then((purchasesRes: Purchase[]) => {
      setPurchases(purchasesRes)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  function retryMintBooster(purchase: Purchase) {
    if (loading) return
    setSelectedPurchase(purchase)
    if (!user) {
      setResponse({ message: 'You need to be connected to retry boosters', type: 'error', paymentId: undefined })
      setShowModal(true)
      return
    }
    setLoading(true)
    const referralCode = cookie.get('user') ? String(cookie.get('referral')) : undefined
    MinterService.register(purchase.type, purchase.quantity, purchase.paymentId, user.address, referralCode)
      .then((res: any) => {
        setResponse({ message: res.message, type: 'success', paymentId: res.paymentId })
        setShowModal(true)
        loadPurchases()
      })
      .catch((error: any) => {
        const message = error.response?.data?.message || 'Something went wrong'
        setResponse({ message, type: 'error', paymentId: error.paymentId })
        setShowModal(true)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    loadPurchases()
  }, [user?.address])

  return (
    <div className="purchases">
      {(loading && !selectedPurchase) ? <Loader /> : null}
      <div className="purchases__list">
      <div className="purchases__item">
            <div className="purchase__payment-id">PAYMENT</div>
            <div className="purchase__quantity">AMOUNT</div>
            <div className="purchase__type">TYPE</div>
            <div className="purchase__status">STATUS</div>
            <div className="purchase__actions">&nbsp;</div>
      </div>

        {purchases.map((purchase: Purchase) => (
        <div className="purchases__item" key={`purchase-${purchase.paymentId}`}>
            <div className="purchase__payment-id">
              <a href={`https://immutascan.io/tx/${purchase.paymentId}`} target="_blank" rel="noreferrer">
                {purchase.paymentId}
              </a>
            </div>
            <div className="purchase__quantity">{purchase.quantity}</div>
            <div className="purchase__type">{purchase.type}</div>
            <div className="purchase__status">{purchase.status}</div>
            <div className="purchase__actions">{
              purchase.status === 'PENDING' && (
                <div className='btn btn-primary' onClick={() => { retryMintBooster(purchase) }}>
                  Retry
                  {loading && purchase === selectedPurchase && <ButtonLoader />}
                </div>
              )
            }</div>
        </div>
        ))}
      </div>
      <ResponseMinterModal showModal={showModal} setShowModal={setShowModal} response={response} />
    </div>
  )
}
