import { useEffect, useState } from 'react'
// eslint-disable-next-line camelcase
import { useRecoilRefresher_UNSTABLE, useRecoilValueLoadable } from 'recoil'
import { sdevStoreLockTimeSelector } from 'selectors/staking.selectors'

export default function useItemLockTimer(props: { id: string | undefined }) {
  const [lockTime, setLockTime] = useState<number | undefined>(undefined)
  const timeLock = useRecoilValueLoadable(sdevStoreLockTimeSelector(props.id?.toString()))
  const refresh = useRecoilRefresher_UNSTABLE(sdevStoreLockTimeSelector(props.id?.toString()))
  useEffect(() => {
    if (timeLock.contents > 0) {
      if (lockTime === undefined) {
        setLockTime(timeLock.contents || 0)
      }
    }
  }, [timeLock.contents])
  useEffect(() => {
    const interval = setInterval(() => {
      if (lockTime && lockTime > 0) setLockTime(lockTime - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [lockTime])
  return { lockTime: lockTime || 0, loadingTimer: timeLock.state === 'loading', refreshTimer: refresh }
}
