import { Box } from 'components/Box/box.component'
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core' */
import Flex from 'components/Flex/Flex'
import Text from 'components/Text/Text'

type AirdropTaskProps = {
  title: string
  description: string
  completed: boolean
}

export default function AirdropTaskItem(props: AirdropTaskProps) {
  const { description } = props
  return <Box width='9vw' style={{ textAlign: 'center', marginBottom: '20px' }}>
    <Flex flexDirection='column' gap={'2ch'}>
      <div>
        <h2>{description.split(' ')[0]}</h2>
      </div>
      <div style={{ fontSize: '14px', height: '30px', color: 'white' }}>
        <Text fontWeight={'bold'}>
          {description.split(' ').slice(1).join(' ')}
        </Text>
      </div>
      {/* <div style={{ paddingTop: '10px' }}>
        {completed ? <FontAwesomeIcon icon={faCheck as IconProp} color="lightgreen" /> : null}
        {!completed ? <FontAwesomeIcon icon={faCheck as IconProp} color="grey" /> : null}
      </div> */}
    </Flex>
  </Box>
}
