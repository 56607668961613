import Asset from 'domain/asset.model'
import { useContext } from 'react'
import { CraftingIngredient } from 'domain/crafting-recipe.model'
import { CardType } from 'domain/card.model'
import { CraftingContext } from './crafting-context.component'
import useIsIngredientCompleted from './hooks/useIsIngredientCompleted.hook'

function IngredientQty({
  ingredient, hasEnough, ingredientsAvailable, isQtySatisfied,
}:
  {
    ingredient: CraftingIngredient, hasEnough: boolean,
    ingredientsAvailable: Map<string, Asset[]> | undefined, isQtySatisfied: boolean
  }) {
  const isSatisfiedClass = isQtySatisfied ? 'qty-satisfied' : 'qty-not-satisfied'
  return <div className={`ingredient-info ${hasEnough ? isSatisfiedClass : 'no-ingredient'}`}>
    <div className='ingredient-info__name'>{ingredient.name}</div>
    {ingredientsAvailable && <div className={`ingredient-info__qty ${hasEnough ? isSatisfiedClass : 'no-ingredient'}`}>
      {ingredientsAvailable?.get(ingredient.id)?.length || '0'}/{ingredient.quantity}
    </div>}
  </div>
}

function CardMetadata({ card }: { card?: CardType }) {
  if (!card) return <></>
  return <div className='crafting-ingredient-metadata'>
    <div className='crafting-ingredient-metadata__set'>{card.Set}</div>
    <div className='crafting-ingredient-metadata__card-number'>{card.SetAndNumber}</div>
  </div>
}

function Ingredient({ ingredient }: { ingredient: CraftingIngredient }) {
  const { ingredientsAvailable, setSelectingCard, ingredientsCardInfo } = useContext(CraftingContext)
  const hasEnough = (ingredientsAvailable?.get(ingredient.id)?.length || 0) >= ingredient.quantity
  const isQtySatisfied = useIsIngredientCompleted(ingredient.id)
  return <div className='recipe-ingredient' onClick={() => setSelectingCard(ingredient.id)}>
    <div className='ingredient-overlay' />
    {!hasEnough && <div className='ingredient-status __not-enough'>INSUFFICIENT</div>}
    {isQtySatisfied && <div className='ingredient-status __ready'>READY</div>}
    {!isQtySatisfied && hasEnough && <div className='ingredient-status __not-ready'>
      CLICK HERE
      <p>TO SELECT</p>
    </div>}
    <img
      src={ingredient.art}
      alt={ingredient.id} />
    <IngredientQty isQtySatisfied={isQtySatisfied}
      hasEnough={hasEnough} ingredient={ingredient} ingredientsAvailable={ingredientsAvailable} />
    <CardMetadata card={ingredientsCardInfo?.get(ingredient.id)} />
  </div >
}

export default function RecipeIngredients() {
  const { selectedRecipe } = useContext(CraftingContext)
  return <div className='ingredients-container'>
    <h2 style={selectedRecipe ? {} : { opacity: 0.5, textDecoration: 'line-through' }}>Materials</h2>
    <div className='ingredient-list'>
      {selectedRecipe?.ingredients.map((ingredient) => <Ingredient ingredient={ingredient} key={ingredient.id} />)}
    </div>
  </div>
}
