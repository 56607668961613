import './TournamentRounds.component.scss'

function getState(round: number, currentRound: number) {
  if (round === currentRound) return 'current'
  if (round < currentRound) return 'past'
  return 'future'
}

export default function TournamentRounds(
  { rounds, currentRound }: {rounds: number, currentRound: number},
) {
  return (
    <div className="tournament-rounds">
      {Array(rounds).fill(0).map((_, index) => (
        <div
        key={index}
        className={`tournament-rounds__round tournament-rounds__round--${getState(index, currentRound)}`}
        >
          {`R${index + 1}`}
        </div>
      ))}
    </div>
  )
}
