import { CraftingRecipe } from 'domain/crafting-recipe.model'
import { useRecoilValueLoadable } from 'recoil'
import { craftingRecipesSelector } from 'selectors/crafting'
import CraftingRecipeComponent from './crafting-recipe.component'

export default function CraftingRecipes() {
  const recipes = useRecoilValueLoadable(craftingRecipesSelector)
  if (recipes.state === 'loading') return <div>Loading...</div>
  return <div className='recipes-container'>
    <h2 style={{ padding: '15px 0', textAlign: 'left', fontSize: '1.4rem' }}>RECIPES</h2>
    <div className='crafting-recipes scroll'>
      {recipes.contents?.map((recipe: CraftingRecipe) => <CraftingRecipeComponent key={recipe.id} recipe={recipe} />)}
    </div>
  </div>
}
