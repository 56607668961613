import { useState } from 'react'
import { Box } from 'components/Box/box.component'
import Flex from 'components/Flex/Flex'
import PageHeader from 'views/airdrop/components/page-header/page-header.component'
import PageBody from 'views/airdrop/components/page-body/page-body.component'
import Button from 'components/Button/Button.component'
import Text from 'components/Text/Text'
import StakingMyDev from './mydev.component'
import StakingMyStake from './mystake.component'
import StakingMysDev from './mysdev.component'
import AddstakingModalComponent from './stake-modal/addstaking-modal.component'
import StakeStore from './stake-store/stakestore.component'
import hero from '../assets/staking-hero.jpg'
import AddOneStepStakingModalComponent from './stake-modal/one-step-add-staking-modal.component'

export default function StakingComponent() {
  const [showStakeModal, setShowStakeModal] = useState(false)

  return <>
    <PageHeader imageSrc={hero} overlay>
      <div className='page-header__title' style={{ fontSize: '3rem' }}>
        <h3>$DEV STAKING</h3>
      </div>
      <div className='page-header__subtitle'>
        <Text fontWeight={600} fontSize={'1.3rem'}>Stake your $DEV to earn rewards</Text>
        <Button variant='primary_alt' size='medium'>
          <a target='_blank' rel='noreferrer' className='page-header__subtitle__link button' href="https://docs.deviantsfactions.com/deviants-factions-rulebook/dev-staking">Staking rules</a>
        </Button>
      </div>
    </PageHeader >
    <PageBody>
      <Flex flexDirection='row'>
        <AddstakingModalComponent isActive={showStakeModal} closeAction={() => setShowStakeModal(false)} />
        <Box width='830px'>
          <StakingMyDev stakeAction={() => setShowStakeModal(true)} />
          <StakingMysDev />
          <AddOneStepStakingModalComponent />
          <StakeStore />
          <StakingMyStake />
        </Box>
      </Flex>
    </PageBody>
  </>
}
