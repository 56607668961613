import { Link } from 'react-router-dom'
import ButtonTwitter from 'components/ButtonTwitter/buttonTwitter.component'
import ButtonDiscord from 'components/ButtonDiscord/buttonDiscord.component'
import Modal from '../../Modal/Modal.component'
import './response-minter-modal.component.scss'

type ResponseModalArgs = {
  showModal: boolean,
  setShowModal: (showModal: boolean) => void,
  response: {
    message: string,
    type: string,
    paymentId?: string
  }
}

function ResponseMinterModal({ showModal, setShowModal, response }: ResponseModalArgs) {
  const closeModal = () => setShowModal(false)

  return (
    <Modal isActive={showModal} closeAction={closeModal} id="response-minter-modal">
      <div className="close btn btn-small btn-primary" onClick={() => setShowModal(false)}>&#x2715;</div>
      <h2 className= "response-minter-modal__title">
        {response.type === 'success' ? 'Success!' : 'Error'}
      </h2>
      <div className="response-minter-modal__message">
        <p>{response.message}</p>
        {response.type === 'error' && <p>Just try again or contact the Staff through our <a href="https://discord.com/invite/D6MN4DFP4n" target="_blank" rel="noreferrer">Discord</a> if the error persists.</p>}
        {response.paymentId && <div className="response-minter-modal__payment-id">{response.paymentId}</div>}
      </div >
      <div className="response-minter-modal__actions">
        <Link to="/purchases" className="btn btn-primary btn-medium my-purchases">My Purchases</Link>
        {response?.type === 'success' && (
          <ButtonTwitter tweet="I%20just%20bought%20some%20Boosters%20from%20the%20Promo%20Sale%20at%20deviantsfactions.com!%20%0A%0AWill%20you%20stay%20out%20of%20the%20TCG%20Conflict%20to%20shape%20History?%20%0A%0A%40DeviantsNFT" />
        )}
        { response?.type === 'error' && (
          <ButtonDiscord />
        )}
      </div>
    </Modal>
  )
}

export default ResponseMinterModal
