import './error.scss'

const ErrorIconAnimated = () => (<><svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle className="crossmark__circle " cx="26" cy="26" r="25" fill="none" />
    <path className="crossmark__cross" fill="none" d="M16,16 l20,20" />
    <path className="crossmark__cross " fill="none" d="M16,36 l20,-20" />
  </svg>
</>)

export default ErrorIconAnimated
