export default function OwnerSvg() {
  return (
    <div>
      <svg version="1.1" x="0px" y="0px"
        viewBox="0 0 640 640" enableBackground="new 0 0 640 640">
        <g>
          <path d="M319,589.5c-51.1,0-102.2,0-153.3,0c-1.7,0-2.6-0.5-3.3-1.9c-5.6-9.9-11.2-19.7-16.9-29.6c-5.5-9.5-11-18.9-16.5-28.4
    c-5.2-9-10.4-18.1-15.6-27.2c-4.2-7.3-8.5-14.6-12.7-21.9c-6.5-11.2-12.9-22.5-19.4-33.7c-5.9-10.3-11.9-20.5-17.9-30.8
    c-4.8-8.2-9.4-16.5-14.2-24.8c-5.3-9.2-10.7-18.5-16-27.7c-7-12.2-14-24.4-21-36.5c-0.9-1.6-1.8-3.2-2.8-4.7
    c-0.6-0.9-0.6-1.6,0-2.5c9.8-17,19.5-33.9,29.3-50.9c12.3-21.4,24.7-42.7,37.1-64.1c21.8-37.6,43.6-75.2,65.4-112.8
    c7.6-13.1,15.2-26.2,22.8-39.4c0.6-1.1,1.3-1.5,2.6-1.5c102.3,0,204.5,0,306.8,0c1,0,1.8,0.1,2.4,1.2c8.8,15.5,17.7,30.9,26.6,46.3
    c22.1,38.4,44.2,76.9,66.3,115.3c20,34.8,40,69.6,60.1,104.4c0.7,1.2,0.6,2,0,3.1c-48.9,85.1-97.8,170.3-146.7,255.5
    c-2.1,3.7-4.3,7.3-6.3,11.1c-0.7,1.2-1.4,1.6-2.8,1.6c-36.8,0-73.7,0-110.5,0C347.8,589.5,333.4,589.5,319,589.5z M515.1,446.2
    c0.1,0,0.2,0,0.4,0.1c0.2-0.3,0.5-0.6,0.7-1c9.2-16.2,18.5-32.3,27.7-48.5c14.3-25,28.5-50,42.8-74.9c1-1.7,1.3-2.8,0.1-4.8
    c-4.1-6.5-7.9-13.3-11.7-20c-4.2-7.3-8.4-14.6-12.6-21.9c-4.3-7.3-8.5-14.7-12.8-22c-7.7-13.1-15.4-26.3-23-39.4
    c-9.1-15.5-18-31.1-27.1-46.5c-15.1-25.7-30.2-51.4-43.9-77.9c-0.6-1.1-1.2-1.4-2.4-1.4c-43.6,0-87.3,0-130.9,0
    c-0.5,0-1,0.1-1.9,0.1c1.1,1,1.9,1.8,2.8,2.5c20.8,18.5,41.6,37.1,62.5,55.6c1.1,1,1.6,2,1.6,3.5c0,132.6,0,265.3,0,397.9
    c0,1,0,2,0,2.9c-0.1,1.5,0.5,2,2,2c17.9-0.1,35.8,0,53.6,0c2,0,2.3-0.3,2.3-2.3c0-15.1,0-30.1,0-45.2c0-96.9,0-193.7,0-290.6
    c0-0.7,0.1-1.4,0.2-2.5c0.8,0.5,1.3,0.7,1.7,1.1c22.2,19.7,44.3,39.4,66.5,59c1.2,1,1.7,2.1,1.7,3.7c0,56.3,0,112.6,0,168.8
    C515,445.1,515.1,445.7,515.1,446.2z M318,88.2c-1.2-0.1-1.9-0.1-2.5-0.1c-42.5,0-85,0-127.5,0c-1.7,0-2.8,0.7-3.6,2.1
    c-1.8,3.2-3.7,6.4-5.5,9.5c-10.7,18.4-21.4,36.8-32.1,55.3c-7.8,13.5-15.7,27-23.5,40.5c-4.6,7.8-9.1,15.7-13.6,23.5
    c-6.9,11.8-13.8,23.6-20.6,35.4c-3.9,6.7-7.7,13.5-11.6,20.2c-5.5,9.4-11,18.7-16.5,28c-3.2,5.5-6.4,11-9.5,16.5
    c-0.3,0.6-0.3,1.6,0,2.1c1.1,2.1,2.4,4.1,3.5,6.1c9.4,16.1,18.8,32.2,28.2,48.4c13.6,23.4,27.2,46.7,40.9,70.1
    c0.2,0.4,0.5,0.8,1.1,1.5c0-1.1,0-1.6,0-2.1c0-56.5,0-113,0-169.5c0-1.5,0.5-2.6,1.6-3.6c18.2-16.1,36.3-32.2,54.4-48.3
    c4.1-3.6,8.2-7.3,12.3-10.9c0.4-0.3,0.9-0.5,1.3-0.7c0.2,0.5,0.4,1,0.5,1.5c0,0.2,0,0.3,0,0.5c0,111.9,0,223.8,0,335.6
    c0,2.4,0.2,2.6,2.5,2.6c16.6,0,33.2,0,49.8,0c2.5,0,2.5,0,2.5-2.5c0-133.4,0-266.8,0-400.1c0-1.5,0.5-2.4,1.5-3.4
    c17.6-15.4,35.2-30.9,52.7-46.3C308.8,96.3,313.2,92.5,318,88.2z"/>
        </g>
      </svg>
    </div>
  )
}
