import Flex from 'components/Flex/Flex'
import './twitter-button.styles.scss'
import useTwitterLink from 'views/airdrop/hooks/useTwitterLink.hook'
import { useRecoilValueLoadable } from 'recoil'
import { profileSelector } from 'selectors/selectors'
import Text from 'components/Text/Text'
import XLogo from '../../assets/x.svg'

export default function TwitterButton() {
  const { link } = useTwitterLink()
  const { contents: profile, state } = useRecoilValueLoadable(profileSelector)

  const goToTwitterSignIn = () => {
    if (link && !profile.info?.twitterHandle) {
      window.location.href = link
    }
  }

  if (!link || state === 'loading') return <></>
  return <button className="twitter-button" onClick={() => goToTwitterSignIn()}>
    {!profile.info?.twitterHandle && <Flex flexDirection='row' gap='10px' alignItems='center'>
      <img src={XLogo} width={'24px'} alt="X Logo" />
      <Text>Connect with <b>X</b></Text>
    </Flex>}
    {profile.info?.twitterHandle && <Flex flexDirection='row' gap='10px' alignItems='center'>
      <img src={XLogo} width={'24px'} alt="X Logo" />
      <Flex flexDirection='column' gap="0.5ch" alignItems='flex-start'>
        <Text fontSize={'0.6rem'}>Connected as</Text>
        <Text fontSize={'0.75rem'} fontWeight={'bold'}>@{profile.info.twitterHandle}</Text>
      </Flex>
    </Flex>}
  </button>
}
