import './ButtonLoader.component.scss'

export default function ButtonLoader() {
  return (
  <div className="cssload-loader">
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
    <div className="cssload-side"></div>
  </div>
  )
}
