/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import { GetRecoilValue, selector, selectorFamily } from 'recoil'
import StakingRepository, { StakingPool } from 'repositories/staking.repository'
// eslint-disable-next-line import/extensions
import { connectedWallet } from './selectors'

export type SDevStoreItem = {
  id: string,
  name: string,
  art: string,
  cost: number,
  active: boolean
}

export const stakedDevPoolSelector = selector<StakingPool | undefined>({
  key: 'stakedDevPoolSelector',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return undefined
      const stakes = await StakingRepository.getStakedDev(address.address)
      return stakes
    } catch (err) {
      return undefined
    }
  },
})

export const sdevStoreSelector = selector<SDevStoreItem[]>({
  key: 'sdevStore',
  get: async () => {
    try {
      const store = await StakingRepository.getStore()
      return store.items
    } catch (err) {
      return []
    }
  },
})

export const sdevStoreLockTimeSelector = selectorFamily({
  key: 'sdevStoreLockTime',
  get: (itemId?: string | undefined) => async ({ get }: { get: GetRecoilValue }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return 0
      if (!itemId) return 0
      const store = await StakingRepository.getLockTime(itemId, address.address)
      return store.lockTime
    } catch (err) {
      return 0
    }
  },
})

export const yesterdayEarningsSdevSelector = selector<number>({
  key: 'yesterdayEarningsSdevSelector',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return 0
      const { earnings } = await StakingRepository.getYesterdaysEarnings(address.address)
      return earnings
    } catch (err) {
      return 0
    }
  },
})

export const getStakedDevSelector = selector<number>({
  key: 'getStakedDevSelector',
  get: async ({ get }) => {
    try {
      const address = get(connectedWallet)
      if (!address?.address) return 0
      const stakes = await StakingRepository.getStakedDevAmount(address.address)
      return stakes.stakedDev
    } catch (err) {
      return 0
    }
  },
})
