import Button from 'components/Button/Button.component'
import Flex from 'components/Flex/Flex'
import Input from 'components/Input/Input.component'
import Text from 'components/Text/Text'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useState } from 'react'
import useBalance from 'views/exchange/hooks/useBalance.hook'
import useDevDeposit from '../../hooks/useDevDeposit.hook'

export default function DepositDevModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState(0)
  const { balance: dev, refresh } = useBalance('dev')
  const { deposit } = useDevDeposit({
    onSuccess() {
      refresh()
      setIsOpen(false)
    },
  })

  const setMax = () => {
    setAmount(dev)
  }

  const executeDeposit = () => {
    deposit(amount)
  }

  const isValid = () => amount > 0 && amount <= dev

  return <>
    <Modal id='withdraw-dev' isActive={isOpen} closeAction={() => setIsOpen(false)}>
      <h2>Deposit DEV</h2>
      <Text fontSize={'1rem'} fontWeight={'500'} textAlign='center'>
        By performing this action the amount of <b> DEV </b>
        you indicate below will be transferred from your <b>IMX account</b> to your <b>game balance.</b>
      </Text>
      <Flex flexDirection='column' gap='0.4ch'>
        <Flex justifyContent='center' flexDirection='row' gap='1ch' style={{ width: '100%', height: '60px' }}>
          <Input min={0.1} step={0.1} value={amount} onChange={(e) => setAmount(e.target.valueAsNumber || 0)} max={dev || 0} type='number' placeholder='Amount' style={{ width: '200px', fontSize: '1.2rem', textAlign: 'center' }} />
          <Button onClick={() => setMax()} variant='ghost' size='medium' style={{ padding: '5px 25px' }}>MAX</Button>
        </Flex>
        <Text fontWeight={'bold'} fontSize='0.8rem'>IN WALLET: DEV {dev}</Text>
      </Flex>
      <Button disabled={!isValid()} style={{ width: '290px', height: '50px' }} variant='primary_alt' size='medium' onClick={() => executeDeposit()}>DEPOSIT</Button>
    </Modal>
    <Button variant='primary_alt' size='medium' onClick={() => setIsOpen(true)}>DEPOSIT</Button>
  </>
}
