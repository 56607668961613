import './preload-card-assets.scss'

import Mawaken from './assets/markup/awaken.png'
import Mconfig from './assets/markup/config.png'
import Mentropy from './assets/markup/entropy.png'
import Mfemale from './assets/markup/female.png'
import Mgroup from './assets/markup/group.png'
import Mhq from './assets/markup/hq.png'
import Minhuman from './assets/markup/inhuman.png'
import Mmale from './assets/markup/male.png'
import MnonBinary from './assets/markup/non-binary.png'
import Mowner from './assets/markup/owner.png'
import Mpromo from './assets/markup/promo.png'
import Muser from './assets/markup/user.png'

import Gfemale from './assets/gender/female.png'
import Gmale from './assets/gender/male.png'
import GnonBinary from './assets/gender/non-binary.png'

const preload:string[] = [
  '/images/card/character/atk/basic.png',
  '/images/card/character/atk/blast.png',
  '/images/card/character/atk/blast-l.png',
  '/images/card/character/atk/blast-r.png',
  '/images/card/character/atk/planning.png',
  '/images/card/character/atk/range-l.png',
  '/images/card/character/atk/range-r.png',
  '/images/card/character/color/awaken.png',
  '/images/card/character/color/entropy.png',
  '/images/card/character/color/inhuman.png',
  '/images/card/character/color/owner.png',
  '/images/card/character/color/undeviant.png',
  Gmale,
  Gfemale,
  GnonBinary,
  '/images/card/character/rarity/common.png',
  '/images/card/character/rarity/legendary.png',
  '/images/card/character/rarity/rare.png',
  '/images/card/character/rarity/uncommon.png',
  '/images/card/hq/color/awaken.png',
  '/images/card/hq/color/entropy.png',
  '/images/card/hq/color/inhuman.png',
  '/images/card/hq/color/owner.png',
  '/images/card/hq/rarity/common.png',
  '/images/card/hq/rarity/legendary.png',
  '/images/card/hq/rarity/rare.png',
  '/images/card/hq/rarity/uncommon.png',
  '/images/card/technology/color/awaken.png',
  '/images/card/technology/color/entropy.png',
  '/images/card/technology/color/inhuman.png',
  '/images/card/technology/color/owner.png',
  '/images/card/technology/color/undeviant.png',
  '/images/card/technology/rarity/common.png',
  '/images/card/technology/rarity/legendary.png',
  '/images/card/technology/rarity/rare.png',
  '/images/card/technology/rarity/uncommon.png',
  Mawaken,
  Mconfig,
  Mentropy,
  Mfemale,
  Mgroup,
  Mhq,
  Minhuman,
  Mmale,
  MnonBinary,
  Mowner,
  Mpromo,
  Muser,
]

export default function PreloadCardAssets() {
  return (
    <div className="preload-card-assets">
      {
        preload.map((p, i) => (
          <div key={`preload-${i}`} className="preload" style={{ backgroundImage: `url(${p})` }} />
        ))
      }
    </div>
  )
}
