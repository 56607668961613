/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import joji_ramenshack from '../../assets/images/branding/joji_ramenshack.png'
import china from '../../assets/images/branding/china.png'
import ngfowner from '../../assets/images/branding/ngfowner.png'
import deviants_rule from '../../assets/images/branding/deviants_rule.png'
import flo_pro from '../../assets/images/branding/flo_pro.png'
import lost_my_head_yesterday from '../../assets/images/branding/lost_my_head_yesterday.png'
import pizza from '../../assets/images/branding/pizza.png'
import shushing_face from '../../assets/images/branding/shushing_face.png'
import the_end_really_fking from '../../assets/images/branding/the_end_really_fking.png'
import empanada from '../../assets/images/branding/empanada.png'
import mauro_was_here from '../../assets/images/branding/mauro_was_here.png'
import letsgo from '../../assets/images/branding/letsgo.png'
import nihko from '../../assets/images/branding/nihko.png'

import './background-branding.scss'

export const BackgroundBrands = (
  { placement, height }:
    { placement?: any, height?: any },
) => {
  return <div className='background-brands'
    style={{
      [placement]: '10vh',
      height: height || '10vh',
    }}>
    {sprites.map((image: any, i) => <img key={i} style={{
      left: image.left,
      top: image.top,
      transform: `rotate(${image.rotate}deg)`,
    }} src={image.src}></img>)}
  </div>
}

const sprites = [
  {
    src: china, left: '10%', top: '0', rotate: 0,
  },
  {
    src: mauro_was_here, left: '25%', top: '100px', rotate: 0,
  },
  {
    src: letsgo, left: '2.5%', top: '120px', rotate: -35,
  },
  {
    src: nihko, left: '30%', top: '15px', rotate: 30,
  },
  {
    src: joji_ramenshack, left: '40%', top: '0px', rotate: 0,
  },
  {
    src: ngfowner, left: '55%', top: '5px', rotate: 0,
  },
  {
    src: deviants_rule, left: '62%', top: '-10px', rotate: 0,
  },
  {
    src: flo_pro, left: '67%', top: '50px', rotate: 0,
  },
  {
    src: lost_my_head_yesterday, left: '76%', top: '100px', rotate: 0,
  },
  {
    src: pizza, left: '1%', top: '35px', rotate: 0,
  },
  {
    src: shushing_face, left: '85%', top: '5px', rotate: 0,
  },
  {
    src: the_end_really_fking, left: '90%', top: '100px', rotate: 0,
  },
  {
    src: empanada, left: '95%', top: '-10px', rotate: 0,
  },
]
