import classNames from 'classnames'

import './button.component.scss'

type ButtonType = {
  children?: any;
  className?: string;
  variant: 'primary' | 'outline' | 'icon' | 'secondary' | 'regular' | 'cancel';
  size: 'small' | 'medium';
  left?: boolean;
  right?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  id?: string,
}

export default function Button({
  children,
  className,
  variant,
  size,
  left,
  right,
  disabled,
  onClick,
  id,
  ...props
}: ButtonType) {
  const buttonClass = classNames('btn', `btn-${variant}`, `btn-${size}`, `${className}`, {
    'btn-left': left,
    'btn-right': right,
    'btn-disabled': disabled,
  })

  return (
  <div className={`btn-shadow ${buttonClass}`}>
    <button {...props} disabled={disabled} className={buttonClass} onClick={onClick} id={id}>
      {children}
    </button>
  </div>
  )
}
