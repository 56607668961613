/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
import Button from 'components/Button/Button.component'
import Flex from 'components/Flex/Flex'
import Input from 'components/Input/Input.component'
import Text from 'components/Text/Text'
import { Modal } from 'components/deckbuilder/Modal/Modal.component'
import { useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { connectedWallet, getTodayWithdraw, profileBalancesSelector } from 'selectors/selectors'
import Toast from 'components/Toast/Toast.component'
import useDevWithdraw from '../../hooks/useDevWithdraw.hook'

export default function WithdrawDevModal() {
  const inGameDev = useRecoilValueLoadable(profileBalancesSelector).contents?.xdev || 0
  const user = useRecoilValueLoadable(connectedWallet).contents
  const todayDevWithdraw = useRecoilValueLoadable<{ withdraw: number, dailyLimits: number, available: number } | undefined>(getTodayWithdraw({ address: user?.address, asset: 'DEV' }))
  const [tx, setTx] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [amount, setAmount] = useState(0)
  const { withdraw, loading } = useDevWithdraw({
    onSuccess(txId: string) {
      setTx(txId)
      setIsOpen(false)
      setAmount(0)
    },
  })

  const executeWithdraw = () => {
    if (amount > (todayDevWithdraw?.contents?.available || 0) || amount < 1) return
    withdraw(amount)
  }

  const setMax = () => {
    setAmount((todayDevWithdraw?.contents?.available || 0))
  }

  const isValid = () => amount > 0 && amount <= (todayDevWithdraw?.contents?.available || 0)

  return <>
    <Toast id='withdraw-dev-toast' isActive={!!tx} duration={3000} onClose={() => setTx(null)}>
      <Flex flexDirection='column' gap="1ch">
        <Text fontWeight={'bold'} fontSize='0.8rem'>
          <Text fontWeight={'bold'} fontSize='0.8rem'>Withdrawal successful!</Text>
        </Text>
        <Text fontWeight={'bold'} fontSize='0.8rem'>TX: {tx}</Text>
      </Flex>
    </Toast>
    <Modal id="withdraw-dev" isActive={isOpen} closeAction={() => setIsOpen(false)}>
      <h2>Withdraw DEV</h2>
      <Text fontSize={'1rem'} fontWeight={'500'} textAlign='center'>
        By performing this action the amount of <b> DEV </b>
        you indicate below will be transferred from your <b> game balance</b> to your <b>IMX account.</b>
      </Text>
      <Flex flexDirection='column' gap="0.4ch">
        <Flex justifyContent='center' flexDirection='row' gap="1ch" style={{ width: '100%', height: '60px' }}>
          <Input step={0.1} value={amount} onChange={(e) => setAmount(e.target.valueAsNumber || 0)} max={inGameDev || 0} type='number' placeholder='Amount' style={{ width: '200px', fontSize: '1.2rem', textAlign: 'center' }} />
          <Button onClick={() => setMax()} variant='ghost' size='medium' style={{ padding: '5px 25px' }}>MAX</Button>
        </Flex>
        <Text fontWeight={'bold'} fontSize='0.8rem'>IN GAME: DEV {inGameDev}</Text>
        <Text fontWeight={'bold'} fontSize='0.8rem'>AVAILABLE TODAY: DEV {todayDevWithdraw?.contents?.available}</Text>
      </Flex>
      <Button disabled={loading || !isValid()} style={{ width: '290px', height: '50px' }} variant='primary_alt' size='medium' onClick={() => executeWithdraw()}>WITHDRAW</Button>
    </Modal>
    <Button disabled={inGameDev === 0} variant='primary_alt' size='medium' onClick={() => setIsOpen(true)}>WITHDRAW</Button>
  </>
}
